import { Td, Tr } from "@chakra-ui/react";
import { Price } from "api/prices/_types/Price";
import { useAuth } from "hooks/auth/useAuth";
import { captalize } from "utils/captalize";
import { IRequestedMaterialsProps } from "../ConsultPrices";

interface ConsultPriceTableRowProps {
  price: Price & {
    airPrices: {
      vehicle: string;
      price: number;
    }[];
    highwayPrices: {
      vehicle: string;
      price: number;
    }[];
  },
  materialsTotalCost: number
  priceGeloSeco: number
  materialsTotalPrice: number
  materialsToConsultTable: IRequestedMaterialsProps[]
  airTransferBudgetValue: number
  highwayTransferBudgetValue: number
  taxedWeight: number
}

interface VehiclePrice {
  vehicle: string
  price: number
}


function formatVehiclePrices(vehicle: string, prices: VehiclePrice[], shippingType: string, taxedWeight: number, extraKg: number) {
  const vehiclePrices = prices?.filter(price => price.vehicle === vehicle)

  const doesHasVehiclePrices = Boolean(vehiclePrices?.length)

  if (doesHasVehiclePrices) {
    return vehiclePrices?.map((price) => {

      if (taxedWeight > 2 && shippingType === 'air') {
        const totalPrice = (extraKg * (taxedWeight - 2)) + price.price
        return totalPrice
      }

      if (taxedWeight > 10 && shippingType === 'highway') {
        const totalPrice = (extraKg * (taxedWeight - 10)) + price.price
        return totalPrice
      }

      return price.price

    })
  }

  return []
}

export function ConsultPriceTableRow({
  price,
  materialsTotalCost,
  priceGeloSeco,
  materialsTotalPrice,
  materialsToConsultTable,
  airTransferBudgetValue,
  highwayTransferBudgetValue,
  taxedWeight
}: ConsultPriceTableRowProps) {

  const { userLogged } = useAuth()

  const isUserLoggedCustomer = userLogged?.user_type === 'CLIENTE' || userLogged?.user_type === 'REMETENTE'


  const doesExistMotorcycleHighwayPrices = Boolean(price.highwayPrices.find(
    (highwayPrice) => highwayPrice.price !== null && highwayPrice.vehicle === 'MOTO'
  ))

  const doesExistMotorcycleAirPrices = Boolean(price.airPrices.find(
    (airPrice) => airPrice.price !== null && airPrice.vehicle === 'MOTO'
  ))

  const doesExistCarAirPrices = Boolean(price.airPrices.find(
    (airPrice) => airPrice.price !== null && airPrice.vehicle === 'CARRO'
  ))

  const doesExistCarHighwayPrices = Boolean(price.highwayPrices.find(
    (highwayPrice) => highwayPrice.price !== null && highwayPrice.vehicle === 'CARRO'
  ))

  return (
    <Tr >
      <Td>
        {captalize(price.sourceCity.name)}
      </Td>
      <Td>
        {captalize(price.destinationCity.name)}
      </Td>
      <Td>{price.category}</Td>
      <Td>
        {doesExistMotorcycleAirPrices ? (
          formatVehiclePrices('MOTO', price.airPrices, 'air', taxedWeight, price.airExtraKg).map(
            (price) => {
              return new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              }).format(price + materialsTotalCost + priceGeloSeco)
            })
        ) : (
          '-'
        )}
      </Td>
      <Td>
        {doesExistCarAirPrices ? (
          formatVehiclePrices('CARRO', price.airPrices, 'air', taxedWeight, price.airExtraKg).map(
            (price) => {
              return new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              }).format(price + materialsTotalCost + priceGeloSeco)
            })
        ) : (
          '-'
        )}
      </Td>
      <Td>
        {price.airExtraKg !== null ? new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        }).format(price.airExtraKg) : '-'}
      </Td>
      <Td>
        {price.airDeadline !== null
          ? `D + ${price.airDeadline}`
          : '-'
        }
      </Td>
      <Td>
        {doesExistMotorcycleHighwayPrices ? (
          formatVehiclePrices('MOTO', price.highwayPrices, 'highway', taxedWeight, price.highwayExtraKg).map(
            (price) => {
              return new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              }).format(price + materialsTotalCost + priceGeloSeco)
            })
        ) : (
          '-'
        )}
      </Td>
      <Td>
        {doesExistCarHighwayPrices ? (
          formatVehiclePrices('CARRO', price.highwayPrices, 'highway', taxedWeight, price.highwayExtraKg).map(
            (price) => {
              return new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              }).format(price + materialsTotalCost + priceGeloSeco)
            })
        ) : (
          '-'
        )}
      </Td>
      <Td>
        {price.highwayExtraKg !== null
          ? price.highwayExtraKg
          : '-'}
      </Td>
      <Td>
        {price.highwayDeadline !== null
          ? `D + ${price.highwayDeadline}`
          : '-'
        }
      </Td>
      <Td>{
        new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        }).format(materialsTotalCost)
      }</Td>
      <Td>{
        new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        }).format(priceGeloSeco)
      }</Td>
      <Td>{
        new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        }).format(materialsTotalPrice)
      }</Td>
      {materialsToConsultTable?.map((material) => (
        <Td key={material.material}>
          {`R$ ${material.totalPrice}`}
        </Td>
      ))}
      {userLogged?.loglife_employee === true && !isUserLoggedCustomer && (
        <>
          <Td>
            {
              new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              }).format(airTransferBudgetValue)
            }
          </Td>
          <Td>
            {
              new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              }).format(highwayTransferBudgetValue)
            }
          </Td>
        </>
      )}

      <Td>
        {price?.shippings?.length !== 0 ? price?.shippings
          ?.map((shipping) => shipping?.shipping?.trading_name)
          .filter(Boolean)
          .join(', ')
          .split(', ')
          .map((name, index) => (
            <span key={index}>
              {name}
              {index !== price.shippings.length - 1 && ', '}
            </span>
          )) : '-'}
      </Td>
    </Tr>
  )
}
