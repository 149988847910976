import { createContext, ReactNode } from 'react'
import { api } from '../services/api'

import 'react-toastify/dist/ReactToastify.css'
import swal from 'sweetalert'
import { ShippingProps } from './ShippingContext'

interface BranchProviderProps {
  children: ReactNode
  pathname?: string
}

export interface BranchProps {
  id: string
  nickname: string
  shipping_id: string
  email: string
  hub_id: string
  cellphone: string
  telephone: string
  cep: string
  street: string
  number: string
  complement: string
  neighborhood: string
  city: string
  state: string
  business_open: string
  business_close: string
  saturday_open: string | null
  saturday_close: string | null
  sunday_open: string | null
  sunday_close: string | null
  open_on_holidays: string
  holiday_open: string | null
  holiday_close: string | null
  observation: string
  shippingIDBranch: ShippingProps
  hubIDBranch: {
    name: string
  }
  situation: string
}

type BranchInput = Omit<BranchProps, 'id' | 'shippingIDBranch' | 'hubIDBranch'>

interface BranchContextProps {
  createBranch: (branchInput: BranchInput) => Promise<boolean>
  deleteBranch: (branchId: string) => Promise<boolean | undefined>
  editBranch: (
    branchId: string,
    branchInput: BranchInput,
  ) => Promise<boolean | undefined>
}

export const BranchContext = createContext<BranchContextProps>(
  {} as BranchContextProps,
)

export function BranchProvider({ children, pathname }: BranchProviderProps) {
  // const [branches, setBranches] = useState<BranchProps[]>([])
  // const [isBranchEdited, setIsBranchEdited] = useState(false)
  // const [isBranchLoaded, setIsBranchLoaded] = useState(false)

  // const { userLogged } = useContext(AuthContext)

  // useEffect(() => {
  //   function run() {
  //     if (userLogged) {
  //       api.get("/branch").then(res => {
  //         if (res) {
  //           setBranches(res.data)
  //           setIsBranchLoaded(true)
  //         }
  //       })
  //     }
  //   }
  //   run()
  // }, [userLogged, isBranchEdited, branches.length, pathname])

  async function createBranch(branchInput: BranchInput) {
    try {
      await api.post('/branch', branchInput)
      setTimeout(() => {
        swal('Poof! A base de transportadora foi criada com sucesso!', {
          icon: 'success',
        })
      }, 1500)
      return true
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
      return false
    }
  }

  async function deleteBranch(branchId: string) {
    try {
      await api.delete(`/branch/${branchId}`)
      swal('Poof! A base de transportadora foi deletada com sucesso!', {
        icon: 'success',
      })
      return true
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
      return false
    }
  }

  async function editBranch(branchId: string, branchInput: BranchInput) {
    try {
      await api.put(`/branch/${branchId}`, branchInput)
      setTimeout(() => {
        swal('Poof! A base de transportadora foi atualizada com sucesso!', {
          icon: 'success',
        })
      }, 1500)
      return true
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
      return false
    }
  }

  return (
    <BranchContext.Provider value={{ createBranch, deleteBranch, editBranch }}>
      {children}
    </BranchContext.Provider>
  )
}
