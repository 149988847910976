import { Skeleton, Td, Tr } from "@chakra-ui/react";

export function PricesTableRowLoading() {
  return (
    <Tr>
      {Array.from({ length: 19 }).map((_, index) => {
        return (
          <Td key={String(index)}>
            <Skeleton h="20px" bg="gray.200" w="full" />
          </Td>
        )
      })}

    </Tr>
  )
}
