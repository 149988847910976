import { Button, Flex, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Spinner } from "@chakra-ui/react";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { PriceForm, PriceSchemaInputs } from "./PriceForm";
import { FormProvider, useForm } from "react-hook-form";
import { useToastify } from "hooks/toastify/useToastify";
import { editPrice } from "api/prices/editPrice";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getPrice } from "api/prices/getPrice";
import { useEffect } from "react";
import { useHub } from "hooks/hub/useHub";
import { useCity } from "hooks/city/useCity";
import { useShipping } from "hooks/shipping/useShipping";
import { statesWithUfList } from "utils/CustomLists/statesWithUfList";
import { transformStringToNumber } from "utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber";

interface EditPriceProps {
  onClose: () => void
  priceId: string
}

function checkIfValueIsBiggerThenZero(value: string, originalValue: string) {
  return transformStringToNumber(value) > 0 ? originalValue : ''
}

const editPriceSchema = yup.object().shape({
  situation: yup.string().required(),
  sourceCityUf: yup.string().required(),
  destinationCityUf: yup.string().required(),
  sourceCityId: yup.string().required(),
  sourceHubId: yup.string().required(),
  destinationCityId: yup.string().required(),
  destinationHubId: yup.string().required(),
  category: yup.string().required(),
  routeType: yup.string().required(),
  serviceType: yup.string().required(),
  airMinimumPrice: yup.string().when('routeType', {
    is: (value: string) => value === 'AMBOS' || value === 'AÉREO',
    then: yup
      .string()
      .transform(checkIfValueIsBiggerThenZero)
      .required(),
  }),
  airExtraKg: yup.string().when('routeType', {
    is: (value: string) => value === 'AMBOS' || value === 'AÉREO',
    then: yup
      .string()
      .transform(checkIfValueIsBiggerThenZero)
      .required(),
  }),
  airDeadline: yup
    .number()
    .nullable()
    .when('routeType', {
      is: (value: string) => value === 'AMBOS' || value === 'AÉREO',
      then: yup
        .number()
        .nullable()
        .required(),
    }),
  highwayMinimumPrice: yup.string().when('routeType', {
    is: (value: string) => value === 'AMBOS' || value === 'RODOVIÁRIO',
    then: yup
      .string()
      .transform(checkIfValueIsBiggerThenZero)
      .required(),
  }),
  highwayExtraKg: yup.string().when('routeType', {
    is: (value: string) => value === 'AMBOS' || value === 'RODOVIÁRIO',
    then: yup
      .string()
      .transform(checkIfValueIsBiggerThenZero)
      .required(),
  }),
  highwayDeadline: yup
    .number()
    .nullable()
    .when('routeType', {
      is: (value: string) => value === 'AMBOS' || value === 'RODOVIÁRIO',
      then: yup
        .number()
        .nullable()
        .required(),
    }),
  additionalCollectPrice: yup
    .string()
    .transform(checkIfValueIsBiggerThenZero)
    .required(),
  additionalDeliveryPrice: yup
    .string()
    .transform(checkIfValueIsBiggerThenZero)
    .required(),
  shippingsIds: yup.mixed().when('serviceType', {
    is: 'FRACIONADO',
    then: yup.array().min(1,).required(),
  }),
})

export function EditPrice({ onClose, priceId }: EditPriceProps) {
  const formMethods = useForm<PriceSchemaInputs>({
    resolver: yupResolver(editPriceSchema)
  })

  const { formState: { isSubmitting }, handleSubmit, setValue } = formMethods

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const {
    data: priceResult,
    isLoading: isLoadingPriceResult
  } = useQuery({
    queryKey: ['price', priceId],
    queryFn: () => getPrice({ priceId })
  })

  useEffect(() => {
    if (priceResult !== undefined) {
      setValue(
        'sourceCityUf',
        Object.entries(statesWithUfList)
          .filter(
            ([key, value]) => key.toUpperCase() === priceResult?.price?.sourceCity.state,
          )
          .map(([key, value]) => key)[0],
      );
      setValue('sourceCityId', priceResult?.price?.sourceCityId)
      setValue(
        'destinationCityUf',
        Object.entries(statesWithUfList)
          .filter(
            ([key, value]) =>
              key.toUpperCase() === priceResult?.price?.destinationCity.state,
          )
          .map(([key, value]) => key)[0],
      );
      setValue('destinationCityId', priceResult?.price?.destinationCityId)
      setValue('category', priceResult?.price?.category)
      setValue('routeType', priceResult?.price?.routeType)
      setValue(
        'airMinimumPrice',
        priceResult?.price?.airMinimumPrice !== null ? String(priceResult?.price?.airMinimumPrice) : '',
      )
      setValue(
        'airExtraKg',
        priceResult?.price?.airExtraKg !== null ? String(priceResult?.price?.airExtraKg) : '',
      )
      setValue(
        'airDeadline',
        priceResult?.price?.airDeadline !== null ? priceResult?.price?.airDeadline : null,
      )
      setValue(
        'highwayMinimumPrice',
        priceResult?.price?.highwayMinimumPrice !== null
          ? String(priceResult?.price?.highwayMinimumPrice)
          : '',
      )
      setValue(
        'highwayExtraKg',
        priceResult?.price?.highwayExtraKg !== null ? String(priceResult?.price?.highwayExtraKg) : '',
      )
      setValue(
        'highwayDeadline',
        priceResult?.price?.highwayDeadline !== null ? priceResult?.price?.highwayDeadline : null,
      )
      setValue('additionalCollectPrice', String(priceResult?.price?.additionalCollectPrice));
      setValue(
        'additionalDeliveryPrice',
        String(priceResult?.price?.additionalDeliveryPrice),
      )
      setValue('observation', priceResult?.price?.observation !== null ? priceResult?.price?.observation : '')
      setValue('vehicle', priceResult?.price?.vehicle !== null ? priceResult?.price?.vehicle : '')
      setValue('serviceType', priceResult?.price?.serviceType)
      setValue('situation', priceResult?.price?.situation)

      const shippings = priceResult?.price?.shippings.map(shipping => shipping.shippingId);

      setValue('shippingsIds', shippings ?? []);
    }
  }, [priceResult, setValue]);


  const {
    hubs: { data: hubs, isLoading: isHubsLoading },
  } = useHub(null, true)

  const {
    cities: { data: cities, isLoading: isCitiesLoading },
  } = useCity(null, true)

  const {
    shipping: { data: shippings, isLoading: isShippingLoading },
  } = useShipping(null, true, false)

  const { mutateAsync: editPriceFn } = useMutation({
    mutationFn: editPrice,
    onSuccess(_data, _variables, _context) {

      queryClient.invalidateQueries({ queryKey: 'prices' })
      onClose()
    }
  })

  async function handleEditPrice(values: PriceSchemaInputs) {
    await promiseMessage(editPriceFn({
      body: {
        ...values,
        additionalCollectPrice: transformStringToNumber(values.additionalCollectPrice),
        additionalDeliveryPrice: transformStringToNumber(values.additionalDeliveryPrice),
        airExtraKg: transformStringToNumber(values.airExtraKg),
        airMinimumPrice: transformStringToNumber(values.airMinimumPrice),
        highwayExtraKg: transformStringToNumber(values.highwayExtraKg),
        highwayMinimumPrice: transformStringToNumber(values.highwayMinimumPrice)
      },
      priceId
    }), 'Preço editado com sucesso!')
  }

  const priceShippings = priceResult?.price?.shippings.map(shipping => shipping.shippingId)

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">
        Editar Preço
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody
        as="form"
        maxH='600px'
        overflowY='scroll'
        onSubmit={handleSubmit(handleEditPrice)}
      >
        {(isCitiesLoading || isHubsLoading || isShippingLoading || isLoadingPriceResult) ? (
          <Spinner />
        ) : (
          <FormProvider {...formMethods}>
            <PriceForm
              slug="edit"
              cities={cities}
              hubs={hubs}
              shippings={shippings}
              defaultCheckedOptionsShippings={priceShippings}
            />
          </FormProvider>
        )}
        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Editar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}
