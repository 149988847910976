import { Flex, Icon, IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure, Link as ChakraLink, } from "@chakra-ui/react";
import { Interactions } from "components/Interactions/Interactions";
import { BranchProps } from "contexts/BranchContext";
import { OccurrenceProps } from "contexts/OccurrenceContext";
import { ServiceProps } from "contexts/ServiceContext";
import { differenceInMinutes, format, isBefore, isSameDay, set } from "date-fns";
import { Address } from "hooks/address/dtos/Address";
import { useAuth } from "hooks/auth/useAuth";
import { interactionStatusColors } from "pages/Dashboard/Llm/components/DelayedCollects";
import { FaClock, FaPeopleArrows } from "react-icons/fa";
import { FiEdit3, FiEye } from "react-icons/fi";
import { Link } from "react-router-dom";
import { formatDate } from "utils/DateFunctions/formatDate";

interface AllocatedServiceTableRowProps {
  service: ServiceProps
  branches: BranchProps[]
  addresses: Address[]
}
export function AllocatedServiceTableRow({ service, branches, addresses }: AllocatedServiceTableRowProps) {

  const { userLogged } = useAuth()
  const isLLMUser = userLogged.loglife_employee

  const { isOpen, onOpen, onClose } = useDisclosure();

  const sourceCrossdockingBranchID =
    service.serviceIDRequested.source_crossdocking_branch_id

  const sourceBranch = sourceCrossdockingBranchID
    ? service.serviceIDBoard.find(
      (boardService) =>
        boardService.collector_id ===
        service.serviceIDRequested
          .crossdocking_collector_id,
    ) !== undefined
      ? service.serviceIDLanding.find(
        (landingService) =>
          landingService.collector_id ===
          service.serviceIDRequested
            .crossdocking_collector_id,
      ) !== undefined
        ? String(
          branches
            .filter(
              (branch) =>
                branch.id ===
                service.serviceIDRequested
                  .source_crossdocking_branch_id,
            )
            .map((branch) => branch.nickname),
        )
        : String(
          branches
            .filter(
              (branch) =>
                branch.id ===
                service.serviceIDRequested
                  .source_branch_id,
            )
            .map((branch) => branch.nickname),
        )
      : String(
        branches
          .filter(
            (branch) =>
              branch.id ===
              service.serviceIDRequested.source_branch_id,
          )
          .map((branch) => branch.nickname),
      )
    : String(
      branches
        .filter(
          (branch) =>
            branch.id ===
            service.serviceIDRequested.source_branch_id,
        )
        .map((branch) => branch.nickname),
    )

  const destinationBranch = sourceCrossdockingBranchID
    ? service.serviceIDBoard.find(
      (boardService) =>
        boardService.collector_id ===
        service.serviceIDRequested
          .crossdocking_collector_id,
    ) !== undefined
      ? service.serviceIDLanding.find(
        (landingService) =>
          landingService.collector_id ===
          service.serviceIDRequested
            .crossdocking_collector_id,
      ) !== undefined
        ? String(
          branches
            .filter(
              (branch) =>
                branch.id ===
                service.serviceIDRequested
                  .destination_branch_id,
            )
            .map((branch) => branch.nickname),
        )
        : String(
          branches
            .filter(
              (branch) =>
                branch.id ===
                service.serviceIDRequested
                  .destination_crossdocking_branch_id,
            )
            .map((branch) => branch.nickname),
        )
      : String(
        branches
          .filter(
            (branch) =>
              branch.id ===
              service.serviceIDRequested
                .destination_crossdocking_branch_id,
          )
          .map((branch) => branch.nickname),
      )
    : String(
      branches
        .filter(
          (branch) =>
            branch.id ===
            service.serviceIDRequested
              .destination_branch_id,
        )
        .map((branch) => branch.nickname),
    )

  const shipping = sourceCrossdockingBranchID
    ? service.serviceIDBoard.find(
      (boardService) =>
        boardService.collector_id ===
        service.serviceIDRequested
          .crossdocking_collector_id,
    ) !== undefined
      ? service.serviceIDLanding.find(
        (landingService) =>
          landingService.collector_id ===
          service.serviceIDRequested
            .crossdocking_collector_id,
      ) !== undefined
        ? String(
          branches
            .filter(
              (branch) =>
                branch.id ===
                service.serviceIDRequested
                  .source_crossdocking_branch_id,
            )
            .map(
              (branch) =>
                branch.shippingIDBranch.company_name,
            ),
        )
        : String(
          branches
            .filter(
              (branch) =>
                branch.id ===
                service.serviceIDRequested
                  .source_branch_id,
            )
            .map(
              (branch) =>
                branch.shippingIDBranch.company_name,
            ),
        )
      : String(
        branches
          .filter(
            (branch) =>
              branch.id ===
              service.serviceIDRequested.source_branch_id,
          )
          .map(
            (branch) =>
              branch.shippingIDBranch.company_name,
          ),
      )
    : String(
      branches
        .filter(
          (branch) =>
            branch.id ===
            service.serviceIDRequested.source_branch_id,
        )
        .map(
          (branch) =>
            branch.shippingIDBranch.company_name,
        ),
    )

  const operationalNumber = sourceCrossdockingBranchID
    ? service.serviceIDBoard.find(
      (boardService) =>
        boardService.collector_id ===
        service.serviceIDRequested
          .crossdocking_collector_id,
    ) !== undefined
      ? service.serviceIDLanding.find(
        (landingService) =>
          landingService.collector_id ===
          service.serviceIDRequested
            .crossdocking_collector_id,
      ) !== undefined
        ? String(
          service.serviceIDBoard
            .filter(
              (boardService) =>
                boardService.branch_id ===
                service.serviceIDRequested
                  .source_crossdocking_branch_id,
            )
            .map(
              (boardService) =>
                boardService.operational_number,
            ),
        )
        : String(
          service.serviceIDBoard
            .filter(
              (boardService) =>
                boardService.branch_id ===
                service.serviceIDRequested
                  .source_branch_id,
            )
            .map(
              (boardService) =>
                boardService.operational_number,
            ),
        )
      : String(
        service.serviceIDBoard
          .filter(
            (boardService) =>
              boardService.branch_id ===
              service.serviceIDRequested.source_branch_id,
          )
          .map(
            (boardService) =>
              boardService.operational_number,
          ),
      )
    : String(
      service.serviceIDBoard
        .filter(
          (boardService) =>
            boardService.branch_id ===
            service.serviceIDRequested.source_branch_id,
        )
        .map(
          (boardService) =>
            boardService.operational_number,
        ),
    )

  const trackerLink = sourceCrossdockingBranchID
    ? service.serviceIDBoard.find((boardService) =>
      boardService.collector_id ===
      service.serviceIDRequested.crossdocking_collector_id
    )
      ? service.serviceIDLanding.find((landingService) =>
        landingService.collector_id ===
        service.serviceIDRequested.crossdocking_collector_id
      )
        ? String(
          service.serviceIDBoard
            .filter(
              (boardService) =>
                boardService.branch_id ===
                service.serviceIDRequested.source_crossdocking_branch_id
            )
            .map((boardService) => boardService.tracker_link)
        ) || null
        : String(
          service.serviceIDBoard
            .filter(
              (boardService) =>
                boardService.branch_id ===
                service.serviceIDRequested.source_branch_id
            )
            .map((boardService) => boardService.tracker_link)
        ) || null
      : null
    : String(
      service.serviceIDBoard
        .filter(
          (boardService) =>
            boardService.branch_id ===
            service.serviceIDRequested.source_branch_id
        )
        .map((boardService) => boardService.tracker_link)
    ) || null;

  const boardVolume = sourceCrossdockingBranchID
    ? service.serviceIDBoard.find(
      (boardService) =>
        boardService.collector_id ===
        service.serviceIDRequested
          .crossdocking_collector_id,
    ) !== undefined
      ? service.serviceIDLanding.find(
        (landingService) =>
          landingService.collector_id ===
          service.serviceIDRequested
            .crossdocking_collector_id,
      ) !== undefined
        ? String(
          service.serviceIDBoard
            .filter(
              (boardService) =>
                boardService.branch_id ===
                service.serviceIDRequested
                  .source_crossdocking_branch_id,
            )
            .map(
              (boardService) => boardService.board_volume,
            ),
        )
        : String(
          service.serviceIDBoard
            .filter(
              (boardService) =>
                boardService.branch_id ===
                service.serviceIDRequested
                  .source_branch_id,
            )
            .map(
              (boardService) => boardService.board_volume,
            ),
        )
      : String(
        service.serviceIDBoard
          .filter(
            (boardService) =>
              boardService.branch_id ===
              service.serviceIDRequested.source_branch_id,
          )
          .map(
            (boardService) => boardService.board_volume,
          ),
      )
    : String(
      service.serviceIDBoard
        .filter(
          (boardService) =>
            boardService.branch_id ===
            service.serviceIDRequested.source_branch_id,
        )
        .map((boardService) => boardService.board_volume),
    )

  const deliveryDelayIntercurrences = [
    "ATRASO NA ENTREGA",
    "CANCELAMENTO DE VOO",
    "CORTE DE VOO (NÃO ALOCADO VOO PLANEJADO)",
    "ATRASO NA LIBERAÇÃO"
  ]

  const serviceDeliveryDelay = service.occurrenceIDService
    ?.filter(occurrence =>
      deliveryDelayIntercurrences.includes(occurrence.intercurrence) && occurrence.proceed === "SIM")
    ?.reduce((acc, curr) => new Date(acc.createdAt) > new Date(curr.createdAt) ? acc : curr, {} as OccurrenceProps)

  const hasDeliveryDelay = serviceDeliveryDelay?.id ? true : false

  const deliveryDate = serviceDeliveryDelay?.occurrence_date
    ? formatDate.handle(serviceDeliveryDelay.occurrence_date, "DateWithoutHourToShow")
    : formatDate.handle(service.serviceIDRequested.delivery_date, "DateWithoutHourToShow")

  const deliveryHour = serviceDeliveryDelay?.occurrence_hour
    ? formatDate.handle(serviceDeliveryDelay.occurrence_hour, "DateOnlyWithHourMinute")
    : formatDate.handle(service.serviceIDRequested.delivery_hour, "DateOnlyWithHourMinute")

  const isCrossdockingService = service.serviceIDRequested.crossdocking_collector_id

  const plannedFlight = isCrossdockingService
    ? (service?.serviceIDBoard?.find(board => board.collector_id === isCrossdockingService)
      ? service?.serviceIDRequested?.planned_flight
      : service?.serviceIDRequested?.crossdocking_planned_flight)
    : service?.serviceIDRequested?.planned_flight

  const isCrossdockingAllocation = isCrossdockingService ?
    !service.serviceIDAllocate.find(allocate => {
      return allocate.collector_id === isCrossdockingService
    })
    : false

  const availabilityForecastDate = isCrossdockingAllocation
    ? service?.serviceIDRequested.crossdocking_availability_forecast_day
    : service?.serviceIDRequested.availability_forecast_day

  const availabilityForecastHour = isCrossdockingAllocation
    ? service?.serviceIDRequested.crossdocking_availability_forecast_time
    : service?.serviceIDRequested.availability_forecast_time




  let isServiceDelayed = false

  if (isCrossdockingService) {

    const isBoardDateBeforeToToday = isBefore(
      new Date(service.serviceIDRequested.board_date),
      new Date()
    )

    const isBoardDateSameToToday = isSameDay(
      new Date(service.serviceIDRequested.board_date),
      new Date()
    )

    if (!isBoardDateSameToToday && isBoardDateBeforeToToday) {
      isServiceDelayed = true
    } else if (isBoardDateSameToToday) {
      const [boardHours, boardMinutes] = format(new Date(service.serviceIDRequested.board_hour), "HH:mm").split(':').map(Number)

      isServiceDelayed = differenceInMinutes(
        set(new Date(), {
          hours: boardHours,
          minutes: boardMinutes
        }), new Date()
      ) < 240
    }
  }
  return (
    <Tr
    color={hasDeliveryDelay ? "yellow.400" : ''}
  >
    <Td>
      <Flex align='baseline' gap={2}>
        {isServiceDelayed && (
          <Icon as={FaClock} color={'red.500'} />
        )}
        {isLLMUser && (
          <Flex h="full" justify="flex-end" align="flex-end">
          <IconButton
            aria-label="Abrir Interações"
            icon={<FaPeopleArrows />}
            colorScheme={interactionStatusColors[service.last_interaction_status] || interactionStatusColors.default}
            size="sm"
            onClick={onOpen}
            variant='ghost'

          />
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />

            <Interactions isOpen={isOpen} serviceId={service.id} />
          </Modal>

        </Flex>
        )}
      </Flex>
    </Td>
    <Td>
      {!!userLogged && (
        <>
          {userLogged.permissions.includes(
            'add-allocated-service',
          ) && (
              <Link to={`/servicos/alocar/${service.id}`}>
                <Icon
                  cursor="pointer"
                  as={FiEdit3}
                  fontSize="20"
                  mt={['2', '2', '0']}
                />
              </Link>
            )}
          <Link to={`/servico/detalhes/${service.id}`}>
            <Icon
              cursor="pointer"
              as={FiEye}
              fontSize="20"
            />
          </Link>
        </>
      )}
    </Td>
    <Td fontSize="12">{service.protocol}</Td>
    <Td fontSize="12">{deliveryDate}</Td>
    <Td fontSize="12">{deliveryHour}</Td>
    <Td fontSize="12">
      {shipping.toString().toUpperCase()}
    </Td>
    <Td fontSize="12">{
      trackerLink ?
        <ChakraLink href={trackerLink} isExternal>
          {operationalNumber}
        </ChakraLink>
        : operationalNumber}</Td>
    <Td fontSize="12">
      {sourceBranch.toString().toUpperCase()}
    </Td>
    <Td fontSize="12">
      {destinationBranch.toString().toUpperCase()}
    </Td>
    <Td fontSize="12">
      {plannedFlight.toString().toUpperCase() ?? '-'}
    </Td>
    <Td fontSize="12">
      {formatDate.handle(availabilityForecastDate, 'DateWithoutHourToShow')}  -  {formatDate.handle(availabilityForecastHour, 'DateOnlyWithHourMinute')}
    </Td>
    <Td fontSize="12">
      {service.customerIDService.trading_firstname.toUpperCase()}
    </Td>
    <Td fontSize="12">{boardVolume}</Td>
    <Td fontSize="12">
      {Array.from(
        new Set(
          addresses
            .filter((address) =>
              service.serviceIDRequested.source_address_id.includes(
                address.id,
              ),
            )
            .map((address) => address.cityIDAddress.name),
        ),
      )
        .join(', ')
        .toUpperCase()}
    </Td>
    <Td fontSize="12">
      {Array.from(
        new Set(
          addresses
            .filter((address) =>
              service.serviceIDRequested.destination_address_id.includes(
                address.id,
              ),
            )
            .map((address) => address.cityIDAddress.name),
        ),
      )
        .join(', ')
        .toUpperCase()}
    </Td>
  </Tr>
  )
}
