import { Box, FormControl, FormLabel, Input, InputGroup, InputLeftAddon, Text } from "@chakra-ui/react";
import AsyncSelect from "react-select/async"
import * as yup from "yup"
import { Controller, useFormContext } from "react-hook-form";
import { captalize } from "utils/captalize";
import { useCurrency } from "react-hook-currency";
import { Collector } from "hooks/collector/dtos/Collector";

interface SpecialBudgetCostFormProps {
  collectors: Collector[]
}

export interface SpecialBudgetCostSchema {
  valueIncents: string
  collectorsIds: string[]
}

export const specialBudgetCostSchema = yup.object({
  valueIncents: yup.string().required(),
  collectorsIds: yup.array().required(),
})

export function SpecialBudgetCostForm({ collectors }: SpecialBudgetCostFormProps) {

  const {
    format: currencyFormat, onChange: onCurrencyChange,
  } = useCurrency({
    style: 'decimal'
  })

  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<SpecialBudgetCostSchema>()


  async function promiseOptions(inputValue: string): Promise<Array<{ value: string, label: string }>> {
    return collectors?.filter(collector => `${collector.trading_name}`.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())).map(collector => {
      return {
        value: collector?.id,
        label: captalize(collector.trading_name),
      }
    })
  }

  return (
    <Box w='full'>
      <FormControl isInvalid={!!errors?.collectorsIds}>
        <FormLabel fontSize="sm">
          Coletador(es)
        </FormLabel>
        <Controller
          control={control}
          name="collectorsIds"
          render={({ field }) => (
            <AsyncSelect
              name={field.name}
              onChange={(arr) => field.onChange(arr?.map(v => v.value))}
              cacheOptions
              defaultOptions
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  padding: '0px',
                  minHeight: '20px',
                  height: '32px',
                  borderRadius: '6px',
                  border: state.isFocused ? 'none' : '',
                  borderColor: 'rgba(0, 0, 0, 0.09)',
                  fontSize: '14px',
                  fontWeight: 'normal',
                  boxShadow: state.isFocused ? '0 0 0 2px #38c3fa' : '',
                }),
                valueContainer: (baseStyles) => ({
                  ...baseStyles,
                  padding: '0px 8px'
                }),
                input: (baseStyles) => ({
                  ...baseStyles,
                  margin: '0px',
                  padding: '0px',
                  height: '100%',
                }),
                multiValue: (baseStyles) => ({
                  ...baseStyles,
                  margin: '2px'
                }),
                dropdownIndicator: (baseStyles) => ({
                  ...baseStyles,
                  padding: '4px'
                }),
                clearIndicator: (baseStyles) => ({
                  ...baseStyles,
                  padding: '4px'
                }),
                placeholder: (baseStyles) => ({
                  ...baseStyles,
                  color: 'black'
                })
              }}
              placeholder="Coletadores..."
              loadOptions={promiseOptions}
              isClearable={true}
              isMulti
            />
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.valueIncents} mt={3}>
        <FormLabel fontSize="sm">
          Valor
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="valueIncents"
          control={control}
          render={({ field }) => {
            return (
              <InputGroup
                size="sm"
              >
                <InputLeftAddon
                  borderTopLeftRadius="md"
                  borderBottomLeftRadius="md"
                >
                  R$
                </InputLeftAddon>
                <Input
                  {...register("valueIncents")}
                  size="sm"
                  rounded="md"
                  w="full"
                  name={field.name}
                  value={field.value}
                  defaultValue={currencyFormat('000')}
                  onChange={e => field.onChange(onCurrencyChange(e))}
                />
              </InputGroup>
            )
          }}
        />
      </FormControl>
    </Box>
  )
}
