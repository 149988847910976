import { Grid, GridItem, } from "@chakra-ui/react"
import { getDelayedCollects } from "api/service/getDelayedCollects"

import { InfoCardsLoading } from "pages/Dashboard/components/InfoCardsLoading"
import { useEffect } from "react"

import { useQuery } from "react-query"

import { DeleyedCollectCard } from "./DeleyedCollectCard"

interface DelayedCollectsProps {
  onSetIsError: () => void
}

export const interactionStatusColors = {
  'without-return': 'red',
  'awaiting-return': 'yellow',
  default: 'blue'
};

export function DelayedCollects({ onSetIsError }: DelayedCollectsProps) {



  const {
    data: result,
    isError,
    isLoading: isLoadingDelayedCollectsResult
  } = useQuery({
    queryKey: ['delayed-collects'],
    queryFn: getDelayedCollects,
    refetchInterval: 1000 * 60 * 2, // 2 minutes,
    refetchIntervalInBackground: true,
  })

  useEffect(() => {
    if (isError) onSetIsError()
  }, [isError, onSetIsError])



  return (
    <Grid
      gap="6"
      templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(1, 1fr)", xl: "repeat(2, 1fr)" }}
    >
      {isLoadingDelayedCollectsResult && (
        <InfoCardsLoading />
      )}
      {result?.services?.map((service) => {
        return (
          <GridItem
            key={service.id}
          >
            <DeleyedCollectCard
              service={service}
            />
          </GridItem>
        )
      })}

    </Grid>
  )
}
