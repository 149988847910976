import { Alert, AlertDescription, AlertIcon, AlertTitle, Divider, Flex, GridItem, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Skeleton, Text } from "@chakra-ui/react";
import { getInteractionsByService } from "api/service/interactions/getInteractionsByService";
import { InteractionTypes } from "api/service/interactions/interaction";
import { format } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { useQuery } from "react-query";
import { captalize } from "utils/captalize";
import { CreateInteractionForm } from "./CreateInteractionForm";

export interface InteractionsProps {
  serviceId: string
  isOpen: boolean
}

const switchInteractionDetailBgColor = (interactionType: InteractionTypes) => {
  switch (interactionType) {
    case 'awaiting-return':
      return 'yellow.100'
    case 'awaiting-update':
      return 'green.100'
    case 'without-return':
      return 'red.100'
    default:
      return 'gray.200'
  }
}

export function Interactions({ serviceId, isOpen = false }: InteractionsProps) {
  const { userLogged } = useAuth()

  const {
    data: interactionsResult,
    isLoading: isLoadingInteractionsResult
  } = useQuery({
    queryKey: ['interactions', serviceId],
    queryFn: () => getInteractionsByService({
      routeParams: { serviceId }
    }),
    enabled: isOpen
  })

  return (
    <ModalContent
      minW="400px"
      maxH="350px"
      overflowY="auto"
    >
      <ModalHeader>
        Interações
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        {isLoadingInteractionsResult && (
          Array.from({ length: 3 }).map((_, index) => {
            return (
              <GridItem key={index} >
                <Skeleton rounded="md" mb={4} startColor="gray.300" endColor="gray.500" h={10} w="full" />
              </GridItem>
            )
          })
        )}
        {interactionsResult?.interactions?.length === 0 ? (
          <Alert
            status="info"
            variant="subtle"
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            textAlign='center'
            height='150px'
            rounded="lg"
          >
            <AlertIcon boxSize="40px" />
            <AlertTitle mt='4' mb='1' fontSize='md'>
              O serviço ainda não possui interações!
            </AlertTitle>
            <AlertDescription maxWidth='sm'>
              Parece que o serviço ainda não possui interações.
            </AlertDescription>
          </Alert>
        ) : (
          <Flex
            direction="column"
            gap="3"
            maxH="400px"
            overflowY="scroll"
          >
            {interactionsResult?.interactions?.map((interaction) => {
              return (
                <Flex
                  p="3"
                  align="center"
                  gap="4"
                  rounded="md"
                  key={interaction.id}
                  fontSize="xs"
                  bgColor={switchInteractionDetailBgColor(interaction.type)}
                >
                  <Text as="b">{format(new Date(interaction.created_at), 'dd/MM')}</Text>
                  <Text as="b">{format(new Date(interaction.created_at), 'HH:mm')}</Text>
                  <Text flex="1">{interaction.description}</Text>
                  <Text as="b">{captalize(`${interaction.owner.firstname} ${interaction.owner.lastname}`)}</Text>
                </Flex>
              )
            })}
          </Flex>
        )}

        <Divider my="6" />

        {userLogged?.user_type.includes('SUCESSO DO CLIENTE') && (
          <CreateInteractionForm serviceId={serviceId} />
        )}
      </ModalBody>
    </ModalContent>
  )
}
