import { Button, Flex, Icon, IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react"
import { Interactions } from "components/Interactions/Interactions"
import { differenceInMinutes, set } from "date-fns"
import { useAuth } from "hooks/auth/useAuth"
import { Occurrence } from "hooks/occurrences/dtos/Occurrence"
import { Services } from "hooks/services/dtos/InDeliveryService"
import { interactionStatusColors } from "pages/Dashboard/Llm/components/DelayedCollects"
import { FaExclamation, FaEye, FaPen, FaPeopleArrows } from "react-icons/fa"
import { Link } from "react-router-dom"
import { formatDate } from "utils/DateFunctions/formatDate"

interface InDeliveryServiceTableRowProps {
  service: Services
}

function compareDeliveryDateWithDateNow(
  deliveryDate: string,
  deliveryHour: string
) {
  const [collectEndHours, collectEndMinutes] = formatDate
    .handle(deliveryHour, 'DateOnlyWithHourMinute').split(':')


  const differenceToNow = differenceInMinutes(
    new Date(),
    set(new Date(deliveryDate), {
      hours: Number(collectEndHours),
      minutes: Number(collectEndMinutes)
    })
  )

  return differenceToNow > 0
}

export function InDeliveryServiceTableRow({ service }: InDeliveryServiceTableRowProps) {
  const { userLogged } = useAuth()
  const isLLMUser = userLogged.loglife_employee

  const { isOpen, onOpen, onClose } = useDisclosure();

  const deliveryDelayOccurrences = [
    'ATRASO NA ENTREGA',
    'CANCELAMENTO DE VOO',
    'CORTE DE VOO (NÃO ALOCADO VOO PLANEJADO)',
    'ATRASO NA TRANSFERÊNCIA',
    'ATRASO NA LIBERAÇÃO'
  ]

  const serviceDeliveryDelayOccurrence =
    service.occurrenceIDService
      .filter(occurrence => deliveryDelayOccurrences.includes(occurrence.intercurrence))
      .reduce((acc, curr) => {
        return new Date(acc.createdAt) > new Date(curr.createdAt) ? acc : curr
      }, {} as Occurrence)

  const deliveryDate = serviceDeliveryDelayOccurrence?.occurrence_date
    ? formatDate.handle(serviceDeliveryDelayOccurrence.occurrence_date, "DateWithoutHourToShow")
    : formatDate.handle(service.serviceIDRequested.delivery_date, "DateWithoutHourToShow")

  const deliveryHour = serviceDeliveryDelayOccurrence?.occurrence_hour
    ? formatDate.handle(serviceDeliveryDelayOccurrence.occurrence_hour, "DateOnlyWithHourMinute")
    : formatDate.handle(service.serviceIDRequested.delivery_hour, "DateOnlyWithHourMinute")

  let serviceHasPendingDeliveryAddressesToFinishOrStart = true

  const hasSomeDeliveryServiceInProggress = service.serviceIDDelivery
    .find(delivery => delivery.step === 'GOING')

  if (
    !hasSomeDeliveryServiceInProggress &&
    service.serviceIDRequested.destination_address_id.length ===
    service.serviceIDDelivery.length
  ) {
    serviceHasPendingDeliveryAddressesToFinishOrStart = false
  }

  const serviceHasPendingNewDeliveryAddressesToValidate =
    service.serviceIDRequested.additional_delivery_addresses.length > 0

  const deliveryAssignedDrivers = service.deliveryDriversAssigned
    ? Array.from(new Set(service.deliveryDriversAssigned))
      .map((assignment) => `${assignment.driver.firstname}
      ${assignment.driver.lastname}`)
      .join(', ')
    : ''

  const userHasStartDeliveryServicePermission =
    userLogged?.permissions.includes('add-delivery-service')

  const userHasValidateNewDeliveryServiceAddressesPermission =
    userLogged?.permissions.includes('validate-new-delivery-service-addresses')


  return (
    <Tr
      color={!!serviceDeliveryDelayOccurrence.id
        ? 'yellow.300'
        : compareDeliveryDateWithDateNow(
          service.serviceIDRequested.delivery_date,
          service.serviceIDRequested.delivery_hour
        )
          ? 'red.300'
          : ''
      }
    >
      <Td>
        <Flex align='baseline' gap={2}>
          {service?.serviceIDRequested?.budgetIDService?.priority_budget && (
            <Icon as={FaExclamation} color={'red.500'} />
          )}
          {isLLMUser && (
            <Flex h="full" justify="flex-end" align="flex-end">
              <IconButton
                aria-label="Abrir Interações"
                icon={<FaPeopleArrows />}
                colorScheme={interactionStatusColors[service.last_interaction_status] || interactionStatusColors.default}
                size="sm"
                onClick={onOpen}
                variant='ghost'

              />
              <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />

                <Interactions isOpen={isOpen} serviceId={service.id} />
              </Modal>

            </Flex>
          )}
        </Flex>
      </Td>
      <Td>
        <Flex>
          {
            userHasStartDeliveryServicePermission &&
            serviceHasPendingDeliveryAddressesToFinishOrStart &&
            service.step !== 'toValidateAdditionalDeliveryAddresses' && (
              <Button
                as={Link}
                to={`/servicos/entregas/${service.id}`}
                variant='unstyled'
              >
                <Flex w='full' h='full' align='center' justify='center'>
                  <Icon as={FaPen} />
                </Flex>
              </Button>
            )}
          <Button
            as={Link}
            to={`/servico/detalhes/${service.id}`}
            variant='unstyled'
          >
            <Flex w='full' h='full' align='center' justify='center'>
              <Icon as={FaEye} />
            </Flex>
          </Button>
        </Flex>
      </Td>
      <Td>{service.protocol}</Td>
      <Td>{deliveryDate}</Td>
      <Td>{deliveryHour}</Td>
      <Td>{service.customerIDService.trading_firstname}</Td>
      <Td>{service.serviceIDRequested.gelo_seco}</Td>
      <Td>{Array.from(new Set(service.source_cities.map(city => city.name))).join(', ')}</Td>
      <Td>{Array.from(new Set(service.destination_cities.map(city => city.name))).join(', ')}</Td>
      <Td>{service.serviceIDLanding.map(landing => landing.landing_volume).join(', ') || '-'}</Td>
      <Td>{service.recipients.join(', ')}</Td>
      <Td>{service.serviceIDRequested.destinationCollectorIDService.trading_name}</Td>
      <Td>{deliveryAssignedDrivers || '-'}</Td>
    </Tr>
  )
}
