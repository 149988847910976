import { Box, Button, Flex, FormControl, FormLabel, IconButton, Input, Select, Stack, Text } from "@chakra-ui/react";
import { Controller, useFieldArray,useFormContext, useWatch } from "react-hook-form";
import * as yup from "yup"
import { statesWithUfList } from "utils/CustomLists/statesWithUfList";
import { FaPlus, FaTrash } from "react-icons/fa";
import { CitiesProps } from "services/getFunctions/city/getCity";
import { captalize } from "utils/captalize";
import { getShippings } from "api/shippings/getShippings";
import AsyncSelect from "react-select/async"
import { ConsultPricesSchema } from "../ConsultPrices";
import { InputProps } from "contexts/InputContext";

interface ConsultPriceFormProps {
  cities: CitiesProps[]
  inputs: InputProps[]
}

export const vehicleTypes = [
  { key: "0", value: 'CARRO', showOption: 'CARRO' },
  { key: "1", value: 'MOTO', showOption: 'MOTO' },
  { key: "2", value: 'CAMINHÃO', showOption: 'CAMINHÃO' },
  { key: "3", value: 'TODOS', showOption: 'TODOS' },
]

export const consultPriceSchema = yup.object().shape({

})


export function ConsultPriceForm({ cities, inputs }: ConsultPriceFormProps) {
  const {
    register,
    control,

    formState: { errors },
  } = useFormContext<ConsultPricesSchema>()

  const [sourceUfsStateAndCities, destinationUfsStateAndCities, hasIncludeGeloSeco, hasMaterials] = useWatch({
    control,
    name: ['sourceCitiesIds', 'destinationCitiesIds', 'includeGeloSeco',  'hasMaterials']
  })

  const isIncludeGeloSeco = hasIncludeGeloSeco === 'yes'
  const isConsultWithMaterials = hasMaterials === "yes"

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'materials'
  })

  const {
    fields: sourceCityFields,
    append: appendSourceCityField,
    remove: removeSourceCityField } = useFieldArray({
      control,
      name: 'sourceCitiesIds'
    })

  const {
    fields: destinationCityFields,
    append: appendDestinationCityField,
    remove: removeDestinationCityField } = useFieldArray({
      control,
      name: 'destinationCitiesIds'
    })

  const listMaterialRemoveOptionsSelect = ['LACRE', 'ALMOFADA ABSORVENTE', 'ADESIVO VEÍCULO', 'EMBALAGEM SECUNDÁRIA (POTE 250 ML)', 'EMBALAGEM SECUNDÁRIA CANISTER 1L', 'FITA PERSONALIZADA']

  const newListInputs = inputs?.filter(input => !listMaterialRemoveOptionsSelect.includes(input.name))


  async function promiseOptions(inputValue: string): Promise<Array<{ value: string, label: string }>> {
    const response = await getShippings({ currentPage: 1, pageSize: 5, tradingName: inputValue })

    return response.shippings.map(shipping => {
      return { value: shipping.id, label: captalize(shipping.trading_name) }
    })
  }

  return (
    <Box w='full'>
      <Stack direction="column" spacing="24px" mt="4">
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <Flex
              border="1px solid"
              borderColor="gray.200"
              p="4"
              rounded="lg"
              direction="column"
            >
              {sourceCityFields?.map((field, index) => {
                const selectedCitiesIds = sourceUfsStateAndCities
                  .filter((_, idx) => idx !== index)
                  .map(city => city.sourceCityId)

                const citiesBySourceUf = cities
                  .filter(city => city.state === sourceUfsStateAndCities[index]?.sourceUf.toUpperCase())
                  .filter(city => !selectedCitiesIds.includes(city.id))

                return (
                  <Stack
                    key={field.id}
                    spacing={6}
                    direction={["column", "column", "row"]}
                    w="full"
                    mt={5}
                  >
                    <FormControl isInvalid={!!errors.sourceCitiesIds}>
                      <FormLabel fontSize="sm">
                        UF Origem
                        <Text as="sup" color="red.500">*</Text>
                      </FormLabel>
                      <Select
                        {...register(`sourceCitiesIds.${index}.sourceUf`)}
                        name={`sourceCitiesIds.${index}.sourceUf`}
                        placeholder="Selecione..."
                        rounded="md"
                        size='sm'
                      >
                        {Object.entries(statesWithUfList).map(([key, value]) => (
                          <option value={key} key={key}>
                            {value.uf.toUpperCase()}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl isInvalid={!!errors.sourceCitiesIds}>
                      <FormLabel fontSize="sm">
                        Cidade Origem
                        <Text as="sup" color="red.500">*</Text>
                      </FormLabel>
                      <Select
                        {...register(`sourceCitiesIds.${index}.sourceCityId`)}
                        name={`sourceCitiesIds.${index}.sourceCityId`}
                        placeholder="Selecione..."
                        rounded="md"
                        size='sm'
                      >
                        {citiesBySourceUf
                          .map(city => {
                            return (
                              <option value={city.id} key={city.id}>
                                {captalize(city?.name)}
                              </option>
                            )
                          })}

                      </Select>
                    </FormControl>
                    {sourceCityFields.length > 1 && (
                      <Flex
                        alignItems='end'
                      >
                        <IconButton
                          aria-label="Remover cidade e uf de origem"
                          icon={<FaTrash />}
                          variant="ghost"
                          onClick={() => removeSourceCityField(index)}
                          size='sm'
                        />
                      </Flex>
                    )}
                  </Stack>
                )
              })}
              <Button
                size="sm"
                leftIcon={<FaPlus />}
                colorScheme="blue"
                onClick={() => appendSourceCityField({ sourceUf: '', sourceCityId: '' })}
                mt="3"
                alignSelf="end"
              >
                Adicionar
              </Button>
            </Flex>
          </Stack>
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <Flex
              border="1px solid"
              borderColor="gray.200"
              p="4"
              rounded="lg"
              direction="column"
            >
              {destinationCityFields?.map((field, index) => {
                const selectedCitiesIds = destinationUfsStateAndCities
                  .filter((_, idx) => idx !== index)
                  .map(city => city.destinationCityId)


                const citiesByDestinationUf = cities
                  .filter(city => city.state === destinationUfsStateAndCities[index]?.destinationUf.toUpperCase())
                  .filter(city => !selectedCitiesIds.includes(city.id))

                return (
                  <Stack
                    key={field.id}
                    spacing={6}
                    direction={["column", "column", "row"]}
                    w="full"
                    mt={5}
                  >
                    <FormControl isInvalid={!!errors.destinationCitiesIds}>
                      <FormLabel fontSize="sm">
                        UF Destino
                        <Text as="sup" color="red.500">*</Text>
                      </FormLabel>
                      <Select
                        {...register(`destinationCitiesIds.${index}.destinationUf`)}
                        name={`destinationCitiesIds.${index}.destinationUf`}
                        placeholder="Selecione..."
                        rounded="md"
                        size='sm'
                      >
                        {Object.entries(statesWithUfList).map(([key, value]) => (
                          <option value={key} key={key}>
                            {value.uf.toUpperCase()}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl isInvalid={!!errors.destinationCitiesIds}>
                      <FormLabel fontSize="sm">
                        Cidade Destino
                        <Text as="sup" color="red.500">*</Text>
                      </FormLabel>
                      <Select
                        {...register(`destinationCitiesIds.${index}.destinationCityId`)}
                        name={`destinationCitiesIds.${index}.destinationCityId`}
                        placeholder="Selecione..."
                        rounded="md"
                        size='sm'
                      >
                        {citiesByDestinationUf
                          .map(city => {
                            return (
                              <option value={city.id} key={city.id}>
                                {captalize(city?.name)}
                              </option>
                            )
                          })}

                      </Select>
                    </FormControl>
                    {destinationCityFields.length > 1 && (
                      <Flex
                        alignItems='end'
                      >
                        <IconButton
                          aria-label="Remover cidade e uf de destino"
                          icon={<FaTrash />}
                          variant="ghost"
                          onClick={() => removeDestinationCityField(index)}
                          size='sm'
                        />
                      </Flex>
                    )}
                  </Stack>
                )
              })}
              <Button
                size="sm"
                leftIcon={<FaPlus />}
                colorScheme="blue"
                onClick={() => appendDestinationCityField({ destinationCityId: '', destinationUf: '' })}
                mt="3"
                alignSelf="end"
              >
                Adicionar
              </Button>
            </Flex>
          </Stack>
        </Stack>
        <FormControl isInvalid={!!errors.shippingIds}>
          <FormLabel>
            Transportadoras
          </FormLabel>
          <Controller
            control={control}
            name="shippingIds"
            render={({ field }) => (
              <AsyncSelect
                name={field.name}
                onChange={(arr) => field.onChange(arr?.map(v => v.value))}
                cacheOptions
                defaultOptions
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    padding: '0px',
                    minHeight: '20px',
                    height: '32px',
                    borderRadius: '6px',
                    border: state.isFocused ? 'none' : '',
                    borderColor: 'rgba(0, 0, 0, 0.09)',
                    fontSize: '14px',
                    fontWeight: 'normal',
                    boxShadow: state.isFocused ? '0 0 0 2px #38c3fa' : '',
                  }),
                  valueContainer: (baseStyles) => ({
                    ...baseStyles,
                    padding: '0px 8px'
                  }),
                  input: (baseStyles) => ({
                    ...baseStyles,
                    margin: '0px',
                    padding: '0px',
                    height: '100%',
                  }),
                  multiValue: (baseStyles) => ({
                    ...baseStyles,
                    margin: '2px'
                  }),
                  dropdownIndicator: (baseStyles) => ({
                    ...baseStyles,
                    padding: '4px'
                  }),
                  clearIndicator: (baseStyles) => ({
                    ...baseStyles,
                    padding: '4px'
                  }),
                  placeholder: (baseStyles) => ({
                    ...baseStyles,
                    color: 'black'
                  })
                }}
                placeholder="Transportadoras..."
                loadOptions={promiseOptions}
                isClearable={true}
                isMulti
              />
            )}
          />
        </FormControl>


        <Stack
          spacing="6"
          direction={["column", "column", "row"]}

        >

          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors.vehicle}>
              <FormLabel fontSize="sm">
                Veículo
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Select
                {...register('vehicle')}
                name="vehicle"
                placeholder="Selecione..."
                rounded="md"
                size='sm'
              >
                {vehicleTypes?.map((vehicle) => {
                  return (
                    <option value={vehicle.value} key={vehicle.key}>
                      {captalize(vehicle?.showOption)}
                    </option>
                  )
                })}

              </Select>
            </FormControl>
          </Stack>
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors.taxedWeight}>
              <FormLabel fontSize="sm">
                Peso Taxado
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('taxedWeight')}
                name='taxedWeight'
                type='number'
                rounded="md"
                size="sm"
              />
            </FormControl>

          </Stack>
        </Stack>
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors.hasMaterials}>
              <FormLabel fontSize="sm">
                Consulta com materiais?
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Select
                {...register('hasMaterials')}
                name="hasMaterials"
                placeholder="Selecione..."
                rounded="md"
                size='sm'
              >
                <option value='yes' >Sim</option>
                <option value='no' >Não</option>

              </Select>
            </FormControl>
          </Stack>
        </Stack>
        {isConsultWithMaterials && (
          <Flex
            border="1px solid"
            borderColor="gray.200"
            p="4"
            rounded="lg"
            direction="column"
          >
            {fields?.map((field, index) => {

              return (
                <Stack
                  key={field.id}
                  spacing={6}
                  direction={["column", "column", "row"]}
                  w="full"
                  mt={5}
                >
                  <FormControl isInvalid={!!errors.materials}>
                    <FormLabel fontSize="sm">
                      Material
                      <Text as="sup" color="red.500">*</Text>
                    </FormLabel>
                    <Select
                      {...register(`materials.${index}.inputId`)}
                      name={`materials.${index}.inputId`}
                      placeholder="Selecione..."
                      rounded="md"
                      size='sm'
                    >
                      {newListInputs.map((input) => (
                        <option value={input.id} key={input.id}>
                          {input.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl isInvalid={!!errors.materials}>
                    <FormLabel fontSize="sm">
                      Quantidade
                      <Text as="sup" color="red.500">*</Text>
                    </FormLabel>
                    <Input
                      {...register(`materials.${index}.quantity`)}
                      name={`materials.${index}.quantity`}
                      type='number'
                      rounded="md"
                      min="0"
                      size="sm"
                    />
                  </FormControl>

                  <Flex
                    alignItems='end'
                  >
                    <IconButton
                      aria-label="Remover material"
                      icon={<FaTrash />}
                      variant="ghost"
                      onClick={() => remove(index)}
                      size='sm'
                    />
                  </Flex>

                </Stack>
              )
            })}
            <Button
              size="sm"
              leftIcon={<FaPlus />}
              colorScheme="blue"
              onClick={() => append({ inputId: '', quantity: 0 })}
              mt="3"
              alignSelf="end"
            >
              Adicionar
            </Button>
          </Flex>
        )}
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors.includeGeloSeco}>
              <FormLabel fontSize="sm">
                Incluir Gelo Seco?
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Select
                {...register('includeGeloSeco')}
                name="includeGeloSeco"
                placeholder="Selecione..."
                rounded="md"
                size='sm'
              >
                <option value='yes' >Sim</option>
                <option value='no' >Não</option>

              </Select>
            </FormControl>
          </Stack>
          {isIncludeGeloSeco && (
            <Stack
              direction="column"
              w="full"
              spacing="0.25"
            >
              <FormControl isInvalid={!!errors.geloSecoQuantity}>
                <FormLabel fontSize="sm">
                  Quantidade
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>
                <Input
                  {...register('geloSecoQuantity')}
                  name='geloSecoQuantity'
                  type='number'
                  rounded="md"
                  size="sm"
                />
              </FormControl>

            </Stack>
          )}
        </Stack>
      </Stack>
    </Box>
  )
}
