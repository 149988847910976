import { set } from 'date-fns'
import { transformStringToNumber } from '../GeneralFunctions/FormatValuesFuntions/transformStringToNumber'

interface DataProps {
  customer_id: string
  situation: string
  source_hub_id: string
  crossdocking_hub_id: string | null
  destination_hub_id: string
  source_cities: string[]
  destination_cities: string[]
  source_address_qty: number
  destination_address_qty: number
  deadline: number
  service_type: string
  franchising: number
  modal: string
  vehicle: string
  caixa_termica: number
  embalagem_secundaria: number
  gelo_seco: number
  gelox: number
  isopor3l: number
  isopor7l: number
  terciaria3l: number
  terciaria8l: number
  price: number
  price_kg_extra: number | null
  transfer_budget: number | null
  price_add_collect: number
  price_add_delivery: number
  price_unsuccessful_collect: number
  deadline_delivery: string
  days_service: string[]
  priority_budget: 'yes' | 'no'
  observation: string
  has_crossdocking: 'SIM' | 'NÃO'
  last_mile_budget: string
  shippingIds: string[]
  shipping_type: 'Rápido' | 'Econômico'
  does_share_extra_kg: 'SIM' | 'NÃO'
  modal_crossdocking: string
  does_validate_new_addresses_automatically: 'yes' | 'no'
}

export function budgetFormatDataToBack(data: DataProps) {

  const dataSourceCities =
    data.source_cities.toString().split(',').length > 1
      ? data.source_cities
      : data.source_cities.toString().split('  ')
  const dataDestinationCities =
    data.destination_cities.toString().split(',').length > 1
      ? data.destination_cities
      : data.destination_cities.toString().split('  ')

  const dataDaysOfWeek =
    data.days_service.toString().split(',').length > 1
      ? data.days_service
      : data.days_service.toString().split('  ')
  const daysOfWeekByNumber: number[] = []
  dataDaysOfWeek.forEach((day) => {
    if (day === 'DOMINGO') {
      daysOfWeekByNumber.push(0)
    }
    if (day === 'SEGUNDA') {
      daysOfWeekByNumber.push(1)
    }
    if (day === 'TERÇA') {
      daysOfWeekByNumber.push(2)
    }
    if (day === 'QUARTA') {
      daysOfWeekByNumber.push(3)
    }
    if (day === 'QUINTA') {
      daysOfWeekByNumber.push(4)
    }
    if (day === 'SEXTA') {
      daysOfWeekByNumber.push(5)
    }
    if (day === 'SÁBADO') {
      daysOfWeekByNumber.push(6)
    }
  })

  const [deadlineDeliveryHours, deadlineDeliveryMinutes] = data.deadline_delivery.split(":").map(date => Number(date))

  const deadlineDelivery = set(new Date(), {
    hours: deadlineDeliveryHours,
    minutes: deadlineDeliveryMinutes
  })

  const newBudgetFormatedData = {
    ...data,
    customer_id: data.customer_id,
    situation: data.situation,
    source_hub_id: data.source_hub_id,
    crossdocking_hub_id: data.crossdocking_hub_id && data.has_crossdocking ===  'SIM'
      ? data.crossdocking_hub_id
      : null,
    destination_hub_id: data.destination_hub_id,
    source_cities: dataSourceCities,
    destination_cities: dataDestinationCities,
    source_address_qty: data.source_address_qty,
    destination_address_qty: data.destination_address_qty,
    deadline: data.deadline,
    service_type: data.service_type,
    franchising: transformStringToNumber(String(data.franchising)),
    modal: data.modal,
    vehicle: data.vehicle,
    caixa_termica: data.caixa_termica,
    embalagem_secundaria: data.embalagem_secundaria,
    gelo_seco: data.gelo_seco,
    gelox: data.gelox,
    isopor3l: data.isopor3l,
    isopor7l: data.isopor7l,
    terciaria3l: data.terciaria3l,
    terciaria8l: data.terciaria8l,
    price: transformStringToNumber(String(data.price)),
    price_kg_extra:
      data.price_kg_extra !== null
        ? transformStringToNumber(String(data.price_kg_extra))
        : null,
    transfer_budget:
      data.transfer_budget !== null
        ? transformStringToNumber(String(data.transfer_budget))
        : null,
    price_add_collect: transformStringToNumber(String(data.price_add_collect)),
    price_add_delivery: transformStringToNumber(
      String(data.price_add_delivery),
    ),
    price_unsuccessful_collect: transformStringToNumber(
      String(data.price_unsuccessful_collect),
    ),
    days_service: daysOfWeekByNumber,
    deadline_delivery: deadlineDelivery,
    priority_budget: data.priority_budget,
    observation: data.observation,
    last_mile_budget: transformStringToNumber(String(data.last_mile_budget)),
    does_share_extra_kg: data.does_share_extra_kg === 'SIM',
    modal_crossdocking: data.modal_crossdocking,
    does_validate_new_addresses_automatically: data.does_validate_new_addresses_automatically === 'yes'
  }

  return newBudgetFormatedData
}
