import { Box, Flex, Heading, IconButton } from "@chakra-ui/react"
import { getCltDrivers } from "api/cltDrivers/getCltDrivers"
import { useAuth } from "hooks/auth/useAuth";
import { useEffect } from "react";
import { FaExchangeAlt, FaPlus } from "react-icons/fa";
import { useInfiniteQuery } from "react-query"
import { Link, useHistory } from "react-router-dom";
import { CltDriversKanbanColumn } from "./components/CltDriversKanbanColumn";

export function CltDriversKanban() {
  const { userLogged } = useAuth()
  const history = useHistory()

  const doesUserCanViewCltDriver = userLogged?.permissions?.includes(
    'view-clt-drivers'
  )

  const doesUserCanCreateCltDriver = userLogged?.permissions?.includes(
    'create-clt-driver'
  )

  useEffect(() => {
    if (!doesUserCanViewCltDriver) history.push('/')
  }, [history, doesUserCanViewCltDriver])


  const {
    data: hiringCltDriversResult,
    isLoading: isHiringCltDriversResultLoading,
    hasNextPage: hasNextPageHiringCltDrivers,
    fetchNextPage: fetchNextPageHiringCltDrivers,
    isFetchingNextPage: isFetchingNextPageHiringCltDrivers,
  } = useInfiniteQuery({
    queryKey: ['hiring-drivers-clt'],
    queryFn: ({ pageParam = 1 }) => getCltDrivers({
      currentPage: pageParam,
      pageSize: 10,
      status: 'hiring',
    }),
    getNextPageParam: (lastPage) => lastPage?.meta?.currentPage < lastPage.totalPages
      ? lastPage?.meta?.currentPage + 1
      : undefined,
    keepPreviousData: true,
  });

  const {
    data: hiringMotorcyleDetailsCltDriversResult,
    isLoading: isHiringMotorcyleDetailsCltDriversResultLoading,
    hasNextPage: hasNextPageHiringMotorcyleDetailsCltDrivers,
    fetchNextPage: fetchNextPageHiringMotorcyleDetailsCltDrivers,
    isFetchingNextPage: isFetchingNextPageHiringMotorcyleDetailsCltDrivers,
  } = useInfiniteQuery({
    queryKey: ['hiring-motorcyle-details-drivers-clt'],
    queryFn: ({ pageParam = 1 }) => getCltDrivers({
      currentPage: pageParam,
      pageSize: 10,
      status: 'hiring-motorcyle-details',
    }),
    getNextPageParam: (lastPage) => lastPage?.meta?.currentPage < lastPage.totalPages
      ? lastPage?.meta?.currentPage + 1
      : undefined,
    keepPreviousData: true,
  });

  const {
    data: hiringScalabriniRegisterCltDriversResult,
    isLoading: isHiringScalabriniRegisterCltDriversResultLoading,
    hasNextPage: hasNextPageHiringScalabriniRegisterCltDrivers,
    fetchNextPage: fetchNextPageHiringScalabriniRegisterCltDrivers,
    isFetchingNextPage: isFetchingNextPageHiringScalabriniRegisterCltDrivers,
  } = useInfiniteQuery({
    queryKey: ['hiring-scalabrini-register-drivers-clt'],
    queryFn: ({ pageParam = 1 }) => getCltDrivers({
      currentPage: pageParam,
      pageSize: 10,
      status: 'hiring-scalabrini-register',
    }),
    getNextPageParam: (lastPage) => lastPage?.meta?.currentPage < lastPage.totalPages
      ? lastPage?.meta?.currentPage + 1
      : undefined,
    keepPreviousData: true,
  });

  const {
    data: hiringPontomaisRegisterCltDriversResult,
    isLoading: isHiringPontomaisRegisterCltDriversResultLoading,
    hasNextPage: hasNextPageHiringPontomaisRegisterCltDrivers,
    fetchNextPage: fetchNextPageHiringPontomaisRegisterCltDrivers,
    isFetchingNextPage: isFetchingNextPageHiringPontomaisRegisterCltDrivers,
  } = useInfiniteQuery({
    queryKey: ['hiring-pontomais-register-drivers-clt'],
    queryFn: ({ pageParam = 1 }) => getCltDrivers({
      currentPage: pageParam,
      pageSize: 10,
      status: 'hiring-pontomais-register',
    }),
    getNextPageParam: (lastPage) => lastPage?.meta?.currentPage < lastPage.totalPages
      ? lastPage?.meta?.currentPage + 1
      : undefined,
    keepPreviousData: true,
  });

  const {
    data: hiringSigningDocumentResult,
    isLoading: isHiringSigningDocumentResultLoading,
    hasNextPage: hasNextPageHiringSigningDocument,
    fetchNextPage: fetchNextPageHiringSigningDocument,
    isFetchingNextPage: isFetchingNextPageHiringSigningDocument,
  } = useInfiniteQuery({
    queryKey: ['hiring-signing-document'],
    queryFn: ({ pageParam = 1 }) => getCltDrivers({
      currentPage: pageParam,
      pageSize: 10,
      status: 'hiring-signing-document',
    }),
    getNextPageParam: (lastPage) => lastPage?.meta?.currentPage < lastPage.totalPages
      ? lastPage?.meta?.currentPage + 1
      : undefined,
    keepPreviousData: true,
  });

  const {
    data: hiringBankDetailsResult,
    isLoading: isHiringBankDetailsResultLoading,
    hasNextPage: hasNextPageHiringBankDetails,
    fetchNextPage: fetchNextPageHiringBankDetails,
    isFetchingNextPage: isFetchingNextPageHiringBankDetails,
  } = useInfiniteQuery({
    queryKey: ['hiring-bank-details'],
    queryFn: ({ pageParam = 1 }) => getCltDrivers({
      currentPage: pageParam,
      pageSize: 10,
      status: 'hiring-bank-details',
    }),
    getNextPageParam: (lastPage) => lastPage?.meta?.currentPage < lastPage.totalPages
      ? lastPage?.meta?.currentPage + 1
      : undefined,
    keepPreviousData: true,
  });

  const {
    data: activeResult,
    isLoading: isActiveResultLoading,
    hasNextPage: hasNextPageActive,
    fetchNextPage: fetchNextPageActive,
    isFetchingNextPage: isFetchingNextPageActive,
  } = useInfiniteQuery({
    queryKey: ['active'],
    queryFn: ({ pageParam = 1 }) => getCltDrivers({
      currentPage: pageParam,
      pageSize: 10,
      status: 'active',
    }),
    getNextPageParam: (lastPage) => lastPage?.meta?.currentPage < lastPage.totalPages
      ? lastPage?.meta?.currentPage + 1
      : undefined,
    keepPreviousData: true,
  });

  const {
    data: dismissingLegalPreventionResult,
    isLoading: isDismissingLegalPreventionResultLoading,
    hasNextPage: hasNextPageDismissingLegalPrevention,
    fetchNextPage: fetchNextPageDismissingLegalPrevention,
    isFetchingNextPage: isFetchingNextPageDismissingLegalPrevention,
  } = useInfiniteQuery({
    queryKey: ['dismissing-legal-prevention'],
    queryFn: ({ pageParam = 1 }) => getCltDrivers({
      currentPage: pageParam,
      pageSize: 10,
      status: 'dismissing-legal-prevention',
    }),
    getNextPageParam: (lastPage) => lastPage?.meta?.currentPage < lastPage.totalPages
      ? lastPage?.meta?.currentPage + 1
      : undefined,
    keepPreviousData: true,
  });

  const {
    data: dismissingApprovationResult,
    isLoading: isDismissingApprovationResultLoading,
    hasNextPage: hasNextPageDismissingApprovation,
    fetchNextPage: fetchNextPageDismissingApprovation,
    isFetchingNextPage: isFetchingNextPageDismissingApprovation,
  } = useInfiniteQuery({
    queryKey: ['dismissing-approvation'],
    queryFn: ({ pageParam = 1 }) => getCltDrivers({
      currentPage: pageParam,
      pageSize: 10,
      status: 'dismissing-approvation',
    }),
    getNextPageParam: (lastPage) => lastPage?.meta?.currentPage < lastPage.totalPages
      ? lastPage?.meta?.currentPage + 1
      : undefined,
    keepPreviousData: true,
  });

  const {
    data: dismissingComunicationResult,
    isLoading: isDismissingComunicationResultLoading,
    hasNextPage: hasNextPageDismissingComunication,
    fetchNextPage: fetchNextPageDismissingComunication,
    isFetchingNextPage: isFetchingNextPageDismissingComunication,
  } = useInfiniteQuery({
    queryKey: ['dismissing-comunication'],
    queryFn: ({ pageParam = 1 }) => getCltDrivers({
      currentPage: pageParam,
      pageSize: 10,
      status: 'dismissing-comunication',
    }),
    getNextPageParam: (lastPage) => lastPage?.meta?.currentPage < lastPage.totalPages
      ? lastPage?.meta?.currentPage + 1
      : undefined,
    keepPreviousData: true,
  });

  const {
    data: dismissingAsoAndNoticeResult,
    isLoading: isDismissingAsoAndNoticeResultLoading,
    hasNextPage: hasNextPageDismissingAsoAndNotice,
    fetchNextPage: fetchNextPageDismissingAsoAndNotice,
    isFetchingNextPage: isFetchingNextPageDismissingAsoAndNotice,
  } = useInfiniteQuery({
    queryKey: ['dismissing-aso-and-notice'],
    queryFn: ({ pageParam = 1 }) => getCltDrivers({
      currentPage: pageParam,
      pageSize: 10,
      status: 'dismissing-aso-and-notice',
    }),
    getNextPageParam: (lastPage) => lastPage?.meta?.currentPage < lastPage.totalPages
      ? lastPage?.meta?.currentPage + 1
      : undefined,
    keepPreviousData: true,
  });

  const {
    data: dismissingFulfillingNoticeResult,
    isLoading: isDismissingFulfillingNoticeResultLoading,
    hasNextPage: hasNextPageDismissingFulfillingNotice,
    fetchNextPage: fetchNextPageDismissingFulfillingNotice,
    isFetchingNextPage: isFetchingNextPageDismissingFulfillingNotice,
  } = useInfiniteQuery({
    queryKey: ['dismissing-fulfilling-notice'],
    queryFn: ({ pageParam = 1 }) => getCltDrivers({
      currentPage: pageParam,
      pageSize: 10,
      status: 'dismissing-fulfilling-notice',
    }),
    getNextPageParam: (lastPage) => lastPage?.meta?.currentPage < lastPage.totalPages
      ? lastPage?.meta?.currentPage + 1
      : undefined,
    keepPreviousData: true,
  });

  const {
    data: dismissingDocumentationResult,
    isLoading: isDismissingDocumentationResultLoading,
    hasNextPage: hasNextPageDismissingDocumentation,
    fetchNextPage: fetchNextPageDismissingDocumentation,
    isFetchingNextPage: isFetchingNextPageDismissingDocumentation,
  } = useInfiniteQuery({
    queryKey: ['dismissing-documentation'],
    queryFn: ({ pageParam = 1 }) => getCltDrivers({
      currentPage: pageParam,
      pageSize: 10,
      status: 'dismissing-documentation',
    }),
    getNextPageParam: (lastPage) => lastPage?.meta?.currentPage < lastPage.totalPages
      ? lastPage?.meta?.currentPage + 1
      : undefined,
    keepPreviousData: true,
  });

  const {
    data: dismissingTrctSignatureResult,
    isLoading: isDismissingTrctSignatureResultLoading,
    hasNextPage: hasNextPageDismissingTrctSignature,
    fetchNextPage: fetchNextPageDismissingTrctSignature,
    isFetchingNextPage: isFetchingNextPageDismissingTrctSignature,
  } = useInfiniteQuery({
    queryKey: ['dismissing-trct-signature'],
    queryFn: ({ pageParam = 1 }) => getCltDrivers({
      currentPage: pageParam,
      pageSize: 10,
      status: 'dismissing-trct-signature',
    }),
    getNextPageParam: (lastPage) => lastPage?.meta?.currentPage < lastPage.totalPages
      ? lastPage?.meta?.currentPage + 1
      : undefined,
    keepPreviousData: true,
  });

  const {
    data: dismissingTerminationResult,
    isLoading: isDismissingTerminationResultLoading,
    hasNextPage: hasNextPageDismissingTermination,
    fetchNextPage: fetchNextPageDismissingTermination,
    isFetchingNextPage: isFetchingNextPageDismissingTermination,
  } = useInfiniteQuery({
    queryKey: ['dismissing-termination'],
    queryFn: ({ pageParam = 1 }) => getCltDrivers({
      currentPage: pageParam,
      pageSize: 10,
      status: 'dismissing-termination',
    }),
    getNextPageParam: (lastPage) => lastPage?.meta?.currentPage < lastPage.totalPages
      ? lastPage?.meta?.currentPage + 1
      : undefined,
    keepPreviousData: true,
  });

  const {
    data: movedResult,
    isLoading: isMovedResultLoading,
    hasNextPage: hasNextPageMoved,
    fetchNextPage: fetchNextPageMoved,
    isFetchingNextPage: isFetchingNextPageMoved,
  } = useInfiniteQuery({
    queryKey: ['moved'],
    queryFn: ({ pageParam = 1 }) => getCltDrivers({
      currentPage: pageParam,
      pageSize: 10,
      status: 'moved',
    }),
    getNextPageParam: (lastPage) => lastPage?.meta?.currentPage < lastPage.totalPages
      ? lastPage?.meta?.currentPage + 1
      : undefined,
    keepPreviousData: true,
  });

  const {
    data: dismissedResult,
    isLoading: isDismissedResultLoading,
    hasNextPage: hasNextPageDismissed,
    fetchNextPage: fetchNextPageDismissed,
    isFetchingNextPage: isFetchingNextPageDismissed,
  } = useInfiniteQuery({
    queryKey: ['dismissed'],
    queryFn: ({ pageParam = 1 }) => getCltDrivers({
      currentPage: pageParam,
      pageSize: 10,
      status: 'dismissed',
    }),
    getNextPageParam: (lastPage) => lastPage?.meta?.currentPage < lastPage.totalPages
      ? lastPage?.meta?.currentPage + 1
      : undefined,
    keepPreviousData: true,
  });

  const columnsCltDrivers = [
    {
      title: 'Contratando',
      cltDrivers: hiringCltDriversResult?.pages.flatMap((page) => page.cltDrivers) || [],
      cltDriversCount: hiringCltDriversResult?.pages[0]?.count,
      color: 'blue.300',
      hasNextPage: hasNextPageHiringCltDrivers,
      fetchNextPage: fetchNextPageHiringCltDrivers,
      isFetchingNextPage: isFetchingNextPageHiringCltDrivers,
      isLoading: isHiringCltDriversResultLoading,
    },
    {
      title: 'Contratação (Dados da moto)',
      cltDrivers: hiringMotorcyleDetailsCltDriversResult?.pages.flatMap((page) => page.cltDrivers) || [],
      cltDriversCount: hiringMotorcyleDetailsCltDriversResult?.pages[0]?.count,
      color: 'blue.400',
      hasNextPage: hasNextPageHiringMotorcyleDetailsCltDrivers,
      fetchNextPage: fetchNextPageHiringMotorcyleDetailsCltDrivers,
      isFetchingNextPage: isFetchingNextPageHiringMotorcyleDetailsCltDrivers,
      isLoading: isHiringMotorcyleDetailsCltDriversResultLoading,
    },
    {
      title: 'Contratação (Cadastro na Scalabrini)',
      cltDrivers: hiringScalabriniRegisterCltDriversResult?.pages.flatMap((page) => page.cltDrivers) || [],
      cltDriversCount: hiringScalabriniRegisterCltDriversResult?.pages[0]?.count,
      color: 'blue.300',
      hasNextPage: hasNextPageHiringScalabriniRegisterCltDrivers,
      fetchNextPage: fetchNextPageHiringScalabriniRegisterCltDrivers,
      isFetchingNextPage: isFetchingNextPageHiringScalabriniRegisterCltDrivers,
      isLoading: isHiringScalabriniRegisterCltDriversResultLoading,
    },
    {
      title: 'Contratação (iFractal)',
      cltDrivers: hiringPontomaisRegisterCltDriversResult?.pages.flatMap((page) => page.cltDrivers) || [],
      cltDriversCount: hiringPontomaisRegisterCltDriversResult?.pages[0]?.count,
      color: 'blue.300',
      hasNextPage: hasNextPageHiringPontomaisRegisterCltDrivers,
      fetchNextPage: fetchNextPageHiringPontomaisRegisterCltDrivers,
      isFetchingNextPage: isFetchingNextPageHiringPontomaisRegisterCltDrivers,
      isLoading: isHiringPontomaisRegisterCltDriversResultLoading,
    },
    {
      title: 'Contratação (Assinatura de documentos)',
      cltDrivers: hiringSigningDocumentResult?.pages.flatMap((page) => page.cltDrivers) || [],
      cltDriversCount: hiringSigningDocumentResult?.pages[0]?.count,
      color: 'blue.300',
      hasNextPage: hasNextPageHiringSigningDocument,
      fetchNextPage: fetchNextPageHiringSigningDocument,
      isFetchingNextPage: isFetchingNextPageHiringSigningDocument,
      isLoading: isHiringSigningDocumentResultLoading,
    },
    {
      title: 'Contratação (Dados bancários)',
      cltDrivers: hiringBankDetailsResult?.pages.flatMap((page) => page.cltDrivers) || [],
      cltDriversCount: hiringBankDetailsResult?.pages[0]?.count,
      color: 'blue.300',
      hasNextPage: hasNextPageHiringBankDetails,
      fetchNextPage: fetchNextPageHiringBankDetails,
      isFetchingNextPage: isFetchingNextPageHiringBankDetails,
      isLoading: isHiringBankDetailsResultLoading,
    },
    {
      title: 'Ativos',
      cltDrivers: activeResult?.pages.flatMap((page) => page.cltDrivers) || [],
      cltDriversCount: activeResult?.pages[0]?.count,
      color: 'green.300',
      hasNextPage: hasNextPageActive,
      fetchNextPage: fetchNextPageActive,
      isFetchingNextPage: isFetchingNextPageActive,
      isLoading: isActiveResultLoading,
    },
    {
      title: 'Demissão (Prevenção jurídica)',
      cltDrivers: dismissingLegalPreventionResult?.pages.flatMap((page) => page.cltDrivers) || [],
      cltDriversCount: dismissingLegalPreventionResult?.pages[0]?.count,
      color: 'orange.300',
      hasNextPage: hasNextPageDismissingLegalPrevention,
      fetchNextPage: fetchNextPageDismissingLegalPrevention,
      isFetchingNextPage: isFetchingNextPageDismissingLegalPrevention,
      isLoading: isDismissingLegalPreventionResultLoading,
    },
    {
      title: 'Demissão (Aprovação)',
      cltDrivers: dismissingApprovationResult?.pages.flatMap((page) => page.cltDrivers) || [],
      cltDriversCount: dismissingApprovationResult?.pages[0]?.count,
      color: 'orange.300',
      hasNextPage: hasNextPageDismissingApprovation,
      fetchNextPage: fetchNextPageDismissingApprovation,
      isFetchingNextPage: isFetchingNextPageDismissingApprovation,
      isLoading: isDismissingApprovationResultLoading,
    },
    {
      title: 'Demissão (Comunicação)',
      cltDrivers: dismissingComunicationResult?.pages.flatMap((page) => page.cltDrivers) || [],
      cltDriversCount: dismissingComunicationResult?.pages[0]?.count,
      color: 'orange.300',
      hasNextPage: hasNextPageDismissingComunication,
      fetchNextPage: fetchNextPageDismissingComunication,
      isFetchingNextPage: isFetchingNextPageDismissingComunication,
      isLoading: isDismissingComunicationResultLoading,
    },
    {
      title: 'Demissão (Aso & Aviso prévio)',
      cltDrivers: dismissingAsoAndNoticeResult?.pages.flatMap((page) => page.cltDrivers) || [],
      cltDriversCount: dismissingAsoAndNoticeResult?.pages[0]?.count,
      color: 'orange.300',
      hasNextPage: hasNextPageDismissingAsoAndNotice,
      fetchNextPage: fetchNextPageDismissingAsoAndNotice,
      isFetchingNextPage: isFetchingNextPageDismissingAsoAndNotice,
      isLoading: isDismissingAsoAndNoticeResultLoading,
    },
    {
      title: 'Demissão (Cumprindo aviso prévio)',
      cltDrivers: dismissingFulfillingNoticeResult?.pages.flatMap((page) => page.cltDrivers) || [],
      cltDriversCount: dismissingFulfillingNoticeResult?.pages[0]?.count,
      color: 'orange.300',
      hasNextPage: hasNextPageDismissingFulfillingNotice,
      fetchNextPage: fetchNextPageDismissingFulfillingNotice,
      isFetchingNextPage: isFetchingNextPageDismissingFulfillingNotice,
      isLoading: isDismissingFulfillingNoticeResultLoading,
    },
    {
      title: 'Demissão (Documentação)',
      cltDrivers: dismissingDocumentationResult?.pages.flatMap((page) => page.cltDrivers) || [],
      cltDriversCount: dismissingDocumentationResult?.pages[0]?.count,
      color: 'orange.300',
      hasNextPage: hasNextPageDismissingDocumentation,
      fetchNextPage: fetchNextPageDismissingDocumentation,
      isFetchingNextPage: isFetchingNextPageDismissingDocumentation,
      isLoading: isDismissingDocumentationResultLoading,
    },
    {
      title: 'Demissão (Assinatura TRCT)',
      cltDrivers: dismissingTrctSignatureResult?.pages.flatMap((page) => page.cltDrivers) || [],
      cltDriversCount: dismissingTrctSignatureResult?.pages[0]?.count,
      color: 'orange.300',
      hasNextPage: hasNextPageDismissingTrctSignature,
      fetchNextPage: fetchNextPageDismissingTrctSignature,
      isFetchingNextPage: isFetchingNextPageDismissingTrctSignature,
      isLoading: isDismissingTrctSignatureResultLoading,
    },
    {
      title: 'Demissão (Rescisão)',
      cltDrivers: dismissingTerminationResult?.pages.flatMap((page) => page.cltDrivers) || [],
      cltDriversCount: dismissingTerminationResult?.pages[0]?.count,
      color: 'orange.300',
      hasNextPage: hasNextPageDismissingTermination,
      fetchNextPage: fetchNextPageDismissingTermination,
      isFetchingNextPage: isFetchingNextPageDismissingTermination,
      isLoading: isDismissingTerminationResultLoading,
    },
    {
      title: 'Afastado',
      cltDrivers: movedResult?.pages.flatMap((page) => page.cltDrivers) || [],
      cltDriversCount: movedResult?.pages[0]?.count,
      color: 'red.300',
      hasNextPage: hasNextPageMoved,
      fetchNextPage: fetchNextPageMoved,
      isFetchingNextPage: isFetchingNextPageMoved,
      isLoading: isMovedResultLoading,
    },
    {
      title: 'Demitido',
      cltDrivers: dismissedResult?.pages.flatMap((page) => page.cltDrivers) || [],
      cltDriversCount: dismissedResult?.pages[0]?.count,
      color: 'red.300',
      hasNextPage: hasNextPageDismissed,
      fetchNextPage: fetchNextPageDismissed,
      isFetchingNextPage: isFetchingNextPageDismissed,
      isLoading: isDismissedResultLoading,
    },
  ];

  return (
    <Box
      bg="white"
      p="6"
      rounded="md"
    >
      <Flex
        align="center"
        justify="space-between"
      >
        <Heading letterSpacing="tight">Motoristas CLT</Heading>
        <Flex width='full' justifyContent='flex-end' gap={3}>
          <IconButton
            aria-label="Visualizar tabela"
            icon={<FaExchangeAlt />}
            variant='ghost'
            borderWidth={'thin'}
            size="sm"
            onClick={() => history.push('/motoristas-clt')}
          />
          {doesUserCanCreateCltDriver && (
            <IconButton
              as={Link}
              aria-label=""
              icon={<FaPlus />}
              colorScheme="blue"
              size="sm"
              to="/motorista-clt/registrar"
            />
          )}
        </Flex>
      </Flex>
      <Flex gap={4} overflowX="auto" mt={4}>
        {columnsCltDrivers?.map((cltDriver, index) => {
          return (
            <CltDriversKanbanColumn
              key={index}
              cltDriver={cltDriver}
            />
          )
        })}
      </Flex>
    </Box>
  )
}
