import { Button, Flex, FormControl, FormLabel, Input, InputGroup, InputLeftAddon, Radio, RadioGroup, Select, Stack, Text, Textarea } from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { approveCltIntercurrence } from "api/cltIntercurrences/approveCltIntercurrence"
import { GetCltIntercurrencesResponse } from "api/cltIntercurrences/getCltIntercurrences"
import { useBusinessBudgetRoutes } from "hooks/businessBudget/useBusinessBudgetsRoutes"
import { useCustomers } from "hooks/customer/useCustomers"
import { useToastify } from "hooks/toastify/useToastify"
import { useEffect, useState } from "react"
import { useCurrency } from "react-hook-currency"
import { Controller, useForm, useWatch } from "react-hook-form"
import { useMutation, useQueryClient } from "react-query"
import { transformStringToNumber } from "utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber"
import * as yup from "yup"

interface ApproveExtraHourCltIntercurrenceSchema {
  hasGenerateExtraDiscount: string
  withMotorcycleRental: string
  withTelephony: string
  withFuel: string
  withVaVr: string
  withVt: string
  withToll: string
  withExtras: string
  withPayroll: string
  withVacationPayroll: string
  withPontomaisAdjust: string
  withPayrollHrAdjustment: string
  vacationPayrollDescription: string
  amountToBeCharged: string
  customerId: string
  businessBudgetId: string
}

const approveExtraHourCltIntercurrenceSchema = yup.object({
  hasGenerateExtraDiscount: yup.string().required(),
  withMotorcycleRental: yup.mixed().when('hasGenerateExtraDiscount', {
    is: (value: string) => value !== 'n/a',
    then: yup.string().required()
  }),
  withTelephony: yup.mixed().when('hasGenerateExtraDiscount', {
    is: (value: string) => value !== 'n/a',
    then: yup.string().required()
  }),
  withFuel: yup.mixed().when('hasGenerateExtraDiscount', {
    is: (value: string) => value !== 'n/a',
    then: yup.string().required()
  }),
  withVaVr: yup.mixed().when('hasGenerateExtraDiscount', {
    is: (value: string) => value !== 'n/a',
    then: yup.string().required()
  }),
  withVt: yup.mixed().when('hasGenerateExtraDiscount', {
    is: (value: string) => value !== 'n/a',
    then: yup.string().required()
  }),
  withToll: yup.mixed().when('hasGenerateExtraDiscount', {
    is: (value: string) => value !== 'n/a',
    then: yup.string().required()
  }),
  withExtras: yup.mixed().when('hasGenerateExtraDiscount', {
    is: (value: string) => value !== 'n/a',
    then: yup.string().required()
  }),
  withPayroll: yup.mixed().when('hasGenerateExtraDiscount', {
    is: (value: string) => value !== 'n/a',
    then: yup.string().required()
  }),
  withPontomaisAdjust: yup.mixed().when('hasGenerateExtraDiscount', {
    is: (value: string) => value !== 'n/a',
    then: yup.string().required()
  }),
  withVacationPayroll: yup.mixed().when('hasGenerateExtraDiscount', {
    is: (value: string) => value !== 'n/a',
    then: yup.string().required()
  }),
  withPayrollHrAdjustment: yup.mixed().when('hasGenerateExtraDiscount', {
    is: (value: string) => value !== 'n/a',
    then: yup.string().required()
  }),
  vacationPayrollDescription: yup.mixed().when(['hasGenerateExtraDiscount', 'withVacationPayroll'], {
    is: (hasGenerateExtraDiscountValue: string, withPayrollHrAdjustment: string) => hasGenerateExtraDiscountValue !== 'n/a' && withPayrollHrAdjustment === 'yes',
    then: yup.string().required()
  }),
  customerId: yup.mixed().when('hasGenerateExtraDiscount', {
    is: (value: string) => value !== 'n/a',
    then: yup.string().required()
  }),
  businessBudgetId: yup.mixed().when(['hasGenerateExtraDiscount', '$isLoglifeCustomer'], {
    is: (hasGenerateExtraDiscountValue: string, isLoglifeCustomer: boolean) => hasGenerateExtraDiscountValue !== 'n/a' && !isLoglifeCustomer,
    then: yup.string().required()
  }),
  amountToBeCharged: yup.mixed().when(['hasGenerateExtraDiscount', '$isLoglifeCustomer'], {
    is: (hasGenerateExtraDiscountValue: string, isLoglifeCustomer: boolean) => hasGenerateExtraDiscountValue !== 'n/a' && !isLoglifeCustomer,
    then: yup.string().required()
  }),
})

interface ApproveExtraHourCltIntercurrenceProps {
  cltIntercurrenceId: string
}

export function ApproveExtraHourCltIntercurrence({
  cltIntercurrenceId
}: ApproveExtraHourCltIntercurrenceProps) {
  const [isLogLifeCustomer, setIsLogLifeCustomer] = useState(false)

  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting,
    }
  } = useForm<ApproveExtraHourCltIntercurrenceSchema>({
    resolver: yupResolver(approveExtraHourCltIntercurrenceSchema),
    context: {
      isLogLifeCustomer: isLogLifeCustomer
    }
  })

  console.log(errors)

  const hasGenerateExtraDiscount = useWatch({
    control,
    name: 'hasGenerateExtraDiscount'
  })

  const withVacationPayroll = useWatch({
    control,
    name: 'withVacationPayroll'
  })

  const isShowAllOtherFields = hasGenerateExtraDiscount !== 'n/a'

  const queryClient = useQueryClient()

  const { mutateAsync: approveCltIntercurrenceFn } = useMutation({
    mutationFn: approveCltIntercurrence,
    onSuccess: (_data, { routeParams }) => {
      const cached = queryClient.getQueriesData<GetCltIntercurrencesResponse>({
        queryKey: ['clt-intercurrences']
      })

      queryClient.invalidateQueries({ queryKey: 'approved-clt-intercurrences' })
      queryClient.invalidateQueries({ queryKey: 'rejected-clt-intercurrences' })
      queryClient.invalidateQueries({ queryKey: 'requested-cltintercurrences' })
      queryClient.invalidateQueries({ queryKey: ['clt-intercurrence', routeParams.cltIntercurrenceId] })

      cached.forEach(([cachedKey, cachedValue]) => {
        if (!cachedValue) return

        queryClient.setQueryData(cachedKey, {
          ...cachedValue,
          cltIntercurrences: cachedValue.cltIntercurrences.map((cltIntercurrence) => {
            if (cltIntercurrence.id === routeParams.cltIntercurrenceId) {
              return { ...cltIntercurrence, status: 'approved' }
            }

            return cltIntercurrence
          })
        })
      })
    }
  })

  const { promiseMessage } = useToastify()

  async function handleApproveCltIntercurrence(values: ApproveExtraHourCltIntercurrenceSchema) {
    const body = {}

    body['hasGenerateExtraDiscount'] = values.hasGenerateExtraDiscount

    if (values.hasGenerateExtraDiscount !== 'n/a') {

      body['withMotorcycleRental'] = values.withMotorcycleRental === 'yes'
      body['withTelephony'] = values.withTelephony === 'yes'
      body['withFuel'] = values.withFuel === 'yes'
      body['withVaVr'] = values.withVaVr === 'yes'
      body['withVt'] = values.withVt === 'yes'
      body['withToll'] = values.withToll === 'yes'
      body['withExtras'] = values.withExtras === 'yes'
      body['withPayroll'] = values.withPayroll === 'yes'
      body['withVacationPayroll'] = values.withVacationPayroll === 'yes'
      body['withPontomaisAdjust'] = values.withPontomaisAdjust
      body['withPayrollHrAdjustment'] = values.withPayrollHrAdjustment === 'yes'
      body['customerId'] = values.customerId

      if (values.withVacationPayroll === 'yes') {
        body['vacationPayrollDescription'] = values.vacationPayrollDescription
      }

      if (!isLogLifeCustomer) {
        body['businessBudgetId'] = values.businessBudgetId
        body['amountToBeChargedInCents'] = Math.ceil(transformStringToNumber(values.amountToBeCharged) * 100)
      }
    }

    await promiseMessage(approveCltIntercurrenceFn({
      body,
      routeParams: {
        cltIntercurrenceId
      }
    }), 'Intercorrência aprovada com sucesso!')
  }

  const { data: customers } = useCustomers({})

  const customerId = useWatch({ control, name: 'customerId' })

  const selectedCustomer = customers?.find(customer => customer.id === customerId)

  useEffect(() => {
    if (selectedCustomer?.trading_firstname === 'LOGLIFE') {
      setIsLogLifeCustomer(true)
    } else {
      setIsLogLifeCustomer(false)
    }
  }, [selectedCustomer])

  const { data: businessBudgetRoutes } = useBusinessBudgetRoutes({
    customer_id: customerId,
    queryOptions: {
      enabled: !isLogLifeCustomer && !!customerId
    }
  })

  const { format: currencyFormat, onChange: onCurrencyChange } = useCurrency({
    style: 'decimal'
  })


  return (
    <Flex
      direction="column"
      gap="3"
      as="form"
      onSubmit={handleSubmit(handleApproveCltIntercurrence)}
    >

      <Stack
        direction={{ base: 'column', lg: 'row' }}
        spacing="3"
      >
        <FormControl isInvalid={!!errors.hasGenerateExtraDiscount}>
          <FormLabel>
            Vai gerar extra ou desconto?
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Controller
            name="hasGenerateExtraDiscount"
            control={control}
            render={({ field }) => (
              <RadioGroup {...field}>
                <Stack direction="column">
                  <Radio value="yes">Sim</Radio>
                  <Radio value="no">Não</Radio>
                  <Radio value="n/a">N.A</Radio>
                </Stack>
              </RadioGroup>
            )}
          />
        </FormControl>

        {isShowAllOtherFields && (
          <FormControl isInvalid={!!errors.withMotorcycleRental}>
            <FormLabel>
              Aluguel de moto
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="withMotorcycleRental"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="column">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        )}

      </Stack>

      {isShowAllOtherFields && (
        <>
          <Stack
            direction={{ base: 'column', lg: 'row' }}
            spacing="3"
          >
            <FormControl isInvalid={!!errors.withTelephony}>
              <FormLabel>
                Telefonia
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Controller
                name="withTelephony"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <Stack direction="column">
                      <Radio value="yes">Sim</Radio>
                      <Radio value="no">Não</Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
            </FormControl>

            <FormControl isInvalid={!!errors.withFuel}>
              <FormLabel>
                Combustível
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Controller
                name="withFuel"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <Stack direction="column">
                      <Radio value="yes">Sim</Radio>
                      <Radio value="no">Não</Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
            </FormControl>

          </Stack>

          <Stack
            direction={{ base: 'column', lg: 'row' }}
            spacing="3"
          >
            <FormControl isInvalid={!!errors.withVaVr}>
              <FormLabel>
                VA/VR
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Controller
                name="withVaVr"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <Stack direction="column">
                      <Radio value="yes">Sim</Radio>
                      <Radio value="no">Não</Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
            </FormControl>

            <FormControl isInvalid={!!errors.withVt}>
              <FormLabel>
                VT
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Controller
                name="withVt"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <Stack direction="column">
                      <Radio value="yes">Sim</Radio>
                      <Radio value="no">Não</Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
            </FormControl>

          </Stack>

          <Stack
            direction={{ base: 'column', lg: 'row' }}
            spacing="3"
          >
            <FormControl isInvalid={!!errors.withToll}>
              <FormLabel>
                Pedágio
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Controller
                name="withToll"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <Stack direction="column">
                      <Radio value="yes">Sim</Radio>
                      <Radio value="no">Não</Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
            </FormControl>

            <FormControl isInvalid={!!errors.withExtras}>
              <FormLabel>
                Extras
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Controller
                name="withExtras"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <Stack direction="column">
                      <Radio value="yes">Sim</Radio>
                      <Radio value="no">Não</Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
            </FormControl>
          </Stack>

          <Stack
            direction={{ base: 'column', lg: 'row' }}
            spacing="3"
          >
            <FormControl isInvalid={!!errors.withPayroll}>
              <FormLabel>
                Folha de pagamento
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Controller
                name="withPayroll"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <Stack direction="column">
                      <Radio value="yes">Sim</Radio>
                      <Radio value="no">Não</Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
            </FormControl>

            <FormControl isInvalid={!!errors.withPontomaisAdjust}>
              <FormLabel>
                Ajuste no iFractal realizado?
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Controller
                name="withPontomaisAdjust"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <Stack direction="column">
                      <Radio value="yes">Sim</Radio>
                      <Radio value="no">Não</Radio>
                      <Radio value="n/a">N/A</Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
            </FormControl>
          </Stack>

          <Stack
            direction={{ base: 'column', lg: 'row' }}
            spacing="3"
          >
            <FormControl isInvalid={!!errors.withVacationPayroll}>
              <FormLabel>
                Folha de pagamento - HE Feriado
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Controller
                name="withVacationPayroll"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <Stack direction="column">
                      <Radio value="yes">Sim</Radio>
                      <Radio value="no">Não</Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
            </FormControl>

            <FormControl isInvalid={!!errors.withPayrollHrAdjustment}>
              <FormLabel>
                Ajuste HE folha de pagamento
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Controller
                name="withPayrollHrAdjustment"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <Stack direction="column">
                      <Radio value="yes">Sim</Radio>
                      <Radio value="no">Não</Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
            </FormControl>
          </Stack>

          {withVacationPayroll === 'yes' && (
            <FormControl isInvalid={!!errors.vacationPayrollDescription}>
              <FormLabel>
                Descrição
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Textarea
                {...register('vacationPayrollDescription')}
              />
            </FormControl>
          )}

          <Stack
            direction={{ base: 'column', lg: 'row' }}
            spacing="3"
          >
            <FormControl isInvalid={!!errors.customerId}>
              <FormLabel>
                Cliente
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Select
                {...register('customerId')}
                placeholder="Selecione uma opção..."
              >
                {customers?.map(customer => {
                  return (
                    <option key={customer.id} value={customer.id}>{customer.trading_firstname}</option>
                  )
                })}
              </Select>
            </FormControl>

            {!isLogLifeCustomer && (
              <FormControl isInvalid={!!errors.businessBudgetId}>
                <FormLabel>
                  Cidade/Rota
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>
                <Select
                  {...register('businessBudgetId')}
                  placeholder="Selecione uma opção..."
                >
                  {businessBudgetRoutes?.routes.map(route => {
                    return (
                      <option key={route.id} value={route.id}>{route.route_nickname}</option>
                    )
                  })}
                </Select>
              </FormControl>

            )}
          </Stack>

          {!isLogLifeCustomer && (
            <FormControl isInvalid={!!errors.amountToBeCharged}>
              <FormLabel>
                Valor a ser cobrado
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>

              <InputGroup>
                <InputLeftAddon>R$</InputLeftAddon>
                <Input
                  {...register('amountToBeCharged')}
                  onChange={onCurrencyChange}
                  defaultValue={currencyFormat('000')}
                />
              </InputGroup>

            </FormControl>
          )}
        </>
      )}

      <Button
        type="submit"
        colorScheme="blue"
        w="min"
        alignSelf="end"
        disabled={isSubmitting}
        isLoading={isSubmitting}
      >
        Salvar
      </Button>
    </Flex>
  )
}
