import { api } from "services/api";
import axios from 'axios';
import { RequestError } from 'utils/errors/RequestErrors';

interface GenerateMotorcycleRentalAditiveBody {
  name: string
  cpf: string
  rg: string
  address: string
  firstContractDate: string
  valueInCents: number
}

interface GenerateMotorcycleRentalAditiveParams {
  body: GenerateMotorcycleRentalAditiveBody
}

export async function generateMotorcycleRentalAditive({
  body
}: GenerateMotorcycleRentalAditiveParams) {
  try {
    const { data } = await api.post('/contracts/motorcycle-rental-aditive', body)

    return data

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

