import { Box, Circle, HStack, Icon, Modal, ModalOverlay, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { FaCalendarAlt, FaPen, FaUserTie } from "react-icons/fa";
import { InternCltDetail } from "./InternCltDetail";
import { format } from "date-fns";
import { InternClt } from "api/internClts/_types/internClt";
import { useAuth } from "hooks/auth/useAuth";
import { useHistory } from "react-router-dom";

interface InternCltsKanbanCardProps {
  internClt: InternClt
}

export function InternCltsKanbanCard({ internClt }: InternCltsKanbanCardProps) {
  const { userLogged } = useAuth()
  const history = useHistory()

  const userCanEditInternClt = userLogged?.permissions.includes('edit-intern-clt')
  const {
    isOpen: isInternCltDetailModalOpen,
    onToggle: onToggleInternCltDetailModal,
  } = useDisclosure()

  return (
    <Box
      w="full"
      p={4}
      bg="white"
      borderRadius="md"
      boxShadow="sm"
      border="1px solid #E2E8F0"
      onClick={onToggleInternCltDetailModal}
      cursor='pointer'
    >
      <HStack justify="space-between" w="full">
        <Text fontWeight="bold" mb={2}>
          {internClt?.name}
        </Text>
        {userCanEditInternClt && (
          <Circle
            size="5"
            color="white"
            onClick={(e) => {
              e.stopPropagation()
              history.push(`/interno-clt/${internClt.id}/editar`)
            }}
          >
            <Icon as={FaPen} boxSize={"3.5"} color='blue.900' />
          </Circle>
        )}

      </HStack>


      <VStack align="start" spacing={2} fontSize="sm" color="gray.600">
        <Box>
          <HStack spacing={2}>
            <Icon as={FaCalendarAlt} />
            <Text fontSize="xs" fontWeight="bold">DATA ADMISSÃO</Text>
          </HStack>
          <Text ml={5}>{internClt?.admission_date ? format(new Date(internClt?.admission_date), 'dd/MM/yyyy') : '-'}</Text>
        </Box>
        <Box>
          <HStack spacing={2}>
            <Icon as={FaUserTie} />
            <Text fontSize="xs" fontWeight="bold">CARGO</Text>
          </HStack>
          <Text ml={5}>{internClt?.office?.name}</Text>
        </Box>
      </VStack>

      <Modal
        isOpen={isInternCltDetailModalOpen}
        onClose={onToggleInternCltDetailModal}
        size='6xl'
      >
        <ModalOverlay />
        <InternCltDetail
          internCltId={internClt.id}
        />
      </Modal>
    </Box>
  )
}
