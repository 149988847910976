import axios from "axios"
import { useAuth } from "hooks/auth/useAuth"
import { useToastify } from "hooks/toastify/useToastify"
import { useMutation, useQueryClient } from "react-query"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

interface IActionPreventiveResponsibles {
  alignment_actions: string
  preventive_responsible: string
  preventive_limit_date: string
}

interface CreateOccurrenceInput {
  service_id: string
  customer_id: string
  shipping_id?: string
  source_collector_id: string
  destination_collector_id: string
  owner: string
  type: string
  intercurrence: string
  occurrence_hour: string
  occurrence_date: string
  responsible_collector_id?: string
  service_step: string
  observation?: string
  occurrence_detail?: string
  occurrence_delay?: number
  source_city: string
  destination_city: string
  materials?: string
  collector_fine_value?: number
  service_new_source_branch_id?: string
  service_new_destination_branch_id?: string
  unsuccess_delivery_address_id?: string
  shipping_ticket_number?: number
  cause?: string
  corrective?: string
  preventive?: string
  destination_dry_ice_provider_id?: string
  additional_charge_step?: 'collect' | 'delivery'
}

interface UpdateOccurrenceAttachmentPhotoRequestProps {
  occurrenceId: string
  input: FormData
}

interface ValidateOccurrenceInput {
  proceed: 'SIM' | 'NÃO',
  service_new_planned_travel?: string | null
  cause?: string
  corrective?: string
  reason?: string
  provider_id?: string
}

interface ValidateOccurrenceRequestProps {
  id: string
  input: ValidateOccurrenceInput
}

interface AlignOccurrenceInput {
  alignment_indicated_user: string
  alignments: IActionPreventiveResponsibles[]
  collector_fine_value: number
}

interface AlignOccurrenceRequestProps {
  id: string
  input: AlignOccurrenceInput
}

interface AttachOccurrenceEvidencesRequestProps {
  occurrenceAlignmentId: string
  occurrenceId: string
  input: FormData
}


async function createOccurrenceReqFunction(input: CreateOccurrenceInput) {
  try {

    const { data } = await api.post("/occurrence", input)

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado")
    }
  }
}

async function updateOccurrenceAttachmentPhotoReqFunction({
  occurrenceId,
  input
}: UpdateOccurrenceAttachmentPhotoRequestProps) {
  try {
    await api.patch(`/occurrence/photo/${occurrenceId}`, input)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado")
    }
  }
}

async function validateOccurrenceReqFunction({
  id,
  input
}: ValidateOccurrenceRequestProps
) {
  try {
    await api.put(`/occurrence/validate/${id}`, input)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado')
    }
  }
}

async function alignOccurrenceReqFunction({
  id,
  input
}: AlignOccurrenceRequestProps
) {
  try {
    await api.put(`/occurrence/align/${id}`, input)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado')
    }
  }
}

async function attachOccurrenceEvidencesReqFunction({
  occurrenceAlignmentId,
  occurrenceId,
  input
}: AttachOccurrenceEvidencesRequestProps
) {
  try {
    await api.patch(`/occurrence/${occurrenceId}/alignments/${occurrenceAlignmentId}/attachments`, input)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado')
    }
  }
}

async function deleteOccurrenceReqFunction(id: string) {
  try {
    await api.delete(`/occurrence/${id}`)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado')
    }
  }
}

export function useOccurrenceFunctions() {
  const { handleLogout } = useAuth()

  const queryClient = useQueryClient()
  const { errorMessage, successMessage } = useToastify()

  const onRequestError = (message: string, status: number) => {
    if (status === 401) {
      errorMessage(message)
      handleLogout()
    } else {
      errorMessage(message)
    }
  }

  const onRequestSuccess = async (message: string) => {
    await queryClient.invalidateQueries(['occurrences'])

    successMessage(message)
  }

  const createOccurrence = useMutation("createOccurrence", createOccurrenceReqFunction, {
    onError: (error: RequestError) => onRequestError(error.message, error.httpCode),
    onSuccess: () => onRequestSuccess("Ocorrência criada com sucesso!")
  })

  const updateOccurrenceAttachmentPhoto = useMutation("createOccurrence", updateOccurrenceAttachmentPhotoReqFunction, {
    onError: (error: RequestError) => onRequestError(error.message, error.httpCode),
    onSuccess: () => onRequestSuccess("Foto atualizada com sucesso!")
  })

  const validateOccurrence = useMutation("validateOccurrence", validateOccurrenceReqFunction, {
    onError: (error: RequestError) => onRequestError(error.message, error.httpCode),
    onSuccess: () => onRequestSuccess("Ocorrência validada com sucesso!")
  })

  const alignOccurrence = useMutation("alignOccurrence", alignOccurrenceReqFunction, {
    onError: (error: RequestError) => onRequestError(error.message, error.httpCode),
    onSuccess: () => onRequestSuccess("Ocorrência alinhada com sucesso!")
  })

  const attachOccurrenceEvidences = useMutation("attachOccurrenceEvidences", attachOccurrenceEvidencesReqFunction, {
    onError: (error: RequestError) => onRequestError(error.message, error.httpCode),
    onSuccess: () => onRequestSuccess("Anexos enviados com sucesso!")
  })

  const deleteOccurrence = useMutation("deleteOccurrence", deleteOccurrenceReqFunction, {
    onError: (error: RequestError) => onRequestError(error.message, error.httpCode),
    onSuccess: () => onRequestSuccess("Ocorrência excluída com sucesso!")
  })

  return {
    alignOccurrence,
    createOccurrence,
    deleteOccurrence,
    validateOccurrence,
    attachOccurrenceEvidences,
    updateOccurrenceAttachmentPhoto,
  }
}
