import { Flex, Heading, FormControl, FormLabel, Stack, Textarea, Button, useRadioGroup, Text } from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import { FaCheck, FaInfo, FaTimes } from "react-icons/fa"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useMutation, useQueryClient } from "react-query"
import { createInteraction } from "api/service/interactions/createInteraction"
import { useToastify } from "hooks/toastify/useToastify"
import { InteractionsRadio } from "./InteractionsRadio"

const interactionOptions = {
  'without-return': {
    icon: FaTimes,
    styles: {
      bgColor: 'red.400',
      color: 'white'
    }
  },
  'awaiting-return': {
    icon: FaInfo,
    styles: {
      bgColor: 'yellow.400',
      color: 'white'
    }
  },
  'awaiting-update': {
    icon: FaCheck,
    styles: {
      bgColor: 'green.400',
      color: 'white'
    }
  },
}

interface CreateInteractionFormSchema {
  type: string
  description: string
}

const createInteractionSchema = yup.object({
  type: yup.string().required(),
  description: yup.string().required()
})

interface CreateInteractionFormProps {
  serviceId: string
}

export function CreateInteractionForm({ serviceId }: CreateInteractionFormProps) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting }
  } = useForm<CreateInteractionFormSchema>({
    resolver: yupResolver(createInteractionSchema)
  })

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'type',
  })

  const group = getRootProps()

  const queryClient = useQueryClient()

  const { mutateAsync: createInteractionFn } = useMutation({
    mutationFn: createInteraction,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['interactions', serviceId] })
      queryClient.invalidateQueries({ queryKey: ['delayed-collects'] })
      queryClient.invalidateQueries({ queryKey: ['next-to-delay-collects'] })
      queryClient.invalidateQueries({ queryKey: ['delayed-deliveries'] })
      queryClient.invalidateQueries({ queryKey: ['next-to-delay-deliveries'] })
      reset()
    }
  })

  const { promiseMessage } = useToastify()


  async function handleCreateInteraction(values: CreateInteractionFormSchema) {
    await promiseMessage(createInteractionFn({
      body: { ...values },
      routeParams: { serviceId: serviceId }
    }), 'Interação criada! 🎉')
  }

  return (
    <Flex
      direction="column"
      gap="6"
      as="form"
      onSubmit={handleSubmit(handleCreateInteraction)}
    >
      <Heading size="xs" letterSpacing="tight">Criar interação</Heading>
      <FormControl isInvalid={!!errors.type}>
        <FormLabel display="flex" gap="1" alignItems="center" fontSize="sm">
          Tipo de interação
          <Text color="red">*</Text>
        </FormLabel>
        <InteractionsRadio.Root
          {...register('type')}
          gap="2"
          align="center"
          {...group}
        >
          {Object.entries(interactionOptions).map(([key, { styles, icon }]) => {
            const radio = getRadioProps({ value: key })

            return (
              <InteractionsRadio.RadioItem
                key={key}
                props={radio}
                radio={{ icon, styles }}
              />
            )
          })}
        </InteractionsRadio.Root>
      </FormControl>

      <Stack
        direction="column"
        gap="2"
      >
        <FormControl isInvalid={!!errors.description}>
          <FormLabel display="flex" gap="1" alignItems="center" fontSize="sm">
            Descrição
            <Text color="red">*</Text>
          </FormLabel>
          <Textarea
            {...register('description')}
            fontSize="sm"
          />
        </FormControl>
      </Stack>

      <Button
        size="sm"
        colorScheme="blue"
        type="submit"
        w="min"
        rounded="xl"
        alignSelf="flex-end"
        isLoading={isSubmitting}
        isDisabled={isSubmitting}
      >
        Criar
      </Button>
    </Flex>
  )
}
