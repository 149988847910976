import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Flex, Icon, IconButton, Text, useDisclosure } from "@chakra-ui/react"
import { useAuth } from "hooks/auth/useAuth"
import { ComunicationAnswer, ComunicationQuestion, InternComunication } from "hooks/internComunication/dtos/InternComunication"
import { FaCheckCircle, FaClipboardList, FaClock, FaFileImage, FaHistory, FaIdBadge, FaInfoCircle, FaPaperPlane, FaPen, FaPlaneArrival, FaPlaneDeparture, FaRoute, FaTags, FaTruckLoading, FaUserAlt, FaUserFriends, FaUserTag } from "react-icons/fa"
import { internComunicationStatusDict } from "utils/internComunication/statusDict"
import { InternComunicationValidateAnswerModal } from "./InternComunicationValidateAnswerModal"
import { InternComunicationCardItem } from "./InternComunicationCardItem"
import { InternComunicationHistoryModal } from "./InternComunicationHistoryModal"
import { InternComunicationAnswerModal } from "./InternComunicationAnswerModal"
import { formatDate } from "utils/DateFunctions/formatDate"
import { differenceInMinutes } from "date-fns"
import { InternComunicationValidateAutoGeneratedModal } from "./InternComunicationValidateAutoGeneratedModal"
import { useEffect, useRef } from "react"
import { handleChangeUrl } from "utils/handleChangeUrl"
import { InternComunicationTransferResponsibleModal } from "./InternComunicationTransferResponsibleModal"


interface InternComunicationCardProps {
  internComunication: InternComunication
  ownerInternComunicationId?: string
  responsibleInternComunicationId?: string
}

export function InternComunicationCard({
  internComunication,
  ownerInternComunicationId,
  responsibleInternComunicationId
}: InternComunicationCardProps) {

  const { userLogged } = useAuth()

  const userLoggedHasValidateInternComunicationPermission = userLogged?.permissions.includes("validate-intern-comunication")
  const userLoggedHasAnswerInternComunicationPermission = userLogged?.permissions.includes("answer-intern-comunication")

  const {
    isOpen: isInternComunicationAnswerModalOpen,
    onClose: onCloseInternComunicationAnswerModal,
    onOpen: onOpenInternComunicationAnswerModal
  } = useDisclosure()

  const {
    isOpen: isInternComunicationValidateAnswerModalOpen,
    onClose: onCloseInternComunicationValidateAnswerModal,
    onOpen: onOpenInternComunicationValidateAnswerModal
  } = useDisclosure()

  const {
    isOpen: isInternComunicationHistoryModalOpen,
    onClose: onCloseInternComunicationHistoryModal,
    onOpen: onOpenInternComunicationHistoryModal
  } = useDisclosure()

  const {
    isOpen: isInternComunicationAutoGeneratedValidateModalOpen,
    onClose: onCloseInternComunicationAutoGeneratedValidateModal,
    onOpen: onOpenInternComunicationAutoGeneratedValidateModal,
  } = useDisclosure()

  const {
    isOpen: isInternComunicationTransferResponsibleModalOpen,
    onOpen: onOpenInternComunicationTransferResponsibleModal,
    onClose: onCloseInternComunicationTransferResponsibleModal,
  } = useDisclosure()

  const internComunicationTransferResponsibleFieldRef = useRef(null);


  useEffect(() => {
    if (responsibleInternComunicationId) {
      onOpenInternComunicationAnswerModal()
    }
  }, [onOpenInternComunicationAnswerModal, responsibleInternComunicationId])

  useEffect(() => {
    if (ownerInternComunicationId) {
      onOpenInternComunicationValidateAnswerModal()
    }
  }, [onOpenInternComunicationValidateAnswerModal, ownerInternComunicationId])

  const isAwaitingResponsibleAnswerStatus = internComunication.status === "WAITING-RESPONSIBLE-ANSWER"
  const isAwaitingOwnerAnswerStatus = internComunication.status === "WAITING-OWNER-ANSWER"
  const isAwaitingCommercialValidationStatus = internComunication.status === "WAITING-COMMERCIAL-VALIDATION"
  const isAwaitingFinancialValidationStatus = internComunication.status === "WAITING-FINANCIAL-VALIDATION"
  const isAwaitingLastMileValidationStatus = internComunication.status === "WAITING-LAST-MILE-VALIDATION"
  const isAwaitingMonitoringValidationStatus = internComunication.status === "WAITING-MONITORING-VALIDATION"

  const isUserLoggedInternComunicationResponsible = userLogged?.id === internComunication.comunication_responsible
  const isUserLoggedInternComunicationOwner = userLogged?.id === internComunication.comunication_owner
  const isUserLoggedCommercial = userLogged?.user_type.includes('COMERCIAL')
  const isUserLoggedFinancial = userLogged?.user_type.includes('FINANCEIRO')
  const isUserLoggedLastMile = userLogged?.user_type.includes('LAST MILE')
  const isUserLoggedMonitoring = userLogged?.user_type.includes('TORRE DE CONTROLE')
  const isUserLoggedMonitoringOrOperationDirection = [
    'SUCESSO DO CLIENTE - DIRETORIA',
    'SUCESSO DO CLIENTE - GERÊNCIA'
  ].includes(userLogged?.user_type)

  const isShowAnswerButtonToResponsible =
    isAwaitingResponsibleAnswerStatus &&
    isUserLoggedInternComunicationResponsible &&
    userLoggedHasAnswerInternComunicationPermission

  const isShowAnswerButtonToOwner =
    isAwaitingOwnerAnswerStatus &&
    isUserLoggedInternComunicationOwner &&
    userLoggedHasValidateInternComunicationPermission

  const isShowValidateButtonToCommercialResponsibleUserType =
    isAwaitingCommercialValidationStatus &&
    isUserLoggedCommercial

  const isShowValidateButtonToFinancialResponsibleUserType =
    isAwaitingFinancialValidationStatus &&
    isUserLoggedFinancial

  const isShowValidateButtonToLastMileResponsibleUser =
    (isUserLoggedInternComunicationResponsible || isUserLoggedLastMile || isUserLoggedMonitoringOrOperationDirection) &&
    isAwaitingLastMileValidationStatus

  const isShowValidateButtonToMonitoringOrOperationDirection =
    (
      (userLogged?.id === internComunication.comunication_responsible && isAwaitingMonitoringValidationStatus) ||
      isAwaitingMonitoringValidationStatus
    ) &&
    (isUserLoggedMonitoring || isUserLoggedMonitoringOrOperationDirection)

  const internComunicationQuestions = typeof internComunication.comunication_questions === 'string'
    ? JSON.parse(internComunication.comunication_questions) as ComunicationQuestion[]
    : internComunication.comunication_questions as ComunicationQuestion[]
  const internComunicationQuestionToAnswerModal = internComunicationQuestions[internComunicationQuestions.length - 1]?.description



  const internComunicationAnswers = typeof internComunication.comunication_answers === 'string'
    ? JSON.parse(internComunication.comunication_answers) as ComunicationAnswer[]
    : internComunication.comunication_answers as ComunicationAnswer[]
  const internComunicationLastResolutionMessages = {
    comunication_question: internComunicationQuestions[internComunicationQuestions.length - 1]?.description,
    comunication_answer: internComunicationAnswers[internComunicationQuestions.length - 1]?.description,
    attachments: internComunicationAnswers[internComunicationQuestions.length - 1]?.attachments
  }

  const internComunicationAnswerAttachments = internComunicationAnswers
    .flatMap((answer) => answer.attachments);


  const combinedInternComunicationAttachments = [internComunication?.attachment, ...internComunicationAnswerAttachments];


  const internComunicationAttachmentLinks = combinedInternComunicationAttachments
    .filter(Boolean)
    .map(attachment => attachment);



  return (
    <Flex
      direction='column'
      maxH='400px'
      h='full'
      bg="gray.50"
      borderRadius="8px"
      boxShadow="rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px"
      overflowY="scroll"
    >

      <Flex
        bg={
          internComunication.status !== 'DONE'
            ? differenceInMinutes(new Date(internComunication.comunication_deadline), new Date()) < 0
              ? "red.300"
              : "blue.300"
            : "blue.300"
        }
        justifyContent="space-between"
        w="full"
        gap="4"
        borderRadius="8px 8px 0 0"
        p="2"
      >
        <Text fontSize="xs" fontWeight="bold" color="white">
          {internComunication.protocol}
        </Text>
        <Text fontSize="xs" fontWeight="bold" color="white">
          {internComunicationStatusDict(internComunication.status)}
        </Text>
      </Flex>
      <Flex fontSize="sm" align="center" direction="column" h='full' justify='space-between' p={2} w="full">
        {internComunication.comunication_responsible && (
          <IconButton
            w={["full", "full", "min"]}
            size='sm'
            aria-label="Transferir Comunicação"
            icon={<FaPaperPlane />}
            alignSelf='flex-end'
            variant='outline'
            onClick={onOpenInternComunicationTransferResponsibleModal}
          />
        )}

        <InternComunicationTransferResponsibleModal
          isOpen={isInternComunicationTransferResponsibleModalOpen}
          onClose={onCloseInternComunicationTransferResponsibleModal}
          onOpen={onOpenInternComunicationTransferResponsibleModal}
          initialFocusRef={internComunicationTransferResponsibleFieldRef}
          closeOnBlur={true}
          internComunication={internComunication}
        />

        <Flex direction='column' align="center" w='full'>
          <InternComunicationHistoryModal
            isInternComunicationHistoryModalOpen={isInternComunicationHistoryModalOpen}
            onCloseInternComunicationHistoryModal={onCloseInternComunicationHistoryModal}
            comunicationAnswers={typeof internComunication.comunication_answers === 'string'
              ? JSON.parse(internComunication.comunication_answers) as ComunicationAnswer[]
              : internComunication.comunication_answers as ComunicationAnswer[]}
            comunicationQuestions={typeof internComunication.comunication_questions === 'string'
              ? JSON.parse(internComunication.comunication_questions) as ComunicationQuestion[]
              : internComunication.comunication_questions as ComunicationQuestion[]}
          />

          <InternComunicationAnswerModal
            isInternComunicationAnswerModalOpen={isInternComunicationAnswerModalOpen}
            onCloseInternComunicationAnswerModal={onCloseInternComunicationAnswerModal}
            internComunicationAnswerModalInfo={{
              ...internComunication,
              comunication_question: internComunicationQuestionToAnswerModal
            }}
          />

          <InternComunicationValidateAutoGeneratedModal
            internComunicationAutoGeneratedValidateModalInfo={{
              id: internComunication.id,
              hasOwner: internComunication.comunication_owner !== null,
              comunicationSubject: internComunication.comunication_subject,
              comunicationType: internComunication.comunication_type,
              internComunicationOpeningQuestionMessages: {
                comunication_question: internComunicationLastResolutionMessages.comunication_question
              }
            }}
            isInternComunicationAutoGeneratedValidateModalOpen={isInternComunicationAutoGeneratedValidateModalOpen}
            onCloseInternComunicationAutoGeneratedValidateModal={onCloseInternComunicationAutoGeneratedValidateModal}
          />

          <InternComunicationValidateAnswerModal
            internComunicationValidateAnswerModalInfo={{ ...internComunication, internComunicationLastResolutionMessages }}
            isInternComunicationValidateAnswerModalOpen={isInternComunicationValidateAnswerModalOpen}
            onCloseInternComunicationValidateAnswerModal={onCloseInternComunicationValidateAnswerModal}
          />

          <InternComunicationCardItem
            itemName={internComunication.protocol.toString()}
            icon={FaInfoCircle}
          />
          <InternComunicationCardItem
            itemName={internComunication.comunication_responsible ? `${internComunication.responsibleIDComunication.firstname} ${internComunication.responsibleIDComunication?.lastname}` : '-'}
            icon={FaUserTag}
          />

          <InternComunicationCardItem
            itemName={internComunication.comunication_owner ? `${internComunication.ownerIDComunication?.firstname} ${internComunication.ownerIDComunication?.lastname}` : '-'}
            icon={FaUserAlt}
          />
          <InternComunicationCardItem
            itemName={internComunication.comunication_type}
            icon={FaTags}
          />
          {internComunication.comunication_subject && (
            <InternComunicationCardItem
              itemName={internComunication.comunication_subject}
              icon={FaInfoCircle}
            />
          )}
          <InternComunicationCardItem
            itemName={formatDate.handle(internComunication.comunication_deadline, "DateWithDateAndHourMinute")}
            icon={FaClock}
          />
          {internComunication.serviceIDComunication !== null && (
            <>
              <InternComunicationCardItem
                itemName={String(internComunication.serviceIDComunication.protocol)}
                icon={FaRoute}
                isLinkItem
                href={`/servico/detalhes/${internComunication.serviceIDComunication.id}`}
              />
              <InternComunicationCardItem
                itemName={formatDate.handle(internComunication.serviceIDComunication.serviceIDRequested.collect_date, "DateWithoutHourToShow")}
                icon={FaTruckLoading}
              />
              <InternComunicationCardItem
                itemName={internComunication.serviceIDComunication.customerIDService?.trading_firstname}
                icon={FaIdBadge}
              />
              <InternComunicationCardItem
                itemName={`${internComunication.serviceIDComunication?.customerIDService.attendanceResponsibleIDCustomer?.firstname} ${internComunication?.serviceIDComunication?.customerIDService.attendanceResponsibleIDCustomer?.lastname}`}
                icon={FaUserFriends}
              />
              <InternComunicationCardItem
                itemName={internComunication.serviceIDComunication.serviceIDRequested.budgetIDService.sourceHubIDBudget.name}
                icon={FaPlaneDeparture}
              />
              <InternComunicationCardItem
                itemName={internComunication.serviceIDComunication.serviceIDRequested.budgetIDService.destinationHubIDBudget.name}
                icon={FaPlaneArrival}
              />
            </>
          )}
          {internComunication.comunication_occurrence && (
            <InternComunicationCardItem
              itemName={internComunication.occurrence.intercurrence}
              icon={FaClipboardList}
            />
          )}
          {Boolean(internComunicationAttachmentLinks.length) && (
            <Flex w="full" gap={1}>
              <Accordion allowMultiple >
                <AccordionItem border="none">
                  <h2>
                    <AccordionButton p={0} w="full" justifyContent="flex-start">
                      <Box flex="1" display="flex" alignItems="center" ml={0}>
                        <Icon as={FaFileImage} fontSize="xl" />
                        <Box as="span" ml={2}>
                          Anexos
                        </Box>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    {internComunicationAttachmentLinks && internComunicationAttachmentLinks?.map((attachment) => {
                      return (
                        <InternComunicationCardItem
                          key={attachment}
                          itemName='VISUALIZAR ANEXO'
                          icon={FaFileImage}
                          isExternal
                          href={handleChangeUrl(attachment)}
                        />
                      )
                    })}
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Flex>
          )}
        </Flex>
        <Flex w="full" gap={1}>
          <Button onClick={onOpenInternComunicationHistoryModal} leftIcon={<Icon as={FaHistory} />} w="full" fontSize="sm" variant="ghost">Histórico</Button>
          {isShowAnswerButtonToResponsible && (
            <Button
              leftIcon={<Icon as={FaPen} />}
              w="full"
              onClick={onOpenInternComunicationAnswerModal}
              fontSize="sm"
              variant="ghost"
              color="blue.300"
            >
              Responder
            </Button>
          )}
          {isShowAnswerButtonToOwner && (
            <Button
              leftIcon={<Icon as={FaCheckCircle} />}
              w="full"
              onClick={onOpenInternComunicationValidateAnswerModal}
              fontSize="sm"
              variant="ghost"
              color="blue.300"
            >
              Validar
            </Button>
          )}
          {(
            isShowValidateButtonToCommercialResponsibleUserType ||
            isShowValidateButtonToFinancialResponsibleUserType ||
            isShowValidateButtonToLastMileResponsibleUser ||
            isShowValidateButtonToMonitoringOrOperationDirection
          ) && (
              <Button
                leftIcon={<Icon as={FaCheckCircle} />}
                w="full"
                onClick={onOpenInternComunicationAutoGeneratedValidateModal}
                fontSize="sm"
                variant="ghost"
                color="blue.300"
              >
                Validar
              </Button>
            )}
        </Flex>
      </Flex>
    </Flex>
  )
}
