import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BoardsRelations } from "./components/BoardsRelations";
import { CollectsRelations } from "./components/CollectsRelations";

export function ServicesRelations() {
  const [tabIndex, setTabIndex] = useState(0)

  const searchParams = useSearchParams()
  const history = useHistory()

  const tab = searchParams.get('tab')

  useEffect(() => {
    switch (tab) {
      case 'collects':
        setTabIndex(0)
        break
      case 'boards':
        setTabIndex(1)
        break
      case 'deliveries':
        setTabIndex(2)
        break
      default:
        setTabIndex(0)
    }
  }, [tab])

  function handleChangeTab(index: number) {
    switch (index) {
      case 0:
        searchParams.set('tab', 'collects')
        break
      case 1:
        searchParams.set('tab', 'boards')
        break
      case 2:
        searchParams.set('tab', 'deliveries')
        break
      default:
        searchParams.set('tab', 'collects')
    }

    searchParams.set('page', '1')

    history.replace({ search: searchParams.toString() })
  }

  return (
    <Box
      p={6}
      rounded="md"
      bg="white"
    >
      <Tabs isLazy index={tabIndex} onChange={handleChangeTab}>
        <TabList>
          <Tab>Coletas</Tab>
          <Tab>Embarques</Tab>
          <Tab isDisabled>Entregas</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <CollectsRelations />
          </TabPanel>
          <TabPanel>
            <BoardsRelations />
            {/* <Boards /> */}
          </TabPanel>
          <TabPanel>
            {/* <Deliveries /> */}
          </TabPanel>
        </TabPanels>

      </Tabs>
    </Box>
  )
}
