import { Box, Button, Flex, Icon, IconButton, Td, Text, Tooltip, Tr } from "@chakra-ui/react"
import { Service } from "api/service/getBoardsRelation"
import { format } from "date-fns"
import { FaArrowRight, FaInfoCircle, FaSearch } from "react-icons/fa"
import { Link } from "react-router-dom"
import { captalize } from "utils/captalize"
import { formatDate } from "utils/DateFunctions/formatDate"
import { formatTimeByTimezoneUser } from "utils/formatTimeByTimezoneUser"
import { serviceDelay } from "utils/serviceDelay"

interface BoardsRelationsTableRowProps {
  service: Service
}

export function BoardsRelationsTableRow({ service }: BoardsRelationsTableRowProps) {

  const userTimezone = new Date().getTimezoneOffset() / 60

  const sourceHubTimezone = Boolean(service?.sourceAddresses?.length)
    ? service?.sourceAddresses[0].city.hub.timezone
    : null

  const isUserTimezoneDifferentFromSourceHubTimezone = userTimezone !== Number(sourceHubTimezone?.split('-')[1])


  const serviceInDelay = serviceDelay({
    timezoneOffset: sourceHubTimezone,
    serviceDate: service?.requestedService.boardDate,
    serviceHour: service?.requestedService.boardHour
  })

  const pathRedirect = '/relacao/servicos?tab=boards&page=1'

  return (
    <Tr
      color={service.hasOccurrence ? 'yellow.400' : serviceInDelay ? 'red.400' : ''}
    >
      <Td>
        <Flex align="center" gap="3">
          <IconButton
            as={Link}
            to={`/servico/detalhes/${service?.id}`}
            aria-label="Detalhes do serviço"
            size="sm"
            icon={<FaSearch />}
            variant="ghost"
          />

          {['toBoardService', 'boardingService'].includes(service.step) && (
            <Button
              as={Link}
              size="xs"
              lineHeight="1"
              leftIcon={<FaArrowRight />}
              rounded="md"
              variant="ghost"
              to={{
                pathname: `/servicos/embarques/${service?.id}`,
                state: {
                  path: pathRedirect
                }
              }}
            >
              Embarcar
            </Button>
          )}
        </Flex>
      </Td>
      <Td>{service.protocol}</Td>
      <Td>
        <Text w="300px" overflow="hidden" whiteSpace="pre-line">
          {service?.customer?.tradingFirstname}
        </Text>
      </Td>
      <Td>
        {service?.sourceAddresses?.map((address, index) => {
          const addressInfoString = captalize(`${address.street}, ${address.number}, ${address.neighborhood}, ${address.city.name}, ${address.complement} - ${address.cep}`)

          return (
            <Text w="300px" key={index} whiteSpace="pre-line">
              {service.sourceAddresses.length > 1 && index + 1 + '.'} {addressInfoString}
            </Text>
          )
        })}

      </Td>
      <Td>
        {sourceHubTimezone ? (
          <Flex align="center">
            {format(formatTimeByTimezoneUser({ serviceDateTime: new Date(service?.requestedService?.boardDate), timezoneOffset: sourceHubTimezone }), 'dd/MM/yyyy')}
            {isUserTimezoneDifferentFromSourceHubTimezone && (
              <Tooltip label={sourceHubTimezone}>
                <Box as="span" ml="2">
                  <Icon as={FaInfoCircle} />
                </Box>
              </Tooltip>
            )}
          </Flex>
        ) : (
          formatDate.handle(service?.requestedService?.boardDate, "DateWithoutHourToShow")
        )}
      </Td>
      <Td>
        {sourceHubTimezone ? (
          <Flex align="center">
            {format(formatTimeByTimezoneUser({ serviceDateTime: new Date(service?.requestedService?.boardHour), timezoneOffset: sourceHubTimezone }), 'HH:mm')}
            {isUserTimezoneDifferentFromSourceHubTimezone && (
              <Tooltip label={sourceHubTimezone}>
                <Box as="span" ml="2">
                  <Icon as={FaInfoCircle} />
                </Box>
              </Tooltip>
            )}
          </Flex>
        ) : (
          formatDate.handle(service?.requestedService?.boardHour, "DateOnlyWithHourMinute")
        )}
      </Td>
      <Td>
        {sourceHubTimezone ? (
          <Flex align="center">
            <Flex flexDirection='column'>
              <Text>Embarque {service?.requestedService?.shipping?.tradingName}</Text>
              <Text>{`${service?.requestedService?.sourceBranch?.nickname} ➡️ ${service?.requestedService?.destinationBranch?.nickname}`}</Text>
              <Text>{`até ${format(formatTimeByTimezoneUser({ serviceDateTime: new Date(service?.requestedService?.boardHour), timezoneOffset: sourceHubTimezone }), 'HH:mm')} `}</Text>
            </Flex>
            {isUserTimezoneDifferentFromSourceHubTimezone && (
              <Tooltip label={sourceHubTimezone}>
                <Box as="span" ml="2">
                  <Icon as={FaInfoCircle} />
                </Box>
              </Tooltip>
            )}
          </Flex>
        ) : (
          <Flex flexDirection='column'>
            <Text>Embarque {service?.requestedService?.shipping?.tradingName}</Text>
            <Text>{`${service?.requestedService?.sourceBranch?.nickname} ➡️ ${service?.requestedService?.destinationBranch?.nickname}`}</Text>
            <Text>{`até  ${formatDate.handle(service?.requestedService?.boardHour, "DateOnlyWithHourMinute")}`}</Text>
          </Flex>
        )}

      </Td>
      <Td>
        {service?.destinationAddresses?.map((address, index) => {
          const addressInfoString = captalize(`${address.street}, ${address.number}, ${address.neighborhood}, ${address.city.name}, ${address.complement} - ${address.cep}`)
          return (
            <Text w="300px" key={index} whiteSpace="pre-line">
              {service?.destinationAddresses.length > 1 && index + 1 + '.'} {addressInfoString}
            </Text>
          )
        })}
      </Td>

      <Td>{service?.requestedService?.sourceCollector?.tradingName}</Td>
      <Td>{service?.requestedService?.destinationCollector?.tradingName}</Td>
    </Tr>
  )
}
