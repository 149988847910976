import { format, toDate } from 'date-fns'
import { transformStringToNumber } from '../GeneralFunctions/FormatValuesFuntions/transformStringToNumber'

interface DataProps {
  situation: string
  company_name: string
  trading_name: string
  cnpj: string
  hub_list: string[]
  operational_email: string
  financial_email: string
  delay_cost: string
  cellphone: string
  telephone: string
  cep: string
  street: string
  number: string
  complement: string
  neighborhood: string
  state: string
  city: string
  municipal_register: string
  payment_type: string
  day_expiration: string
  business_open: string
  business_close: string
  saturday_open: string | null
  saturday_close: string | null
  sunday_open: string | null
  sunday_close: string | null
  has_driver_assign: string | boolean
  observation: string
  has_maintenance: 'yes' | 'no'
  state_register: string
  responsible_name: string
  responsible_email: string
  responsible_cpf: string
  documents: {
    name: string
    dueDate: string
    attachmentId: string
    file: File
  }[]
  doesUseSystem: 'yes' | 'no'
}

export function collectorFormatDataToBack(data: DataProps) {
  const hubList =
    data.hub_list.toString().split(',').length > 1
      ? data.hub_list
      : data.hub_list.toString().split('  ')
  const hourWeekOpen = data.business_open.split(':')
  const hourWeekClose = data.business_close.split(':')
  const hourSaturdayOpen =
    data.saturday_open !== null ? data.saturday_open.split(':') : ''
  const hourSaturdayClose =
    data.saturday_close !== null ? data.saturday_close.split(':') : ''
  const hourSundayOpen =
    data.sunday_open !== null ? data.sunday_open.split(':') : ''
  const hourSundayClose =
    data.sunday_close !== null ? data.sunday_close.split(':') : ''

  const dateNowWeekOpen = toDate(Date.now()).setHours(
    Number(hourWeekOpen[0]),
    Number(hourWeekOpen[1]),
    0,
  )

  const dateNowWeekClose = toDate(Date.now()).setHours(
    Number(hourWeekClose[0]),
    Number(hourWeekClose[1]),
    0,
  )
  const dateNowSaturdayOpen =
    hourSaturdayOpen.length <= 1
      ? null
      : toDate(Date.now()).setHours(
        Number(hourSaturdayOpen[0]),
        Number(hourSaturdayOpen[1]),
        0,
      )
  const dateNowSaturdayClose =
    hourSaturdayClose.length <= 1
      ? null
      : toDate(Date.now()).setHours(
        Number(hourSaturdayClose[0]),
        Number(hourSaturdayClose[1]),
        0,
      )
  const dateNowSundayOpen =
    hourSundayOpen.length <= 1
      ? null
      : toDate(Date.now()).setHours(
        Number(hourSundayOpen[0]),
        Number(hourSundayOpen[1]),
        0,
      )
  const dateNowSundayClose =
    hourSundayClose.length <= 1
      ? null
      : toDate(Date.now()).setHours(
        Number(hourSundayClose[0]),
        Number(hourSundayClose[1]),
        0,
      )

  const formatedDataWeekOpen = format(
    toDate(dateNowWeekOpen),
    "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
  )
  const formatedDataWeekClose = format(
    toDate(dateNowWeekClose),
    "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
  )
  const formatedDataSaturdayOpen =
    dateNowSaturdayOpen === null
      ? null
      : format(toDate(dateNowSaturdayOpen), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
  const formatedDataSaturdayClose =
    dateNowSaturdayClose === null
      ? null
      : format(toDate(dateNowSaturdayClose), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
  const formatedDataSundayOpen =
    dateNowSundayOpen === null
      ? null
      : format(toDate(dateNowSundayOpen), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
  const formatedDataSundayClose =
    dateNowSundayClose === null
      ? null
      : format(toDate(dateNowSundayClose), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")

  const filteredDocuments = data.documents
    .filter(doc => doc.attachmentId)
    .map(({ file, ...rest }) => rest);

  const newCollectorFormatedData = {
    state_register: data.state_register,
    situation: data.situation,
    company_name: data.company_name,
    trading_name: data.trading_name,
    cnpj: data.cnpj,
    hub_list: hubList,
    operational_email: data.operational_email,
    financial_email: data.financial_email,
    delay_cost: transformStringToNumber(String(data.delay_cost)),
    cellphone: data.cellphone,
    telephone: data.telephone,
    cep: data.cep,
    street: data.street,
    number: data.number,
    complement: data.complement,
    neighborhood: data.neighborhood,
    state: data.state,
    city: data.city,
    municipal_register: data.municipal_register,
    payment_type: data.payment_type,
    day_expiration: data.day_expiration,
    business_open: formatedDataWeekOpen,
    business_close: formatedDataWeekClose,
    saturday_open: formatedDataSaturdayOpen,
    saturday_close: formatedDataSaturdayClose,
    sunday_open: formatedDataSundayOpen,
    sunday_close: formatedDataSundayClose,
    has_driver_assign: data.has_driver_assign === "SIM" ? true : false,
    observation: data.observation,
    has_maintenance: data.has_maintenance,
    responsible_name: data.responsible_name,
    responsible_email: data.responsible_email,
    responsible_cpf: data.responsible_cpf,
    documents: filteredDocuments,
    doesUseSystem: data.doesUseSystem === 'yes'
  }

  return newCollectorFormatedData
}
