import { Box, Flex, Icon, IconButton, Modal, ModalOverlay, Td, Tooltip, Tr, useDisclosure } from "@chakra-ui/react"
import { Interactions } from "components/Interactions/Interactions"
import { formatDistanceToNowStrict, set } from "date-fns"
import { formatInTimeZone } from "date-fns-tz"
import { ptBR } from "date-fns/locale"
import { useAuth } from "hooks/auth/useAuth"
import { interactionStatusColors } from "pages/Dashboard/Llm/components/DelayedCollects"
import { FaInfoCircle, FaPeopleArrows } from "react-icons/fa"
import { captalize } from "utils/captalize"
import { timezonesUsed } from "utils/DateFunctions/timezones"
import { serviceHandleStep } from "utils/ServiceFunctions/serviceDict"

interface DelayedCollectTableRowProps {
  service: {
    id: string
    protocol: number
    step: string
    customer: string
    source_hub: string
    source_tz: string
    destination_hub: string
    destination_tz: string
    collect_date: string
    collect_hour_end: string
    last_interaction_status: string
  }
}

export function DelayedCollectTableRow({ service }: DelayedCollectTableRowProps) {
  const { userLogged } = useAuth()
  const isLLMUser = userLogged.loglife_employee
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [
    day,
    month,
    year
  ] = formatInTimeZone(service.collect_date, timezonesUsed[service.source_tz].timezone, 'dd/MM/yyyy').split('/').map(Number)

  const [
    hours,
    minutes
  ] = formatInTimeZone(service.collect_hour_end, timezonesUsed[service.source_tz].timezone, 'HH:mm').split(':').map(Number)

  const collectDateTime = set(new Date(), {
    date: day,
    month: month - 1,
    year,
    hours,
    minutes
  })

  const now = new Date();
  let distanceCollectDateTime = null
  let timeUntilCollect = null

  if (now > collectDateTime) {
    distanceCollectDateTime = formatDistanceToNowStrict(collectDateTime, { locale: ptBR });
  } else {
    timeUntilCollect = formatDistanceToNowStrict(collectDateTime, { locale: ptBR });
  }
  return (
    <Tr>
      <Td>
        {isLLMUser && (
          <Flex h="full" justify="flex-end" align="flex-end">
            <IconButton
              aria-label="Abrir Interações"
              icon={<FaPeopleArrows />}
              colorScheme={interactionStatusColors[service.last_interaction_status] || interactionStatusColors.default}
              size="sm"
              onClick={onOpen}
              variant='ghost'

            />
            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />

              <Interactions isOpen={isOpen} serviceId={service.id} />
            </Modal>
          </Flex>
        )}
      </Td>
      <Td>{service.protocol}</Td>
      <Td>{captalize(service.customer)}</Td>
      <Td>
        <Flex>
          {captalize(service.source_hub)}
          {Number(service.source_tz.split('-')[1]) !== new Date().getTimezoneOffset() / 60 && (
            <Tooltip label={service.source_tz}>
              <Box as="span" ml="2">
                <Icon as={FaInfoCircle} />
              </Box>
            </Tooltip>
          )}
        </Flex>
      </Td>
      <Td>
        <Flex>
          {captalize(service.destination_hub)}
          {Number(service.destination_tz.split('-')[1]) !== new Date().getTimezoneOffset() / 60 && (
            <Tooltip label={service.destination_tz}>
              <Box as="span" ml="2">
                <Icon as={FaInfoCircle} />
              </Box>
            </Tooltip>
          )}
        </Flex>
      </Td>
      <Td>{captalize(serviceHandleStep(service.step))}</Td>
      {timeUntilCollect && (
        <Td isNumeric>Coleta em: {captalize(timeUntilCollect)}
        </Td>
      )}
      {distanceCollectDateTime && (
        <Td isNumeric>{captalize(distanceCollectDateTime)}
        </Td>
      )}
    </Tr>
  )
}
