import { PopoverContent, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { RequestedServiceRequestedAddress } from "api/service/_types/RequestedServiceRequestedAddress";
import { format } from "date-fns";
import { captalize } from "utils/captalize";

interface RequestedCollectAddressesPopoverProps {
  requestedCollectAddresses: RequestedServiceRequestedAddress[]
}

export function RequestedCollectAddressesPopover({
  requestedCollectAddresses
}: RequestedCollectAddressesPopoverProps) {
  return (
    <PopoverContent>
      <TableContainer
        rounded="md"
        w="full"
      >
        <Table size="sm" w="full">
          <Thead
            h="30px"
            bg="#38c3fa"
            color="white"
          >
            <Tr>
              <Th color="white">Endereço</Th>
              <Th color="white">Validado em</Th>
            </Tr>
          </Thead>
          <Tbody>
            {requestedCollectAddresses?.map((requestedAddress) => {
              return (
                <Tr key={requestedAddress.id} bg="white">
                  <Td color="gray.900">{captalize(`${requestedAddress?.address?.street} ${requestedAddress?.address?.trading_name} - ${requestedAddress?.address?.branch}`)}</Td>
                  <Td color="gray.900">{format(new Date(requestedAddress.validatedAt), "dd/MM/yyy '-' HH:mm")}</Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>

    </PopoverContent>
  )
}
