
import { set } from "date-fns";
import { isCpfValid } from "utils/cpfCnpjValidateFunctions";
import * as yup from "yup"

export const createSupportSchema = yup.object({
  type: yup.string().required(),
  subject: yup.string().required(),
  bank: yup.mixed().when('$subject', {
    is: (value: string) =>
      value &&
      ['Alteração de conta de pagamento', 'Solicitação de alteração de dados cadastrais/conta de pagamento']
        .map(item => item.toLowerCase())
        .includes(value.toLowerCase()),
    then: yup.string().required(),
  }),
  agency: yup.mixed().when('$subject', {
    is: (value: string) =>
      value &&
      ['Alteração de conta de pagamento', 'Solicitação de alteração de dados cadastrais/conta de pagamento']
        .map(item => item.toLowerCase())
        .includes(value.toLowerCase()),
    then: yup.string().required(),
  }),
  accountNumber: yup.mixed().when('$subject', {
    is: (value: string) =>
      value &&
      ['Alteração de conta de pagamento', 'Solicitação de alteração de dados cadastrais/conta de pagamento']
        .map(item => item.toLowerCase())
        .includes(value.toLowerCase()),
    then: yup.string().required(),
  }),
  cpf: yup.mixed().when('$subject', {
    is: (value: string) =>
      value &&
      value.toLowerCase() === 'Alteração de conta de pagamento'.toLowerCase(),
    then: yup.string().transform(isCpfValid).required(),
    otherwise: yup.string().nullable(),
  }),
  collectorId: yup.mixed().when('type', {
    is: 'Parceiro',
    then: yup.string().required(),
  }),

  requestAttachmentId: yup.mixed().when('$subject', {
    is: (value: string) =>
      [
        'Alteração de dados cadastrais',
        'Envio de atestados',
        'Envio de documentos (cartão vacina, etc)',
        'Envio de registro de limpeza',
        'Envio de fatura',
        'Envio de nf',
      ].map(item => item.toLowerCase())
        .includes(value.toLowerCase()),
    then: yup.mixed().test((value: FileList) => value.length > 0)
  }),
  description: yup.string(),
  category: yup.mixed().when('type', {
    is: 'Interno',
    then: yup.string().required(),
  }),

  formData: yup.object().shape({
    withdrawalDate: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Aluguel de veículo',
        then: yup.string().required().transform((value, originalValue, ctx) => {
          if (!value) return null

          const [year, month, day] = originalValue?.split('-').map(Number)
          value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

          return value
        }),
      })
    }),
    expectedReturnDate: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Aluguel de veículo',
        then: yup.string().required().transform((value, originalValue, ctx) => {
          if (!value) return null

          const [year, month, day] = originalValue?.split('-').map(Number)
          value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

          return value
        }),
      })
    }),

    driverOfVehicle: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Aluguel de veículo',
        then: yup.string().required(),
      })
    }),
    contractType: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Aluguel de veículo',
        then: yup.string().required(),
      })
    }),

    rentalCompany: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Aluguel de veículo',
        then: yup.string().required(),
      })
    }),

    vehicleToRent: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Aluguel de veículo',
        then: yup.string().required(),
      })
    }),
    reasonForRental: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Aluguel de veículo',
        then: yup.string().required(),
      })
    }),
    rentalCostCenter: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Aluguel de veículo',
        then: yup.string().required(),
      })
    }),

    collector: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Envio de insumos para novo coletador',
        then: yup.string().required(),
      })
    }),
    isPrimaryCollector: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Envio de insumos para novo coletador',
        then: yup.string().required(),
      })
    }),
    expectedStartDate: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Envio de insumos para novo coletador',
        then: yup.string().required().transform((value, originalValue, ctx) => {
          if (!value) return null

          const [year, month, day] = originalValue?.split('-').map(Number)
          value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

          return value
        }),
      })
    }),

    description: yup.object().shape({
      value: yup.string().when('$subject', {
        is: (value: string) => [
          'Solicitação de necessidade de forncedor',
          'Manutenção/Melhoria predial',
          'Quantitativo insumos a enviar aos Hubs',
          'Solicitação de serviço',
          'Provisão de contas a receber (Ajuste, inclusão ou exclusão)',
          'Internet',
          'Servidor Rede',
          'E-mail (Correção ou Melhoria)',
          'Pipefy (Correção ou Melhoria)',
          'Vuupt',
          'Rota Exata',
          'Telefonia',
          'Microsoft Team',
          'Outro',
          'Assinatura de e-mail',
          'Criar e-mail',
          'Solicitação de inclusão de benefício',
          'Validação de documento(s)',
          'Criação de documento(s)',
          'Solicitação de treinamento'
        ].includes(value),
        then: yup.string().required(),
      })
    }),
    partnershipReturnDeadline: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de necessidade de forncedor',
        then: yup.string().required().transform((value, originalValue, ctx) => {
          if (!value) return null

          const [year, month, day] = originalValue?.split('-').map(Number)
          value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

          return value
        }),
      })
    }),

    expectedShippingDate: yup.object().shape({
      value: yup.string().when('$subject', {
        is: (value: string) => [
          'Solicitação de envio insumo emergencial',
          'Quantitativo insumos a enviar aos Hubs'
        ].includes(value),
        then: yup.string().required().transform((value, originalValue, ctx) => {
          if (!value) return null

          const [year, month, day] = originalValue?.split('-').map(Number)
          value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

          return value
        }),
      })
    }),

    emergencyShippingSender: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de envio insumo emergencial',
        then: yup.string().required(),
      })
    }),
    emergencyShippingRecipient: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de envio insumo emergencial',
        then: yup.string().required(),
      })
    }),
    justificationForSupplies: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de envio insumo emergencial',
        then: yup.string().required(),
      })
    }),
    proposedQuantityOfSupplies: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de envio insumo emergencial',
        then: yup.string().required(),
      })
    }),

    confirmedUseOfTarpaulinForProtection: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de envio insumo emergencial',
        then: yup.string().required(),
      })
    }),

    describeRequiredMaterials: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de material do almoxarifado',
        then: yup.string().required(),
      })
    }),

    materialAvailabilityDeadline: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de material do almoxarifado',
        then: yup.string().required().transform((value, originalValue, ctx) => {
          if (!value) return null

          const [year, month, day] = originalValue?.split('-').map(Number)
          value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

          return value
        }),
      })
    }),

    isNewEmployee: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de patrimônio',
        then: yup.string().required(),
      })
    }),
    patrimonyDescriptionAndRequestReason: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de patrimônio',
        then: yup.string().required(),
      })
    }),
    isPatrimonyPurchaseNecessary: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de patrimônio',
        then: yup.string().required(),
      })
    }),
    expectedStartDateForNewService: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Envio de insumo para coletador já existente',
        then: yup.string().required().transform((value, originalValue, ctx) => {
          if (!value) return null

          const [year, month, day] = originalValue?.split('-').map(Number)
          value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

          return value
        }),
      })
    }),

    receiptType: yup.object().shape({
      value: yup.string().when('$subject', {
        is: (value: string) => [
          'Comprovante de pagamento',
          'Consulta previsão de pagamento'
        ].includes(value),
        then: yup.string().required(),
      })
    }),
    startDate: yup.object().shape({
      value: yup.string().when('$subject', {
        is: (value: string) => [
          'Comprovante de pagamento',
          'Consulta previsão de pagamento'
        ].includes(value),
        then: yup.string().required().transform((value, originalValue, ctx) => {
          if (!value) return null

          const [year, month, day] = originalValue?.split('-').map(Number)
          value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

          return value
        }),
      })
    }),
    endDate: yup.object().shape({
      value: yup.string().when('$subject', {
        is: (value: string) => [
          'Comprovante de pagamento',
          'Consulta previsão de pagamento'
        ].includes(value),
        then: yup.string().required().transform((value, originalValue, ctx) => {
          if (!value) return null

          const [year, month, day] = originalValue?.split('-').map(Number)
          value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

          return value
        }),
      })
    }),

    paymentDescription: yup.object().shape({
      value: yup.string().when('$subject', {
        is: (value: string) => [
          'Comprovante de pagamento',
          'Consulta previsão de pagamento',
          'Solicitação de pagamento',
        ].includes(value),
        then: yup.string().required(),
      })
    }),

    suggestedPaymentDate: yup.object().shape({
      value: yup.string().when('$subject', {
        is: (value: string) => [
          'Solicitação de pagamento',
          'Acordo jurídico'
        ].includes(value),
        then: yup.string().required().transform((value, originalValue, ctx) => {
          if (!value) return null

          const [year, month, day] = originalValue?.split('-').map(Number)
          value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

          return value
        }),
      })
    }),
    costCenter: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de pagamento',
        then: yup.string().required(),
      })
    }),
    isPaymentReceiptNeeded: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de pagamento',
        then: yup.string().required(),
      })
    }),

    explainUsageReason: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Utilização de aplicativos (Uber/99)',
        then: yup.string().required(),
      })
    }),

    financialClassification: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Cartão de crédito LogLife',
        then: yup.string().required(),
      })
    }),
    purchaseCostCenter: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Cartão de crédito LogLife',
        then: yup.string().required(),
      })
    }),
    purchaseDescription: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Cartão de crédito LogLife',
        then: yup.string().required(),
      })
    }),
    installmentNumber: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Cartão de crédito LogLife',
        then: yup.string().required(),
      })
    }),
    supplierName: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Cartão de crédito LogLife',
        then: yup.string().required(),
      })
    }),
    purchaseDate: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Cartão de crédito LogLife',
        then: yup.string().required().transform((value, originalValue, ctx) => {
          if (!value) return null

          const [year, month, day] = originalValue?.split('-').map(Number)
          value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

          return value
        }),
      })
    }),
    totalAmount: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Cartão de crédito LogLife',
        then: yup.string().required(),
      })
    }),
    numberOfStops: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de Uber',
        then: yup.string().required(),
      })
    }),

    departureFullAddress: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de Uber',
        then: yup.string().required(),
      })
    }),

    destinationFullAddress: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de Uber',
        then: yup.string().required(),
      })
    }),
    usageDateTime: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de Uber',
        then: yup.string().required().transform((value, originalValue, ctx) => {
          if (!value) return null
          value = new Date(value).toISOString()
          return value
        })
      })
    }),

    usageDescriptionOrReason: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de Uber',
        then: yup.string().required(),
      })
    }),

    uberCostCenter: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de Uber',
        then: yup.string().required(),
      })
    }),
    isCollectorAdjustment: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de reajuste',
        then: yup.string().required(),
      })
    }),
    amountToBePaidByBankTransfer: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Acordo jurídico',
        then: yup.string().required(),
      })
    }),
    amountToBePaidByInvoice: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Acordo jurídico',
        then: yup.string().required(),
      })
    }),
    others: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Acordo jurídico',
        then: yup.string().required(),
      })
    }),
    describePaymentRequestAndDeadlines: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Acordo jurídico',
        then: yup.string().required(),
      })
    }),
    cteNumberToBeCancelled: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Cancelamento de CTE',
        then: yup.string().required(),
      })
    }),
    invoiceNumber: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Cancelamento de NFs',
        then: yup.string().required(),
      })
    }),

    billingDate: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de faturamento avulso',
        then: yup.string().required().transform((value, originalValue, ctx) => {
          if (!value) return null

          const [year, month, day] = originalValue?.split('-').map(Number)
          value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

          return value
        }),
      })
    }),
    dueDate: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de faturamento avulso',
        then: yup.string().required().transform((value, originalValue, ctx) => {
          if (!value) return null

          const [year, month, day] = originalValue?.split('-').map(Number)
          value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

          return value
        }),
      })
    }),
    invoiceType: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de faturamento avulso',
        then: yup.string().required(),
      })
    }),
    cnpj: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Análise de crédito de novo cliente correntista',
        then: yup.string().required(),
      })
    }),
    clientCorporateName: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Análise de crédito de novo cliente correntista',
        then: yup.string().required(),
      })
    }),
    adjustmentStartDate: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Provisão de contas a receber (Ajuste, inclusão ou exclusão)',
        then: yup.string().required().transform((value, originalValue, ctx) => {
          if (!value) return null

          const [year, month, day] = originalValue?.split('-').map(Number)
          value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

          return value
        }),
      })
    }),

    typesSupport: yup.object().shape({
      value: yup.string().when('$subject', {
        is: (value: string) => [
          'Internet', 'Servidor Rede', 'E-mail (Correção ou Melhoria)', 'Pipefy (Correção ou Melhoria)', 'Vuupt', 'Rota Exata', 'Telefonia', 'Microsoft Team', 'Outro', 'Assinatura de e-mail', 'Criar e-mail'
        ].includes(value),
        then: yup.string().required(),
      })
    }),
    whichEmailWouldYouLikeToUse: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Criar e-mail',
        then: yup.string().required(),
      })
    }),
    whichCopiesShouldBeReceived: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Criar e-mail',
        then: yup.string().required(),
      })
    }),
    signatureName: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Novo Entrante',
        then: yup.string().required(),
      })
    }),
    signaturePosition: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Novo Entrante',
        then: yup.string().required(),
      })
    }),
    signaturePhone: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Novo Entrante',
        then: yup.string().required(),
      })
    }),
    signatureAddress: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Novo Entrante',
        then: yup.string().required(),
      })
    }),
    fullName: yup.object().shape({
      value: yup.string().when('$subject', {
        is: (value: string) => [
          'Novo Entrante',
          'Desligamento',
          'Alteração de conta'
        ].includes(value),
        then: yup.string().required(),
      })
    }),
    emailToBeUsed: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Novo Entrante',
        then: yup.string().required(),
      })
    }),
    terminationDateTime: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Desligamento',
        then: yup.string().required().transform((value, originalValue, ctx) => {
          if (!value) return null
          value = new Date(value).toISOString()
          return value
        })
      })
    }),

    whichMonths: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de holerite/Informe de rendimentos',
        then: yup.string().required(),
      })
    }),
    questionOrSuggestion: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Dúvidas sobre o pagamento (salário ou benefícios)',
        then: yup.string().required(),
      })
    }),
    medicalNoteOrDocumentObservation: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Envio de atestados/documentos',
        then: yup.string().required(),
      })
    }),
    adjustmentOrIssue: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Ajustes ou problemas no ponto',
        then: yup.string().required(),
      })
    }),
    bank: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Alteração de conta',
        then: yup.string().required(),
      })
    }),
    agency: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Alteração de conta',
        then: yup.string().required(),
      })
    }),
    accountNumber: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Alteração de conta',
        then: yup.string().required(),
      })
    }),
    accountType: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Alteração de conta',
        then: yup.string().required(),
      })
    }),
    cpf: yup.object().shape({
      value: yup.string().when('$subject', {
        is: (value: string) => [
          'Alteração de conta',
          'Solicitação de RPA'
        ].includes(value),
        then: yup.string().required(),
      })
    }),
    changeReason: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Alteração de conta',
        then: yup.string().required(),
      })
    }),
    employee: yup.object().shape({
      value: yup.string().when('$subject', {
        is: (value: string) => [
          'Promoção de colaborador LLM',
          'Solicitação de HE autorizada'
        ].includes(value),
        then: yup.string().required(),
      })
    }),
    newPositionAndLevel: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Promoção de colaborador LLM',
        then: yup.string().required(),
      })
    }),
    newSalary: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Promoção de colaborador LLM',
        then: yup.string().required(),
      })
    }),
    changeStartDate: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Promoção de colaborador LLM',
        then: yup.string().required().transform((value, originalValue, ctx) => {
          if (!value) return null

          const [year, month, day] = originalValue?.split('-').map(Number)
          value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

          return value
        }),
      })
    }),
    newLeadershipBonus: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Promoção de colaborador LLM',
        then: yup.string().required(),
      })
    }),

    isSignatureAndMicrosoftPositionChangeNeeded: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Promoção de colaborador LLM',
        then: yup.string().required(),
      })
    }),
    reasonForHE: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de HE autorizada',
        then: yup.string().required(),
      })
    }),
    totalTime: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de HE autorizada',
        then: yup.string().required(),
      })
    }),
    heDate: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de HE autorizada',
        then: yup.string().required().transform((value, originalValue, ctx) => {
          if (!value) return null

          const [year, month, day] = originalValue?.split('-').map(Number)
          value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

          return value
        }),
      })
    }),
    kpiToBeReplaced: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Alteração de KPI',
        then: yup.string().required(),
      })
    }),
    kpiName: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Alteração de KPI',
        then: yup.string().required(),
      })
    }),
    kpiTarget: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Alteração de KPI',
        then: yup.string().required(),
      })
    }),
    howItWillBeMeasured: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Alteração de KPI',
        then: yup.string().required(),
      })
    }),

    recurringPayment: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de RPA',
        then: yup.string().required(),
      })
    }),

    applicantName: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de RPA',
        then: yup.string().required(),
      })
    }),

    occurrenceDate: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de RPA',
        then: yup.string().required().transform((value, originalValue, ctx) => {
          if (!value) return null

          const [year, month, day] = originalValue?.split('-').map(Number)
          value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

          return value
        }),
      })
    }),

    paymentDate: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de RPA',
        then: yup.string().required().transform((value, originalValue, ctx) => {
          if (!value) return null

          const [year, month, day] = originalValue?.split('-').map(Number)
          value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

          return value
        }),
      })
    }),

    providerFullName: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de RPA',
        then: yup.string().required(),
      })
    }),

    birthDate: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de RPA',
        then: yup.string().required().transform((value, originalValue, ctx) => {
          if (!value) return null

          const [year, month, day] = originalValue?.split('-').map(Number)
          value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

          return value
        }),
      })
    }),

    birthCity: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de RPA',
        then: yup.string().required(),
      })
    }),

    rg: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de RPA',
        then: yup.string().required(),
      })
    }),
    pis: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de RPA',
        then: yup.string().required(),
      })
    }),
    address: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de RPA',
        then: yup.string().required(),
      })
    }),
    cep: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de RPA',
        then: yup.string().required(),
      })
    }),
    motherName: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de RPA',
        then: yup.string().required(),
      })
    }),
    serviceProvided: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de RPA',
        then: yup.string().required(),
      })
    }),
    inssDeduction: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de RPA',
        then: yup.string().required(),
      })
    }),
    bankDetails: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de RPA',
        then: yup.string().required(),
      })
    }),
    serviceCityState: yup.object().shape({
      value: yup.string().when('$subject', {
        is: 'Solicitação de RPA',
        then: yup.string().required(),
      })
    }),

  }),
});
