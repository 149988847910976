import { Box, Button, Flex, FormControl, FormLabel, Heading, Input, Select, Stack, Text } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"
import { useToastify } from "hooks/toastify/useToastify";
import { useMutation, useQueryClient } from "react-query";
import { evaluateDocument } from "api/documents/evaluateDocument";
import { useLLMUsers } from "hooks/user/useLLMUsers";
import { captalize } from "utils/captalize";
import { set } from "date-fns";

interface AvaluateDocumentRequestProps {
  documentId: string
}

interface AvaluateDocumentRequestSchema {
  updatedById: string
  updateDate: string
  nextReviewDate: string
  classification: string
}

const avaluateDocumentRequestSchema = yup.object({
  updatedById: yup.string().required(),
  updateDate: yup.string().required().transform((value, originalValue, ctx) => {
    if (!value) return null

    const [year, month, day] = originalValue?.split('-').map(Number)
    value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

    return value
  }),
  nextReviewDate: yup.string().required().transform((value, originalValue, ctx) => {
    if (!value) return null

    const [year, month, day] = originalValue?.split('-').map(Number)
    value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

    return value
  }),
  classification: yup.string().required(),
})

export const documentClassifications = [
  "Documento comercial",
  "Documento da qualidade",
  "Documento financeiro",
  "Documento do RH",
  "Documento compras",
  "Documento TI",
  "Documento cultura organizacional",
  "Documento operacional"
];

export function EvaluateDocumentRequest({ documentId }: AvaluateDocumentRequestProps) {

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const {
    data: llmUsersData
  } = useLLMUsers({
    queryParams: {
      situation: 'ATIVO'
    }
  })

  const internalUsers = llmUsersData?.users?.filter((user) => !['MOTORISTA', 'CLIENTE', 'REMETENTE', 'COLETADOR'].includes(user.user_type)).sort((a, b) => `${a.firstname} ${a.lastname}`.localeCompare(
    `${b.firstname} ${b.lastname}`))

  const { mutateAsync: evaluateDocumentFn } = useMutation({
    mutationFn: evaluateDocument,
    onSuccess: () => {
      queryClient.invalidateQueries(['documents'])

      queryClient.invalidateQueries({ queryKey: 'non-evaluated-documents' })
      queryClient.invalidateQueries({ queryKey: 'expired-documents' })
      queryClient.invalidateQueries({ queryKey: 'active-documents' })
      queryClient.invalidateQueries({ queryKey: 'inactive-documents' })
      queryClient.invalidateQueries({ queryKey: ['document', documentId] })

    }
  })

  const {
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<AvaluateDocumentRequestSchema>({
    resolver: yupResolver(avaluateDocumentRequestSchema)
  })
  async function handleEvaluateDocumentRequest(values: AvaluateDocumentRequestSchema) {
    await promiseMessage(evaluateDocumentFn({
      body: values,
      routeParams: {
        documentId
      }
    }), 'Solicitação avaliada!')
  }
  return (
    <Box
      w="full"
      as="form"
      onSubmit={handleSubmit(handleEvaluateDocumentRequest)}
      maxH='500px'
      overflowY='scroll'
    >
      <Heading letterSpacing="tight" size='sm'>Avaliar Documento</Heading>

      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormLabel fontSize="sm">
            Atualizado por
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <FormControl isInvalid={!!errors.updatedById}>
            <Select
              {...register('updatedById')}
              name="updatedById"
              placeholder="Selecione..."
              size="sm"
              rounded="md"
            >
              {internalUsers?.map((user) => {
                return (
                  <option key={user.id} value={user.id}>{captalize(`${user.firstname} ${user.lastname}`)}</option>
                )
              })}
            </Select>
          </FormControl>
        </Stack>
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors?.updateDate}>
            <FormLabel fontSize="sm">
              Data de atualização
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('updateDate')}
              name="updateDate"
              type="date"
              size="sm"
              rounded="md" />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors?.nextReviewDate}>
            <FormLabel fontSize="sm">
              Próxima revisão
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('nextReviewDate')}
              name="nextReviewDate"
              type="date"
              size="sm"
              rounded="md" />
          </FormControl>
        </Stack>
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.classification} mt="3">
            <FormLabel fontSize="sm">
              Classificação
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>

            <Select
              {...register('classification')}
              name="classification"
              placeholder="Selecione..."
              size="sm"
              rounded="md"
            >
              {Object.entries(documentClassifications).map(([key, value]) => (
                <option key={key} value={value}>{value}</option>
              ))}
            </Select>

          </FormControl>
        </Stack>
      </Stack>
      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Avaliar
        </Button>
      </Flex>
    </Box>
  )
}
