import { api } from "services/api";
import axios from 'axios';
import { RequestError } from 'utils/errors/RequestErrors';

interface GenerateSalaryAccountAuthorizationLetterBody {
  address: string
  admissionDate: string
  cpf: string
  name: string
  rg: string
}

interface GenerateSalaryAccountAuthorizationLetterParams {
  body: GenerateSalaryAccountAuthorizationLetterBody
}

export async function generateSalaryAccountAuthorizationLetter({
  body
}: GenerateSalaryAccountAuthorizationLetterParams) {
  try {
    const { data } = await api.post('/contracts/salary-account-authorization-letter', body)

    return data

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

