import { Flex, Icon, IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure, Link as ChakraLink } from "@chakra-ui/react"
import { Interactions } from "components/Interactions/Interactions"
import { AllocatedServiceProps } from "contexts/AllocateServiceContext"
import { BranchProps } from "contexts/BranchContext"
import { OccurrenceProps } from "contexts/OccurrenceContext"
import { RequestedServiceProps, ServiceProps } from "contexts/ServiceContext"
import { differenceInMinutes, format, getDate, getMonth, getYear, isBefore, isSameDay, set } from "date-fns"
import { Address } from "hooks/address/dtos/Address"
import { useAuth } from "hooks/auth/useAuth"
import { interactionStatusColors } from "pages/Dashboard/Llm/components/DelayedCollects"
import { FaClock, FaPeopleArrows } from "react-icons/fa"
import { FiEdit3, FiEye } from "react-icons/fi"
import { Link } from "react-router-dom"
import { LandingServiceProps } from "services/getFunctions/getLandingServiceFunctions"
import { formatDate } from "utils/DateFunctions/formatDate"

interface AvailableServiceTableRowProps {
  service: ServiceProps
  branches: BranchProps[]
  addresses: Address[]
}

const handleChangeTextTableColor = (
  serviceIDLanding: LandingServiceProps[],
  serviceIDRequested: RequestedServiceProps,
  serviceIDAllocated: AllocatedServiceProps[],
) => {
  const crossdockingCollectorID = serviceIDRequested.crossdocking_collector_id
  const sourceCollectorID = serviceIDRequested.source_collector_id
  const destinationCollectorID = serviceIDRequested.destination_collector_id

  if (crossdockingCollectorID) {
    const hasCrossdockingLanding = serviceIDLanding.find(
      (landing) => landing.collector_id === crossdockingCollectorID,
    )

    if (!hasCrossdockingLanding) {
      const allocatedServiceByCrossdockingCollectorID =
        serviceIDAllocated.filter(
          (allocated) => allocated.collector_id === crossdockingCollectorID,
        )

      const allocatedAvailabilityDate =
        allocatedServiceByCrossdockingCollectorID[0].allocate_availability_date
      const allocatedAvailabilityHour =
        allocatedServiceByCrossdockingCollectorID[0].allocate_availability_hour

      const compareDateNowWithAllocated = compareAllocatedDateHourWithDateNow(
        allocatedAvailabilityDate,
        allocatedAvailabilityHour,
      )
      if (compareDateNowWithAllocated) {
        return true
      }
      return
    }
    if (hasCrossdockingLanding) {
      const hasDestinationCollectorLanding = serviceIDLanding.find(
        (landing) => landing.collector_id === destinationCollectorID,
      )

      if (!hasDestinationCollectorLanding) {
        const allocatedServiceByCrossdockingCollectorID =
          serviceIDAllocated.filter(
            (allocated) => allocated.collector_id === sourceCollectorID,
          )
        const allocatedAvailabilityDate =
          allocatedServiceByCrossdockingCollectorID[0]
            .allocate_availability_date
        const allocatedAvailabilityHour =
          allocatedServiceByCrossdockingCollectorID[0]
            .allocate_availability_hour

        const compareDateNowWithAllocated = compareAllocatedDateHourWithDateNow(
          allocatedAvailabilityDate,
          allocatedAvailabilityHour,
        )
        if (compareDateNowWithAllocated) {
          return true
        }
        return
      }
      return
    }
    return
  }

  const allocatedAvailabilityDate =
    serviceIDAllocated[0].allocate_availability_date
  const allocatedAvailabilityHour =
    serviceIDAllocated[0].allocate_availability_hour

  const compareDateNowWithAllocated = compareAllocatedDateHourWithDateNow(
    allocatedAvailabilityDate,
    allocatedAvailabilityHour,
  )

  if (compareDateNowWithAllocated) {
    return true
  }
}

const compareAllocatedDateHourWithDateNow = (
  allocatedAvailabilityDate: string,
  allocatedAvailabilityHour: string,
) => {
  const actualYear = getYear(new Date())
  const actualMonth = getMonth(new Date())
  const actualDate = getDate(new Date())

  const allocatedAvailabilityDateWithDefaultHour = set(
    new Date(allocatedAvailabilityDate),
    { hours: 12, minutes: 0, seconds: 0 },
  )

  const differenceBetweenAllocatedDayAndCurrentDay = differenceInMinutes(
    set(new Date(), { hours: 12, minutes: 0, seconds: 0 }),
    allocatedAvailabilityDateWithDefaultHour,
  )

  if (differenceBetweenAllocatedDayAndCurrentDay > 0) {
    return true
  }

  if (differenceBetweenAllocatedDayAndCurrentDay === 0) {
    const allocatedAvailabilityHourWithDefaultDate = set(
      new Date(allocatedAvailabilityHour),
      { year: actualYear, month: actualMonth, date: actualDate },
    )

    const differenceBetweenAllocatedHourAndCurrentHour = differenceInMinutes(
      set(new Date(), {
        year: actualYear,
        month: actualMonth,
        date: actualDate,
      }),
      allocatedAvailabilityHourWithDefaultDate,
    )

    if (differenceBetweenAllocatedHourAndCurrentHour >= 0) {
      return true
    }
  }

  return false
}


export function AvailableServiceTableRow({
  service,
  branches,
  addresses
}: AvailableServiceTableRowProps) {

  const { userLogged } = useAuth()
  const isLLMUser = userLogged.loglife_employee
  const { isOpen, onOpen, onClose } = useDisclosure();


  const sourceCrossdockingBranchID =
    service.serviceIDRequested.source_crossdocking_branch_id

  const sourceBranch = sourceCrossdockingBranchID
    ? service.serviceIDBoard.find(
      (boardService) =>
        boardService.collector_id ===
        service.serviceIDRequested
          .crossdocking_collector_id,
    ) !== undefined
      ? service.serviceIDLanding.find(
        (landingService) =>
          landingService.collector_id ===
          service.serviceIDRequested
            .crossdocking_collector_id,
      ) !== undefined
        ? String(
          branches
            .filter(
              (branch) =>
                branch.id ===
                service.serviceIDRequested
                  .source_crossdocking_branch_id,
            )
            .map((branch) => branch.nickname),
        )
        : String(
          branches
            .filter(
              (branch) =>
                branch.id ===
                service.serviceIDRequested
                  .source_branch_id,
            )
            .map((branch) => branch.nickname),
        )
      : String(
        branches
          .filter(
            (branch) =>
              branch.id ===
              service.serviceIDRequested.source_branch_id,
          )
          .map((branch) => branch.nickname),
      )
    : String(
      branches
        .filter(
          (branch) =>
            branch.id ===
            service.serviceIDRequested.source_branch_id,
        )
        .map((branch) => branch.nickname),
    )

  const destinationBranch = sourceCrossdockingBranchID
    ? service.serviceIDBoard.find(
      (boardService) =>
        boardService.collector_id ===
        service.serviceIDRequested
          .crossdocking_collector_id,
    ) !== undefined
      ? service.serviceIDLanding.find(
        (landingService) =>
          landingService.collector_id ===
          service.serviceIDRequested
            .crossdocking_collector_id,
      ) !== undefined
        ? String(
          branches
            .filter(
              (branch) =>
                branch.id ===
                service.serviceIDRequested
                  .destination_branch_id,
            )
            .map((branch) => branch.nickname),
        )
        : String(
          branches
            .filter(
              (branch) =>
                branch.id ===
                service.serviceIDRequested
                  .destination_crossdocking_branch_id,
            )
            .map((branch) => branch.nickname),
        )
      : String(
        branches
          .filter(
            (branch) =>
              branch.id ===
              service.serviceIDRequested
                .destination_crossdocking_branch_id,
          )
          .map((branch) => branch.nickname),
      )
    : String(
      branches
        .filter(
          (branch) =>
            branch.id ===
            service.serviceIDRequested
              .destination_branch_id,
        )
        .map((branch) => branch.nickname),
    )

  const shipping = sourceCrossdockingBranchID
    ? service.serviceIDBoard.find(
      (boardService) =>
        boardService.collector_id ===
        service.serviceIDRequested
          .crossdocking_collector_id,
    ) !== undefined
      ? service.serviceIDLanding.find(
        (landingService) =>
          landingService.collector_id ===
          service.serviceIDRequested
            .crossdocking_collector_id,
      ) !== undefined
        ? String(
          branches
            .filter(
              (branch) =>
                branch.id ===
                service.serviceIDRequested
                  .source_crossdocking_branch_id,
            )
            .map(
              (branch) =>
                branch.shippingIDBranch.company_name,
            ),
        )
        : String(
          branches
            .filter(
              (branch) =>
                branch.id ===
                service.serviceIDRequested
                  .source_branch_id,
            )
            .map(
              (branch) =>
                branch.shippingIDBranch.company_name,
            ),
        )
      : String(
        branches
          .filter(
            (branch) =>
              branch.id ===
              service.serviceIDRequested.source_branch_id,
          )
          .map(
            (branch) =>
              branch.shippingIDBranch.company_name,
          ),
      )
    : String(
      branches
        .filter(
          (branch) =>
            branch.id ===
            service.serviceIDRequested.source_branch_id,
        )
        .map(
          (branch) =>
            branch.shippingIDBranch.company_name,
        ),
    )

  const operationalNumber = sourceCrossdockingBranchID
    ? service.serviceIDBoard.find(
      (boardService) =>
        boardService.collector_id ===
        service.serviceIDRequested
          .crossdocking_collector_id,
    ) !== undefined
      ? service.serviceIDLanding.find(
        (landingService) =>
          landingService.collector_id ===
          service.serviceIDRequested
            .crossdocking_collector_id,
      ) !== undefined
        ? String(
          service.serviceIDBoard
            .filter(
              (boardService) =>
                boardService.branch_id ===
                service.serviceIDRequested
                  .source_crossdocking_branch_id,
            )
            .map(
              (boardService) =>
                boardService.operational_number,
            ),
        )
        : String(
          service.serviceIDBoard
            .filter(
              (boardService) =>
                boardService.branch_id ===
                service.serviceIDRequested
                  .source_branch_id,
            )
            .map(
              (boardService) =>
                boardService.operational_number,
            ),
        )
      : String(
        service.serviceIDBoard
          .filter(
            (boardService) =>
              boardService.branch_id ===
              service.serviceIDRequested.source_branch_id,
          )
          .map(
            (boardService) =>
              boardService.operational_number,
          ),
      )
    : String(
      service.serviceIDBoard
        .filter(
          (boardService) =>
            boardService.branch_id ===
            service.serviceIDRequested.source_branch_id,
        )
        .map(
          (boardService) =>
            boardService.operational_number,
        ),
    )

  const trackerLink = sourceCrossdockingBranchID
    ? service.serviceIDBoard.find((boardService) =>
      boardService.collector_id ===
      service.serviceIDRequested.crossdocking_collector_id
    )
      ? service.serviceIDLanding.find((landingService) =>
        landingService.collector_id ===
        service.serviceIDRequested.crossdocking_collector_id
      )
        ? String(
          service.serviceIDBoard
            .filter(
              (boardService) =>
                boardService.branch_id ===
                service.serviceIDRequested.source_crossdocking_branch_id
            )
            .map((boardService) => boardService.tracker_link)
        ) || null
        : String(
          service.serviceIDBoard
            .filter(
              (boardService) =>
                boardService.branch_id ===
                service.serviceIDRequested.source_branch_id
            )
            .map((boardService) => boardService.tracker_link)
        ) || null
      : null
    : String(
      service.serviceIDBoard
        .filter(
          (boardService) =>
            boardService.branch_id ===
            service.serviceIDRequested.source_branch_id
        )
        .map((boardService) => boardService.tracker_link)
    ) || null;


  const boardVolume = sourceCrossdockingBranchID
    ? service.serviceIDBoard.find(
      (boardService) =>
        boardService.collector_id ===
        service.serviceIDRequested
          .crossdocking_collector_id,
    ) !== undefined
      ? service.serviceIDLanding.find(
        (landingService) =>
          landingService.collector_id ===
          service.serviceIDRequested
            .crossdocking_collector_id,
      ) !== undefined
        ? String(
          service.serviceIDBoard
            .filter(
              (boardService) =>
                boardService.branch_id ===
                service.serviceIDRequested
                  .source_crossdocking_branch_id,
            )
            .map(
              (boardService) => boardService.board_volume,
            ),
        )
        : String(
          service.serviceIDBoard
            .filter(
              (boardService) =>
                boardService.branch_id ===
                service.serviceIDRequested
                  .source_branch_id,
            )
            .map(
              (boardService) => boardService.board_volume,
            ),
        )
      : String(
        service.serviceIDBoard
          .filter(
            (boardService) =>
              boardService.branch_id ===
              service.serviceIDRequested.source_branch_id,
          )
          .map(
            (boardService) => boardService.board_volume,
          ),
      )
    : String(
      service.serviceIDBoard
        .filter(
          (boardService) =>
            boardService.branch_id ===
            service.serviceIDRequested.source_branch_id,
        )
        .map((boardService) => boardService.board_volume),
    )
  const deliveryDelayIntercurrences = [
    "ATRASO NA ENTREGA",
    "CANCELAMENTO DE VOO",
    "CORTE DE VOO (NÃO ALOCADO VOO PLANEJADO)",
    "ATRASO NA TRANSFERÊNCIA",
    "ATRASO NA LIBERAÇÃO"
  ]

  const serviceDeliveryDelay = service.occurrenceIDService
    ?.filter(occurrence =>
      deliveryDelayIntercurrences.includes(occurrence.intercurrence) && occurrence.proceed === "SIM")
    ?.reduce((acc, curr) => new Date(acc.createdAt) > new Date(curr.createdAt) ? acc : curr, {} as OccurrenceProps)

  const hasDeliveryDelay = serviceDeliveryDelay?.id ? true : false

  const deliveryDate = serviceDeliveryDelay?.occurrence_date
    ? formatDate.handle(serviceDeliveryDelay.occurrence_date, "DateWithoutHourToShow")
    : formatDate.handle(service.serviceIDRequested.delivery_date, "DateWithoutHourToShow")

  const deliveryHour = serviceDeliveryDelay?.occurrence_hour
    ? formatDate.handle(serviceDeliveryDelay.occurrence_hour, "DateOnlyWithHourMinute")
    : formatDate.handle(service.serviceIDRequested.delivery_hour, "DateOnlyWithHourMinute")

  const isCrossdockingService = service.serviceIDRequested.crossdocking_collector_id

  let isServiceDelayed = false

  if (isCrossdockingService) {

    const isBoardDateBeforeToToday = isBefore(
      new Date(service.serviceIDRequested.board_date),
      new Date()
    )

    const isBoardDateSameToToday = isSameDay(
      new Date(service.serviceIDRequested.board_date),
      new Date()
    )

    if (!isBoardDateSameToToday && isBoardDateBeforeToToday) {
      isServiceDelayed = true
    } else if (isBoardDateSameToToday) {
      const [boardHours, boardMinutes] = format(new Date(service.serviceIDRequested.board_hour), "HH:mm").split(':').map(Number)

      isServiceDelayed = differenceInMinutes(
        set(new Date(), {
          hours: boardHours,
          minutes: boardMinutes
        }), new Date()
      ) < 240
    }
  }
  return (
    <Tr

      color={
        hasDeliveryDelay
          ? "yellow.400"
          : service.step === 'availableService'
            ? handleChangeTextTableColor(
              service.serviceIDLanding,
              service.serviceIDRequested,
              service.serviceIDAllocate,
            )
              ? 'green.400'
              : ''
            : ''
      }
      fontWeight={
        service.step === 'availableService'
          ? handleChangeTextTableColor(
            service.serviceIDLanding,
            service.serviceIDRequested,
            service.serviceIDAllocate,
          )
            ? 'bold'
            : ''
          : ''
      }
    >
      <Td>
        <Flex align='baseline' gap={2}>
          {isServiceDelayed && (
            <Icon as={FaClock} color={'red.500'} />
          )}
          {isLLMUser && (
            <Flex h="full" justify="flex-end" align="flex-end">
              <IconButton
                aria-label="Abrir Interações"
                icon={<FaPeopleArrows />}
                colorScheme={interactionStatusColors[service.last_interaction_status] || interactionStatusColors.default}
                size="sm"
                onClick={onOpen}
                variant='ghost'

              />
              <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />

                <Interactions isOpen={isOpen} serviceId={service.id} />
              </Modal>

            </Flex>
          )}
        </Flex>
      </Td>
      <Td isNumeric>
        {!!userLogged && (
          <>
            {userLogged.permissions.includes(
              'add-available-service',
            ) &&
              service.step !== 'availableService' && (
                <Link
                  to={`/servicos/disponibilizar/desembarque/${service.id}`}
                >
                  <Icon
                    cursor="pointer"
                    as={FiEdit3}
                    fontSize="20"
                    mt={['2', '2', '0']}
                  />
                </Link>
              )}
            <Link to={`/servico/detalhes/${service.id}`}>
              <Icon
                cursor="pointer"
                as={FiEye}
                fontSize="20"
              />
            </Link>
          </>
        )}
      </Td>
      <Td fontSize="12">{service.protocol}</Td>
      <Td fontSize="12">{deliveryDate}</Td>
      <Td fontSize="12">{deliveryHour}</Td>
      <Td fontSize="12">{shipping.toUpperCase()}</Td>
      <Td fontSize="12">{
        trackerLink ?
          <ChakraLink href={trackerLink} isExternal>
            {operationalNumber}
          </ChakraLink>
          : operationalNumber}</Td>
      <Td fontSize="12">{sourceBranch.toUpperCase()}</Td>
      <Td fontSize="12">
        {destinationBranch.toUpperCase()}
      </Td>
      <Td fontSize="12">
        {service.customerIDService.trading_firstname.toUpperCase()}
      </Td>
      <Td fontSize="12">{boardVolume}</Td>
      <Td fontSize="12">
        {Array.from(
          new Set(
            addresses
              .filter((address) =>
                service.serviceIDRequested.source_address_id.includes(
                  address.id,
                ),
              )
              .map((address) => address.cityIDAddress.name),
          ),
        )
          .join(', ')
          .toUpperCase()}
      </Td>
      <Td fontSize="12">
        {Array.from(
          new Set(
            addresses
              .filter((address) =>
                service.serviceIDRequested.destination_address_id.includes(
                  address.id,
                ),
              )
              .map((address) => address.cityIDAddress.name),
          ),
        )
          .join(', ')
          .toUpperCase()}
      </Td>
    </Tr>
  )
}
