import { Flex, IconButton, Input, Select } from "@chakra-ui/react";
import { useAuth } from "hooks/auth/useAuth";

import { useSearchParams } from "hooks/useSearchParams";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FaSearch, FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";

type PriceSituations = 'active' | 'inactive'

interface PriceTableFiltersSchema {
  situation: PriceSituations | 'all'
  sourceCity: string
  destinationCity: string
}

export function PricesTableFilters() {
  const searchParams = useSearchParams()
  const history = useHistory()
  const { userLogged } = useAuth()

  const isCustomerUserLogged = userLogged?.user_type === 'CLIENTE'

  const sourceCity = searchParams.get('sourceCity')
  const destinationCity = searchParams.get('destinationCity')
  const situation = isCustomerUserLogged ? 'active' : searchParams.get('situation') ?? 'all'

  useEffect(() => {
    if (isCustomerUserLogged && searchParams.get('situation') !== 'active') {
      searchParams.set('situation', 'active')

      history.replace({ search: searchParams.toString() })
    }
  }, [isCustomerUserLogged, history, searchParams])

  const {
    register,
    handleSubmit,
    reset,
  } = useForm<PriceTableFiltersSchema>({
    defaultValues: {
      sourceCity: sourceCity,
      destinationCity: destinationCity,
      situation: situation as PriceSituations | 'all',
    }
  })

  async function handleFilterPrices(data: PriceTableFiltersSchema) {
    if (data.sourceCity) {
      searchParams.set('sourceCity', data.sourceCity)
    } else {
      searchParams.delete('sourceCity')
    }
    if (data.destinationCity) {
      searchParams.set('destinationCity', data.destinationCity)
    } else {
      searchParams.delete('destinationCity')
    }

    if (data.situation) {
      searchParams.set('situation', data.situation)
    } else {
      searchParams.delete('situation')
    }

    searchParams.set('page', '1')

    history.replace({ search: searchParams.toString() })
  }

  function handleClearFilters() {
    searchParams.delete('sourceCity')
    searchParams.delete('destinationCity')
    searchParams.delete('situation')

    searchParams.set('page', '1')

    reset({
      sourceCity: '',
      situation: 'all',
      destinationCity: ''

    })

    history.replace({ search: searchParams.toString() })
  }

  return (
    <Flex
      align="center"
      gap="2"
      direction={{ base: 'column', lg: 'row' }}
      mt="6"
      as="form"
      onSubmit={handleSubmit(handleFilterPrices)}
    >
      <Input
        {...register("sourceCity")}
        placeholder="Cidade de Origem"
        size="sm"
        rounded="md"
        w={{ base: 'full', lg: '300px' }}
      />
      <Input
        {...register("destinationCity")}
        placeholder="Cidade de Destino"
        size="sm"
        rounded="md"
        w={{ base: 'full', lg: '300px' }}
      />

      <Select
        {...register("situation")}
        defaultValue="all"
        size="sm"
        rounded="md"
        isDisabled={isCustomerUserLogged}
        w={{ base: 'full', lg: '300px' }}
      >
        <option value='active'>Ativo</option>
        <option value='inactive'>Inativo</option>
        <option value="all">Todos</option>
      </Select>

      <IconButton
        aria-label=""
        icon={<FaSearch />}
        variant="ghost"
        size="sm"
        type="submit"
      />

      <IconButton
        aria-label=""
        icon={<FaTimes />}
        variant="ghost"
        type="button"
        size="sm"
        onClick={handleClearFilters}
      />

    </Flex>
  )
}
