import { ModalBody, ModalCloseButton, ModalContent, ModalHeader, Skeleton, Table, Tbody, Td, Tr } from "@chakra-ui/react"
import { getPrice } from "api/prices/getPrice"
import { useQuery } from "react-query"
import { captalize } from "utils/captalize"
import { statesWithUfList } from "utils/CustomLists/statesWithUfList"
import { PriceStatus } from "./PriceStatus"


interface PriceDetailProps {
  priceId: string
}

export function PriceDetails({
  priceId,
}: PriceDetailProps) {

  const {
    data: priceResult,
    isLoading: isLoadingPriceResult
  } = useQuery({
    queryKey: ['price', priceId],
    queryFn: () => getPrice({ priceId })
  })



  return (
    <ModalContent maxW='750px'>
      <ModalHeader>
        Detalhes do Preço
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody
        maxH='500px'
        overflowY='scroll'
      >
        <Table size="sm">
          <Tbody>
            {isLoadingPriceResult ? (
              Array.from({ length: 10 }).map((_, index) => {
                return (
                  <Tr key={index}>
                    <Td>
                      <Skeleton w="full" h="16px" />
                    </Td>
                    <Td isNumeric>
                      <Skeleton w="full" h="16px" />
                    </Td>
                  </Tr>
                )
              })
            ) : (
              <>
                <Tr>
                  <Td>Situação</Td>
                  <Td display="flex" justifyContent="flex-end">
                    <PriceStatus
                      status={priceResult?.price?.situation}
                    />
                  </Td>
                </Tr>

                <Tr>
                  <Td>UF Origem</Td>
                  <Td isNumeric>
                    {captalize(Object.entries(statesWithUfList)
                      .filter(
                        ([key, value]) =>
                          key.toUpperCase() ===
                          priceResult?.price?.sourceCity.state,
                      )
                      .map(([key, value]) => value.uf)[0])}
                  </Td>
                </Tr>

                <Tr>
                  <Td>Hub Origem</Td>
                  <Td isNumeric>
                    {captalize(priceResult?.price?.sourceHub?.name)}
                  </Td>
                </Tr>

                <Tr>
                  <Td>Cidade de Origem</Td>
                  <Td isNumeric>
                    {captalize(priceResult?.price?.sourceCity.name)}
                  </Td>
                </Tr>
                <Tr>
                  <Td>UF Destino</Td>
                  <Td isNumeric>
                    {captalize(Object.entries(statesWithUfList)
                      .filter(
                        ([key, value]) =>
                          key.toUpperCase() ===
                          priceResult?.price?.destinationCity.state,
                      )
                      .map(([key, value]) => value.uf)[0])}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Cidade de Destino</Td>
                  <Td isNumeric>
                    {captalize(priceResult?.price?.destinationCity?.name)}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Hub Destino</Td>
                  <Td isNumeric>
                    {captalize(priceResult?.price?.destinationHub?.name)}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Tipo de Envio</Td>
                  <Td isNumeric>
                    {captalize(priceResult?.price?.category)}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Tipo de serviço</Td>
                  <Td isNumeric>
                    {priceResult?.price?.serviceType ? captalize(priceResult?.price?.serviceType) : '-'}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Tipo de rota</Td>
                  <Td isNumeric>
                    {captalize(priceResult?.price?.routeType)}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Veículo</Td>
                  <Td isNumeric>
                    {captalize(priceResult?.price?.vehicle)}
                  </Td>
                </Tr>
                {priceResult?.price?.serviceType === 'FRACIONADO' && (
                  <Tr>
                    <Td>Transportadoras</Td>
                    <Td isNumeric>
                      {priceResult?.price?.shippings?.map((shipping) => captalize(shipping?.shipping?.trading_name)).join(', ')}
                    </Td>
                  </Tr>
                )}
                {(priceResult?.price?.routeType === 'AÉREO' || priceResult?.price?.routeType === 'AMBOS') && (
                  <>
                    <Tr>
                      <Td>Preço mínimo aéreo</Td>
                      <Td isNumeric>
                        {new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL'
                        }).format(Number(priceResult?.price?.airMinimumPrice))}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>KG extra aéreo</Td>
                      <Td isNumeric>
                        {new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL'
                        }).format(Number(priceResult?.price?.airExtraKg))}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Prazo aéreo</Td>
                      <Td isNumeric>
                        D + {priceResult?.price?.airDeadline}
                      </Td>
                    </Tr>
                  </>
                )}
              </>
            )}
            {(priceResult?.price?.routeType === 'RODOVIÁRIO' || priceResult?.price?.routeType === 'AMBOS') && (
              <>
                <Tr>
                  <Td>Preço mínimo rodoviário</Td>
                  <Td isNumeric>
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    }).format(Number(priceResult?.price?.highwayMinimumPrice))}
                  </Td>
                </Tr>
                <Tr>
                  <Td>KG extra rodoviário</Td>
                  <Td isNumeric>
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    }).format(Number(priceResult?.price?.highwayExtraKg))}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Prazo rodoviário</Td>
                  <Td isNumeric>
                    D + {priceResult?.price?.highwayDeadline}
                  </Td>
                </Tr>
              </>
            )}
            <Tr>
              <Td>Preço adicional coleta</Td>
              <Td isNumeric>
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }).format(Number(priceResult?.price?.additionalCollectPrice))}
              </Td>
            </Tr>
            <Tr>
              <Td>Preço adicional entrega</Td>
              <Td isNumeric>
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }).format(Number(priceResult?.price?.additionalDeliveryPrice))}
              </Td>
            </Tr>
            <Tr>
              <Td>Observações</Td>
              <Td isNumeric>
                {priceResult?.price?.observation ? priceResult?.price?.observation : '-'}
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </ModalBody>

    </ModalContent>
  )
}
