import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

export interface GetBackupCollectorsUtilizationParams {
  startDate: string
  finalDate: string
}

export interface GetBackupCollectorsUtilizationResponse {
  services: {
    protocol: number
    sourceAddresses: {
      id: string
      city: {
        id: string
        name: string
      }
    }[]
    destinationAddresses: {
      id: string
      city: {
        id: string
        name: string
      }
    }[]
    customer: {
      id: string
      trading_firstname: string
    }
    sourceCollector: string
    destinationCollector: string
    step: string
  }[]
}


export async function getBackupCollectorsUtilization({
  startDate,
  finalDate
}: GetBackupCollectorsUtilizationParams) {
  try {
    const response = await api.get<GetBackupCollectorsUtilizationResponse>('/collectors/backup/report', {
      params: {
        startDate,
        finalDate,
      }
    })

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
