import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { PriceLogs } from "./_types/Price";


interface GetPriceLogsParams {
  pageSize: number
  currentPage: number
  priceId: string
}

export interface GetPriceLogsResponse {
  logs: PriceLogs[]
  meta: {
    currentPage: number
    pageSize: number
    totalPages: number
    count: number
  }
}

export async function getPriceLogs({
  pageSize,
  currentPage,
  priceId
}: GetPriceLogsParams) {
  try {
    const { data } = await api.get<GetPriceLogsResponse>(`/prices/${priceId}/logs`, {
      params: {
        pageSize,
        currentPage,
      }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

