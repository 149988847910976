import { Button, Flex, Input, Select } from "@chakra-ui/react"
import { ExtraDiscountStatus } from "hooks/extrasDiscounts/dtos/ExtrasDiscounts";
import { useSearchParams } from "hooks/useSearchParams";
import { useForm } from "react-hook-form";
import { FaSearch, FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";


interface CollectorsExtrasDiscountsTableFiltersSchema {
  status: ExtraDiscountStatus | 'all'
  collector: string
  startDate: string
  endDate: string
  route: string
}

export function CollectorsExtrasDiscountsTableFilter() {
  const searchParams = useSearchParams()
  const { replace } = useHistory()

  const collector = searchParams.get('collector')
  const status = searchParams.get('status') ?? 'all'
  const startDate = searchParams.get('startDate')
  const endDate = searchParams.get('endDate')

  const {
    register,
    handleSubmit,
    reset,
  } = useForm<CollectorsExtrasDiscountsTableFiltersSchema>({
    defaultValues: {
      collector,
      startDate,
      endDate,
      status: status as ExtraDiscountStatus | 'all'
    }
  })

  async function handleFilterCollectorsExtrasDiscounts(data: CollectorsExtrasDiscountsTableFiltersSchema) {
    if (data.collector) {
      searchParams.set('collector', data.collector)
    } else {
      searchParams.delete('collector')
    }
    if (
      data.status) {
      searchParams.set('status', data.status)
    } else {
      searchParams.delete('status')
    }

    if (data.startDate) {
      searchParams.set('startDate', data.startDate)
    } else {
      searchParams.delete('startDate')
    }

    if (data.endDate) {
      searchParams.set('endDate', data.endDate)
    } else {
      searchParams.delete('endDate')
    }

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  function handleRemoveFilters() {
    searchParams.delete('status')
    searchParams.delete('collector')
    searchParams.delete('startDate')
    searchParams.delete('endDate')
    reset({
      collector: '',
      endDate: '',
      startDate: '',
      status: 'all'
    })

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  return (
    <Flex
      align="center"
      gap="2"
      direction={["column", "column", "row"]}
      mt="6"
      as="form"
      onSubmit={handleSubmit(handleFilterCollectorsExtrasDiscounts)}
    >
      <Input
        {...register("collector")}
        placeholder="Coletador"
        size="sm"
        rounded="md"
        w={["full", "full", "min"]}
      />
      <Input
        {...register("route")}
        placeholder="Rota"
        size="sm"
        rounded="md"
        w={["full", "full", "min"]}
      />
      <Input
        {...register("startDate")}
        type="date"
        size="sm"
        rounded="md"
        w={{ base: 'full', md: 'fit-content' }}
      />

      <Input
        {...register("endDate")}
        type="date"
        size="sm"
        rounded="md"
        w={{ base: 'full', md: 'fit-content' }}
      />

      <Select
        {...register("status")}
        defaultValue="all"
        size="sm"
        rounded="md"
        w={["full", "full", "200px"]}
      >
        <option value="all">Todos status</option>
        <option value="requested">Solicitado</option>
        <option value="approved">Aprovado</option>
        <option value="effectivated">Efetivado</option>
        <option value="rejected">Rejeitado</option>
      </Select>


      <Button
        size="sm"
        leftIcon={<FaSearch />}
        lineHeight="1"
        type="submit"
        w={["full", "full", "min"]}
      >
        Filtrar resultados
      </Button>

      <Button
        size="sm"
        leftIcon={<FaTimes />}
        lineHeight="1"
        variant="ghost"
        type="button"
        onClick={handleRemoveFilters}
        w={["full", "full", "min"]}
      >
        Remover filtros
      </Button>


    </Flex>
  )
}
