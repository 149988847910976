import { IconButton, Link, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Table, Tbody, Td, Tr } from "@chakra-ui/react"
import { ExtraAndDiscount } from "api/extrasAndDiscounts/_types/ExtraAndDiscount";
import { format } from "date-fns";
import { FaExternalLinkAlt } from "react-icons/fa";
import { captalize } from "utils/captalize";
import { handleChangeUrl } from "utils/handleChangeUrl";
import { CustomerExtraDiscountStatus } from "./CustomerExtraDiscountStatus";

interface CustomerExtraDiscountDetailsProps {
  extraDiscount: ExtraAndDiscount
}


export function CustomerExtraDiscountDetails({
  extraDiscount,
}: CustomerExtraDiscountDetailsProps) {

  return (
    <ModalContent >
      <ModalHeader>
        Detalhes do Extra/Desconto
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Table size="sm">
          <Tbody>
            <Tr>
              <Td>Status</Td>
              <Td display="flex" justifyContent="flex-end">
                <CustomerExtraDiscountStatus
                  status={extraDiscount?.closed_at ? 'closed' : 'requested'}
                />
              </Td>
            </Tr>
            <Tr>
              <Td>Cliente</Td>
              <Td isNumeric>
                {captalize(extraDiscount?.customer?.trading_firstname)}
              </Td>
            </Tr>
            <Tr>
              <Td>Data de Criação</Td>
              <Td isNumeric>
                {format(new Date(extraDiscount?.created_at), 'dd/MM/yyyy')}
              </Td>
            </Tr>
            <Tr>
              <Td>Tipo</Td>
              <Td isNumeric>
                {captalize(extraDiscount?.type)}
              </Td>
            </Tr>
            <Tr>
              <Td>Tipo de Serviço</Td>
              <Td isNumeric>
                {captalize(extraDiscount?.service_type)}
              </Td>
            </Tr>
            {extraDiscount?.service_type === 'BUSINESS' && (
              <Tr>
                <Td>Data da Ocorrência</Td>
                <Td isNumeric>
                  {format(new Date(extraDiscount?.occurrence_date), 'dd/MM/yyyy')}
                </Td>
              </Tr>
            )}
            {extraDiscount?.service_type === 'EXPRESS' && (
              <Tr>
                <Td>Protocolo</Td>
                <Td isNumeric>
                  {extraDiscount?.protocol}
                </Td>
              </Tr>
            )}
            {extraDiscount?.service_type === 'BUSINESS' && (
              <Tr>
                <Td>Rota</Td>
                <Td isNumeric>
                  {extraDiscount?.route}
                </Td>
              </Tr>
            )}

            <Tr>
              <Td>Valor</Td>
              <Td isNumeric>
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }).format(extraDiscount?.value / 100)}
              </Td>
            </Tr>

            <Tr>
              <Td>Descrição</Td>
              <Td isNumeric>
                {extraDiscount?.description}
              </Td>
            </Tr>
            {extraDiscount?.attachment && (
              <Tr >
                <Td maxW="150px" overflow="hidden" textOverflow="ellipsis">
                  Anexo
                </Td>
                <Td isNumeric>
                  <IconButton
                    aria-label="Abrir anexo"
                    as={Link}
                    href={handleChangeUrl(extraDiscount?.attachment)}
                    icon={<FaExternalLinkAlt />}
                    size="sm"
                    isExternal
                  />
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </ModalBody>

    </ModalContent>
  )
}
