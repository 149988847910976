import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { ExtraAndDiscount, ExtraAndDiscountStatus } from "./_types/ExtraAndDiscount";

export interface GetCustomersExtrasDiscountsParams {
  status?: ExtraAndDiscountStatus
  pageSize?: number
  currentPage?: number
  customer?: string
  date?: string
  protocol?: string
}

export interface GetCustomersExtrasDiscountsResponse {
  extrasDiscounts: ExtraAndDiscount[]
  meta: {
    totalPages: number
    count: number
  }
}

export async function getCustomersExtrasDiscounts({
  status,
  pageSize,
  currentPage,
  customer,
  date,
  protocol
}: GetCustomersExtrasDiscountsParams) {
  try {
    const response = await api.get<GetCustomersExtrasDiscountsResponse>('/customers/extras-discounts', {
      params: {
        status,
        pageSize,
        currentPage,
        customer,
        date,
        protocol
      }
    })

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}


