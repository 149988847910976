import { usePagination } from "@ajna/pagination"
import { Box, Button, Flex, Heading, Icon, Table, TableContainer, Tbody, Thead, useDisclosure } from "@chakra-ui/react"
import { getBoardsRelation } from "api/service/getBoardsRelation"
import { Pagination } from "components/Pagination/Pagination"
import { useAuth } from "hooks/auth/useAuth"
import { useSearchParams } from "hooks/useSearchParams"
import { FaFilter } from "react-icons/fa"
import { useQuery } from "react-query"
import { useHistory } from "react-router-dom"
import { BoardsRelationsFilters } from "./BoardsRelationsFilters"
import { BoardsRelationsTableHead } from "./BoardsRelationsTableHead"
import { BoardsRelationsTableRow } from "./BoardsRelationsTableRow"

export function BoardsRelations() {
  const searchParams = useSearchParams()
  const { userLogged } = useAuth()

  const page = searchParams.get('page') ?? 1

  const history = useHistory()

  const ROWS_PER_PAGE = 10

  const destinationAddresses = searchParams.get('destinationAddresses')
  const sourceCollectors = searchParams.get('sourceCollectors')
  const destinationCollectors = searchParams.get('destinationCollectors')
  const customers = searchParams.get('customers')
  const protocols = searchParams.get('protocols')
  const boardDates = searchParams.get('boardDates')
  const boardHours = searchParams.get('boardHours')

  const {
    data: boardsRelationsResult
  } = useQuery({
    queryKey: ['boards-relations',
      page,
      customers,
      protocols,
      destinationAddresses,
      sourceCollectors,
      destinationCollectors,
      boardDates,
      boardHours
    ],
    queryFn: () => getBoardsRelation({
      currentPage: Number(page),
      pageSize: ROWS_PER_PAGE,
      timezoneOffset: new Date().getTimezoneOffset() / 60,
      customers,
      destinationAddresses,
      destinationCollectors,
      sourceCollectors,
      protocols,
      boardDates,
      boardHours,
      collectorId: userLogged?.collector_id ?? null
    }),
    refetchOnWindowFocus: false
  })

  const { pagesCount, pages, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      pagesCount: boardsRelationsResult?.meta?.totalPages,
      initialState: {
        pageSize: ROWS_PER_PAGE,
        isDisabled: false,
        currentPage: Number(page),
      },
    });


  const handleChangePage = (page: number) => {
    setCurrentPage(page)
    searchParams.set('page', page.toString())

    history.replace({ search: searchParams.toString() })
  }

  const { isOpen, onToggle } = useDisclosure();


  return (
    <Box>
      <Flex w='full' justifyContent='space-between' >
        <Heading letterSpacing="tight">Embarques</Heading>
        <Button
          variant="ghost"
          onClick={onToggle}
          boxShadow="md"
          borderRadius="md"
          size="sm"
          leftIcon={
            <Icon as={FaFilter} />
          }
        >
          Filtros
        </Button>
      </Flex>
      <BoardsRelationsFilters
        onToggle={onToggle}
        isOpen={isOpen}
      />
      <TableContainer rounded="md" mt={6}>
        <Table size="sm">
          <Thead>
            <BoardsRelationsTableHead />
          </Thead>
          <Tbody>
            {boardsRelationsResult?.services?.map((service) => {
              return (
                <BoardsRelationsTableRow
                  key={String(service.protocol)}
                  service={service}
                />
              )
            })}

          </Tbody>
        </Table>
      </TableContainer>
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </Box>
  )
}
