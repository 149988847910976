import axios from "axios"
import { api } from "services/api"
import { timezonesUsed } from "utils/DateFunctions/timezones"
import { RequestError } from "utils/errors/RequestErrors"

interface Address {
  id: string
  street: string
  neighborhood: string
  number: number
  complement: string
  cep: string
  city: {
    id: string
    name: string
    hub: {
      id: string
      name: string
      timezone: keyof typeof timezonesUsed | null
    }
  }
}

interface Collector {
  id: string
  tradingName: string
}

interface Branch {
  id: string
  nickname: string
}

interface Shipping {
  id: string
  tradingName: string
}

export interface Service {
  id: string
  protocol: number
  step: string
  sourceAddresses: Address[]
  destinationAddresses: Address[]
  customer: {
    id: string
    tradingFirstname: string
  }
  requestedService: {
    serviceType: 'DEDICADO' | 'FRACIONADO'
    collectDate: string
    collectHourStart: string
    collectHourEnd: string
    boardHour: string | null
    deliveryDate: string
    deliveryHour: string
    caixaTermica: number
    embalagemSecundaria: number
    geloSeco: number
    gelox: number
    isopor3l: number
    isopor7l: number
    terciaria3l: number
    terciaria8l: number
    observation: string
    hasNewAddressesToValidate: boolean
    sourceCollector: Collector
    destinationCollector: Collector
    sourceBranch: Branch | null
    destinationBranch: Branch | null
    shipping: Shipping | null
    vehicle: string
    collectServices: {
      id: string
      addressId: string
      step: 'DONE' | 'UNSUCCESS' | 'VALIDATEUNSUCCESS'
    }[] | null
  }
  collectServices: {
    id: string
    addressId: string
    step: 'DONE' | 'UNSUCCESS' | 'VALIDATEUNSUCCES' | 'GOING'
  }[]
}

interface GetCollectsRelationResponse {
  services: Service[]
  meta: {
    totalPages: number
    count: number
  }
}

interface GetCollectsRelationParams {
  currentPage: number
  pageSize: number
  timezoneOffset: number
  destinationAddresses?: string
  destinationCollectors?: string
  sourceCollectors?: string
  customers?: string
  protocols?: string
  vehicles?: string
  collectDates?: string
  collectHours?: string
  collectorId?: string
}

export async function getCollectsRelation({
  currentPage,
  pageSize,
  timezoneOffset,
  destinationAddresses,
  destinationCollectors,
  sourceCollectors,
  customers,
  protocols,
  vehicles,
  collectDates,
  collectHours,
  collectorId
}: GetCollectsRelationParams) {
  try {
    const { data } = await api.get<GetCollectsRelationResponse>(`/services/relations/collects`, {
      params: {
        currentPage,
        pageSize,
        timezoneOffset,
        destinationAddresses,
        destinationCollectors,
        sourceCollectors,
        customers,
        protocols,
        vehicles,
        collectDates,
        collectHours,
        collectorId
      }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!")
    }
  }
}
