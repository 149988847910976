import { Grid, GridItem } from "@chakra-ui/react"
import { getNextToDelayDeliveries } from "api/service/getNextToDelayDeliveries"
import { InfoCardsLoading } from "pages/Dashboard/components/InfoCardsLoading"

import { useEffect } from "react"

import { useQuery } from "react-query"

import { NextToDelayDeliveryCard } from "./NextToDelayDeliveryCard"

interface NextToDelayDeliveriesProps {
  onSetIsError: () => void
}

export function NextToDelayDeliveries({ onSetIsError }: NextToDelayDeliveriesProps) {

  const {
    data: result,
    isError,
    isLoading: isLoadingNextToDelayDeliveriesResult
  } = useQuery({
    queryKey: ['next-to-delay-deliveries'],
    queryFn: getNextToDelayDeliveries,
    refetchInterval: 1000 * 60 * 2, // 2 minutes,
    refetchIntervalInBackground: true,
  })

  useEffect(() => {
    if (isError) onSetIsError()
  }, [isError, onSetIsError])

  return (
    <Grid
      gap="6"
      templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(1, 1fr)", xl: "repeat(2, 1fr)" }}
    >
      {isLoadingNextToDelayDeliveriesResult && (
        <InfoCardsLoading />
      )}
      {result?.services?.map((service) => {
        return (
          <GridItem
            key={service.id}
          >
            <NextToDelayDeliveryCard
              service={service}
            />
          </GridItem>
        )
      })}
    </Grid>
  )
}
