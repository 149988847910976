import { Box, Button, Flex, FormControl, FormLabel, Heading, IconButton, Input, Select, Stack, Text } from "@chakra-ui/react";
import { getCltDrivers } from "api/cltDrivers/getCltDrivers";
import { getInternClts } from "api/internClts/getInternClts";
import { getPatrimonyTypes } from "api/patrimonies/getPatrimonyTypes";
import { useAggregates } from "hooks/aggregate/useAggregates";
import { useState } from "react";
import { Controller, useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { FaMinus, FaRegEdit } from "react-icons/fa";
import { useQuery } from "react-query";
import AsyncSelect from 'react-select/async';
import { captalize } from "utils/captalize";

interface RequestPatimonyFormProps {
  slug?: string
  status?: string
}

export interface RequestPatrimonySchema {
  limitDate: string
  receiptAttachmentId: FileList
  regional: string
  responsibleName: {
    label: string
    value: string
  }
  sector: string
  collaboratorType: string
  patrimonyTypes: {
    patrimonyTypeId: string
    quantity: number
  }[]
  responsible: string
}


const sectors = [
  'Administrativo',
  'Financeiro',
  'Almoxarifado',
  'Comercial',
  'Copa',
  'Lifebox',
  'Operacional',
  'Qualidade',
  'RH',
  'Sala de Reunião',
  'Sala Executivo',
  'Externo'
]

const regionals = [
  'MG',
  'RJ',
  'DF',
  'SP',
  'OUTRA'
]

export const collaboratorTypesOptions = [
  'AGREGADO',
  'INTERNO CLT',
  'MOTORISTA CLT',
]


export function RequestPatimonyForm({ slug = 'create', status }: RequestPatimonyFormProps) {
  const isEditForm = slug === 'edit'


  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<RequestPatrimonySchema>()

  const [collaboratorType] = useWatch({
    control,
    name: ['collaboratorType']
  })

  const {
    fields: patrimonyTypesFields,
    append: appendPatrimonyTypesField,
    remove: removePatrimonyTypesField
  } = useFieldArray({
    control,
    name: 'patrimonyTypes'
  })

  function handleAppendPatrimonyTypeField() {
    appendPatrimonyTypesField({ patrimonyTypeId: '', quantity: 1 })
  }

  function handleRemovePatrimonyTypeField(index: number) {
    removePatrimonyTypesField(index)
  }

  const {
    data: aggregates,
  } = useAggregates()

  async function promiseOptionsCltDrivers(inputValue: string): Promise<Array<{ value: string, label: string }>> {
    const response = await getCltDrivers({ currentPage: 1, pageSize: 5, search: inputValue ?? '' })

    return response.cltDrivers.map(driver => {
      return { value: captalize(`${driver?.driver.firstname} ${driver?.driver.lastname}`), label: captalize(`${driver?.driver.firstname} ${driver?.driver.lastname}`) }
    })

  }
  async function promiseOptionsInternClts(inputValue: string): Promise<Array<{ value: string, label: string }>> {
    const response = await getInternClts({ currentPage: 1, pageSize: 5, name: inputValue ?? '' })

    return response.internClts.map(internClt => {
      return { value: internClt.name, label: internClt.name }
    })
  }

  async function promiseOptionsAggregates(inputValue: string): Promise<Array<{ value: string, label: string }>> {
    return aggregates
      ?.filter((aggregate) => {
        const fullName = `${aggregate?.driver.firstname} ${aggregate?.driver.lastname}`.toLowerCase();
        return !inputValue || fullName.includes(inputValue.toLowerCase());
      })
      ?.map((aggregate) => {
        const fullName = `${aggregate?.driver.firstname} ${aggregate?.driver.lastname}`;
        return {
          value: captalize(fullName),
          label: captalize(fullName)
        };
      });
  }



  const { data: patrimonyTypesResult } = useQuery({
    queryKey: ['patrimonyTypes'],
    queryFn: () => getPatrimonyTypes({}),
    keepPreviousData: true
  })

  const userCanEditPatrimonyTypes = isEditForm && status === 'requested'

  const [isEditing, setIsEditing] = useState(false);

  const toggleEdit = () => setIsEditing((prev) => !prev);


  return (
    <Box w='full'>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormLabel fontSize="sm">
            Regional
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <FormControl isInvalid={!!errors.regional}>
            <Select
              {...register("regional")}
              name="regional"
              placeholder="Selecione..."
              size="sm"
              rounded="md"
            >
              {regionals?.map((regional, index) => {
                return (
                  <option key={index} value={regional}>{regional}</option>
                )
              })}
            </Select>
          </FormControl>
        </Stack>

      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        {isEditForm && (
          <Flex alignItems="center" w='full' >

            <FormControl isInvalid={!!errors?.responsible}>
              <FormLabel fontSize="sm">
                Nome do Responsável
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('responsible')}
                name={'responsible'}
                size="sm"
                rounded="md"
                isDisabled
              />
            </FormControl>
            <IconButton
              aria-label="Editar responsável"
              icon={<FaRegEdit />}
              size="sm"
              ml={2}
              onClick={toggleEdit}
              alignSelf="flex-end"
            />
          </Flex>
        )}


      </Stack>
      {(!isEditForm || isEditing) && (
        <FormControl isInvalid={!!errors?.collaboratorType} mt={3}>
          <FormLabel fontSize="sm">
            Tipo de colaborador
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Select
            {...register("collaboratorType")}
            name="collaboratorType"
            placeholder="Selecione..."
            size="sm"
            rounded="md"
          >
            {collaboratorTypesOptions?.map((colaboradorType, index) => {
              return (
                <option key={index} value={colaboradorType}>{colaboradorType}</option>
              )
            })}
          </Select>
        </FormControl>
      )}
      {collaboratorType && (
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          {collaboratorType === 'MOTORISTA CLT' && (
            <Stack
              direction="column"
              w="full"
              spacing="0.25"
            >
              <FormControl isInvalid={!!errors?.responsibleName}>
                <FormLabel w="full" fontSize='sm'>
                  Nome do Responsável
                  <Text as="sup" color="red.500">*</Text>

                </FormLabel>
                <Controller
                  control={control}
                  name="responsibleName"
                  render={({ field }) => (
                    <AsyncSelect
                      {...register('responsibleName')}
                      name={field.name}
                      onChange={field.onChange}
                      value={field.value}
                      cacheOptions
                      defaultOptions
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          padding: '1px',
                          borderRadius: '6px',
                          borderColor: 'gray.200',
                          border: state.isFocused ? 'none' : '',
                          boxShadow: state.isFocused ? '0 0 0 2px #38c3fa' : ''
                        }),
                        placeholder: (base, props) => ({
                          ...base,
                          color: 'blackAlpha.900'
                        }),
                        dropdownIndicator(base, props) {
                          return {
                            ...base,
                            color: 'blackAlpha.900',
                            width: '34px',
                          }
                        },
                      }}
                      noOptionsMessage={() => 'Não há motoristas cadastrados ou encontrados!'}
                      placeholder="Selecione um colaborador..."
                      loadOptions={promiseOptionsCltDrivers}
                      isClearable={true}

                    />
                  )}
                />
              </FormControl>
            </Stack>
          )}
          {collaboratorType === 'INTERNO CLT' && (
            <Stack
              direction="column"
              w="full"
              spacing="0.25"
            >
              <FormControl isInvalid={!!errors?.responsibleName}>
                <FormLabel w="full" fontSize='sm'>
                  Nome do Responsável
                  <Text as="sup" color="red.500">*</Text>

                </FormLabel>
                <Controller
                  control={control}
                  name="responsibleName"
                  render={({ field }) => (
                    <AsyncSelect
                      {...register('responsibleName')}
                      name={field.name}
                      onChange={field.onChange}
                      value={field.value}
                      cacheOptions
                      defaultOptions
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          padding: '1px',
                          borderRadius: '6px',
                          borderColor: 'gray.200',
                          border: state.isFocused ? 'none' : '',
                          boxShadow: state.isFocused ? '0 0 0 2px #38c3fa' : ''
                        }),
                        placeholder: (base, props) => ({
                          ...base,
                          color: 'blackAlpha.900'
                        }),
                        dropdownIndicator(base, props) {
                          return {
                            ...base,
                            color: 'blackAlpha.900',
                            width: '34px',
                          }
                        },
                      }}
                      noOptionsMessage={() => 'Não há internos cadastrados ou encontrados!'}
                      placeholder="Selecione um colaborador..."
                      loadOptions={promiseOptionsInternClts}
                      isClearable={true}

                    />
                  )}
                />
              </FormControl>
            </Stack>
          )}
          {collaboratorType === 'AGREGADO' && (
            <Stack
              direction="column"
              w="full"
              spacing="0.25"
            >
              <FormControl isInvalid={!!errors?.responsibleName}>
                <FormLabel w="full" fontSize='sm'>
                  Nome do Responsável
                  <Text as="sup" color="red.500">*</Text>

                </FormLabel>
                <Controller
                  control={control}
                  name="responsibleName"
                  render={({ field }) => (
                    <AsyncSelect
                      {...register('responsibleName')}
                      name={field.name}
                      onChange={field.onChange}
                      value={field.value}
                      cacheOptions
                      defaultOptions
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          padding: '1px',
                          borderRadius: '6px',
                          borderColor: 'gray.200',
                          border: state.isFocused ? 'none' : '',
                          boxShadow: state.isFocused ? '0 0 0 2px #38c3fa' : ''
                        }),
                        placeholder: (base, props) => ({
                          ...base,
                          color: 'blackAlpha.900'
                        }),
                        dropdownIndicator(base, props) {
                          return {
                            ...base,
                            color: 'blackAlpha.900',
                            width: '34px',
                          }
                        },
                      }}
                      noOptionsMessage={() => 'Não há agregados cadastrados ou encontrados!'}
                      placeholder="Selecione um colaborador..."
                      loadOptions={promiseOptionsAggregates}
                      isClearable={true}

                    />
                  )}
                />
              </FormControl>
            </Stack>
          )}
        </Stack>
      )}

      <Stack w="full" mt={3}>
        <Heading size='xs'>Tipos de Patrimônio</Heading>
        {patrimonyTypesFields?.map((field, index) => (
          <Flex
            key={field.id}
            border="1px solid"
            borderColor="gray.200"
            borderRadius="lg"
            p={5}
            direction="column"
            w="full"
            boxShadow="sm"
            position="relative"

          >
            {(!isEditForm || userCanEditPatrimonyTypes) && patrimonyTypesFields.length > 1 && (
              <IconButton
                aria-label="Remover patrimônio"
                icon={<FaMinus />}
                variant="ghost"
                colorScheme="red"
                position="absolute"
                top={2}
                right={2}
                size="sm"
                onClick={() => handleRemovePatrimonyTypeField(index)}
                _hover={{ bg: "red.50" }}
              />
            )}
            <Stack
              direction="column"
              w="full"
              spacing="0.25"
            >
              <FormLabel fontSize="sm">
                Patrimônio
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <FormControl isInvalid={!!errors?.patrimonyTypes?.[index]?.patrimonyTypeId}>
                <Select
                  {...register(`patrimonyTypes.${index}.patrimonyTypeId`)}
                  name={`patrimonyTypes.${index}.patrimonyTypeId`}
                  placeholder="Selecione..."
                  size="sm"
                  rounded="md"
                  disabled={isEditForm && status !== 'requested'}
                >
                  {patrimonyTypesResult?.patrimonyTypes.map((patrimonyType) => (
                    <option key={patrimonyType?.id} value={patrimonyType?.id}>
                      {captalize(patrimonyType?.type)}
                    </option>
                  ))}
                </Select>
              </FormControl>

            </Stack>
            <Stack
              direction="column"
              w="full"
              spacing="0.25"
              mt={3}
            >
              <FormControl isInvalid={!!errors?.patrimonyTypes?.[index]?.quantity}>
                <FormLabel fontSize="sm">
                  Quantidade
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>
                <Input
                  {...register(`patrimonyTypes.${index}.quantity`)}
                  name={`patrimonyTypes.${index}.quantity`}
                  size="sm"
                  rounded="md"
                  type="number"
                />
              </FormControl>
            </Stack>
          </Flex>
        ))}
        {(!isEditForm || userCanEditPatrimonyTypes) && (
          <Flex justifyContent="flex-end" mt={2}>
            <Button
              size="sm"
              colorScheme="blue"
              variant="solid"
              onClick={handleAppendPatrimonyTypeField}
            >
              Adicionar
            </Button>
          </Flex>
        )}
      </Stack>

      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <FormControl isInvalid={!!errors?.limitDate}>
          <FormLabel fontSize="sm">
            Data limite
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Input
            {...register('limitDate')}
            name="limitDate"
            type="date"
            size="sm"
            rounded="md"
          />
        </FormControl>
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          spacing="0.25"
          w="full"
        >
          <FormLabel fontSize="sm">
            Setor
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <FormControl isInvalid={!!errors.sector}>
            <Select
              {...register('sector')}
              name="sector"
              placeholder="Selecione..."
              size="sm"
              rounded="md"
            >
              {sectors?.map((sector, index) => {
                return (
                  <option key={index} value={sector}>{captalize(sector)}</option>
                )
              })}
            </Select>
          </FormControl>
        </Stack>
      </Stack>
    </Box>
  )
}
