import {
  Box,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Icon,
  VStack,
} from "@chakra-ui/react";
import { FaFilePdf } from "react-icons/fa";
import { generateSalaryAccountAuthorizationLetter } from "api/contracts/generateSalaryAccountAuthorizationLetter";
import { useMutation, } from "react-query";
import { CltDriver } from "api/cltDrivers/_types/CltDriver";
import { generateMotorcycleRentalAditive } from "api/contracts/generateMotorcycleRentalAditive";
import { generateMotorcycleRental } from "api/contracts/generateMotorcycleRental";

interface ContractsCltDriverProps {
  cltDriver: CltDriver
}

const downloadPDF = (data: Uint8Array, filename: string) => {
  const url = URL.createObjectURL(
    new Blob([data], { type: 'application/pdf' }),
  )

  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  setTimeout(() => {
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }, 0);

};


export function ContractsCltDriver({ cltDriver }: ContractsCltDriverProps) {

  const { mutateAsync: generateSalaryAccountAuthorizationLetterFn } = useMutation({
    mutationFn: generateSalaryAccountAuthorizationLetter,
  })
  const { mutateAsync: generateMotorcycleRentalAditiveFn } = useMutation({
    mutationFn: generateMotorcycleRentalAditive,
  })
  const { mutateAsync: generateMotorcycleRentalFn } = useMutation({
    mutationFn: generateMotorcycleRental,
  })

  async function handleGenerateSalaryAccountAuthorizationLetter() {
    const generateSalaryAccountAuthorizationLetterResponse = await generateSalaryAccountAuthorizationLetterFn({
      body: {
        address: cltDriver?.address,
        admissionDate: cltDriver?.admission_date,
        cpf: cltDriver?.driver?.cpf,
        name: `${cltDriver?.driver?.firstname} ${cltDriver?.driver?.lastname} `,
        rg: cltDriver?.rg
      }
    })

    downloadPDF(new Uint8Array(generateSalaryAccountAuthorizationLetterResponse.pdfDocData.data), "Carta_Autorização_Conta_Salario.pdf");


  }
  async function handleGenerateMotorcycleRentalAditive() {
    const generateMotorcycleRentalAditiveResponse = await generateMotorcycleRentalAditiveFn({
      body: {
        address: cltDriver?.address,
        firstContractDate: cltDriver?.admission_date,
        cpf: cltDriver?.driver?.cpf,
        name: `${cltDriver?.driver?.firstname} ${cltDriver?.driver?.lastname} `,
        rg: cltDriver?.rg,
        valueInCents: cltDriver?.motorcycle_location_value
      }
    })

    downloadPDF(new Uint8Array(generateMotorcycleRentalAditiveResponse.pdfDocData.data), "Aditivo_de_Contrato_de_Aluguel_de_Moto.pdf");


  }
  async function handleGenerateMotorcycleRental() {
    const generateMotorcycleRentalResponse = await generateMotorcycleRentalFn({
      body: {
        address: cltDriver?.address,
        cpf: cltDriver?.driver?.cpf,
        name: `${cltDriver?.driver?.firstname} ${cltDriver?.driver?.lastname} `,
        rg: cltDriver?.rg,
        valueInCents: cltDriver?.motorcycle_location_value,
        brand: cltDriver?.motorcycleBrand,
        color: cltDriver?.motorcycleColor,
        model: cltDriver?.motorcycleModel,
        plate: cltDriver?.motorcycleLicensePlate,
        year: Number(cltDriver?.motorcycleYear)

      }
    })

    downloadPDF(new Uint8Array(generateMotorcycleRentalResponse.pdfDocData.data), "Contrato_de_Aluguel_de_Moto.pdf");

  }
  return (
    <Box>
      <Popover>
        <PopoverTrigger>
          <VStack

            spacing={1}
            cursor="pointer"
            _hover={{
              transform: 'translateY(-5px)'
            }}
            transition="ease-in-out 0.2s"
          >
            <Icon
              as={FaFilePdf}
              color="blue.500"
              fontSize="lg"
            />
          </VStack>

        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader fontSize='sm'>Documentos</PopoverHeader>
          <PopoverBody>
            <VStack spacing={2} align="stretch">
              {!["hiring", "hiring-motorcyle-details", "hiring-scalabrini-register", "hiring-pontomais-register", "hiring-signing-document"].includes(cltDriver?.status) && (
                <Button
                  width="100%"
                  variant="ghost"
                  justifyContent="flex-start"
                  onClick={handleGenerateSalaryAccountAuthorizationLetter}
                  size="sm"
                  fontWeight="normal"
                  leftIcon={<Icon as={FaFilePdf} />}
                >
                  Carta de autorização para conta salário
                </Button>
              )}
              <Button
                width="100%"
                variant="ghost"
                justifyContent="flex-start"
                onClick={handleGenerateMotorcycleRentalAditive}
                size="sm"
                fontWeight="normal"
                leftIcon={<Icon as={FaFilePdf} />}
              >
                Aditivo de contrato de aluguel de moto
              </Button>

              <Button
                width="100%"
                variant="ghost"
                justifyContent="flex-start"
                onClick={handleGenerateMotorcycleRental}
                size="sm"
                fontWeight="normal"
                leftIcon={<Icon as={FaFilePdf} />}
              >
                Contrato de aluguel de moto
              </Button>

            </VStack>

          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};


