import { Box, Button, Flex, FormControl, FormLabel, Heading, Radio, RadioGroup, Stack, Text } from "@chakra-ui/react"
import { Controller, useForm } from "react-hook-form"
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";
import { sendCltDriverPontoMaisRegisterDetails } from "api/cltDrivers/sendCltDriverPontoMaisRegisterDetails";

interface SendPontoMaisRegisterProps {
  cltDriverId: string
}

export interface SendPontoMaisRegisterSchema {
  hasRegisteredOnPontoMais: string
  hasRegisteredEmail: string
  hasRegisteredPhoto: string
}

const sendPontoMaisRegisterSchema = yup.object({
  hasRegisteredOnPontoMais: yup.string().required(),
  hasRegisteredEmail: yup.string().required(),
  hasRegisteredPhoto: yup.string().required()
})

export function SendPontoMaisRegister({ cltDriverId }: SendPontoMaisRegisterProps) {

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const {
    handleSubmit,
    control,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<SendPontoMaisRegisterSchema>({
    resolver: yupResolver(sendPontoMaisRegisterSchema)
  })
  const { mutateAsync: sendCltDriverPontoMaisRegisterDetailsFn } = useMutation({
    mutationFn: sendCltDriverPontoMaisRegisterDetails,
    onSuccess(_data, { body, cltDriverId }) {
      queryClient.invalidateQueries({ queryKey: 'clts-drivers' })
      queryClient.invalidateQueries({ queryKey: ['clt-driver', cltDriverId] })
    }
  })

  async function handleSendPontoMaisRegister(values: SendPontoMaisRegisterSchema) {
    await promiseMessage(sendCltDriverPontoMaisRegisterDetailsFn({
      body: {
        hasRegisteredOnPontoMais: values.hasRegisteredOnPontoMais === 'yes',
        hasRegisteredEmail: values.hasRegisteredEmail === 'yes',
        hasRegisteredPhoto: values.hasRegisteredPhoto === 'yes'
      },
      cltDriverId
    }), 'Dados iFractal enviados!')
  }

  return (
    <Box
      w="full"
      as="form"
      onSubmit={handleSubmit(handleSendPontoMaisRegister)}
      ml={3}
    >
      <Heading letterSpacing="tight" size='sm'>Contratação - iFractal</Heading>
      <FormControl isInvalid={!!errors.hasRegisteredOnPontoMais} mt={3}>
        <FormLabel fontSize="sm">
          Cadastrado no iFractal ?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="hasRegisteredOnPontoMais"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.hasRegisteredEmail} mt={3}>
        <FormLabel fontSize="sm">
          E-mail cadastrado ?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="hasRegisteredEmail"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.hasRegisteredPhoto} mt={3}>
        <FormLabel fontSize="sm">
          Foto Cadastrada ?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="hasRegisteredPhoto"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>

      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </Box>
  )
}
