import { Box, Circle, HStack, Icon, Modal, ModalOverlay, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { FaCalendarAlt, FaPen } from "react-icons/fa";
import { CltDriverDetail } from "./CltDriverDetail";
import { format } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { useHistory } from "react-router-dom";
import { CltDriver } from "api/cltDrivers/_types/CltDriver";
import { FaMapLocation } from "react-icons/fa6";
import { captalize } from "utils/captalize";

interface CltDriversKanbanCardProps {
  cltDriver: CltDriver
}

export function CltDriversKanbanCard({ cltDriver }: CltDriversKanbanCardProps) {
  const { userLogged } = useAuth()
  const history = useHistory()

  const doesUserCanEditCltDriver = userLogged?.permissions.includes('edit-clt-driver')
  const {
    isOpen: isCltDriverDetailModalOpen,
    onToggle: onToggleCltDriverDetailModal,
  } = useDisclosure()

  const driverFullName = captalize(`
    ${cltDriver?.driver.firstname} ${cltDriver?.driver.lastname}
  `)

  return (
    <Box
      w="full"
      p={4}
      bg="white"
      borderRadius="md"
      boxShadow="sm"
      border="1px solid #E2E8F0"
      onClick={onToggleCltDriverDetailModal}
      cursor='pointer'
    >
      <HStack justify="space-between" w="full">
        <Text fontWeight="bold" mb={2}>
          {driverFullName}
        </Text>
        {doesUserCanEditCltDriver   && (
          <Circle
            size="5"
            color="white"
            onClick={(e) => {
              e.stopPropagation()
              history.push(`/motorista-clt/editar/${cltDriver.id}`)
            }}
          >
            <Icon as={FaPen} boxSize={"3.5"} color='blue.900' />
          </Circle>
        )}

      </HStack>


      <VStack align="start" spacing={2} fontSize="sm" color="gray.600">
        <Box>
          <HStack spacing={2}>
            <Icon as={FaCalendarAlt} />
            <Text fontSize="xs" fontWeight="bold">DATA ADMISSÃO</Text>
          </HStack>
          <Text ml={5}>{cltDriver?.admission_date ? format(new Date(cltDriver?.admission_date), 'dd/MM/yyyy') : '-'}</Text>
        </Box>
        <Box>
          <HStack spacing={2}>
            <Icon as={FaMapLocation} />
            <Text fontSize="xs" fontWeight="bold">REGIONAL</Text>
          </HStack>
          <Text ml={5}>{cltDriver?.regional}</Text>
        </Box>
      </VStack>

      <Modal
        isOpen={isCltDriverDetailModalOpen}
        onClose={onToggleCltDriverDetailModal}
        size='6xl'
      >
        <ModalOverlay />
        <CltDriverDetail
          cltDriverId={cltDriver.id}
          onClose={onToggleCltDriverDetailModal}
        />
      </Modal>
    </Box>
  )
}
