import { Button, Flex, FormControl, FormLabel, IconButton, Input, Link, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Progress, Select, Stack, Table, Tbody, Td, Text, Textarea, Tr } from "@chakra-ui/react"
import { DocumentRequestTypes } from "api/documents/types/Document"
import { useToastify } from "hooks/toastify/useToastify"
import { useMutation, useQueryClient } from "react-query"
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"
import { useAttachmentMutation } from "hooks/attachment/useAttachmentMutation";
import { validateHasFile } from "utils/fileValidation";
import { createDocumentRequest } from "api/documents/createDocumentRequest";
import { ChangeEvent } from "react";
import { documentRequestType } from "utils/documentMappers";
import { FaExternalLinkAlt, FaFileImport } from "react-icons/fa";

interface CreateDocumentRequestProps {
  onCloseModal: () => void
}

interface CreateDocumentRequestSchema {
  requestType: DocumentRequestTypes
  name: string
  documentType: string
  version: string
  documentAttachmentId: FileList
  alterationDescription: string
  relatedPagePathname: string
  showInMenu: 'yes' | 'no'
}

const createDocumentRequestSchema = yup.object({
  requestType: yup.string().required(),
  name: yup.string().required(),
  documentType: yup.string().required(),
  version: yup.string().required(),
  documentAttachmentId: yup.mixed().test(value => validateHasFile(value)).required(),
  alterationDescription: yup.mixed().when('requestType', {
    is: 'alteration',
    then: yup.string().required()
  }),
  relatedPagePathname: yup.string(),
  showInMenu: yup.string().required(),
})

export const documentTypes = [
  "DC - Manual",
  "DC - FOR",
  "DC - POP",
  "DNC - Documento Não Crítico",
  "DNC - Vídeo",
  "DC - Documento Crítico",
  "DNCE - Documento Não Crítico Externo"
];

export function CreateDocumentRequest({ onCloseModal }: CreateDocumentRequestProps) {

  const { mutation: uploadDocumentAttachmentFn, uploadProggress: uploadProggressDocumentAttachment } = useAttachmentMutation()

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const { mutateAsync: createDocumentRequestFn } = useMutation({
    mutationFn: createDocumentRequest,
    onSuccess: () => {
      queryClient.invalidateQueries(['documents'])
      queryClient.invalidateQueries({ queryKey: 'non-evaluated-documents' })
      queryClient.invalidateQueries({ queryKey: 'expired-documents' })


      onCloseModal()
    }
  })

  const {
    register,
    handleSubmit,
    control,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<CreateDocumentRequestSchema>({
    resolver: yupResolver(createDocumentRequestSchema)
  })

  const [documentAttachmentId, requestType] = useWatch({
    control,
    name: ['documentAttachmentId', 'requestType'],
  })

  const isAlterationrequestType = requestType === 'alteration'

  async function handleUploadDocumentAttachment(event: ChangeEvent<HTMLInputElement>) {
    const formData = new FormData()

    formData.append('attachment', event.target.files[0])

    await uploadDocumentAttachmentFn.mutateAsync(formData)

  }

  async function handleCreateDocumentRequest(values: CreateDocumentRequestSchema) {
    await promiseMessage(createDocumentRequestFn({
      body: {
        ...values,
        documentAttachmentId: uploadDocumentAttachmentFn?.data?.attachment?.id,
        showInMenu: values?.showInMenu === 'yes'
      }
    }), 'Solicitação criada!')
  }
  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">Criar Solicitação</ModalHeader>
      <ModalCloseButton />
      <ModalBody
        as="form"
        onSubmit={handleSubmit(handleCreateDocumentRequest)}
        maxH='600px'
        overflowY='scroll'
      >
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormLabel fontSize="sm">
              Tipo de Solicitação
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <FormControl isInvalid={!!errors.requestType}>
              <Select
                {...register('requestType')}
                name="requestType"
                placeholder="Selecione..."
                size="sm"
                rounded="md"
              >
                {Object.entries(documentRequestType).map(([key, value]) => {
                  return (
                    <option key={key} value={key}>{value}</option>
                  )
                })}
              </Select>
            </FormControl>
          </Stack>
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormLabel fontSize="sm">
              Mostrar no menu
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <FormControl isInvalid={!!errors.showInMenu}>
              <Select
                {...register('showInMenu')}
                name="showInMenu"
                placeholder="Selecione..."
                size="sm"
                rounded="md"
              >
                <option value='yes'>Sim</option>
                <option value='no'>Não</option>
              </Select>
            </FormControl>
          </Stack>
        </Stack>
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors.name} mt="3">
              <FormLabel fontSize="sm">
                Nome do documento
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('name')}
                name="name"
                size="sm"
                rounded="md"
              />
            </FormControl>
          </Stack>
        </Stack>
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors.documentType} mt="3">
              <FormLabel fontSize="sm">
                Tipo do documento
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>

              <Select
                {...register('documentType')}
                name="documentType"
                placeholder="Selecione..."
                size="sm"
                rounded="md"
              >
                {Object.entries(documentTypes).map(([key, value]) => (
                  <option key={key} value={value}>{value}</option>
                ))}
              </Select>

            </FormControl>
          </Stack>
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors.version} mt="3">
              <FormLabel fontSize="sm">
                Versão
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('version')}
                name="version"
                size="sm"
                rounded="md"
              />
            </FormControl>
          </Stack>
        </Stack>

        <Stack
          direction="column"
          w="full"
          mt="3"
        >
          <Button
            as={FormLabel}
            htmlFor="documentAttachmentId"
            lineHeight="1"
            leftIcon={<FaFileImport />}
            size="sm"
            w="min"
            cursor="pointer"
            border={!!errors?.documentAttachmentId && '2px solid'}
            borderColor={(!!errors?.documentAttachmentId) && 'red.500'}
          >
            Anexar Documento
          </Button>
          <FormControl isInvalid={!!errors?.documentAttachmentId}>
            <Input
              {...register('documentAttachmentId')}
              name="documentAttachmentId"
              id="documentAttachmentId"
              type="file"
              hidden
              onChangeCapture={handleUploadDocumentAttachment}
            />
          </FormControl>
        </Stack>

        <FormControl isInvalid={!!errors.version} mt="3">
          <FormLabel fontSize="sm">
            Url da página relacionada ao documento
          </FormLabel>
          <Input
            {...register('relatedPagePathname')}
            name="relatedPagePathname"
            size="sm"
            rounded="md"
          />
        </FormControl>

        {documentAttachmentId && (
          <Table size="sm">
            <Tbody>
              {Object.entries(documentAttachmentId).map(([key, file]) => {
                return (
                  <Tr key={key}>
                    <Td fontSize="xs" maxW="100px">{file.name}</Td>
                    <Td fontSize="xs" w="200px">
                      <Progress size="sm" rounded="md" value={uploadProggressDocumentAttachment} />
                    </Td>
                    <Td fontSize="xs" isNumeric>
                      {uploadDocumentAttachmentFn.data && (
                        <IconButton
                          aria-label="Visualizar anexo"
                          as={Link}
                          size="sm"
                          icon={<FaExternalLinkAlt />}
                          href={uploadDocumentAttachmentFn.data.attachment.link}
                          isExternal
                        />
                      )}
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        )}
        {isAlterationrequestType && (
          <Stack
            direction={["column", "column", "row"]}
            mt="3"
          >
            <Stack
              direction="column"
              w="full"
              spacing="0.25"
            >
              <FormControl isInvalid={!!errors?.alterationDescription}>
                <FormLabel fontSize="sm">
                  Descrição da alteração
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>
                <Textarea
                  {...register('alterationDescription')}
                  name="alterationDescription"
                  size="sm"
                  rounded="md"
                />
              </FormControl>
            </Stack>
          </Stack>
        )}
        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Criar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}
