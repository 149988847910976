import { api } from "services/api";
import axios from 'axios';
import { RequestError } from 'utils/errors/RequestErrors';

interface CreateSpecialBudgetCostBody {
  collectorsIds: string[]
  valueInCents: number
}

interface CreateSpecialBudgetCostParams {
  body: CreateSpecialBudgetCostBody
  budgetId: string
}

export async function createSpecialBudgetCost({
  body,
  budgetId
}: CreateSpecialBudgetCostParams) {
  try {
    await api.post(`budgets/${budgetId}/special-costs`, body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

