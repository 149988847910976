import { Box, Flex } from "@chakra-ui/react";
import { captalize } from "utils/captalize";
import { PriceSituations } from "utils/RequestFunctions/Price/requestPriceFunctions";


interface PriceStatusProps {
  status: PriceSituations
}

export function PriceStatus({ status }: PriceStatusProps) {
  return (
    <Flex
      gap="2"
      align="center"
    >
      {status === 'active' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="green.300"
        />
      )}

      {status === 'inactive' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="red.300"
        />
      )}

      {captalize(status === 'active' ? 'Ativo' : 'Inativo')}
    </Flex>
  )
}
