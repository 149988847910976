import { useState } from "react";
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Input,
  Tag,
  TagLabel,
  TagCloseButton,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Stack,
  Icon,
  Heading,
  Tooltip,
  keyframes,
} from "@chakra-ui/react";
import { FaFilter } from "react-icons/fa";
import { useSearchParams } from "hooks/useSearchParams";
import { useHistory } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { AiOutlineEnter } from "react-icons/ai";


interface CollectsRelationsFiltersProps {
  isOpen: boolean
  onToggle: () => void
}

interface CollectFilters {
  label: string
  key: string
  type: "date" | "time" | "text"
}


const collectFilters: CollectFilters[] = [
  { label: "Protocolo", key: "protocols", type: "text" },
  { label: "Cliente", key: "customers", type: "text" },
  { label: "Veículo", key: "vehicles", type: "text" },
  { label: "Destinatários", key: "destinationAddresses", type: "text" },
  { label: "Data de Coleta", key: "collectDates", type: "date" },
  { label: "Horário de Coleta", key: "collectHours", type: "time" },
  { label: "Coletador de Origem", key: "sourceCollectors", type: "text" },
  { label: "Coletador de Destino", key: "destinationCollectors", type: "text" },
];

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;


export function CollectsRelationsFilters({
  isOpen,
  onToggle
}: CollectsRelationsFiltersProps) {
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const [filterTags, setFilterTags] = useState<Record<string, string[]>>({});
  const [filterInputType, setFilterInputType] = useState<"date" | "time" | "text">("text");
  const searchParams = useSearchParams();
  const { replace } = useHistory();

  function handleFilterSelect(filter: string, type: "date" | "time" | "text") {
    setSelectedFilter(filter)
    setFilterInputType(type)
  }

  function handleAddTag(filter: string, tagValue: string) {
    if (!tagValue.trim()) return

    setFilterTags((prev) => ({
      ...prev,
      [filter]: prev[filter] ? [...prev[filter], tagValue.trim()] : [tagValue.trim()],
    }))
  }

  function handleRemoveTag(filter: string, tag: string) {
    setFilterTags((prev) => {
      const updatedTags = {
        ...prev,
        [filter]: prev[filter]?.filter((t) => t !== tag) || [],
      };

      if (updatedTags[filter].length > 0) {
        searchParams.set(filter, updatedTags[filter].join(","));
      } else {
        searchParams.delete(filter);
      }

      replace({ search: searchParams.toString() });

      return updatedTags;
    });
  }

  function handleFilterCollects() {
    Object.keys(filterTags).forEach((key) => {
      if (filterTags[key]?.length) {
        searchParams.set(key, filterTags[key].join(","))
      }
    })

    searchParams.set("page", "1")
    replace({ search: searchParams.toString() })
  }

  function handleRemoveFilters() {
    replace({ search: "" })
    setFilterTags({})
  }

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onToggle}
    >
      <DrawerOverlay />
      <DrawerContent
        bg="gray.50"
        borderRight="1px"
        borderColor="gray.200"
        boxShadow="md"
      >
        <DrawerCloseButton />
        <DrawerHeader
          textAlign="left"
          fontWeight="bold"
        >
          <Flex
            justifyContent={'space-between'}
            alignItems='baseline'
            mt={5}
          >
            <Heading letterSpacing="tight" size='md'>Filtros</Heading>
            <Flex align="center" gap={1}>
              <Box
                as={Button}
                size="sm"
                variant="unstyled"
                onClick={handleRemoveFilters}
                color="gray.500"
                px={2}
                _focus={{
                  boxShadow: "none",
                  outline: "none",
                }}
                _active={{
                  transform: "scale(0.95)",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                  transition: "transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out",
                }}
              >
                Limpar
              </Box>


              <Box
                bg="gray.400"
                borderRadius="50%"
                height="5px"
                width="5px"
                boxShadow="0 2px 4px rgba(0,0,0,0.2)"
              />

              <Box
                as={Button}
                size="sm"
                variant="unstyled"
                onClick={handleFilterCollects}
                color="blue.500"
                px={2}
                _focus={{
                  boxShadow: "none",
                  outline: "none",
                }}
                _active={{
                  transform: "scale(0.95)",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                  transition: "transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out",
                }}
              >
                Filtrar
              </Box>
            </Flex>

          </Flex>
        </DrawerHeader>

        <DrawerBody>
          <Stack
            spacing={5}
          >
            <Flex w='full' direction="row" align="center" gap={2}>
              {selectedFilter && (
                <Flex align="center" gap={2}>
                  <Input
                    placeholder={`${collectFilters.find((f) => f.key === selectedFilter)?.label}`}
                    boxShadow="sm"
                    borderRadius="md"
                    size="sm"
                    type={filterInputType}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && selectedFilter) {
                        e.preventDefault();
                        handleAddTag(selectedFilter, e.currentTarget.value);
                        e.currentTarget.value = "";
                      }
                    }}
                  />
                  <Tooltip label="Pressione Enter para adicionar" fontSize="sm">
                    <Box
                      animation={`${fadeIn} 0.5s`}
                    >
                      <Icon as={AiOutlineEnter} color="blue.500" cursor="pointer" />
                    </Box>
                  </Tooltip>
                </Flex>
              )}
              <Menu>
                <MenuButton
                  as={Button}
                  variant="outline"
                  rounded="lg"
                  fontWeight="normal"
                  boxShadow={selectedFilter ? "md" : "base"}
                  bg={"blue.50"}
                  color={"gray.800"}
                  size="sm"
                >
                  <Flex align="center" gap={2}>
                    <Icon as={FaFilter} />
                  </Flex>
                </MenuButton>
                <MenuList>
                  {collectFilters.map((filter) => (
                    <MenuItem
                      key={filter.key}
                      onClick={() => handleFilterSelect(filter.key, filter.type)}
                      fontSize='sm'
                    >
                      {filter.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>


            </Flex>

            <Accordion allowMultiple>
              {collectFilters.map((filter) => (
                <AccordionItem key={filter.key}>
                  <AccordionButton>
                    <Box
                      flex="1"
                      textAlign="left"
                      fontSize='sm'
                    >
                      {filter.label}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel>
                    <Flex
                      gap={2}
                      wrap="wrap"
                    >
                      {(filterTags[filter.key] || []).map((tag) => (
                        <Tag
                          key={tag}
                          size="md"
                          bg="#38c3fa"
                          borderRadius="md"
                          color="white"
                        >
                          <TagLabel>
                            {filter?.type === 'date' ? format(parseISO(tag), 'dd/MM/yyyy') : tag}
                          </TagLabel>
                          <TagCloseButton onClick={() => handleRemoveTag(filter.key, tag)} />
                        </Tag>
                      ))}
                    </Flex>
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>

  );
}
