import { Box, Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react"

import { GetSpecialBudgetCostsResponse } from "api/budgets/getSpecialBudgetCosts"
import { SpecialBudgetCostTableRow } from "./SpecialBudgetCostTableRow"

interface SpecialBudgetCostsTableProps {
  budgetId: string
  specialBudgetCostsResult: GetSpecialBudgetCostsResponse
}

export function SpecialBudgetCostsTable({
  budgetId,
  specialBudgetCostsResult
}: SpecialBudgetCostsTableProps) {

  return (
    <Box>
      <TableContainer
        border="1px solid"
        borderColor="gray.200"
        rounded="md"
      >
        <Table size="sm">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th color="white">Coletador</Th>
              <Th color="white">Valor (R$)</Th>
              <Th color="white"></Th>
            </Tr>
          </Thead>
          <Tbody>
            {specialBudgetCostsResult?.budgetSpecialCosts?.map((specialCost) => {
              return (
                <SpecialBudgetCostTableRow
                  key={specialCost.id}
                  specialCost={specialCost}
                  budgetId={budgetId}
                />
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}
