import {
  Flex,
  Box,
  Heading,
  Divider,
  HStack,
  Button,
  Stack,
  useMediaQuery,
  Icon,
  Link as ChakraLink,
  FormLabel,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  VStack,
  FormControl,
  IconButton,
  Text,
} from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { CSVLink } from 'react-csv'
import { FaFilePdf, FaLink, FaEye, FaTrash, FaExternalLinkAlt } from 'react-icons/fa'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, NestedValue, Controller, useFieldArray, FormProvider } from 'react-hook-form'
import * as yup from 'yup'

import {
  situation,
  payment_types,
  negative_positive,
} from '../../utils/customLists'


import { Input } from '../../components/Inputs/Input'
import { Select } from '../../components/Inputs/SelectInput'
import { TextArea } from '../../components/Inputs/TextInput'
import { apiCep } from '../../services/cepApiService/cepApi'
import { CheckboxCollector } from '../../components/Inputs/CheckboxCollector'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { InputMaskCustom } from '../../components/Inputs/InputMask'
import { handleSwitchMask } from '../../utils/handleChangeMask'
import { toast } from 'react-toastify'
import { useHub } from '../../hooks/hub/useHub'
import { isCnpjValid, isCpfValid } from '../../utils/cpfCnpjValidateFunctions'
import { useCurrency } from 'react-hook-currency'
import { apiIbge } from '../../services/ibgeApiservice/ibgeApi'
import {
  IIbgeApiCityProps,
} from '../../services/ibgeApiservice/IIbgeApi'
import { useCollector } from 'hooks/collector/useCollector'
import { formatDate } from 'utils/DateFunctions/formatDate'
import { useIbgeState } from 'hooks/ibge/useIbgeState'
import { useCollectorBoxTag } from 'hooks/collector/useCollectorBoxTag'
import { useCollectorsFunctions } from 'hooks/collector/useCollectorsFunctions'
import { useToastify } from "hooks/toastify/useToastify"
import { useQueryClient } from "react-query"
import { handleChangeUrl } from 'utils/handleChangeUrl'
import { positiveNegativeOptions } from 'utils/CustomLists/positiveNegativeOptions'
import { AttachmentTableRowField } from './components/AttachmentTableRowField'
import { set, format as formatDateFns } from 'date-fns'

interface FormInputProps {
  situation: string
  company_name: string
  trading_name: string
  hub_list: NestedValue<string[]>
  cnpj: string
  operational_email: string
  financial_email: string
  delay_cost: string
  cellphone: string
  telephone: string
  cep: string
  state: string
  city: string
  street: string
  number: string
  neighborhood: string
  complement: string
  municipal_register: string
  payment_type: string
  day_expiration: string
  business_open: string
  business_close: string
  saturday_open: string | null
  saturday_close: string | null
  sunday_open: string | null
  sunday_close: string | null
  has_driver_assign: string | boolean
  observation: string,
  attachments?: FileList
  has_maintenance: 'yes' | 'no'
  state_register: string
  responsible_name: string
  responsible_email: string
  responsible_cpf: string
  documents: {
    name: string
    dueDate: string
    attachmentId: string
    file: File
  }[]
  doesUseSystem: 'yes' | 'no'
}

interface CustomerFormProps {
  slug: string
  id?: string
  isDisabled?: boolean
  href: string
  title: string
  action: string
  submit: (values: FormInputProps) => Promise<void>
}

const headers = [
  { label: 'SITUAÇÃO', key: 'situation' },
  { label: 'RAZÃO SOCIAL', key: 'company_name' },
  { label: 'NOME FANTASIA', key: 'trading_name' },
  { label: 'LISTA DE HUBS', key: 'hub_list' },
  { label: 'CNPJ', key: 'cnpj' },
  { label: 'EMAIL OPERACIONAL', key: 'operational_email' },
  { label: 'EMAIL FINANCEIRO', key: 'financial_email' },
  { label: 'CUSTO POR ATRASOS', key: 'delay_cost' },
  { label: 'CELULAR', key: 'cellphone' },
  { label: 'TELEFONE', key: 'telephone' },
  { label: 'CEP', key: 'cep' },
  { label: 'ESTADO', key: 'state' },
  { label: 'CIDADE', key: 'city' },
  { label: 'RUA', key: 'street' },
  { label: 'NÚMERO', key: 'number' },
  { label: 'BAIRRO', key: 'neighborhood' },
  { label: 'COMPLEMENTO', key: 'complement' },
  { label: 'REGISTRO MUNICIPAL', key: 'municipal_register' },
  { label: 'INSCRIÇÃO ESTADUAL', key: 'state_register' },
  { label: 'TIPO DE PAGAMENTO', key: 'payment_type' },
  { label: 'DATA DE VENCIMENTO', key: 'day_expiration' },
  { label: 'HORÁRIO ABERTURA DE SEG. A SEXTA', key: 'business_open' },
  { label: 'HORÁRIO FECHAMENTO DE SEG. A SEXTA', key: 'business_close' },
  { label: 'HORÁRIO ABERTURA SÁBADOS', key: 'saturday_open' },
  { label: 'HORÁRIO FECHAMENTO SÁBADOS', key: 'saturday_close' },
  { label: 'HORÁRIO ABERTURA DOMINGO', key: 'sunday_open' },
  { label: 'HORÁRIO FECHAMENTO DOMINGO', key: 'sunday_close' },
  { label: 'OBSERVAÇÕES', key: 'observation' },
]

const collectorSchema = yup.object().shape({
  situation: yup.string().required('Selecione uma situação'),
  company_name: yup.string().required('Campo Obrigatório'),
  trading_name: yup.string().required('Campo Obrigatório'),
  cnpj: yup.string().transform(isCnpjValid).required('CNPJ inválido'),
  hub_list: yup.lazy((value) => {
    switch (typeof value) {
      case 'object':
        return yup.array().nullable().required('Campo Obrigatório')
      case 'string':
        return yup.string().nullable().required('Campo Obrigatório')
      default:
        return yup.array().nullable().required('Campo Obrigatório')
    }
  }),
  operational_email: yup
    .string()
    .email('Formato de e-mail inválido')
    .required('Campo Obrigatório'),
  financial_email: yup
    .string()
    .email('Formato de e-mail inválido')
    .required('Campo Obrigatório'),
  delay_cost: yup.string().required('Campo Obrigatório'),
  cellphone: yup.string().required('Campo Obrigatório'),
  telephone: yup.string().required('Campo Obrigatório'),
  cep: yup.string().required('Campo Obrigatório'),
  state: yup.string().required('Campo Obrigatório'),
  city: yup.string().required('Campo Obrigatório'),
  street: yup.string().required('Campo Obrigatório'),
  number: yup.string().required('Campo Obrigatório'),
  complement: yup.string().notRequired(),
  neighborhood: yup.string().required('Campo Obrigatório'),
  municipal_register: yup.string().required('Campo Obrigatório'),
  payment_type: yup.string().required('Campo Obrigatório'),
  day_expiration: yup.string().required('Campo Obrigatório'),
  has_driver_assign: yup.string().required('Campo Obrigatório'),
  business_open: yup.string().required('Campo Obrigatório'),
  business_close: yup.string().required('Campo Obrigatório'),
  saturday_open: yup.string().nullable(),
  saturday_close: yup.string().nullable(),
  sunday_open: yup.string().nullable(),
  sunday_close: yup.string().nullable(),
  attachment: yup.mixed(),
  observation: yup.string(),
  has_maintenance: yup.string().required('Campo Obrigatório'),
  state_register: yup
    .string()
    .required('Campo Obrigatório')
    .test({
      test: value => /^[0-9]+$/.test(value),
      message: 'Insira apenas números!'
    })
    .test({
      test: value => value.length === 9,
      message: 'São necessários exatamente 9 digitos!'
    }),
  responsible_name: yup.string().required('Campo Obrigatório'),
  responsible_email: yup
    .string()
    .email('Formato de e-mail inválido')
    .required('Campo Obrigatório'),
  responsible_cpf: yup.string().transform(isCpfValid).required('CPF inválido'),
  documents: yup.array().of(
    yup.object({
      name: yup.string().nullable(),
      dueDate: yup.string().nullable().transform((value, originalValue, ctx) => {
        if (!value) return null

        const [year, month, day] = originalValue?.split('-').map(Number)
        value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

        return value
      }),
      attachmentId: yup.string().nullable()
    })
  ).optional(),
  doesUseSystem: yup.string().required('Campo Obrigatório'),
})

const documentsNames = [
  "Alvará de Funcionamento",
  "Alvará Sanitário",
  "Certidão de Responsável Técnico",
  "AVCB",
  "Certificado de Regularidade IBAMA",
  "CVV",
  "AATPP"
];

export function CollectorForm({
  slug,
  id,
  isDisabled = false,
  href,
  title,
  action,
  submit,
}: CustomerFormProps) {
  const [isWideVersion] = useMediaQuery('(min-width: 1280px)')

  const [isCitiesLoading, setIsCitiesLoading] = useState(true)
  const [citiesList, setCitiesList] = useState<IIbgeApiCityProps[]>([])

  const [hasOperationSaturday, setHasOperationSaturday] = useState('')
  const [hasOperationSunday, setHasOperationSunday] = useState('')

  const [isLoadingPage, setIsLoadingPage] = useState(true)

  const {
    hubs: { data: hubs, isLoading: isHubLoading },
  } = useHub(null, true, false)

  const {
    data: collector, isLoading: isCollectorLoading,
  } = useCollector(id, {
    onSuccess(data) {
      if (data.saturday_open === null) {
        setHasOperationSaturday('NÃO')
      } else {
        setHasOperationSaturday('SIM')
      }

      if (data.sunday_open === null) {
        setHasOperationSunday('NÃO')
      } else {
        setHasOperationSunday('SIM')
      }

      const timeOpenWeek = formatDate.handle(data.business_open, "DateOnlyWithHourMinute")
      const timeCloseWeek = formatDate.handle(data.business_close, "DateOnlyWithHourMinute")
      const timeOpenSaturday = formatDate.handle(data.saturday_open, "DateOnlyWithHourMinute")
      const timeCloseSaturday = formatDate.handle(
        data.saturday_close, "DateOnlyWithHourMinute"
      )
      const timeOpenSunday = formatDate.handle(data.sunday_open, "DateOnlyWithHourMinute")
      const timeCloseSunday = formatDate.handle(data.sunday_close, "DateOnlyWithHourMinute")

      setValue('situation', data.situation)
      setValue('company_name', data.company_name)
      setValue('trading_name', data.trading_name)
      setValue('hub_list', data.hub_list)
      setValue('cnpj', data.cnpj)
      setValue('operational_email', data.operational_email)
      setValue('financial_email', data.financial_email)
      setValue('delay_cost', String(data.delay_cost))
      setValue('cellphone', data.cellphone)
      setValue('telephone', data.telephone)
      setValue('cep', data.cep)
      setValue('street', data.street)
      setValue('number', data.number)
      setValue('complement', data.complement)
      setValue('neighborhood', data.neighborhood)
      setValue('state', data.state)
      setValue('city', data.city)
      setValue('municipal_register', data.municipal_register)
      if (data.state_register) {
        setValue('state_register', data.state_register)
      }
      setValue('payment_type', data.payment_type)
      setValue('day_expiration', data.day_expiration)
      setValue('has_driver_assign', data.has_driver_assign ? 'SIM' : 'NÃO')
      setValue('business_open', timeOpenWeek as string)
      setValue('business_close', timeCloseWeek as string)
      setValue('saturday_open', timeOpenSaturday)
      setValue('saturday_close', timeCloseSaturday)
      setValue('sunday_open', timeOpenSunday)
      setValue('sunday_close', timeCloseSunday)
      setValue('observation', data.observation)
      setValue('has_maintenance', data.has_maintenance ? 'yes' : 'no')
      setValue('responsible_name', data.responsible_name ?? '')
      setValue('responsible_email', data.responsible_email ?? '')
      setValue('responsible_cpf', data.responsible_cpf ?? '')
      setValue('doesUseSystem', data.does_use_system ? 'yes' : 'no')
    },
  })

  const {
    ibgeStates: { data: states, isFetching: isStatesFetching }
  } = useIbgeState()

  const {
    data: collectorBoxTagData,
    isFetching: isFetchingCollectorBoxTag
  } = useCollectorBoxTag({
    id,
    queryOptions: { enabled: !!id },
  })

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const {
    deleteCollectorAttachment: { mutateAsync: deleteCollectorAttachment },
  } = useCollectorsFunctions();

  async function handleDeleteCollectorAttachment(
    collectorId: string,
    filename: string
  ) {
    await promiseMessage(
      deleteCollectorAttachment({
        collectorId,
        filename,
      }),
      "Anexo removido com sucesso!"
    );

    await queryClient.invalidateQueries(["collector"]);
  }



  const bytes = new Uint8Array(collectorBoxTagData)

  const url = URL.createObjectURL(
    new Blob([bytes], { type: 'application/pdf' }),
  )

  const formMethods = useForm<FormInputProps>({
    resolver: yupResolver(collectorSchema),
    defaultValues: {
      documents: documentsNames.map(name => ({ name, dueDate: "", attachmentId: "" }))
    }
  })

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    control,
    formState: { errors, isSubmitting },
  } = formMethods

  const { fields } = useFieldArray({ control, name: "documents" });

  const handleSetAttachmentId = (attachmentId: string, index: number) => {
    setValue(`documents.${index}.attachmentId`, attachmentId);
  };

  const companyName = watch('company_name')
  const tradingName = watch('trading_name')
  const attachments = watch('attachments')

  const { onChange, format } = useCurrency({
    style: 'decimal',
  })

  function handleGetFormDataToArray() {
    const dataArr = []
    const formData = getValues()
    dataArr.push(formData)

    return dataArr
  }

  const csvReportProps = {
    data: handleGetFormDataToArray() as object[],
    headers,
    filename: `relatório-coletador-${companyName}`,
  }

  const selectedState = watch('state')
  const cepValue = watch('cep')
  const selectedCity = watch('city')

  useEffect(() => {
    if (!isHubLoading && !isCollectorLoading && !isStatesFetching && !isCitiesLoading) {
      setIsLoadingPage(false)
    }
  }, [isHubLoading, isCollectorLoading, isStatesFetching, isCitiesLoading])

  useEffect(() => {
    async function run() {
      if (slug === 'adicionar') {
        setValue('situation', 'ATIVO')
      }

      const cepSplited = cepValue?.split('-').join('')
      if (
        !cepSplited?.includes('_') &&
        cepSplited !== undefined &&
        cepSplited !== '' &&
        slug === 'adicionar'
      ) {
        try {
          const data = await apiCep.get(`/${cepSplited}/json/`)
          const cityFilteredByCep = data.localidade

          if (states) {
            const filteredState = states
              .filter((state) => state.sigla === data.uf)
              .map((state) => state.nome)
              .toString()

            setValue('street', data.logradouro)
            setValue('neighborhood', data.bairro)
            if (!isCitiesLoading) {
              setValue('city', cityFilteredByCep.toUpperCase())
            }
            setValue('state', filteredState.toUpperCase())
            setValue('complement', data.complemento)
          }
        } catch {
          toast.error('Erro ao consultar cep!')
        }
      }
    }
    run()
  }, [setValue, cepValue, slug, states, isCitiesLoading])

  useEffect(() => {
    function run() {
      if (hasOperationSaturday === 'NÃO') {
        setValue('saturday_open', ' ')
        setValue('saturday_close', ' ')
      }

      if (hasOperationSunday === 'NÃO') {
        setValue('sunday_open', ' ')
        setValue('sunday_close', ' ')
      }
    }
    run()
  }, [setValue, hasOperationSaturday, hasOperationSunday])

  useEffect(() => {
    async function run() {
      if (states && selectedState) {
        const uf = states
          .filter((state) => state.nome.toUpperCase() === selectedState)
          .map((state) => state.sigla)
          .toString()

        try {
          const citiesList = await apiIbge.getCities(`/estados/${uf}/municipios`)

          setCitiesList(citiesList)
        } finally {
          setIsCitiesLoading(false)
        }
      }
    }
    run()
  }, [setValue, states, selectedState, selectedCity])

  const collectorAttachments = collector?.attachments;

  if (isLoadingPage && slug !== "adicionar") {
    return <GeneralContentLoading />
  }


  return (

    <Flex>
      <Box
        as="form"
        flex="1"
        borderRadius="8px"
        bg="white"
        p={['6', '8']}
        onSubmit={handleSubmit(submit)}
        noValidate
      >
        <Heading size="lg" fontFamily="poppins">
          {title}
        </Heading>

        <Divider my="6" borderColor="gray.700" />

        <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
          <Input
            {...register('company_name')}
            isDisabled={isDisabled}
            name="company_name"
            label="Razão Social"
            error={errors.company_name}
            required
          />

          <Input
            {...register('trading_name')}
            isDisabled={isDisabled}
            name="trading_name"
            label="Nome Fantasia"
            error={errors.trading_name}
            required
          />
        </Stack>

        <HStack spacing="24px" mt="4">
          {slug === 'adicionar' ? (
            <Input
              {...register('situation')}
              name="situation"
              label="Situação"
              isDisabled
              required
            />
          ) : (
            <Select
              {...register('situation')}
              name="situation"
              label="Situação"
              error={errors.situation}
              isDisabled={isDisabled}
              situations={situation}
              placeholder="Selecione a situação"
              required
            />
          )}

          <Controller
            control={control}
            name="cnpj"
            render={({ field: { onChange, value } }) => (
              <InputMaskCustom
                {...register('cnpj')}
                name="cnpj"
                defaultValue={value}
                onChange={onChange}
                mask={handleSwitchMask('cnpj')}
                label="CNPJ"
                error={errors.cnpj}
                isDisabled={isDisabled}
                required
              />
            )}
          />
        </HStack>
        <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
          <Input
            {...register('responsible_name')}
            isDisabled={isDisabled}
            name="responsible_name"
            label="Nome do responsável"
            error={errors.responsible_name}
            required
          />
        </Stack>
        <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>

          <Controller
            control={control}
            name="responsible_cpf"
            render={({ field: { onChange, value, name } }) => (
              <InputMaskCustom
                name={name}
                defaultValue={value}
                onChange={onChange}
                mask={handleSwitchMask('cpf')}
                label="CPF do responsável"
                error={errors.responsible_cpf}
                isDisabled={isDisabled}
                required
              />
            )}
          />
          <Input
            {...register('responsible_email')}
            name="responsible_email"
            label="Email do responsável"
            placeholder="email@exemplo.com"
            error={errors.responsible_email}
            isDisabled={isDisabled}
            required
          />
        </Stack>


        <HStack spacing="24px" mt="4">
          <CheckboxCollector
            {...register('hub_list')}
            name="hub_list"
            label="Perterce ao HUB"
            hubs={hubs}
            defaultOption={collector?.hub_list}
            error={errors.hub_list}
            isDisabled={isDisabled}
            required
          />
        </HStack>

        <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
          <Input
            {...register('operational_email')}
            name="operational_email"
            label="Email Operacional"
            placeholder="email@exemplo.com"
            error={errors.operational_email}
            isDisabled={isDisabled}
            required
          />
          <Input
            {...register('financial_email')}
            name="financial_email"
            placeholder="email@exemplo.com"
            label="Email Financeiro"
            error={errors.financial_email}
            isDisabled={isDisabled}
            required
          />
        </Stack>

        <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
          <Input
            {...register('delay_cost')}
            name="delay_cost"
            label="Custo por atrasos (por minuto)"
            addChildren="R$"
            error={errors.delay_cost}
            onChange={onChange}
            defaultValue={format('000')}
            isDisabled={isDisabled}
            required
          />
          <HStack w="full" spacing="24px">
            <Controller
              control={control}
              name="cellphone"
              render={({ field: { onChange, value } }) => (
                <InputMaskCustom
                  {...register('cellphone')}
                  mask={handleSwitchMask('cellphone')}
                  onChange={onChange}
                  defaultValue={value}
                  name="cellphone"
                  label="Celular"
                  error={errors.cellphone}
                  isDisabled={isDisabled}
                  required
                />
              )}
            />

            <Controller
              control={control}
              name="telephone"
              render={({ field: { onChange, value } }) => (
                <InputMaskCustom
                  {...register('telephone')}
                  name="telephone"
                  defaultValue={value}
                  onChange={onChange}
                  mask={handleSwitchMask('phone')}
                  label="Telefone"
                  error={errors.telephone}
                  isDisabled={isDisabled}
                  required
                />
              )}
            />
          </HStack>
        </Stack>

        <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
          <Controller
            control={control}
            name="cep"
            render={({ field: { onChange, value } }) => (
              <InputMaskCustom
                {...register('cep')}
                name="cep"
                label="CEP"
                error={errors.cep}
                isDisabled={isDisabled}
                defaultValue={value}
                onChange={onChange}
                mask={handleSwitchMask('cep')}
                required
              />
            )}
          />
          <HStack w="100%" spacing="24px">
            <Input
              {...register('street')}
              name="street"
              label="Rua"
              error={errors.street}
              isDisabled={isDisabled}
              required
            />

            <Input
              {...register('number')}
              name="number"
              label="Número"
              error={errors.number}
              isDisabled={isDisabled}
              required
            />
          </HStack>
        </Stack>

        <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
          <Input
            {...register('complement')}
            name="complement"
            label="Complemento"
            error={errors.complement}
            isDisabled={isDisabled}
          />
          <Input
            {...register('neighborhood')}
            name="neighborhood"
            label="Bairro"
            error={errors.neighborhood}
            isDisabled={isDisabled}
            required
          />

          <Select
            {...register('state')}
            name="state"
            label="Estado"
            error={errors.state}
            states={states}
            placeholder="Selecione um estado"
            isDisabled={isDisabled}
            required
          />
          <Select
            {...register('city')}
            name="city"
            label="Cidade"
            error={errors.city}
            citiesIBGE={citiesList}
            placeholder="Selecione uma Cidade"
            isDisabled={isDisabled}
            required
          />
        </Stack>

        <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
          <Stack
            w="full"
            spacing={6}
            direction={{ base: 'column', lg: 'row' }}
          >
            <Input
              {...register('municipal_register')}
              name="municipal_register"
              label="Registro Municipal"
              error={errors.municipal_register}
              isDisabled={isDisabled}
              required
            />

            <Input
              {...register('state_register')}
              name="state_register"
              label="Inscrição Estadual"
              error={errors.state_register}
              isDisabled={isDisabled}
              required
            />
          </Stack>
          <HStack w="100%" spacing={6}>
            <Select
              {...register('payment_type')}
              name="payment_type"
              payment_types={payment_types}
              label="Tipo de pagamento"
              placeholder="Selecione uma opção..."
              error={errors.payment_type}
              isDisabled={isDisabled}
              required
            />
            <Input
              {...register('day_expiration')}
              name="day_expiration"
              label="Data de Vencimento"
              error={errors.day_expiration}
              isDisabled={isDisabled}
              required
            />
          </HStack>
        </Stack>

        <Stack mt="4">
          <Select
            {...register("has_driver_assign")}
            name="has_driver_assign"
            label="Atribuir motorista"
            negative_positive={negative_positive}
            placeholder="Selecione uma opção..."
            required
          />
        </Stack>

        <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
          <Input
            name="week_select"
            value="SIM"
            label="Funcionamento de segunda a sexta?"
            error={undefined}
            isDisabled={true}
            required
          />
          <HStack w="100%">
            <Input
              {...register('business_open')}
              name="business_open"
              label="Horário de abertura"
              type="time"
              error={errors.business_open}
              isDisabled={isDisabled}
              required
            />
            <Input
              {...register('business_close')}
              name="business_close"
              label="Horário de fechamento"
              type="time"
              error={errors.business_close}
              isDisabled={isDisabled}
              required
            />
          </HStack>
        </Stack>

        <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
          <Select
            onChange={(e) => setHasOperationSaturday(e.target.value)}
            value={hasOperationSaturday}
            negative_positive={negative_positive}
            placeholder="Selecione uma opção..."
            name="weekend_select"
            label="Funcionamento aos sábados?"
            error={undefined}
            isDisabled={isDisabled}
            required
          />
          <HStack w="100%">
            <Input
              {...register('saturday_open')}
              name="saturday_open"
              label="Horário de abertura"
              type="time"
              error={
                hasOperationSaturday === 'SIM'
                  ? errors.business_open
                  : undefined
              }
              isDisabled={
                !(slug !== 'visualizar' && hasOperationSaturday === 'SIM')
              }
              required
            />
            <Input
              {...register('saturday_close')}
              name="saturday_close"
              label="Horário de fechamento"
              type="time"
              error={
                hasOperationSaturday === 'SIM'
                  ? errors.saturday_close
                  : undefined
              }
              isDisabled={
                !(slug !== 'visualizar' && hasOperationSaturday === 'SIM')
              }
              required
            />
          </HStack>
        </Stack>

        <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
          <Select
            onChange={(e) => setHasOperationSunday(e.target.value)}
            value={hasOperationSunday}
            negative_positive={negative_positive}
            placeholder="Selecione uma opção..."
            name="weekend_select"
            label="Funcionamento aos domingos?"
            error={undefined}
            isDisabled={isDisabled}
            required
          />
          <HStack w="100%">
            <Input
              {...register('sunday_open')}
              name="sunday_open"
              label="Horário de abertura"
              type="time"
              error={
                hasOperationSunday === 'SIM' ? errors.sunday_open : undefined
              }
              isDisabled={
                !(slug !== 'visualizar' && hasOperationSunday === 'SIM')
              }
              required
            />
            <Input
              {...register('sunday_close')}
              name="sunday_close"
              label="Horário de fechamento"
              type="time"
              error={
                hasOperationSunday === 'SIM' ? errors.sunday_close : undefined
              }
              isDisabled={
                !(slug !== 'visualizar' && hasOperationSunday === 'SIM')
              }
              required
            />
          </HStack>
        </Stack>

        <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>

          <Select
            {...register('has_maintenance')}
            name='has_maintenance'
            options={positiveNegativeOptions}
            label='Realiza manutenção?'
            placeholder='Selecione uma opção...'
            error={errors.has_maintenance}
            required

          />
          <Select
            {...register('doesUseSystem')}
            name='doesUseSystem'
            options={positiveNegativeOptions}
            label='Utiliza sistema?'
            placeholder='Selecione uma opção...'
            error={errors.doesUseSystem}
            required

          />
        </Stack>

        <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
          <TextArea
            {...register('observation')}
            name="observation"
            label="Observações"
            isDisabled={isDisabled}
          />
        </Stack>

        {collectorAttachments && collectorAttachments.length > 0 && (
          <TableContainer w="full">
            <Table size="sm" mt={4}>
              <Thead>
                <Tr>
                  <Th>Anexos Cadastrados</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {collectorAttachments.map((attachment) => {
                  return (
                    <Tr key={attachment}>
                      <Th>{attachment} </Th>
                      <Th isNumeric>
                        <Button
                          as={ChakraLink}
                          href={handleChangeUrl(attachment)}
                          variant="ghost"
                          isExternal
                        >
                          <Icon as={FaEye} />
                        </Button>
                        {slug === "editar" && (
                          <Button
                            variant="ghost"
                            onClick={() => {
                              handleDeleteCollectorAttachment(id, attachment);
                            }}
                          >
                            <Icon as={FaTrash} />
                          </Button>
                        )}
                      </Th>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        )}

        <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
          <Input
            {...register('attachments')}
            name="attachments"
            label="Anexos(Opcional)"
            type="file"
            multiple
            accept=".pdf"
            error={errors.attachments}
            hidden

          >

            <Flex w="full" align="center" justify="flex-start">
              <Flex align="center">
                <Button
                  as={FormLabel}
                  htmlFor='attachments'
                  _hover={{
                    cursor: 'pointer'
                  }}
                  mr="2"
                  leftIcon={<Icon as={FaFilePdf} />}
                >
                  Upload
                </Button>
              </Flex>
            </Flex>
          </Input>
        </Stack>

        {attachments && (
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>Anexos</Th>
                </Tr>
              </Thead>
              <Tbody>
                {Object.entries(attachments).map(([key, file]) => {
                  return (
                    <Tr key={key}>
                      <Th >{file.name} </Th>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          </TableContainer>
        )}
        <FormLabel>Documentos</FormLabel>
        <VStack
          spacing={4}
          border="1px solid"
          borderColor="gray.200"
          p="4"
          rounded='md'
        >
          {fields.map((field, index) => {
            const documentsCollector = collector?.documents.find(document => document.name === field.name)
            return (
              <>
                <FormControl key={field.id}>
                  <FormLabel>{field.name}</FormLabel>
                  {documentsCollector && (
                    <VStack align="start" spacing={2} mb={4}>
                      <HStack>
                        <IconButton
                          aria-label="Abrir anexo"
                          as={ChakraLink}
                          href={documentsCollector.attachment?.link}
                          icon={<FaExternalLinkAlt />}
                          size="sm"
                          isExternal
                          colorScheme="blue"
                          variant="outline"
                        />
                        <Text color="gray.600" fontSize="sm">{documentsCollector?.attachment?.title}</Text>
                      </HStack>
                      <Text fontSize="sm" color="gray.500">
                        Data de Vencimento: <strong>{formatDateFns(new Date(documentsCollector.dueDate), 'dd/MM/yyyy')}</strong>
                      </Text>
                    </VStack>
                  )}
                  <Input
                    {...formMethods.register(`documents.${index}.dueDate`)}
                    name={`documents.${index}.dueDate`}
                    label="Data de Vencimento"
                    type="date"
                    isDisabled={isDisabled}
                  />
                  <FormLabel fontSize='sm' mt={3}>
                    Anexo
                  </FormLabel>
                  <FormProvider {...formMethods}>
                    <AttachmentTableRowField
                      index={index}
                      isFilledField={!!formMethods.watch(`documents.${index}.attachmentId`)}
                      onSetAttachmentId={(attachmentId) => handleSetAttachmentId(attachmentId, index)} />
                  </FormProvider>
                </FormControl>
                {index < fields.length - 1 && <Divider mt={4} />}
              </>
            )
          })}
        </VStack>

        <Flex w="full" mt="8" flexDirection={['column', 'column', 'row']}>
          <Flex w="full" gap={2} justifyContent={['center', 'center', 'flex-start']}>
            <CSVLink
              {...csvReportProps}
              separator={';'}
              style={{ width: isWideVersion ? '' : '100%' }}
            >
              <Button
                type="button"
                colorScheme="green"
                w={['full', 'full', '']}
              >
                Exportar CSV
              </Button>
            </CSVLink>
            <Button
              as={ChakraLink}
              href={url}
              isExternal
              isLoading={isFetchingCollectorBoxTag}
              leftIcon={<Icon as={FaLink} />}
              download={`tampa-de-caixa-${tradingName}.pdf`}
            >
              Dowload tampa de caixa
            </Button>
          </Flex>
          <Flex w="full" justifyContent="flex-end" mt={['4', '4', '0']}>
            <HStack flex={['1', '1', '0']}>
              {slug === 'visualizar' ? (
                <Link to={href}>
                  <Button
                    type="button"
                    colorScheme="blue"
                    isLoading={isSubmitting}
                    loadingText="Carregando"
                  >
                    {action}
                  </Button>
                </Link>
              ) : (
                <Button
                  type="submit"
                  colorScheme="blue"
                  isLoading={isSubmitting}
                  loadingText="Carregando"
                  w={['full', 'full', 'full', '']}
                >
                  {action}
                </Button>
              )}

              <Link
                to="/coletadores"
                style={{ width: isWideVersion ? '' : '100%' }}
              >
                <Button type="button" colorScheme="gray">
                  Lista de Coletadores
                </Button>
              </Link>
            </HStack>
          </Flex>
        </Flex>
      </Box>
    </Flex>

  )
}
