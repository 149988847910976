
import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { ExtraAndDiscount } from "./_types/ExtraAndDiscount";

interface GetExtraAndDiscountResponse {
  extraAndDiscount: ExtraAndDiscount
}

interface GetExtraAndDiscountProps {
  extraAndDiscountId: string
}

export async function getExtraAndDiscount({
  extraAndDiscountId
}: GetExtraAndDiscountProps) {
  try {
    const response = await api.get<GetExtraAndDiscountResponse>(`extras-and-discounts/${extraAndDiscountId}`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
