import { api } from "services/api";
import axios from 'axios';
import { RequestError } from 'utils/errors/RequestErrors';

interface CreatePatrimonyRequestBody {
  limitDate: string
  regional: string
  responsibleName: string
  sector: string
  patrimonyTypes: {
    patrimonyTypeId: string
    quantity: number
  }[]
}

interface CreatePatrimonyRequestParams {
  body: CreatePatrimonyRequestBody
}

export async function createPatrimonyRequest({
  body
}: CreatePatrimonyRequestParams) {
  try {
    await api.post('patrimony-requests', body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

