import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

export interface GetCustomersCostCenterParams {
  currentPage: number
  pageSize: number
  costCenter?: string
}

export interface GetCustomersCostCenterResponse {
  costCenters: {
    customerId: string
    costCenter: string
  }[]
  meta: {
    totalPages: number
    currentPage: number
    pageSize: number
  }
}



export async function getCustomersCostCenters({
  currentPage,
  pageSize,
  costCenter
}: GetCustomersCostCenterParams) {
  try {
    const response = await api.get<GetCustomersCostCenterResponse>('/customers/cost-centers', {
      params: {
        currentPage,
        pageSize,
        costCenter
      }
    })

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
