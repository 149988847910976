import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface CloseExtraAndDiscountParams {
  extraAndDiscountId: string
}
export async function closeExtraAndDiscount({
  extraAndDiscountId,
}: CloseExtraAndDiscountParams) {
  try {
    await api.patch(`/extras-and-discounts/${extraAndDiscountId}/close`)

  } catch (error) {
    console.log("error", error)
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
