import axios from "axios";
import { RequestError } from "utils/errors/RequestErrors";

interface GetTransferBudgetDeviationReportProps {
  queryParams: {
    startDate: string
    finalDate: string
  }
}

interface GetTransferBudgetDeviationReportResponse {
  protocol: number
  sourceAddresses: {
    id: string
    city: {
      id: string
      name: string
    }
  }[]
  destinationAddresses: {
    id: string
    city: {
      id: string
      name: string
    }
  }[]
  boards: {
    id: string
    boardVolume: number
    boardWeight: number
    realWeight: number
    cteTransferCost: number
  }[]
  requestedService: {
    id: string
    budget: {
      extraKgPrice: number
      transferBudget: number
      franchising: number
    }
  }
  customer: {
    id: string
    tradingFirstname: string
  }
  collectDate: string
}


export async function getTransferBudgetDeviationReport({
  queryParams
}: GetTransferBudgetDeviationReportProps): Promise<GetTransferBudgetDeviationReportResponse[]> {
  try {

    const userInfo = localStorage.getItem('userInfo')

    const response = await fetch(`${process.env.REACT_APP_API_BACKEND}/services/reports/transfer-budget-deviation?startDate=${queryParams.startDate}&finalDate=${queryParams.finalDate}`, {
      headers: {
        'Authorization': `${JSON.parse(userInfo).token}`
      }
    });

    if (!response.ok) {
      throw new RequestError('Erro ao inesperado ao gerar o relatório!');
    }

    // Verifica se a resposta está sendo entregue como stream
    const reader = response.body.getReader()
    const decoder = new TextDecoder()
    let buffer = ''

    let data = []

    while (true) {
      const { done, value } = await reader.read()

      if (done) {
        console.log('Stream has ended')
        break;
      }

      buffer += decoder.decode(value, { stream: true })

      let boundary = 0
      while ((boundary = buffer.indexOf('\n')) !== -1) {
        const chunk = buffer.slice(0, boundary)
        buffer = buffer.slice(boundary + 1)

        if (chunk.trim()) {
          try {
            const parsedChunk = JSON.parse(chunk);
            data = data.concat(JSON.parse(chunk))
            // Parse o pedaço de JSON
            console.log('Chunk JSON recebido:', parsedChunk);
          } catch (error) {
            console.error('Erro ao parsear o chunk:', error);
          }
        }
      }
    }

    return data
  } catch (error) {

    console.error('Error', error)

    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

