import { Box, Button, Checkbox, CheckboxGroup, Flex, FormControl, FormLabel, Heading, Input, InputGroup, InputLeftAddon, Radio, RadioGroup, Stack, Text, VStack } from "@chakra-ui/react";
import { useToastify } from "hooks/toastify/useToastify";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useCurrency } from "react-hook-currency";
import { dismissalComunicationInternClt } from "api/internClts/dismissalComunicationInternClt";
import { transformStringToNumber } from "utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber";


interface DismissalComunicationInternCltProps {
  internCltId: string
}

export interface DismissalComunicationInternCltSchema {
  isTicketCardBlocked: string
  isRemovedOnTransfacilAndOtimo: string
  isConferredPointRegisters: string
  inactivations: string[]
  isWithCoParticipationDiscount: string
  coParticipationDiscountValueInCents: number
}

const dismissalComunicationInternCltSchema = yup.object({
  isTicketCardBlocked: yup.string().required(),
  isRemovedOnTransfacilAndOtimo: yup.string().required(),
  isConferredPointRegisters: yup.string().required(),
  inactivations: yup.array().min(1).of(yup.string()).required(),
  isWithCoParticipationDiscount: yup.string().required(),
  coParticipationDiscountValueInCents: yup.mixed().when('isWithCoParticipationDiscount', {
    is: 'yes',
    then: yup.number().required().transform((value, originalValue) => Math.ceil(transformStringToNumber(originalValue) * 100)),
  })

})

const signedDocsChecklist = [
  { name: 'iFractal' },
  { name: 'Plataforma de treinamento' },
]


export function DismissalComunicationInternClt({ internCltId }: DismissalComunicationInternCltProps) {

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const {
    handleSubmit,
    register,
    control,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<DismissalComunicationInternCltSchema>({
    resolver: yupResolver(dismissalComunicationInternCltSchema)
  })

  const isWithCoParticipationDiscount = useWatch({
    control,
    name: 'isWithCoParticipationDiscount'
  })

  const {
    format: currencyFormat, onChange: onCurrencyChange,
  } = useCurrency({
    style: 'decimal'
  })

  const { mutateAsync: dismissalComunicationInternCltFn } = useMutation({
    mutationFn: dismissalComunicationInternClt,
    onSuccess(_data, { body, internCltId }) {
      queryClient.invalidateQueries({ queryKey: 'intern-clts' })
      queryClient.invalidateQueries({ queryKey: ['intern-clt', internCltId] })
    }
  })

  async function handleDismissalComunicationInternClt(values: DismissalComunicationInternCltSchema) {
    await promiseMessage(dismissalComunicationInternCltFn({
      body: {
        ...values,
        isWithCoParticipationDiscount: values.isWithCoParticipationDiscount === 'yes'
      },
      internCltId
    }), 'Comunicação enviada!')
  }


  return (
    <Box
      w="full"
      as="form"
      onSubmit={handleSubmit(handleDismissalComunicationInternClt)}
    >
      <Heading letterSpacing="tight" size='sm'>Demissão comunicação</Heading>
      <FormControl isInvalid={!!errors.isTicketCardBlocked} mt={3}>
        <FormLabel>
          Cartão ticket bloqueado ?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="isTicketCardBlocked"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="Sim">Sim</Radio>
                <Radio size='sm' rounded='md' value="Não">Não</Radio>
                <Radio size='sm' rounded='md' value="N/A">N/A</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.isRemovedOnTransfacilAndOtimo} mt={3}>
        <FormLabel>
          Funcionário desativado do site Transfácil e Otimo ?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="isRemovedOnTransfacilAndOtimo"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="Sim">Sim</Radio>
                <Radio size='sm' rounded='md' value="Não">Não</Radio>
                <Radio size='sm' rounded='md' value="N/A">N/A</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.isConferredPointRegisters} mt={3}>
        <FormLabel>
          Realizou conferência de registros de ponto ?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="isConferredPointRegisters"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="Sim">Sim</Radio>
                <Radio size='sm' rounded='md' value="N/A">N/A</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl mt={3} isInvalid={!!errors.inactivations}>
        <FormLabel fontSize='sm'>
          Funcionário inativado:
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          control={control}
          name="inactivations"
          render={({ field }) => {
            return (
              <CheckboxGroup
                value={field.value}
                onChange={field.onChange}
              >
                <VStack
                  spacing={3}
                  p={2}
                  border="1px solid"
                  borderColor="gray.200"
                  rounded="md"
                  align="left"
                >
                  {signedDocsChecklist.map(check => {
                    return (
                      <Checkbox key={check.name} value={check.name}>
                        <Text fontSize="sm">
                          {check.name}
                        </Text>
                      </Checkbox>
                    )
                  })}
                </VStack>

              </CheckboxGroup>
            )
          }}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.isWithCoParticipationDiscount} mt={3}>
        <FormLabel>
          Funcionário possui descontos de coparticipação em aberto para descontar ?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="isWithCoParticipationDiscount"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      {isWithCoParticipationDiscount === 'yes' && (
        <FormControl isInvalid={!!errors?.coParticipationDiscountValueInCents} mt={3}>
          <FormLabel fontSize="sm">
            Valor a ser descontado:
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Controller
            name="coParticipationDiscountValueInCents"
            control={control}
            render={({ field }) => {
              return (
                <InputGroup
                  size="sm"
                >
                  <InputLeftAddon
                    borderTopLeftRadius="md"
                    borderBottomLeftRadius="md"
                  >
                    R$
                  </InputLeftAddon>
                  <Input
                    {...register("coParticipationDiscountValueInCents")}
                    size="sm"
                    rounded="md"
                    w="full"
                    name={field.name}
                    defaultValue={currencyFormat('000')}
                    onChange={e => field.onChange(onCurrencyChange(e))}
                  />
                </InputGroup>
              )
            }}
          />
        </FormControl>
      )}
      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </Box>
  )
}
