import { Button, Checkbox, CheckboxGroup, Flex, FormControl, FormLabel, Radio, RadioGroup, Stack, Text } from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { approveCltIntercurrence } from "api/cltIntercurrences/approveCltIntercurrence"
import { GetCltIntercurrencesResponse } from "api/cltIntercurrences/getCltIntercurrences"
import { useToastify } from "hooks/toastify/useToastify"
import { Controller, useForm } from "react-hook-form"
import { useMutation, useQueryClient } from "react-query"
import * as yup from "yup"

interface ApproveTurnChangeCltIntercurrenceSchema {
  withMotorcycleRental: string
  withFuel: string
  withPontomaisAdjust: string
  wasChangedDriverOnBusinessBudget: string
  turnChangeChecklist: string[]
}

const approveTurnChangeCltIntercurrenceSchema = yup.object({
  withMotorcycleRental: yup.string().required(),
  withFuel: yup.string().required(),
  withPontomaisAdjust: yup.string().required(),
  wasChangedDriverOnBusinessBudget: yup.string().required(),
  turnChangeChecklist: yup.array().of(yup.string()).min(1)
})

interface ApproveTurnChangeCltIntercurrenceProps {
  cltIntercurrenceId: string
}

export function ApproveTurnChangeCltIntercurrence({
  cltIntercurrenceId
}: ApproveTurnChangeCltIntercurrenceProps) {
  const {
    control,
    handleSubmit,
    formState: {
      errors,
      isSubmitting,
    }
  } = useForm<ApproveTurnChangeCltIntercurrenceSchema>({
    resolver: yupResolver(approveTurnChangeCltIntercurrenceSchema)
  })

  const queryClient = useQueryClient()

  const { mutateAsync: approveCltIntercurrenceFn } = useMutation({
    mutationFn: approveCltIntercurrence,
    onSuccess: (_data, { routeParams }) => {
      const cached = queryClient.getQueriesData<GetCltIntercurrencesResponse>({
        queryKey: ['clt-intercurrences']
      })

      queryClient.invalidateQueries({ queryKey: 'approved-clt-intercurrences' })
      queryClient.invalidateQueries({ queryKey: 'rejected-clt-intercurrences' })
      queryClient.invalidateQueries({ queryKey: 'requested-cltintercurrences' })
      queryClient.invalidateQueries({ queryKey: ['clt-intercurrence', routeParams.cltIntercurrenceId] })

      cached.forEach(([cachedKey, cachedValue]) => {
        if (!cachedValue) return

        queryClient.setQueryData(cachedKey, {
          ...cachedValue,
          cltIntercurrences: cachedValue.cltIntercurrences.map((cltIntercurrence) => {
            if (cltIntercurrence.id === routeParams.cltIntercurrenceId) {
              return { ...cltIntercurrence, status: 'approved' }
            }

            return cltIntercurrence
          })
        })
      })
    }
  })

  const { promiseMessage } = useToastify()

  async function handleApproveCltIntercurrence(values: ApproveTurnChangeCltIntercurrenceSchema) {
    const body = {}

    body['withMotorcycleRental'] = values.withMotorcycleRental === 'yes'
    body['withFuel'] = values.withFuel === 'yes'
    body['withPontomaisAdjust'] = values.withPontomaisAdjust
    body['wasChangedDriverOnBusinessBudget'] = values.wasChangedDriverOnBusinessBudget
    body['turnChangeChecklist'] = values.turnChangeChecklist

    await promiseMessage(approveCltIntercurrenceFn({
      body,
      routeParams: {
        cltIntercurrenceId
      }
    }), 'Intercorrência aprovada com sucesso!')
  }

  return (
    <Flex
      direction="column"
      gap="3"
      as="form"
      onSubmit={handleSubmit(handleApproveCltIntercurrence)}
    >

      <Stack
        direction={{ base: 'column', lg: 'row' }}
        spacing="3"
      >
        <FormControl isInvalid={!!errors.withMotorcycleRental}>
          <FormLabel>
            Aluguel de moto
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Controller
            name="withMotorcycleRental"
            control={control}
            render={({ field }) => (
              <RadioGroup {...field}>
                <Stack direction="column">
                  <Radio value="yes">Sim</Radio>
                  <Radio value="no">Não</Radio>
                </Stack>
              </RadioGroup>
            )}
          />
        </FormControl>

        <FormControl isInvalid={!!errors.withFuel}>
          <FormLabel>
            Combustível
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Controller
            name="withFuel"
            control={control}
            render={({ field }) => (
              <RadioGroup {...field}>
                <Stack direction="column">
                  <Radio value="yes">Sim</Radio>
                  <Radio value="no">Não</Radio>
                </Stack>
              </RadioGroup>
            )}
          />
        </FormControl>
      </Stack>

      <Stack
        direction={{ base: 'column', lg: 'row' }}
        spacing="3"
      >
        <FormControl isInvalid={!!errors.withPontomaisAdjust}>
          <FormLabel>
            Ajuste no iFractal realizado?
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Controller
            name="withPontomaisAdjust"
            control={control}
            render={({ field }) => (
              <RadioGroup {...field}>
                <Stack direction="column">
                  <Radio value="yes">Sim</Radio>
                  <Radio value="no">Não</Radio>
                  <Radio value="n/a">N/A</Radio>
                </Stack>
              </RadioGroup>
            )}
          />
        </FormControl>

        <FormControl isInvalid={!!errors.wasChangedDriverOnBusinessBudget}>
          <FormLabel>
            Se mudança de rota, realizado alteração do motorista no orçamento business?
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Controller
            name="wasChangedDriverOnBusinessBudget"
            control={control}
            render={({ field }) => (
              <RadioGroup {...field}>
                <Stack direction="column">
                  <Radio value="yes">Sim</Radio>
                  <Radio value="n/a">N/A</Radio>
                </Stack>
              </RadioGroup>
            )}
          />
        </FormControl>
      </Stack>

      <FormControl isInvalid={!!errors.turnChangeChecklist}>
        <FormLabel>
          Funcionário bateu ponto no dia?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="turnChangeChecklist"
          control={control}
          render={({ field }) => (
            <CheckboxGroup {...field}>
              <Stack direction="column">
                <Checkbox value="1 - Ajuste no cadastro">1 - Ajuste no cadastro;</Checkbox>
                <Checkbox value="2 - Ajuste na jornada no iFractal">2 - Ajuste na jornada no iFractal;</Checkbox>
                <Checkbox value="3 - Envio da alteração para a contabilidade para gerar aditivo">2 - Envio da alteração para a contabilidade para gerar aditivo;</Checkbox>
                <Checkbox value="4 - Verificação se implica em ajuste salário/aluguel moto/benefícios">4 - Verificação se implica em ajuste salário/aluguel moto/benefícios;</Checkbox>
                <Checkbox value="5 - Funcionário assinou aditivo jornada de trabalho">5 - Funcionário assinou aditivo jornada de trabalho;</Checkbox>
                <Checkbox value="6 - Funcionário assinou Ficha de empregado">6 - Funcionário assinou Ficha de empregado;</Checkbox>
                <Checkbox value="7 - Registrado no cadastro do funcionário o histórico dos valores anteriores ao ajuste atual">7 - Registrado no cadastro do funcionário o histórico dos valores anteriores ao ajuste atual;</Checkbox>
              </Stack>
            </CheckboxGroup>
          )}
        />
      </FormControl>

      <Button
        type="submit"
        colorScheme="blue"
        w="min"
        alignSelf="end"
        disabled={isSubmitting}
        isLoading={isSubmitting}
      >
        Salvar
      </Button>
    </Flex>
  )
}
