import { api } from "services/api";
import axios from 'axios';
import { RequestError } from 'utils/errors/RequestErrors';

interface EditPatrimonyRequestBody {
  limitDate: string
  regional: string
  sector: string
  patrimonyTypes: {
    patrimonyTypeId: string
    quantity: number
  }[]
  responsibleName: string
}

interface EditPatrimonyRequestParams {
  body: EditPatrimonyRequestBody
  patrimonyRequestId: string
}

export async function editPatrimonyRequest({
  body,
  patrimonyRequestId
}: EditPatrimonyRequestParams) {
  try {
    await api.put(`patrimony-requests/${patrimonyRequestId}`, body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

