import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Price } from "./_types/Price";

export interface GetPriceResponse {
  price: Price
}

interface GetPriceParams {
  priceId: string
}

export async function getPrice({
  priceId
}: GetPriceParams) {
  try {
    const response = await api.get<GetPriceResponse>(`/prices/${priceId}`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
