import { Box, Flex, HStack, Icon, IconButton, Td, Text, Tooltip, Tr, VStack } from "@chakra-ui/react";
import { Service } from "api/service/getCollectsRelation";
import { format, isToday } from "date-fns";
import { Link } from "react-router-dom";
import { formatTimeByTimezoneUser } from "utils/formatTimeByTimezoneUser"
import { RiTimeZoneFill } from "react-icons/ri";
import { FaSearch } from "react-icons/fa";
import { captalize } from "utils/captalize";


interface CollectsRelationsTableRowProps {
  service: Service
}

export function CollectsRelationsTableRow({
  service
}: CollectsRelationsTableRowProps) {

  const userTimezone = new Date().getTimezoneOffset() / 60

  const sourceHubTimezone = Boolean(service?.sourceAddresses?.length)
    ? service?.sourceAddresses[0].city.hub.timezone
    : null

  const isUserTimezoneDifferentFromSourceHubTimezone = userTimezone !== Number(sourceHubTimezone?.split('-')[1])

  const isDedicatedService = service.requestedService.serviceType === 'DEDICADO'

  const isFractionedService = service.requestedService.serviceType === 'FRACIONADO'

  const isDeliveryDateToday = isToday(new Date(service.requestedService.deliveryDate))

  const inputs = Object.entries(service.requestedService).reduce(
    (inputs, [key, value]) => {
      if (key === 'caixaTermica' && value > 0) {
        inputs['Caixa Térmica'] = value
      }

      if (key === 'embalagemSecundaria' && value > 0) {
        inputs['Embalagem Secundária Ziplock'] = value
      }

      if (key === 'geloSeco' && value > 0) {
        inputs['Gelo Seco'] = value
      }

      if (key === 'gelox' && value > 0) {
        inputs['Gelox'] = value
      }

      if (key === 'isopor3l' && value > 0) {
        inputs['Isopor 3l'] = value
      }

      if (key === 'isopor7l' && value > 0) {
        inputs['Isopor 7l'] = value
      }

      if (key === 'terciaria3l' && value > 0) {
        inputs['Terciária 3l'] = value
      }

      if (key === 'terciaria8l' && value > 0) {
        inputs['Terciária 8l'] = value
      }

      return inputs
    }, {})

  const doesServiceHasInputs = Object.keys(inputs).length > 0

  const serviceHasMoreThanOneSourceAddress = service?.sourceAddresses?.length > 1
  const serviceHasMoreThanOneDestinationAddress = service?.destinationAddresses?.length > 1

  return (
    <Tr>
      <Td>
        <IconButton
          icon={<FaSearch />}
          as={Link}
          to={`/servico/detalhes/${service.id}`}
          aria-label=""
          size="sm"
          variant="ghost"
        />
      </Td>
      <Td>{service.protocol}</Td>
      <Td>{captalize(service.customer.tradingFirstname)}</Td>
      <Td>{captalize(service.requestedService.vehicle)}</Td>
      <Td>
        {service?.sourceAddresses?.map((address, index) => {

          const addressInfoString = captalize(`${address.street}, ${address.number}, ${address.neighborhood}, ${address.city.name}, ${address.complement} - ${address.cep}`)

          const doesAddressAlreadyCollected = service?.collectServices?.find(
            (collect) => collect.addressId === address.id && ['DONE', 'UNSUCCESS'].includes(collect.step)
          )

          if (doesAddressAlreadyCollected) {
            return (
              <Text as="del" w="300px" key={address.id} display="block" whiteSpace="normal">
                {Boolean(serviceHasMoreThanOneSourceAddress) && index + 1 + '.'} {addressInfoString}
                <br />
              </Text>
            )
          }

          const doesAddressInProggress = service?.collectServices?.find(
            (collect) => collect.addressId === address.id && collect.step === 'GOING'
          )

          if (doesAddressInProggress) {
            return (
              <Text
                as={Link}
                display="block"
                textDecoration="underline"
                _hover={{
                  opacity: 0.9,
                }}
                to={{
                  pathname: `/servico/${service.id}/finalizar/coleta/endereco/${address.id}`,
                  state: {
                    path: '/relacao/servicos?tab=collects&page=1'
                  }
                }}
                w="300px"
                key={address.id}
                whiteSpace="normal"
              >
                {Boolean(serviceHasMoreThanOneSourceAddress) && index + 1 + '.'} {addressInfoString}
                <br />
              </Text>
            )
          }

          return (
            <Text
              as={Link}
              display="block"
              textDecoration="underline"
              _hover={{
                opacity: 0.9,
              }}
              to={{
                pathname: `/servico/${service.id}/coleta/endereco/${address.id}`,
                state: {
                  path: '/relacao/servicos?tab=collects&page=1'
                }
              }}
              w="300px"
              key={address.id}
              whiteSpace="normal"
            >
              {Boolean(serviceHasMoreThanOneSourceAddress) && index + 1 + '.'} {addressInfoString}
              <br />
            </Text>
          )
        })}
      </Td>
      <Td>
        {sourceHubTimezone ? (
          <Flex align="center" gap={2}>
            {format(formatTimeByTimezoneUser({ serviceDateTime: new Date(service.requestedService.collectDate), timezoneOffset: sourceHubTimezone }), 'dd/MM/yyyy')}
            {isUserTimezoneDifferentFromSourceHubTimezone && (
              <Tooltip label={sourceHubTimezone} hasArrow>
                <Box display="flex" alignItems="center" justifyContent="center" as="span">
                  <Icon as={RiTimeZoneFill} w={4} h={4} />
                </Box>
              </Tooltip>
            )}
          </Flex>
        ) : (
          format(new Date(service.requestedService.collectDate), 'HH:mm')
        )}
      </Td>
      <Td>
        <Flex align="center" gap={2}>
          {`${format(formatTimeByTimezoneUser({ serviceDateTime: new Date(service.requestedService.collectHourStart), timezoneOffset: sourceHubTimezone ?? "UTC-" + userTimezone }), 'HH:mm')} às ${format(formatTimeByTimezoneUser({ serviceDateTime: new Date(service.requestedService.collectHourEnd), timezoneOffset: sourceHubTimezone }), 'HH:mm')}`}

          {isUserTimezoneDifferentFromSourceHubTimezone && (
            <Tooltip label={sourceHubTimezone} hasArrow>
              <Box display="flex" alignItems="center" justifyContent="center" as="span">
                <Icon as={RiTimeZoneFill} w={4} h={4} />
              </Box>
            </Tooltip>
          )}
        </Flex>
      </Td>
      <Td maxW="300px">
        <Flex align="center" gap={2}>
          {isFractionedService && (
            <VStack align="left">
              <Text whiteSpace="pre-line">
                Embarque {captalize(service.requestedService.shipping.tradingName)}{' '}
                {captalize(`${service.requestedService.sourceBranch.nickname} ➡️ ${service.requestedService.destinationBranch.nickname}`)}{' '}
                {`até ${format(formatTimeByTimezoneUser({ serviceDateTime: new Date(service.requestedService.boardHour), timezoneOffset: sourceHubTimezone ?? "UTC-" + userTimezone }), 'HH:mm')}`}
              </Text>
            </VStack>
          )}

          {isDedicatedService && isDeliveryDateToday && (
            <Text>
              Entrega até {format(formatTimeByTimezoneUser({ serviceDateTime: new Date(service.requestedService.deliveryHour), timezoneOffset: sourceHubTimezone ?? "UTC-" + userTimezone }), 'HH:mm')}
            </Text>
          )}

          {isDedicatedService && !isDeliveryDateToday && (
            <VStack>
              <Text>
                Entrega até {format(formatTimeByTimezoneUser({ serviceDateTime: new Date(service.requestedService.deliveryHour), timezoneOffset: sourceHubTimezone ?? "UTC-" + userTimezone }), 'HH:mm')}{' '}
                do dia {format(formatTimeByTimezoneUser({ serviceDateTime: new Date(service.requestedService.deliveryDate), timezoneOffset: sourceHubTimezone ?? "UTC-" + userTimezone }), 'dd/MM/yyyy')}
              </Text>
            </VStack>
          )}

          {isUserTimezoneDifferentFromSourceHubTimezone && (
            <Tooltip label={sourceHubTimezone} hasArrow>
              <Box display="flex" alignItems="center" justifyContent="center" as="span">
                <Icon as={RiTimeZoneFill} w={4} h={4} />
              </Box>
            </Tooltip>
          )}
        </Flex>
      </Td>
      <Td>
        {doesServiceHasInputs ? (
          Object.entries(inputs).map(([inputName, quantity]) => {
            return (
              <Text key={inputName} >{inputName}: <Text as="b">{quantity}</Text></Text>
            )
          })
        ) : (
          <Text>N/A</Text>
        )}

      </Td>
      <Td>
        {service?.destinationAddresses?.map((address, index) => {
          const addressInfoString = captalize(`${address.street}, ${address.number}, ${address.neighborhood}, ${address.city.name}, ${address.complement} - ${address.cep}`)

          return (
            <Text
              w="300px"
              key={address.id}
              whiteSpace="normal"
            >
              {Boolean(serviceHasMoreThanOneDestinationAddress) && index + 1 + '.'} {addressInfoString}
              <br />
            </Text>
          )
        })}
      </Td>
      <Td>
        {service.requestedService.observation ?? 'N/A'}
      </Td>
      <Td>
        {captalize(service.requestedService.sourceCollector.tradingName)}
      </Td>
      <Td>
        {captalize(service.requestedService.destinationCollector.tradingName)}
      </Td>
    </Tr>
  )
}
