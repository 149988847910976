
import axios from "axios";
import { CollectedServiceProps } from "contexts/CollectedServiceContext";
import { RequestedServiceProps } from "contexts/ServiceContext";
import { Address } from "hooks/address/dtos/Address";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Delivery } from "./deliveries/@types/delivery";
import { Service } from "./_types/Service";

interface GetServiceResponse {
  service: Service & {
    serviceIDCollect: CollectedServiceProps[]
    serviceIDDelivery: Delivery[]
    serviceIDRequested: RequestedServiceProps & {
      sourceAddresses: Address[]
      destinationAddresses: Address[]
    }
    last_interaction_status: string | null
  }
}

interface GetServiceParams {
  serviceId: string
}

export async function getService({
  serviceId
}: GetServiceParams) {
  try {
    const response = await api.get<GetServiceResponse>(`/services/${serviceId}`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
