import { Customer } from "api/customers/_types/Customer"
import axios from "axios"
import { RequestedServiceProps } from "contexts/ServiceContext"
import { Address } from "hooks/address/dtos/Address"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"
import { Service } from "./_types/Service"

export interface GetServicesWithDelayedValidationResponse {
  services: Array<Service & {
    customerIDService: Customer
    serviceIDRequested: RequestedServiceProps
    sourceAddresses: Address[]
    destinationAddresses: Address[]
    last_interaction_status: string | null
  }>
}

interface GetServicesWithDelayedValidationParams {
  timezoneOffset: number
}

export async function getServicesWithDelayedValidation({
  timezoneOffset
}: GetServicesWithDelayedValidationParams) {
  try {
    const { data } = await api.get<GetServicesWithDelayedValidationResponse>('/services/validations/delayed-30-minutes', {
      params: { timezoneOffset }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!")
    }
  }
}
