import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Price } from "./_types/Price";

export interface ConsultPriceResponse {
  prices: Array<Price & {
    airPrices: {
      vehicle: string
      price: number
    }[]
    highwayPrices: {
      vehicle: string
      price: number
    }[]
  }>
  meta: {
    count: number
    totalPages: number
    currentPage: number
  }
}

interface ConsultPriceParams {
  currentPage: number
  pageSize: number
  destinationCitiesIds?: string
  shippingsIds?: string
  sourceCitiesIds?: string
  vehicle?: string
}

export async function consultPrice({
  currentPage,
  pageSize,
  destinationCitiesIds,
  sourceCitiesIds,
  shippingsIds,
  vehicle
}: ConsultPriceParams) {
  try {
    const response = await api.get<ConsultPriceResponse>(`/prices/consult/`, {
      params: {
        currentPage,
        pageSize,
        destinationCitiesIds,
        sourceCitiesIds,
        shippingsIds,
        vehicle
      },
    })

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
