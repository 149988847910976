import { createContext, ReactNode, useCallback } from "react";
import { api } from "../services/api";

import swal from "sweetalert";

import { CollectedServiceProps } from "./CollectedServiceContext";
import { AllocatedServiceProps } from "./AllocateServiceContext";
import { DeliveryServiceProps } from "./DeliveryServiceContext";
import { LandingServiceProps } from "../services/getFunctions/getLandingServiceFunctions";
import { BoardServiceProps } from "../services/getFunctions/getBoardServiceFunctions";
import { AvailableServiceProps } from "../services/getFunctions/getAvailableServiceFunctions";
import { BranchProps } from "./BranchContext";
import { RequestedBusinessServiceProps } from "../utils/RequestFunctions/Service/RequestedBusiness/requestRequestedBusinessServiceFunctions";
import { OccurrenceProps } from "./OccurrenceContext";
import { BudgetProps } from "./BudgetContext";
import { Collector } from "hooks/collector/dtos/Collector";
import { GeloSecoAddressesProps } from "utils/RequestFunctions/Service/Request/requestRequestServiceFunctions";
import { MaintenanceService } from "hooks/services/maintenance/dtos/MaintenanceService";
import { ServiceCancelReasons } from "hooks/services/useServiceFunctions";
import { ProviderProps } from "./ProviderContext";
import { CustomerProps } from "./CustomerContext";
import { IDriverProps } from "services/getFunctions/driver/getDrivers";
import { ExtraDiscount } from "hooks/extrasDiscounts/dtos/ExtrasDiscounts";
import { Billing } from "api/billings/billing";
import { Interaction } from "api/service/interactions/interaction";

interface RequestedServiceProviderProps {
  children: ReactNode;
  pathname?: string;
}

interface IDestinationAddressesInputProps {
  address_id: string;
  quantity: number;
}

export interface IAssignDriverToAddressProps {
  driver_id: string;
  address_id: string;
}

interface IAssignDriverToProviderProps {
  driver_id: string;
  provider_id: string;
}

interface CancelServiceInputs {
  cancel_reason: ServiceCancelReasons
  cancel_observation?: string
}

export interface DeliveryDriversAssigned {
  service_id: string
  driver_id: string
  address_id: string
  volume: number | null
  driver: IDriverProps
}

interface IAttachments {
  attachment: FileList
}

export interface ServiceProps {
  cancel_reason: ServiceCancelReasons | null
  cancel_observation: string | null
  id: string;
  protocol: number;
  step: string;
  customer_id: string;
  group_id: number | null;
  cte_loglife: string | null;
  cte_loglife_pdf_associated: string | null;
  cte_complementary_pdf_associated: string | null;
  assigned_pdf?: string | null;
  is_business: boolean;
  has_gelo_seco_maintenance?: boolean;
  pending_gelo_seco_maintenance?: boolean;
  landing_downtime?: Date | string;
  pending_crossdocking_gelo_seco_maintenance?: boolean;
  customerIDService: CustomerProps
  interactions: Interaction[]
  billing: Billing
  audited_at: string | null
  is_destination_board_branch_confirmed: boolean | null
  is_crossdocking_destination_board_branch_confirmed: boolean | null
  serviceIDRequested: RequestedServiceProps;
  collects_total_volumes: number | null

  serviceIDAllocate: AllocatedServiceProps[];

  serviceIDAvailable: AvailableServiceProps[];

  serviceIDBoard: BoardServiceProps[];

  serviceIDCollect: CollectedServiceProps[];

  serviceIDDelivery: DeliveryServiceProps[];

  serviceIDLanding: LandingServiceProps[];

  occurrenceIDService?: OccurrenceProps[];

  maintenanceIDService: MaintenanceService[]

  serviceIDRequestedBusiness: RequestedBusinessServiceProps;

  deliveryDriversAssigned: DeliveryDriversAssigned[]

  extrasDiscounts: {
    extraDiscountId: string
    id: string
    serviceId: string,
    extraDiscount: ExtraDiscount
  }[]
  last_interaction_status: string | null
}

export interface RequestedServiceProps {
  id: string;
  customer_id: string;
  budget_id: string;
  owner: string;
  source_address_id: string[];
  destination_address_id: string[];
  source_collector_id: string | null;
  crossdocking_collector_id: string | null;
  destination_collector_id: string | null;
  source_branch_id: string | null;
  source_crossdocking_branch_id: string | null;
  destination_crossdocking_branch_id: string | null;
  destination_branch_id: string | null;
  provider_id: string | null;
  observation: string;
  deadline: number;
  service_type: string;
  franchising: number;
  modal: string;
  crossdocking_modal: string | null;
  vehicle: string;
  crossdocking_planned_flight: string | null;
  planned_flight: string;
  crossdocking_board_date: string | null;
  crossdocking_board_hour: string | null;
  board_date: string | null;
  board_hour: string | null;
  crossdocking_availability_forecast_day: string | null;
  crossdocking_availability_forecast_time: string | null;
  availability_forecast_day: string | null;
  availability_forecast_time: string | null;
  caixa_termica: number;
  embalagem_secundaria: number;
  gelo_seco: number;
  gelox: number;
  isopor3l: number;
  isopor7l: number;
  terciaria3l: number;
  terciaria8l: number;
  collect_date: string;
  collect_hour_start: string;
  collect_hour_end: string;
  delivery_date: string;
  delivery_hour: string;
  material_type: string | null;
  price_kg_extra: number | null
  additional_collect_addresses: string[] | null;
  additional_delivery_addresses: string[]
  driver_address_assign?: string[] | null | IAssignDriverToAddressProps[];
  driver_provider_assign?: string[] | null | IAssignDriverToProviderProps[];
  gelo_seco_addresses: string | null | GeloSecoAddressesProps[];
  destination_addresses_input:
  | string[]
  | IDestinationAddressesInputProps[]
  | null;

  serviceIDRequested: ServiceProps
  budgetIDService: BudgetProps;
  sourceCollectorIDService: Collector;
  destinationCollectorIDService: Collector;
  providerHubIDService: ProviderProps
  sourceBranchIDService: BranchProps;
  sourceCrossdockingBranchIDService: BranchProps;
  destinationCrossdockingBranchIDService: BranchProps;
  destinationBranchIDService: BranchProps;
  crossdockingCollectorIDService: Collector
  addresses_requested_to_cancel: string[] | null;
  attachments?: string[] | null | IAttachments[]
  requested_collect_addresses_observations: {
    address_id: string
    observation: string
  }[] | string | null
  budget_caixa_termica: number
  budget_embalagem_secundaria: number
  budget_gelo_seco: number
  budget_gelox: number
  budget_isopor3l: number
  budget_isopor7l: number
  budget_terciaria3l: number
  budget_terciaria8l: number
  budget_source_address_qty: number
  budget_destination_address_qty: number
  budget_source_cities: string[]
  budget_destination_cities: string[]
}

type RequestedServiceInput = Omit<
  RequestedServiceProps,
  | "id"
  | "price_kg_extra"
  | "serviceIDRequested"
  | "budgetIDService"
  | "sourceCollectorIDService"
  | "destinationCollectorIDService"
  | "providerHubIDService"
  | "sourceBranchIDService"
  | "destinationBranchIDService"
  | "crossdockingCollectorIDService"
  | "sourceCrossdockingBranchIDService"
  | "destinationCrossdockingBranchIDService"
  | "additional_collect_addresses"
  | "addresses_requested_to_cancel"
  | "additional_delivery_addresses"
  | "requested_collect_addresses_observations"
  | "budget_caixa_termica"
  | "budget_embalagem_secundaria"
  | "budget_gelo_seco"
  | "budget_gelox"
  | "budget_isopor3l"
  | "budget_isopor7l"
  | "budget_terciaria3l"
  | "budget_terciaria8l"
  | "budget_source_address_qty"
  | "budget_destination_address_qty"
  | "budget_source_cities"
  | "budget_destination_cities"
>;

type CreateRequestedServiceInput = {
  customer_id: string;
  budget_id: string;
  owner: string;
  source_address_id: string[];
  destination_address_id: string[];
  destination_addresses_input:
  | {
    address_id: string;
    quantity: number;
  }[]
  | null;
  deadline: number;
  service_type: string;
  franchising: number;
  modal: string;
  vehicle: string;
  caixa_termica: number;
  embalagem_secundaria: number;
  gelo_seco: number;
  gelox: number;
  isopor3l: number;
  isopor7l: number;
  terciaria3l: number;
  terciaria8l: number;
  collect_date: string;
  collect_hour_start: string;
  collect_hour_end: string;
  delivery_date: string;
  observation: string;
  delivery_hour: string;
  material_type: string;
};

type RecurrentServiceInput = {
  situation: string;
  budget_id: string;
  caixa_termica: number;
  collect_date: string | null;
  delivery_date: string | null;
  collect_hour_end: string;
  collect_hour_start: string;
  days_of_week: number[];
  deadline: number;
  delivery_hour: string;
  destination_address_id: string[];
  embalagem_secundaria: number;
  franchising: number;
  gelo_seco: number;
  gelox: number;
  isopor3l: number;
  isopor7l: number;
  modal: string;
  observation: string;
  service_type: string;
  source_address_id: string[];
  terciaria3l: number;
  terciaria8l: number;
  vehicle: string;
};

interface RequestedServiceContextProps {
  createRequestedService: (
    requestedServiceInput: CreateRequestedServiceInput,
    userId: string
  ) => Promise<boolean>;
  createRequestedServiceRecurrent: (
    requestedServiceInput: RecurrentServiceInput,
    userId: string
  ) => Promise<boolean>;
  deleteService: (serviceId: string) => Promise<boolean | undefined>;
  deleteRecurrentService: (serviceId: string) => Promise<boolean | undefined>;
  editRequestedService: (
    requestedServiceId: string,
    requestedServiceInput: RequestedServiceInput
  ) => Promise<boolean | undefined>;
  cancelRequestedService: (
    requestedServiceId: string,
    cancelServiceInput: CancelServiceInputs
  ) => Promise<boolean | undefined>;
  validateRequestedService: (
    requestedServiceId: string,
    requestedServiceInput: RequestedServiceInput
  ) => Promise<boolean | undefined>;
  editCurrentRequestedService: (
    requestedServiceId: string,
    requestedServiceInput: RequestedServiceInput
  ) => Promise<boolean | undefined>;
  createRecurrentServiceByDayOfWeek: (
    dayOfWeek: number,
    collectDate: string,
    userId: string
  ) => Promise<boolean | undefined>;
  hasAnyDoneService: (
    serviceId: string,
    isServiceType: string,
    isStep: string,
    updatedService: ServiceProps[]
  ) => Promise<boolean | undefined>;
  handleChangeStepOfService: (
    serviceId: string,
    step: string
  ) => Promise<boolean | undefined>;
  handleAllUnccessService: (
    serviceId: string,
    isServiceType: string,
    updatedService: ServiceProps[]
  ) => Promise<boolean | undefined>;
  assignDriverToAddress: (
    serviceId: string,
    input: IAssignDriverToAddressProps[]
  ) => Promise<boolean | undefined>;
  assignDriverToProvider: (
    serviceId: string,
    input: IAssignDriverToProviderProps[]
  ) => Promise<boolean | undefined>;
}

export const RequestedServiceContext =
  createContext<RequestedServiceContextProps>(
    {} as RequestedServiceContextProps
  );

export function RequestedServiceProvider({
  children,
  pathname,
}: RequestedServiceProviderProps) {
  async function createRequestedService(
    requestedServiceInput: CreateRequestedServiceInput,
    userId: string
  ) {
    try {
      await api.post("/requested-service", {
        ...requestedServiceInput,
        userId,
      });
      setTimeout(() => {
        swal("Poof! O serviço único foi solicitado com sucesso!", {
          icon: "success",
        });
      }, 1500);
      return true;
    } catch (error: any) {
      swal("Erro", `${error.response.data.message}`, "error");
      return false;
    }
  }

  async function createRequestedServiceRecurrent(
    requestedServiceInput: RecurrentServiceInput,
    userId: string
  ) {
    try {
      await api.post("/recurrent-service", {
        ...requestedServiceInput,
        userId,
      });

      setTimeout(() => {
        swal("Poof! O serviço recorrente foi solicitado com sucesso!", {
          icon: "success",
        });
      }, 1500);
      return true;
    } catch (error: any) {
      swal("Erro", `${error.response.data.message}`, "error");
      return false;
    }
  }

  async function createRecurrentServiceByDayOfWeek(
    dayOfWeek: number,
    collectDate: string,
    userId: string
  ) {
    try {
      await api.post("/execute/recurrent-service", {
        dayOfWeek,
        collectDate,
        userId,
      });

      setTimeout(() => {
        swal("Poof! Os serviços recorrentes foram criados com sucesso!", {
          icon: "success",
        });
      }, 1500);
      return true;
    } catch (error: any) {
      swal("Erro", `${error.response.data.message}`, "error");
      return false;
    }
  }

  async function deleteService(serviceId: string) {
    try {
      await api.delete(`/service/${serviceId}`);
      swal("Poof! O serviço foi excluído com sucesso!", {
        icon: "success",
      });
      return true;
    } catch (error: any) {
      swal("Erro", `${error.response.data.message}`, "error");
      return false;
    }
  }

  async function deleteRecurrentService(serviceId: string) {
    try {
      await api.delete(`/recurrent-service/${serviceId}`);
      swal("Poof! O serviço recorrente foi excluído com sucesso!", {
        icon: "success",
      });
      return true;
    } catch (error: any) {
      swal("Erro", `${error.response.data.message}`, "error");
      return false;
    }
  }

  async function editRequestedService(
    serviceId: string,
    requestedServiceInput: RequestedServiceInput
  ) {
    try {
      await api.put(`/requested-service/${serviceId}`, requestedServiceInput);
      setTimeout(() => {
        swal("Poof! O serviço foi atualizado com sucesso!", {
          icon: "success",
        });
      }, 1500);
      return true;
    } catch (error: any) {
      swal("Erro", `${error.response.data.message}`, "error");
      return false;
    }
  }

  async function cancelRequestedService(serviceId: string, cancelServiceInput: CancelServiceInputs) {
    try {
      await api.patch(`/service/cancel/${serviceId}`, cancelServiceInput);
      setTimeout(() => {
        swal("Poof! O serviço foi cancelado com sucesso!", {
          icon: "success",
        });
      }, 1500);
      return true;
    } catch (error: any) {
      swal("Erro", `${error.response.data.message}`, "error");
      return false;
    }
  }

  async function validateRequestedService(
    serviceId: string,
    requestedServiceInput: RequestedServiceInput
  ) {
    try {
      await api.put(`/requested-service/${serviceId}`, {
        ...requestedServiceInput,
        hasValidate: true,
      });
      setTimeout(() => {
        swal("Poof! O serviço foi validado com sucesso!", {
          icon: "success",
        });
      }, 1500);
      return true;
    } catch (error: any) {
      swal("Erro", `${error.response.data.message}`, "error");
      return false;
    }
  }

  async function editCurrentRequestedService(
    serviceId: string,
    requestedServiceInput: RequestedServiceInput
  ) {
    try {
      await api.put(
        `/in-progress/requested-service/${serviceId}`,
        requestedServiceInput
      );
      setTimeout(() => {
        swal("Poof! O serviço foi atualizado com sucesso!", {
          icon: "success",
        });
      }, 1500);
      return true;
    } catch (error: any) {
      swal("Erro", `${error.response.data.message}`, "error");
      return false;
    }
  }

  const hasAnyDoneService = useCallback(
    async (
      serviceId: string,
      isServiceType: string,
      isStep: string,
      updatedService: ServiceProps[]
    ) => {
      try {
        await api.put(`/service/${serviceId}`, {
          ...updatedService,
          isServiceType,
          isStep,
        });
        return true;
      } catch (error: any) {
        swal("Erro", `${error.response.data.message}`, "error");
        return false;
      }
    },
    []
  );

  async function handleChangeStepOfService(serviceId: string, step: string) {
    try {
      await api.put(`/service/${serviceId}`, {
        isStep: step,
        correctingStep: true,
      });
      return true;
    } catch (error: any) {
      swal("Erro", `${error.response.data.message}`, "error");
      return false;
    }
  }

  async function assignDriverToAddress(
    serviceId: string,
    input: IAssignDriverToAddressProps[]
  ) {
    try {
      await api.put(`/requested-service/assign-address/${serviceId}`, {
        driver_address_assign: input,
      });

      return true;
    } catch (error: any) {
      swal("Erro", `${error.response.data.message}`, "error");
      return false;
    }
  }

  async function assignDriverToProvider(
    serviceId: string,
    input: IAssignDriverToProviderProps[]
  ) {
    try {
      await api.put(`/requested-service/assign-provider/${serviceId}`, {
        driver_provider_assign: input,
      });

      return true;
    } catch (error: any) {
      swal("Erro", `${error.response.data.message}`, "error");
      return false;
    }
  }

  const handleAllUnccessService = useCallback(
    async (
      serviceId: string,
      isServiceType: string,
      updatedService: ServiceProps[]
    ) => {
      try {
        await api.put(`/service/${serviceId}`, {
          ...updatedService,
          isServiceType,
          isStep: "unsuccessService",
        });
        return true;
      } catch (error: any) {
        swal("Erro", `${error.response.data.message}`, "error");
        return false;
      }
    },
    []
  );

  return (
    <RequestedServiceContext.Provider
      value={{
        hasAnyDoneService,
        handleChangeStepOfService,
        handleAllUnccessService,
        createRequestedService,
        createRequestedServiceRecurrent,
        createRecurrentServiceByDayOfWeek,
        editRequestedService,
        deleteService,
        deleteRecurrentService,
        cancelRequestedService,
        validateRequestedService,
        editCurrentRequestedService,
        assignDriverToAddress,
        assignDriverToProvider,
      }}
    >
      {children}
    </RequestedServiceContext.Provider>
  );
}
