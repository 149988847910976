import { CltDriverStatus } from "api/cltDrivers/_types/CltDriver";

export const cltDriverStatusMap: Record<CltDriverStatus, string> = {
  hiring: 'Contratando',
  "hiring-motorcyle-details": 'Contratação (Dados da moto)',
  "hiring-scalabrini-register": 'Contratação (Cadastro na Scalabrini)',
  "hiring-pontomais-register": 'Contratação (iFractal)',
  "hiring-signing-document": 'Contratação (Assinatura de documentos)',
  "hiring-bank-details": 'Contratação (Dados bancários)',
  active: 'Ativo',
  "dismissing-legal-prevention": 'Demissão (Prevenção jurídica)',
  "dismissing-approvation": 'Demissão (Aprovação)',
  "dismissing-comunication": 'Demissão (Comunicação)',
  "dismissing-aso-and-notice": 'Demissão (Aso & Aviso prévio)',
  "dismissing-fulfilling-notice": 'Demissão (Cumprindo aviso prévio)',
  "dismissing-documentation": 'Demissão (Documentação)',
  "dismissing-trct-signature": 'Demissão (Assinatura TRCT)',
  "dismissing-termination": 'Demissão (Rescisão)',
  moved: 'Afastado',
  dismissed: 'Demitido'
}
