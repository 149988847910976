import axios from "axios"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

interface GetTransferCostEstimateResponse {
  shippingCostWeight: {
    id: string
    kg: number
    price_in_cents: number
    created_at: string
    updated_at: string
    createdAt: string
    updatedAt: string
    shipping_cost_id: string
    shippingCost: {
      id: string
      extra_kg_price_in_cents: number
      created_at: string
      updated_at: string
      createdAt: string
      updatedAt: string
      shipping_id: string
      source_branch_id: string
      destination_branch_id: string
    }
  } | null
}

interface GetTransferCostEstimateParams {
  shippingId: string
  sourceBranchId: string
  destinationBranchId: string
  weight: number
}


export async function getTransferCostEstimate({
  shippingId,
  sourceBranchId,
  destinationBranchId,
  weight
}: GetTransferCostEstimateParams) {
  try {
    const { data } = await api.get<GetTransferCostEstimateResponse>(`services/validation/estimated-transfer-cost?shippingId=${shippingId}&sourceBranchId=${sourceBranchId}&destinationBranchId=${destinationBranchId}&weight=${weight}`)



    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!")
    }
  }
}
