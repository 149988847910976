import { Box, Button, Flex, Spinner } from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { createSpecialBudgetCost } from "api/budgets/createSpecialBudgetCost"
import { useCollectors } from "hooks/collector/useCollectors"
import { useToastify } from "hooks/toastify/useToastify"
import { FormProvider, useForm } from "react-hook-form"
import { useMutation, useQueryClient } from "react-query"
import { transformStringToNumber } from "utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber"
import { specialBudgetCostSchema } from "./SpecialBudgetCostForm"
import { SpecialBudgetCostForm, SpecialBudgetCostSchema } from "./SpecialBudgetCostForm"

interface CreateSpecialBudgetCostProps {
  budgetId: string
}

export function CreateSpecialBudgetCost({
  budgetId
}: CreateSpecialBudgetCostProps) {

  const formMethods = useForm<SpecialBudgetCostSchema>({
    resolver: yupResolver(specialBudgetCostSchema)
  })

  const { formState: { isSubmitting}, handleSubmit, reset } = formMethods

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const {
    data: collectors, isLoading: isCollectorsLoading,
  } = useCollectors()

  const { mutateAsync: createSpecialBudgetCostFn } = useMutation({
    mutationFn: createSpecialBudgetCost,
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: 'special-budget-costs' })
      reset()
    }
  })

  async function handleCreateSpecialBudgetCost(values: SpecialBudgetCostSchema, event:React.MouseEvent ) {
    event.stopPropagation();
    await promiseMessage(createSpecialBudgetCostFn({
      body: {
        collectorsIds: values.collectorsIds,
        valueInCents: Math.ceil(transformStringToNumber(values.valueIncents) * 100)
      },
      budgetId
    }), 'Custo especial criado!')
  }

  return (

    <Box
      w="full"
      as="form"
      flexDirection="column"
      px={2}
    >
      {isCollectorsLoading ? (
        <Spinner />
      ) : (
        <FormProvider {...formMethods}>
          <SpecialBudgetCostForm
            collectors={collectors}
          />
        </FormProvider>
      )}

      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          onClick={handleSubmit(handleCreateSpecialBudgetCost)}
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Criar
        </Button>
      </Flex>

    </Box>
  )
}
