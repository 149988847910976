import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { PriceCategory } from "./_types/Price";

interface CreatePriceBody {
  sourceCityUf: string
  sourceCityId: string
  sourceHubId: string
  destinationCityUf: string
  destinationCityId: string
  destinationHubId: string
  routeType: string
  airFranchising: string
  highwayFranchising: string
  airMinimumPrice: number
  airExtraKg: number
  airDeadline: number | null
  highwayMinimumPrice: number
  highwayExtraKg: number
  highwayDeadline: number | null
  additionalCollectPrice: number
  additionalDeliveryPrice: number
  observation: string
  category: PriceCategory
  vehicle: string
  shippingsIds: string[]
  serviceType: 'FRACIONADO' | 'DEDICADO'
}


interface CreatePriceProps {
  body: CreatePriceBody
}

export async function createPrice({
  body
}: CreatePriceProps) {
  try {
    const response = await api.post(`/prices`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
