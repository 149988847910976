import { Box, FormControl, FormLabel, Input, InputGroup, InputLeftAddon, Select, Stack, Text, Textarea } from "@chakra-ui/react"
import { PriceCategory } from "api/prices/_types/Price"
import { ShippingProps } from "contexts/ShippingContext"
import { Controller ,useFormContext, useWatch } from "react-hook-form"
import { CitiesProps } from "services/getFunctions/city/getCity"
import { IHubsProps } from "utils/RequestFunctions/Hubs/requestHubFunctions"
import { useEffect, useState } from "react"
import { searchBoxFilter } from "utils/searchBoxFilter"
import { statesWithUfList } from '../../../utils/CustomLists/statesWithUfList'
import { captalize } from "utils/captalize"
import { switchRouteTypes } from "utils/CustomLists/switchRouteTypes"
import { SearchBox } from "components/SearchBox/SearchBox"
import { GeneralCheckbox } from "components/Inputs/GeneralCheckbox"
import { useCurrency } from "react-hook-currency"
import { deadline_delivery } from "utils/customLists"

interface PriceFormProps {
  slug: string
  cities: CitiesProps[]
  hubs: IHubsProps[]
  shippings: ShippingProps[]
  defaultCheckedOptionsShippings?: string[]
}

export interface PriceSchemaInputs {
  situation: 'active' | 'inactive'
  sourceCityUf: string
  sourceCityId: string
  sourceHubId: string
  destinationCityUf: string
  destinationCityId: string
  destinationHubId: string
  routeType: string
  airFranchising: string
  highwayFranchising: string
  airMinimumPrice: string
  airExtraKg: string
  airDeadline: number | null
  highwayMinimumPrice: string
  highwayExtraKg: string
  highwayDeadline: number | null
  additionalCollectPrice: string
  additionalDeliveryPrice: string
  observation: string
  category: PriceCategory
  vehicle: string
  shippingsIds: string[]
  serviceType: 'FRACIONADO' | 'DEDICADO'
}

export function PriceForm({
  cities,
  hubs,
  shippings,
  slug,
  defaultCheckedOptionsShippings
}: PriceFormProps) {

  const [shippingFiltered, setShippingFiltered] = useState('')

  const {
    register,
    setValue,
    control,
    formState: { errors },
  } = useFormContext<PriceSchemaInputs>()

  const {
    format: currencyFormat, onChange: onCurrencyChange,
  } = useCurrency({
    style: 'decimal'
  })


  const [routeType, serviceType, sourceCityUf, sourceCityId, destinationCityUf, destinationCityId, categoryType] = useWatch({
    control,
    name: ['routeType', 'serviceType', 'sourceCityUf', 'sourceCityId', 'destinationCityUf', 'destinationCityId', 'category']
  })

  useEffect(() => {
    if (sourceCityId) {
      const sourceHubBySourceCity = cities.find(
        (city) => city.id === sourceCityId,
      )?.hub_id
      if (sourceHubBySourceCity) {
        setValue('sourceHubId', sourceHubBySourceCity)
      }
    }
  }, [sourceCityId, setValue, cities])

  useEffect(() => {
    if (destinationCityId) {
      const destinationHubBySourceCity = cities.find(
        (city) => city.id === destinationCityId,
      )?.hub_id
      if (destinationHubBySourceCity) {
        setValue('destinationHubId', destinationHubBySourceCity)
      }
    }
  }, [destinationCityId, setValue, cities])

  useEffect(() => {
    if (categoryType === 'Rápido') {

      if (routeType === 'AÉREO' || routeType === 'AMBOS') {
        setValue('airFranchising', '2 KG')
      }

      if (routeType === 'RODOVIÁRIO' || routeType === 'AMBOS') {
        setValue('highwayFranchising', '10 KG')
      }
    }

    if (categoryType === 'Econômico') {
      if (routeType === 'AÉREO' || routeType === 'AMBOS') {
        setValue('airFranchising', '10 KG')
      }

      if (routeType === 'RODOVIÁRIO' || routeType === 'AMBOS') {
        setValue('highwayFranchising', '10 KG')
      }
    }

    if (routeType === 'AÉREO') {
      setValue('highwayMinimumPrice', '')
      setValue('highwayExtraKg', '')
    }
    if (routeType === 'RODOVIÁRIO') {
      setValue('airMinimumPrice', '')
      setValue('airExtraKg', '')
    }



  }, [routeType, setValue, categoryType])

  const shippingsCheckboxOptions = shippings?.filter((shipping) => {
    if (routeType === 'RODOVIÁRIO') {
      return shipping.modal === routeType
    }
    if (routeType === 'AÉREO') {
      return shipping.modal === routeType
    }
    return shipping

  })?.map((shipping) => {
    return {
      key: shipping.id,
      value: shipping.id,
      showOption: shipping.trading_name
    }
  })

  const shippingsOptions = shippingsCheckboxOptions?.filter((shipping) => {
    const shippingFilter = searchBoxFilter(
      shipping.showOption,
      shippingFiltered,
    )
    if (shippingFiltered === "") {
      return shipping
    }
    return shippingFilter
  })


  return (
    <Box w='full'>
      {slug === 'edit' && (
        <FormControl isInvalid={!!errors.situation} mt={3}>
          <FormLabel fontSize="sm">
            Situação
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Select
            {...register('situation')}
            name="situation"
            placeholder="Selecione..."
            rounded="md"
            size='sm'
          >
            <option value='active'>Ativo</option>
            <option value='inactive'>Inativo</option>
          </Select>
        </FormControl>
      )}
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.sourceCityUf}>
            <FormLabel fontSize="sm">
              UF Origem
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Select
              {...register('sourceCityUf')}
              name="sourceCityUf"
              placeholder="Selecione..."
              rounded="md"
              size='sm'
            >
              {Object.entries(statesWithUfList).map(([key, value]) => (
                <option value={key} key={key}>
                  {value.uf.toUpperCase()}
                </option>
              ))}
            </Select>
          </FormControl>
        </Stack>
        {sourceCityUf && (
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors.sourceCityId}>
              <FormLabel fontSize="sm">
                Cidade Origem
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Select
                {...register('sourceCityId')}
                name="sourceCityId"
                placeholder="Selecione..."
                rounded="md"
                size='sm'
              >
                {cities?.filter((city) => city.state === sourceCityUf.toUpperCase())
                  .map(city => {
                    return (
                      <option value={city.id} key={city.id}>
                        {captalize(city?.name)}
                      </option>
                    )
                  })}

              </Select>
            </FormControl>
          </Stack>
        )}

      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        {sourceCityUf && (
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors.sourceHubId} >
              <FormLabel fontSize="sm">
                Hub Origem
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Select
                {...register('sourceHubId')}
                name="sourceHubId"
                placeholder="Selecione..."
                rounded="md"
                size='sm'
                isDisabled
              >
                {hubs
                  .map(hub => {
                    return (
                      <option value={hub.id} key={hub.id}>
                        {captalize(hub?.name)}
                      </option>
                    )
                  })}

              </Select>
            </FormControl>
          </Stack>
        )}
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.destinationCityUf}>
            <FormLabel fontSize="sm">
              UF Destino
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Select
              {...register('destinationCityUf')}
              name="destinationCityUf"
              placeholder="Selecione..."
              rounded="md"
              size='sm'
            >
              {Object.entries(statesWithUfList).map(([key, value]) => (
                <option value={key} key={key}>
                  {value.uf.toUpperCase()}
                </option>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        {destinationCityUf && (
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors.destinationCityId}>
              <FormLabel fontSize="sm">
                Cidade Destino
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Select
                {...register('destinationCityId')}
                name="destinationCityId"
                placeholder="Selecione..."
                rounded="md"
                size='sm'
              >
                {cities?.filter((city) => city.state === destinationCityUf.toUpperCase())
                  .map(city => {
                    return (
                      <option value={city.id} key={city.id}>
                        {captalize(city?.name)}
                      </option>
                    )
                  })}

              </Select>
            </FormControl>
          </Stack>

        )}
        {destinationCityId && (
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors.destinationHubId}>
              <FormLabel fontSize="sm">
                Hub Destino
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Select
                {...register('destinationHubId')}
                name="destinationHubId"
                placeholder="Selecione..."
                rounded="md"
                size='sm'
                isDisabled
              >
                {hubs
                  .map(hub => {
                    return (
                      <option value={hub.id} key={hub.id}>
                        {captalize(hub?.name)}
                      </option>
                    )
                  })}

              </Select>
            </FormControl>
          </Stack>
        )}
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.category}>
            <FormLabel fontSize="sm">
              Tipo de Envio
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Select
              {...register('category')}
              name="category"
              placeholder="Selecione..."
              rounded="md"
              size='sm'
            >
              <option value='Rápido'>Rápido</option>
              <option value='Econômico'>Econômico</option>
            </Select>
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.serviceType}>
            <FormLabel fontSize="sm">
              Tipo de Serviço
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Select
              {...register('serviceType')}
              name="serviceType"
              placeholder="Selecione..."
              rounded="md"
              size='sm'
            >
              <option value='FRACIONADO'>FRACIONADO</option>
              <option value='DEDICADO'>DEDICADO</option>
            </Select>
          </FormControl>
        </Stack>
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.routeType}>
            <FormLabel fontSize="sm">
              Tipo de Rota
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Select
              {...register('routeType')}
              name="routeType"
              placeholder="Selecione..."
              rounded="md"
              size='sm'
            >
              {switchRouteTypes?.handle()?.map((route) => (
                <option value={route.value} key={route.id}>
                  {route.value.toUpperCase()}
                </option>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.vehicle}>
            <FormLabel fontSize="sm">
              Veículo
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Select
              {...register('vehicle')}
              name="vehicle"
              placeholder="Selecione..."
              rounded="md"
              size='sm'
            >
              <option value='CARRO'>CARRO</option>
              <option value='MOTO'>MOTO</option>
            </Select>
          </FormControl>
        </Stack>
      </Stack>
      {routeType && serviceType === 'FRACIONADO' && (
        <Stack w="full" mt="4" spacing="10px" justify="flex-start">
          <>
            <SearchBox
              isCheckbox={true}
              size="sm"
              placeholder="Buscar Transportadora..."
              handleSearch={(e) =>
                setShippingFiltered(e.target.value)
              }
            />
            <Box
              overflowY="auto"
              height="300px"
              borderWidth="1px"
              borderRadius="lg"
              p="2"
            >
              <Controller
                control={control}
                name="shippingsIds"
                render={({ field }) => (
                  <GeneralCheckbox
                    name="shippingsIds"
                    defaultCheckedOptions={defaultCheckedOptionsShippings}
                    onCheckboxChange={field.onChange}
                    checkboxOptions={shippingsOptions}
                    label="Transportadora (s)"
                  />
                )}
              />
            </Box>
          </>
        </Stack>
      )}

      {(routeType === 'AÉREO' || routeType === 'AMBOS') && (
        <>

          <Stack
            spacing="6"
            direction={["column", "column", "row"]}
            mt="3"
          >
            <Stack
              direction="column"
              w="full"
              spacing="0.25"
            >
              <FormControl isInvalid={!!errors.airMinimumPrice}>
                <FormLabel fontSize="sm">
                  Preço mínimo aéreo
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>
                <Controller
                  name="airMinimumPrice"
                  control={control}
                  render={({ field }) => {
                    return (
                      <InputGroup
                        size="sm"
                      >
                        <InputLeftAddon
                          borderTopLeftRadius="md"
                          borderBottomLeftRadius="md"
                        >
                          R$
                        </InputLeftAddon>
                        <Input
                          {...register("airMinimumPrice")}
                          size="sm"
                          rounded="md"
                          w="full"
                          name={field.name}
                          value={field.value}
                          defaultValue={currencyFormat('000')}
                          onChange={e => field.onChange(onCurrencyChange(e))}
                        />
                      </InputGroup>
                    )
                  }}
                />
              </FormControl>
            </Stack>
            <Stack
              direction="column"
              w="full"
              spacing="0.25"
            >
              <FormControl isInvalid={!!errors.airFranchising}>
                <FormLabel fontSize="sm">
                  Franquia aérea
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>
                <Input
                  {...register('airFranchising')}
                  name='airFranchising'
                  rounded="md"
                  size="sm"
                  isDisabled
                />
              </FormControl>
            </Stack>
          </Stack>
          <Stack
            spacing="6"
            direction={["column", "column", "row"]}
            mt="3"
          >
            <Stack
              direction="column"
              w="full"
              spacing="0.25"
            >
              <FormControl isInvalid={!!errors.airExtraKg}>
                <FormLabel fontSize="sm">
                  KG extra aéreo
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>
                <Controller
                  name="airExtraKg"
                  control={control}
                  render={({ field }) => {
                    return (
                      <InputGroup
                        size="sm"
                      >
                        <InputLeftAddon
                          borderTopLeftRadius="md"
                          borderBottomLeftRadius="md"
                        >
                          R$
                        </InputLeftAddon>
                        <Input
                          {...register("airExtraKg")}
                          size="sm"
                          rounded="md"
                          w="full"
                          name={field.name}
                          value={field.value}
                          defaultValue={currencyFormat('000')}
                          onChange={e => field.onChange(onCurrencyChange(e))}
                        />
                      </InputGroup>
                    )
                  }}
                />
              </FormControl>
            </Stack>
            <Stack
              direction="column"
              w="full"
              spacing="0.25"
            >
              <FormControl isInvalid={!!errors.airDeadline}>
                <FormLabel fontSize="sm">
                  Prazo aéreo
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>
                <Select
                  {...register('airDeadline')}
                  name="airDeadline"
                  placeholder="Selecione..."
                  rounded="md"
                  size='sm'
                >
                  {deadline_delivery.map((deadline) => (
                    <option value={deadline.id} key={deadline.id}>
                      {deadline.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Stack>
        </>
      )}
      {(routeType === 'RODOVIÁRIO' || routeType === 'AMBOS') && (
        <>
          <Stack
            spacing="6"
            direction={["column", "column", "row"]}
            mt="3"
          >
            <Stack
              direction="column"
              w="full"
              spacing="0.25"
            >
              <FormControl isInvalid={!!errors.highwayMinimumPrice}>
                <FormLabel fontSize="sm">
                  Preço mínimo rodoviário
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>
                <Controller
                  name="highwayMinimumPrice"
                  control={control}
                  render={({ field }) => {
                    return (
                      <InputGroup
                        size="sm"
                      >
                        <InputLeftAddon
                          borderTopLeftRadius="md"
                          borderBottomLeftRadius="md"
                        >
                          R$
                        </InputLeftAddon>
                        <Input
                          {...register("highwayMinimumPrice")}
                          size="sm"
                          rounded="md"
                          w="full"
                          name={field.name}
                          value={field.value}
                          defaultValue={currencyFormat('000')}
                          onChange={e => field.onChange(onCurrencyChange(e))}
                        />
                      </InputGroup>
                    )
                  }}
                />
              </FormControl>
            </Stack>
            <Stack
              direction="column"
              w="full"
              spacing="0.25"
            >
              <FormControl isInvalid={!!errors.highwayFranchising}>
                <FormLabel fontSize="sm">
                  Franquia rodoviária
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>
                <Input
                  {...register('highwayFranchising')}
                  name='highwayFranchising'
                  rounded="md"
                  size="sm"
                  isDisabled
                />
              </FormControl>
            </Stack>
          </Stack>
          <Stack
            spacing="6"
            direction={["column", "column", "row"]}
            mt="3"
          >
            <Stack
              direction="column"
              w="full"
              spacing="0.25"
            >
              <FormControl isInvalid={!!errors.highwayExtraKg}>
                <FormLabel fontSize="sm">
                  KG extra rodoviário
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>
                <Controller
                  name="highwayExtraKg"
                  control={control}
                  render={({ field }) => {
                    return (
                      <InputGroup
                        size="sm"
                      >
                        <InputLeftAddon
                          borderTopLeftRadius="md"
                          borderBottomLeftRadius="md"
                        >
                          R$
                        </InputLeftAddon>
                        <Input
                          {...register("highwayExtraKg")}
                          size="sm"
                          rounded="md"
                          w="full"
                          name={field.name}
                          value={field.value}
                          defaultValue={currencyFormat('000')}
                          onChange={e => field.onChange(onCurrencyChange(e))}
                        />
                      </InputGroup>
                    )
                  }}
                />
              </FormControl>
            </Stack>
            <Stack
              direction="column"
              w="full"
              spacing="0.25"
            >
              <FormControl isInvalid={!!errors.highwayDeadline}>
                <FormLabel fontSize="sm">
                  Prazo rodoviário
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>
                <Select
                  {...register('highwayDeadline')}
                  name="highwayDeadline"
                  placeholder="Selecione..."
                  rounded="md"
                  size='sm'
                >
                  {deadline_delivery.map((deadline) => (
                    <option value={deadline.id} key={deadline.id}>
                      {deadline.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Stack>
        </>
      )}

      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.additionalCollectPrice}>
            <FormLabel fontSize="sm">
              Preço adicional coleta
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="additionalCollectPrice"
              control={control}
              render={({ field }) => {
                return (
                  <InputGroup
                    size="sm"
                  >
                    <InputLeftAddon
                      borderTopLeftRadius="md"
                      borderBottomLeftRadius="md"
                    >
                      R$
                    </InputLeftAddon>
                    <Input
                      {...register("additionalCollectPrice")}
                      size="sm"
                      rounded="md"
                      w="full"
                      name={field.name}
                      value={field.value}
                      defaultValue={currencyFormat('000')}
                      onChange={e => field.onChange(onCurrencyChange(e))}
                    />
                  </InputGroup>
                )
              }}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.additionalDeliveryPrice}>
            <FormLabel fontSize="sm">
              Preço adicional entrega
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="additionalDeliveryPrice"
              control={control}
              render={({ field }) => {
                return (
                  <InputGroup
                    size="sm"
                  >
                    <InputLeftAddon
                      borderTopLeftRadius="md"
                      borderBottomLeftRadius="md"
                    >
                      R$
                    </InputLeftAddon>
                    <Input
                      {...register("additionalDeliveryPrice")}
                      size="sm"
                      rounded="md"
                      w="full"
                      name={field.name}
                      value={field.value}
                      defaultValue={currencyFormat('000')}
                      onChange={e => field.onChange(onCurrencyChange(e))}
                    />
                  </InputGroup>
                )
              }}
            />
          </FormControl>
        </Stack>
      </Stack>
      <FormControl isInvalid={!!errors.observation} mt={3}>
        <FormLabel fontSize="sm">
          Observações

        </FormLabel>
        <Textarea
          {...register('observation')}
          name='observation'
          size="sm"
          rounded="md"
          w="full"
        />
      </FormControl>
    </Box>
  )
}
