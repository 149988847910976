import { Box, Button, Flex, Heading, Spinner, Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { useCity } from "hooks/city/useCity";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useQuery } from "react-query";
import { ConsultPriceForm } from "./components/ConsultPriceForm";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { consultPrice } from "api/prices/consultPrices";
import { useInput } from "hooks/input/useInput";
import { useProviders } from "hooks/provider/useProviders";
import { useAuth } from "hooks/auth/useAuth";
import { ConsultPriceTableRow } from "./components/ConsultPriceTableRow";
import { Pagination } from "components/Pagination/Pagination";
import { useHistory } from "react-router-dom";
import { usePagination } from "@ajna/pagination";
import { useSearchParams } from "hooks/useSearchParams";

export interface IRequestedMaterialsProps {
  material: string
  quantity: number
  price: number
  totalPrice: string
}


export interface ConsultPricesSchema {
  routeType: string
  taxedWeight: number
  hasMaterials: string
  materials: {
    inputId: string
    quantity: number
  }[]
  includeGeloSeco: string
  geloSecoQuantity: number
  vehicle: string
  sourceCitiesIds: {
    sourceUf: string
    sourceCityId: string
  }[]
  destinationCitiesIds: {
    destinationUf: string
    destinationCityId: string
  }[]
  shippingIds: string[]
}



export const consultPriceSchema = yup.object().shape({
  sourceCitiesIds: yup.array().min(1).of(yup.object({
    sourceUf: yup.string().required('Campo Obrigatório'),
    sourceCityId: yup.string().required('Campo Obrigatório')
  })),
  destinationCitiesIds: yup.array().min(1).of(yup.object({
    destinationUf: yup.string().required('Campo Obrigatório'),
    destinationCityId: yup.string().required('Campo Obrigatório')
  })),

  hasMaterials: yup.string().required('Campo Obrigatório'),
  taxedWeight: yup
    .number()
    .typeError('Campo Obrigatório')
    .required('Campo Obrigatório'),
  vehicle: yup.string().required('Campo Obrigatório')
})



export function ConsultPrices() {
  const searchParams = useSearchParams()
  const { userLogged } = useAuth()
  const history = useHistory()

  const isUserLoggedCustomer = userLogged?.user_type === 'CLIENTE' || userLogged?.user_type === 'REMETENTE'

  const formMethods = useForm<ConsultPricesSchema>({
    resolver: yupResolver(consultPriceSchema),
    defaultValues: {
      routeType: 'AMBOS',
      taxedWeight: 0,
      hasMaterials: '',
      materials: [],
      vehicle: '',
      sourceCitiesIds: [{ sourceUf: '', sourceCityId: '' }],
      destinationCitiesIds: [{ destinationUf: '', destinationCityId: '' }]
    }
  })

  const { control, formState: { isSubmitting, isSubmitSuccessful }, handleSubmit } = formMethods

  const [sourceCitiesIds, destinationCitiesIds, shippingIds, vehicle, hasMaterials, geloSecoQuantity, taxedWeight, includeGeloSeco, materialsSelected] = useWatch({
    control,
    name: ['sourceCitiesIds', 'destinationCitiesIds', 'shippingIds', 'vehicle', 'hasMaterials', 'geloSecoQuantity', 'taxedWeight', 'includeGeloSeco', 'materials']
  })

  const hasGeloSeco = includeGeloSeco === 'yes'

  const {
    cities: { data: cities, isLoading: isCitiesLoading },
  } = useCity(null, true)

  const {
    inputs: { data: inputs, isLoading: isInputsLoading }
  } = useInput(null, true, false)

  const { data: providers, isFetching: isFetchingProviders } = useProviders()


  const {
    data: consultPricesResult
  } = useQuery({
    queryKey: [
      'prices',
      sourceCitiesIds,
      destinationCitiesIds,
      shippingIds,
      vehicle
    ],
    enabled: isSubmitSuccessful,
    keepPreviousData: true,
    queryFn: () => consultPrice({
      currentPage: 1,
      pageSize: 10,
      sourceCitiesIds: String(sourceCitiesIds.map(city => city.sourceCityId)),
      destinationCitiesIds: String(destinationCitiesIds.map(city => city.destinationCityId)),
      shippingsIds: Boolean(shippingIds) ? String(shippingIds) : null,
      vehicle: vehicle
    })
  })

  const page = searchParams.get('page')

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    total: consultPricesResult?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: 10,
    }
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    history.replace({ search: searchParams.toString() })
  }

  const isMaterialsSelected = !!materialsSelected.length

  const isConsultWithMaterials = hasMaterials === "yes"

  const materialsToConsultTable = isMaterialsSelected && isConsultWithMaterials
    ? inputs?.reduce((acc, curr) => {
      materialsSelected.forEach(material => {
        if (material.inputId === curr.id) {
          if (curr.name === 'EMBALAGEM SECUNDÁRIA ZIPLOCK') {
            const price = inputs.reduce((totalPrice, input) => {
              if (input.name === 'LACRE' || input.name === 'EMBALAGEM SECUNDÁRIA ZIPLOCK' || input.name === 'ALMOFADA ABSORVENTE') {
                totalPrice += input.unit_price
              }

              return totalPrice
            }, 0)

            acc.push({
              material: curr.name,
              quantity: material.quantity,
              price,
              totalPrice: (material.quantity * price).toFixed(2)
            })
          } else {
            acc.push({
              material: curr.name,
              quantity: material.quantity,
              price: curr.unit_price,
              totalPrice: (material.quantity * curr.unit_price).toFixed(2)
            })
          }
        }
      })
      return acc
    }, [] as Array<IRequestedMaterialsProps>)
    : []

  async function handleConsultPrice() {
    return true
  }


  return (
    <Box
      p="4"
      rounded="md"
      bg="white"
    >
      <Flex justify="space-between">
        <Heading letterSpacing="tight">Consultar Preços</Heading>
      </Flex>

      {(isCitiesLoading || isInputsLoading || isFetchingProviders) ?
        <Spinner />
        : (
          <Box
            as="form"

            onSubmit={handleSubmit(handleConsultPrice)}
          >
            <FormProvider {...formMethods}>
              <ConsultPriceForm
                cities={cities}
                inputs={inputs}
              />
            </FormProvider>
            <Flex
              mt="6"
              w="full"
              justify="flex-end"
            >
              <Button
                type="submit"
                size="sm"
                colorScheme="blue"
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
              >
                Consultar
              </Button>
            </Flex>
          </Box>
        )}

      {consultPricesResult && (
        <TableContainer
          rounded="md"
          mt="6"
        >
          <Table size="sm">
            <Thead>
              <Tr
                h="40px"
                bg="#38c3fa"
              >
                <Th color="white">Cidade Origem</Th>
                <Th color="white">Cidade Destino</Th>
                <Th color="white">Tipo de Material</Th>
                <Th color="white">Preço Total Aéreo (Moto)</Th>
                <Th color="white">Preço Total Aéreo (Carro)</Th>
                <Th color="white">Preço do KG Extra Aéreo</Th>
                <Th color="white">Prazo Aéreo</Th>
                <Th color="white">Preço Total Rodoviário (Moto)</Th>
                <Th color="white">Preço Total Rodoviário (Carro)</Th>
                <Th color="white">Preço do KG Extra Rodoviário</Th>
                <Th color="white">Prazo Rodoviário</Th>
                <Th color="white">Valor das Embalagens/Gelox</Th>
                <Th color="white">Valor do Gelo Seco</Th>
                <Th color="white">Valor Total dos Insumos</Th>
                {materialsToConsultTable?.map((material) => (
                  <Th color="white" key={material.material}>
                    {material.material.toUpperCase()}
                  </Th>
                ))}
                {userLogged?.loglife_employee && !isUserLoggedCustomer && (
                  <>
                    <Th color="white">Budget de transferência Aéreo</Th>
                    <Th color="white">Budget de transferência Rodoviário</Th>
                  </>
                )}
                <Th color="white">Transportadoras</Th>
              </Tr>
            </Thead>
            <Tbody>
              {consultPricesResult && consultPricesResult?.prices?.map((price) => {
                const materialsTotalCost = materialsToConsultTable
                  ? materialsToConsultTable
                    .reduce((acc, curr) => acc += Number(curr.totalPrice), 0)
                  : 0

                const providerBySourceHub = providers?.find(
                  (provider) => provider.hub_id === price.sourceHub.id && provider.is_primary_provider
                )

                const priceGeloSeco = !!providerBySourceHub && hasGeloSeco && geloSecoQuantity > 0
                  ? providerBySourceHub.material_price * geloSecoQuantity
                  : 0

                const materialsTotalPrice = priceGeloSeco + materialsTotalCost


                const airTransferBudgetValue = taxedWeight > 2
                  ? 150 + 25 * (taxedWeight - 2)
                  : 150

                const highwayTransferBudgetValue = taxedWeight > 10
                  ? 100 + 5 * (taxedWeight - 10)
                  : 100
                return (
                  <ConsultPriceTableRow
                    key={price.id}
                    airTransferBudgetValue={airTransferBudgetValue}
                    highwayTransferBudgetValue={highwayTransferBudgetValue}
                    materialsToConsultTable={materialsToConsultTable}
                    materialsTotalCost={materialsTotalCost}
                    materialsTotalPrice={materialsTotalPrice}
                    price={price}
                    priceGeloSeco={priceGeloSeco}
                    taxedWeight={taxedWeight}
                  />

                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}


      <Pagination
        handlePageChange={handleChangePage}
        pagesQuantity={pagesCount}
        pages={pages}
        currentPage={currentPage}
      />

    </Box>
  )

}
