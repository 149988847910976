import { usePagination } from "@ajna/pagination";
import { Box, Button, Flex, Heading, IconButton, Modal, ModalOverlay, Link as ChakraLink, Table, TableContainer, Tbody, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import { getPrices } from "api/prices/getPrices";
import { Price, PriceSituations } from "api/prices/_types/Price";
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton";
import { Pagination } from "components/Pagination/Pagination";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect, useState } from "react";
import { FaFileDownload, FaPlus } from "react-icons/fa";
import { FaFileCsv } from "react-icons/fa6";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";

import { CreatePrice } from "./components/CreatePrice";
import { PricesTableFilters } from "./components/PricesTableFilters";
import { PricesTableRowLoading } from "./components/PricesTableRowLoading";
import { PriceTableRow } from "./components/PriceTableRow";
import { UpdatePricesByCsvModal } from "./components/UpdatePricesByCsvModal";

const priceReportHeaders = [
  { label: 'CIDADE ORIGEM', key: 'sourceCity.name' },
  { label: 'HUB ORIGEM', key: 'sourceHub.name' },
  { label: 'CIDADE DESTINO', key: 'destinationCity.name' },
  { label: 'HUB DESTINO', key: 'destinationHub.name' },
  { label: 'PREÇO MÍNIMO AÉREO', key: 'airMinimumPrice' },
  { label: 'KG EXTRA AÉREO', key: 'airExtraKg' },
  { label: 'PRAZO AÉREO', key: 'airDeadline' },
  { label: 'FRANQUIA AÉREA', key: 'airFranchising' },
  { label: 'PREÇO MÍNIMO RODOVIÁRIO', key: 'highwayMinimumPrice' },
  { label: 'KG EXTRA RODOVIÁRIO', key: 'highwayExtraKg' },
  { label: 'PRAZO RODOVIÁRIO', key: 'highwayDeadline' },
  { label: 'FRANQUIA RODOVIÁRIA', key: 'highwayFranchising' },
  { label: 'PREÇO ADICIONAL COLETA', key: 'additionalCollectPrice' },
  { label: 'PREÇO ADICIONAL ENTREGA', key: 'additionalDeliveryPrice' },
  { label: 'TIPO DE ROTA', key: 'routeType' },
  { label: 'CATEGORIA', key: 'category' },
  { label: 'VEÍCULO', key: 'vehicle' },
  { label: 'TRANSPORTADORA(S)', key: 'shippings' }
];


function formatPriceDataToReport(prices: Price[]) {
  return prices?.map((price) => {
    let airFranchising = '-'
    let highwayFranchising = '-'

    if (price.category === 'Rápido') {
      if (['AÉREO', 'AMBOS'].includes(price.routeType)) {
        airFranchising = '2 KG'
      }

      if (['RODOVIÁRIO', 'AMBOS'].includes(price.routeType)) {
        highwayFranchising = '10 KG'
      }
    }

    if (price.category === 'Econômico') {
      if (['AÉREO', 'AMBOS'].includes(price.routeType)) {
        airFranchising = '10 KG'
      }

      if (['RODOVIÁRIO', 'AMBOS'].includes(price.routeType)) {
        highwayFranchising = '10 KG'
      }
    }

    return {
      ...price,
      source_city_id: price.sourceCity.name,
      destination_city_id: price.destinationCity.name,
      source_hub_id: price.sourceHub?.name,
      destination_hub_id: price.destinationHub?.name,
      highway_extra_kg: price.highwayExtraKg ?? '-',
      highway_deadline: price.highwayDeadline ?? '-',
      highway_minimum_price: price.highwayMinimumPrice ?? '-',
      air_extra_kg: price.airExtraKg ?? '-',
      air_deadline: price.airDeadline ?? '-',
      air_minimum_price: price.airMinimumPrice ?? '-',
      airFranchising: airFranchising,
      highwayFranchising: highwayFranchising,
      shippings: Boolean(price.shippings.length) ? price.shippings?.map((shipping) => shipping.shipping?.trading_name).join(", ") : '-'
    }
  })
}


export function Prices() {
  const [isCsvDownloadEnabled, setIsCsvDownloadEnabled] = useState(false)

  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE

  const { userLogged } = useAuth()

  const userCanViewPrices = userLogged?.permissions.includes('view-price')
  const userCanCreatePrice = userLogged?.permissions?.includes('add-price')
  const userCanEditPrice = userLogged?.permissions.includes('edit-price')

  const searchParams = useSearchParams()
  const history = useHistory()

  const page = searchParams.get('page') ?? '1'
  const situation = searchParams.get('situation')
  const sourceCity = searchParams.get('sourceCity')
  const destinationCity = searchParams.get('destinationCity')

  useEffect(() => {
    if (!userCanViewPrices) {
      history.push('/')
    }
  }, [userCanViewPrices, history])

  const {
    data: pricesResult,
    isLoading: isLoadingPricesResult,
  } = useQuery({
    queryKey: [
      'prices',
      page,
      situation,
      sourceCity,
      destinationCity
    ],
    queryFn: () => getPrices({
      currentPage: Number(page),
      pageSize: 10,
      destinationCity: destinationCity,
      sourceCity: sourceCity,
      situation: situation !== 'all' ? situation as PriceSituations : null
    })
  })

  const {
    data: pricesReportResult,
    isLoading: isLoadingPricesReportResult
  } = useQuery({
    queryKey: [
      'prices-report',
      pricesResult?.meta?.count,
      situation,
      sourceCity,
      destinationCity
    ],
    queryFn: () => getPrices({
      currentPage: 1,
      pageSize: pricesResult?.meta?.count,
      destinationCity: destinationCity,
      sourceCity: sourceCity,
      situation: situation !== 'all' ? situation as PriceSituations : null,
    }),
    onSuccess: () => setIsCsvDownloadEnabled(false),
    enabled: Boolean(pricesResult?.meta?.count) && isCsvDownloadEnabled
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: pricesResult?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: Number(itemLimit),
    }
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    history.replace({ search: searchParams.toString() })
  }

  function handleEnableCsvDownload() {
    setIsCsvDownloadEnabled(true)
  }

  const csvReportProps = {
    headers: priceReportHeaders,
    data: pricesResult ? formatPriceDataToReport(pricesReportResult?.prices) : [],
    filename: 'relatório-precos.csv'
  }

  const {
    isOpen: isUpdatePricesByCsvModalOpen,
    onOpen: onOpenUpdatePricesByCsvModal,
    onClose: onCloseUpdatePricesByCsvModal,
  } = useDisclosure()

  const {
    isOpen: isCreatePriceOpen,
    onToggle: onToggleCreatePrice,
  } = useDisclosure()

  return (
    <Box
      p="4"
      rounded="md"
      bg="white"
    >
      <Flex justify="space-between">
        <Heading letterSpacing="tight">Preços</Heading>
        <Flex width='full' justifyContent='flex-end' gap={3}>
          {userCanEditPrice && (
            <>
              <Modal
                isOpen={isUpdatePricesByCsvModalOpen}
                onClose={onCloseUpdatePricesByCsvModal}
              >
                <ModalOverlay />

                <UpdatePricesByCsvModal />

              </Modal>

              <Button
                onClick={onOpenUpdatePricesByCsvModal}
                leftIcon={<FaPlus />}
                colorScheme="green"
                size="sm"
                w={{ base: 'full', md: 'min' }}
              >
                Atualizar por Csv
              </Button>
            </>
          )}
          {userCanCreatePrice && (
            <Button
              colorScheme='blue'
              as={ChakraLink}
              href="https://transporte-biologico-images.s3.sa-east-1.amazonaws.com/Cria%C3%A7%C3%A3o+de+pre%C3%A7o.pdf"
              isExternal
              size="sm"
              w={{ base: 'full', md: 'min' }}
            >
              POP - Criação de Preço
            </Button>
          )}
          {userCanCreatePrice && (
            <IconButton
              aria-label="Criar preço"
              icon={<FaPlus />}
              colorScheme="blue"
              size="sm"
              onClick={onToggleCreatePrice}

            />
          )}
        </Flex>
        <Modal
          isOpen={isCreatePriceOpen}
          onClose={onToggleCreatePrice}
          isCentered
          size="4xl"
        >
          <ModalOverlay />
          <CreatePrice onClose={onToggleCreatePrice} />
        </Modal>
      </Flex>
      <PricesTableFilters />
      <TableContainer
        rounded="md"
        mt="6"
      >
        <Table size="sm">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th></Th>
              <Th color="white">UF CIDADE ORIGEM</Th>
              <Th color="white">CIDADE ORIGEM</Th>
              <Th color="white">UF CIDADE DESTINO</Th>
              <Th color="white">CIDADE DESTINO</Th>
              <Th color="white">PREÇO MÍNIMO AÉREO</Th>
              <Th color="white">PRAZO AÉREO</Th>
              <Th color="white">EXTRA KG AÉREO</Th>
              <Th color="white">PREÇO MÍNIMO RODOVIÁRIO</Th>
              <Th color="white">PRAZO RODOVIÁRIO</Th>
              <Th color="white">EXTRA KG RODOVIÁRIO</Th>
              <Th color="white">TIPO DE ENVIO</Th>
              <Th color="white">FRANQUIA AÉREA</Th>
              <Th color="white">FRANQUIA RODOVIÁRIO</Th>
              <Th color="white">VEÍCULO</Th>
              <Th color="white">SITUAÇÃO</Th>
              <Th color="white">CRIADO POR</Th>
              <Th color="white">TIPO DE SERVIÇO</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {isLoadingPricesResult ? (
              Array.from({ length: 10 }).map((_, index) => {
                return (
                  <PricesTableRowLoading
                    key={String(index)}
                  />
                )
              })
            ) : (
              pricesResult?.prices?.map((price) => {
                return (
                  <PriceTableRow
                    key={price.id}
                    price={price}
                  />
                )
              })
            )}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex
        w="full"
        justifyContent="flex-end"
        alignItems="baseline"
        gap={3}
      >
        {!pricesReportResult ? (
          <Button
            leftIcon={<FaFileCsv />}
            size="sm"
            colorScheme="green"
            w={{ base: 'full', md: 'min' }}
            onClick={handleEnableCsvDownload}
            mt="4"
            isLoading={isLoadingPricesReportResult}
          >
            Gerar Csv
          </Button>
        ) : (
          <GenerateExcelReportButton
            csvReportProps={csvReportProps}
            leftIcon={<FaFileDownload />}
            size="sm"
            w={{ base: 'full', md: 'min' }}
            buttonTitle='Download csv'
            isLoading={isLoadingPricesReportResult}
          />
        )}


      </Flex>
      <Pagination
        handlePageChange={handleChangePage}
        pagesQuantity={pagesCount}
        pages={pages}
        currentPage={currentPage}
      />
    </Box>
  )
}
