import {
  Flex,
  Box,
  Divider,
  HStack,
  Button,
  Stack,
  Icon,
  Alert,
  AlertIcon,
  Link as ChakraLink,
  useMediaQuery,
  Text,
  FormLabel,
} from '@chakra-ui/react'
import { useEffect, useContext, useState, useRef } from 'react'
import { useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'

import { useFieldArray, useForm, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { FiExternalLink } from 'react-icons/fi'

import { handleChangeUrl } from '../../../utils/handleChangeUrl'
import { negative_positive } from '../../../utils/customLists'
import { format } from 'date-fns'

import {
  DeliveryServiceContext,
  DeliveryServiceProps,
} from '../../../contexts/DeliveryServiceContext'
import { ServiceProps } from '../../../contexts/ServiceContext'

import { GeneralContentLoading } from '../../../components/Loading/GeneralContentLoading'
import { TextArea } from '../../../components/Inputs/TextInput'
import { Select } from '../../../components/Inputs/SelectInput'
import { Input } from '../../../components/Inputs/Input'



import { useService } from '../../../hooks/services/service'
import { useDriver } from '../../../hooks/driver/useDriver'
import { FaFile, FaList, FaPlus, FaSave, FaTimes } from 'react-icons/fa'
import { LandingServiceProps } from '../../../services/getFunctions/getLandingServiceFunctions'
import { useDeliveryServiceFunctions } from '../../../hooks/services/delivery/useDeliveryServiceFunctions'
import { useSwal } from '../../../hooks/swal/useSwal'
import { useAuth } from '../../../hooks/auth/useAuth'
import { useAddress } from 'hooks/address/useAddress'
import { Address } from 'hooks/address/dtos/Address'
import { validateHasFile } from 'utils/imageValidation'
import { toast } from 'react-toastify'
import { useGeolocation } from 'hooks/useGeolocation'

const supportedFileFormats = [
  "image/jpeg",
  "image/pjpeg",
  "image/jpg",
  "image/png",
  "text/plain",
  "application/pdf",
]

interface ContentDeclarationAttachments {
  attachment: FileList
}

interface FormInputProps {
  address_id: string
  service_id: string
  driver_id: string
  destination_addresses_input: number
  arrival_latitude: string
  arrival_longitude: string
  arrival_timestamp: string
  responsible_name: string | null
  landing_volume: number
  current_delivery_volume: number
  delivery_volume: number | null
  box_photo: any | null
  content_declaration_attachments: ContentDeclarationAttachments[] | null
  departure_latitude: string | null
  departure_longitude: string | null
  departure_timestamp: string | null
  unsuccess_latitude: string | null
  unsuccess_longitude: string | null
  box_photo_change: string | null
  content_photo_change: string | null
  unsuccess_times: string | null
  observation: string | null
}

interface DriverProps {
  id: string
  situation: string
  collector_id: string
  collectorIDDriver: {
    company_name: string
  }
  firstname: string
  lastname: string
  cpf: string
  email: string
  observation: string
}

interface QueryParams {
  service_id: string
  address_id: string
  slug: string
}

interface DriverFormProps {
  slug: string
  id?: string
  isDisabled?: boolean
  href: string
  title: string
  action: string
}

const deliverySchema = yup.object().shape({
  driver_id: yup.string().required('Motorista inválido'),
  delivery_volume: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.'),
})

const deliveryCurrentSchema = yup.object().shape({
  driver_id: yup.string().required('Motorista inválido'),
  responsible_name: yup.string().required('Campo Obrigatório!'),
  delivery_volume: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.'),
  content_declaration_attachments: yup.array().of(yup.object({
    attachment: yup.mixed().test('hasFile', 'Campo obrigatório.', value => validateHasFile(value))
  })).required('Campo Obrigatório'),
  content_photo_change: yup.string().required('Campo Obrigatório!'),
  observation: yup.string(),
})

async function alertGeolocationError(error: GeolocationPositionError) {
  if (error.code === 1) {
    toast.error("Para iniciar/finalizar a entrega, é necessário que a localização esteja habilitada!")
  }
  if (error.code === 2) {
    toast.error("Localização não está disponível! Tente recarregar a página ou verifique as configurações do dispositivo.")
  }
}

export function DeliveryBusinessServiceAddressSelected({
  id,
  isDisabled = false,
  href,
  title,
  action,
}: DriverFormProps) {
  const [isWideVersion] = useMediaQuery('(min-width: 1280px)')

  const { service_id, address_id, slug }: QueryParams = useParams()

  const [sourceAddresses, setSourceAddresses] = useState<Address[]>([])
  const [addressCollected, setAddressCollected] = useState<string[]>([])
  const [sourceDrivers, setSourceDrivers] = useState<DriverProps[]>([])
  const [stepOfService, setStepOfService] = useState('')

  const [selectedFileBox, setSelectedFileBox] = useState<any>()

  const [serviceFilteredById, setServiceFilteredById] = useState<
    ServiceProps[]
  >([])
  const [deliveryServiceFilteredById, setDeliveryServiceFilteredById] =
    useState<DeliveryServiceProps[]>([])
  const [landingServiceFilteredById, setLandingServiceFilteredById] = useState<
    LandingServiceProps[]
  >([])

  const [isLoadingPage, setIsLoadingPage] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const [previewBox, setPreviewBox] = useState<string>()

  const { deleteDeliveryService } = useContext(DeliveryServiceContext)
  const { confirmMessage, standardMessage } = useSwal()
  const { userLogged } = useAuth()
  const { position, error: geolocationError } = useGeolocation()

  const {
    createDeliveryService: { mutateAsync: createDeliveryService },
    finishDeliveryService: { mutateAsync: finishDeliveryService },
    updateDeliveryService: { mutateAsync: updateDeliveryService },
  } = useDeliveryServiceFunctions()

  const {
    service: { data: serviceById, isLoading: isServiceLoading },
  } = useService(service_id, false, false)

  const { data: address, isLoading: isAddressLoading } = useAddress(address_id)

  const {
    drivers: { data: drivers, isLoading: isDriverLoading },
  } = useDriver(null, true, false)

  useEffect(() => {
    function run() {
      if (serviceById !== undefined) {
        const serviceFiltered = []
        serviceFiltered.push(serviceById)
        setServiceFilteredById(serviceFiltered)

        if (serviceById.serviceIDDelivery !== null) {
          setDeliveryServiceFilteredById(serviceById.serviceIDDelivery)
        }
        if (serviceById.serviceIDLanding !== null) {
          setLandingServiceFilteredById(serviceById.serviceIDLanding)
        }
      }
    }
    run()
  }, [serviceById])

  useEffect(() => {
    if (address) {
      const sourceAddress = []
      sourceAddress.push(address)
      setSourceAddresses(sourceAddress)
    }
  }, [address])

  const history = useHistory()

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors, isSubmitting },
  } = useForm<FormInputProps>({
    resolver: !addressCollected.includes(address_id)
      ? yupResolver(deliverySchema)
      : yupResolver(deliveryCurrentSchema),
  })

  const serviceType = serviceFilteredById
    .map((service) => service.serviceIDRequestedBusiness.service_type)
    .toString()
  const isDriverSelected = watch('driver_id')
  const changeContentPhoto = watch('content_photo_change')

  useEffect(() => {
    function run() {
      let landingVolume = 0
      landingServiceFilteredById.forEach((landingService) => {
        if (
          landingService.landing_volume !== null &&
          serviceType !== 'DEDICADO'
        ) {
          landingVolume += landingService.landing_volume
        } else {
          setValue('landing_volume', 0)
        }
      })
      setValue('landing_volume', landingVolume)
    }
    run()
  }, [setValue, serviceType, landingServiceFilteredById])

  useEffect(() => {
    if (!isServiceLoading && !isAddressLoading && !isDriverLoading) {
      setIsLoadingPage(false)
    }
  }, [isServiceLoading, isAddressLoading, isDriverLoading])

  useEffect(() => {
    function run() {
      const permissions = userLogged?.permissions
      try {
        if (permissions !== undefined) {
          if (!permissions?.includes('add-delivery-business-service')) {
            history.push('/')
          }
        }
      } catch {
        history.push('/')
      }
    }
    run()
  }, [slug, history, userLogged])

  useEffect(() => {
    if (!selectedFileBox) {
      setPreviewBox(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(selectedFileBox)
    setPreviewBox(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFileBox])

  console.log(errors)

  useEffect(() => {
    function run() {
      const addressId = deliveryServiceFilteredById.map(
        (service) => service.address_id,
      )
      setAddressCollected(addressId)

      if (addressId.includes(address_id) && !slug && !!drivers) {
        const filteredDeliveryServiceByAddressId =
          deliveryServiceFilteredById.filter(
            (service) => service.address_id === address_id,
          )
        filteredDeliveryServiceByAddressId.map((deliveryService) => {
          setValue('address_id', address_id)
          setValue('service_id', service_id)
          if (sourceDrivers.length > 0) {
            setValue('driver_id', deliveryService.driver_id)
          }
          setValue(
            'departure_timestamp',
            format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
          )
          if (geolocationError) {
            return alertGeolocationError(geolocationError)
          }
          if (position) {
            setValue('departure_latitude', position?.coords.latitude.toString())
            setValue(
              'departure_longitude',
              position?.coords.longitude.toString(),
            )
          }

          return (
            setValue('arrival_latitude', deliveryService.arrival_latitude),
            setValue('arrival_longitude', deliveryService.arrival_longitude),
            setValue('arrival_timestamp', deliveryService.arrival_timestamp)
          )
        })
      }

      if (!addressId.includes(address_id) && !slug) {
        setValue(
          'arrival_timestamp',
          format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
        )
        setValue('address_id', address_id)
        setValue('service_id', service_id)

        if (geolocationError) {
          return alertGeolocationError(geolocationError)
        }

        if (position) {
          setValue('arrival_latitude', position?.coords.latitude.toString())
          setValue('arrival_longitude', position?.coords.longitude.toString())
        }

      }
    }

    run()
  }, [
    setValue,
    drivers,
    slug,
    service_id,
    deliveryServiceFilteredById,
    sourceDrivers,
    address_id,
    geolocationError,
    position
  ])

  useEffect(() => {
    function run() {
      if (drivers) {
        const stepOfServiceSelected = serviceFilteredById
          .map((service) => service.step)
          .toString()
        const destinationCollectorId = serviceFilteredById
          .map(
            (service) =>
              service.serviceIDRequestedBusiness.destination_collector_id,
          )
          .toString()

        if (userLogged !== null) {
          if (userLogged.user_type === 'MOTORISTA') {
            const driverSelected = drivers
              .filter(
                (driver) => driver.collector_id === destinationCollectorId,
              )
              .filter((driver) => driver.id === userLogged.driver_id)
            setSourceDrivers(driverSelected)
          } else {
            const driverSelected = drivers.filter(
              (driver) => driver.collector_id === destinationCollectorId,
            )
            setSourceDrivers(driverSelected)
          }
        }
        setStepOfService(stepOfServiceSelected)
      }
    }

    run()
  }, [setValue, serviceFilteredById, userLogged, drivers])

  useEffect(() => {
    function run() {
      if (slug) {
        deliveryServiceFilteredById
          .filter((delService) => delService.address_id === address_id)
          .map((delService) => {
            return (
              setValue('address_id', delService.address_id),
              setValue('service_id', delService.service_id),
              setValue('driver_id', delService.driver_id),
              setValue('arrival_latitude', delService.arrival_latitude),
              setValue('arrival_longitude', delService.arrival_longitude),
              setValue('arrival_timestamp', delService.arrival_timestamp),
              setValue('responsible_name', delService.responsible_name),
              setValue('delivery_volume', delService.delivery_volume),
              setValue('departure_latitude', delService.departure_latitude),
              setValue('departure_longitude', delService.departure_longitude),
              setValue('departure_timestamp', delService.departure_timestamp),
              setValue('observation', delService.observation)
            )
          })
      } else {
        deliveryServiceFilteredById
          .filter((delService) => delService.address_id === address_id)
          .map((delService) => {
            return setValue('delivery_volume', delService.delivery_volume)
          })
        setValue('content_photo_change', ' ')
      }
    }

    run()
  }, [setValue, slug, deliveryServiceFilteredById, address_id])

  async function handleCreateDeliveryBusinessService(values: FormInputProps) {
    if (geolocationError) {
      return alertGeolocationError(geolocationError)
    }
    const hasCreateDeliveryService = await confirmMessage({
      title: 'Deseja iniciar uma entrega',
    })

    const deliveryServiceVolumes =
      serviceById?.serviceIDDelivery !== null &&
        serviceById?.serviceIDDelivery.length! > 0
        ? serviceById?.serviceIDDelivery
          .map((delivery) => delivery.delivery_volume)
          .reduce((a, b) => a! + b!)! + Number(values.delivery_volume)
        : 0 + Number(values.delivery_volume)

    if (serviceType === 'FRACIONADO') {
      if (Number(deliveryServiceVolumes) > Number(values.landing_volume)) {
        return standardMessage(
          'A somatória dos volumes de entrega excedem o volume desembarcado!',
        )
      }
    }

    if (hasCreateDeliveryService) {
      setIsLoading(true)
      try {
        const createDeliveryServiceResponse = await createDeliveryService({
          ...values,
          is_business: true,
        })
        if (createDeliveryServiceResponse) {
          setIsLoading(false)
        }
      } catch {
        setIsLoading(false)
      }
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  const handleFinishDeliveryService = async (values: FormInputProps) => {
    if (geolocationError) {
      return alertGeolocationError(geolocationError)
    }
    const hasFinishDeliveryService = await confirmMessage({
      title: 'Deseja finalizar a entrega?',
    })

    const formData = new FormData()
    formData.append('address_id', values.address_id)
    formData.append('service_id', values.service_id)
    formData.append('driver_id', values.driver_id)
    formData.append('arrival_latitude', values.arrival_latitude)
    formData.append('arrival_longitude', values.arrival_longitude)
    formData.append('arrival_timestamp', values.arrival_timestamp)
    formData.append('responsible_name', values.responsible_name as any)
    formData.append('delivery_volume', values.delivery_volume as any)
    formData.append('departure_latitude', values.departure_latitude as any)
    formData.append('departure_longitude', values.departure_longitude as any)
    formData.append('departure_timestamp', values.departure_timestamp as any)
    formData.append('observation', values.observation as any)

    if (values.content_declaration_attachments) {
      values.content_declaration_attachments.forEach((contentDeclaration) => {
        formData.append('content_declaration_attachments', contentDeclaration.attachment[0])
      })
    }

    const serviceIdFiltered = deliveryServiceFilteredById
      .filter((deliveryService) => deliveryService.address_id === address_id)
      .map((deliveryService) => deliveryService.service_id)

    if (hasFinishDeliveryService) {
      setIsLoading(true)
      try {
        if (serviceIdFiltered.length > 0) {
          if (serviceIdFiltered.length > 1) {
            const deliveryServiceById = deliveryServiceFilteredById
              .filter(
                (deliveryService) => deliveryService.address_id === address_id,
              )
              .map((deliveryService) => deliveryService.id)
            const isAllDuplicatedServicesRemoved = await Promise.all(
              deliveryServiceById.map(async (id, index) => {
                if (index > 0) {
                  deleteDeliveryService(id)
                }
              }),
            )
            if (isAllDuplicatedServicesRemoved) {
              const finishDeliveryServiceReqObj = {
                serviceID: serviceIdFiltered[0],
                finishDeliveryServiceInput: formData as any,
              }
              const finishDeliveryServiceResponse = await finishDeliveryService(
                { ...finishDeliveryServiceReqObj },
              )
              if (finishDeliveryServiceResponse && userLogged) {
                setIsLoading(true)
                history.push(
                  `/servico-business/entrega/enderecos/${service_id}/`,
                )
              }
            }
            return
          }
        }
        const finishDeliveryServiceReqObj = {
          serviceID: service_id,
          finishDeliveryServiceInput: formData as any,
        }
        const finishDeliveryServiceResponse = await finishDeliveryService({
          ...finishDeliveryServiceReqObj,
        })
        if (finishDeliveryServiceResponse && userLogged) {
          history.push(`/servico-business/entrega/enderecos/${service_id}/`)
        }
      } catch {
        setIsLoading(false)
      }
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  const handleUpdateDeliveryService = async (values: FormInputProps) => {
    const formData = new FormData()
    formData.append('address_id', values.address_id)
    formData.append('service_id', values.service_id)
    formData.append('driver_id', values.driver_id)
    formData.append('responsible_name', values.responsible_name as any)
    formData.append('delivery_volume', values.delivery_volume as any)
    formData.append('box_photo', values.box_photo as any)
    values.content_declaration_attachments.forEach((contentDeclaration) => {
      formData.append('content_declaration_attachments', contentDeclaration.attachment[0])
    })

    formData.append('observation', values.observation as any)

    if (values.box_photo_change === 'SIM') {
      formData.append('boxPhotoChoose', 'SIM')
    } else {
      setValue('box_photo', {})
    }
    if (values.content_photo_change === 'SIM') {
      formData.append('contentDeclarationChoose', 'SIM')
    } else {
      setValue('content_declaration_attachments', [])
    }

    const hasUpdateDeliveryService = await confirmMessage({
      title: 'Deseja atualizar uma entrega?',
    })
    const deliveryServiceReqObj = {
      serviceID: service_id,
      updateDeliveryServiceInput: formData as any,
    }

    if (hasUpdateDeliveryService) {
      setIsLoading(true)
      try {
        const updateDeliveryServiceResponse = await updateDeliveryService({
          ...deliveryServiceReqObj,
        })
        if (updateDeliveryServiceResponse) {
          history.push(
            `/servico-business/entregas-finalizadas/editar/${service_id}/`,
          )
        }
      } catch {
        setIsLoading(false)
      }
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  const {
    fields: contentDeclarationAttachmentsFields,
    append: appendContentDeclarationAttachmentField,
    remove: removeContentDeclarationAttachmentField
  } = useFieldArray({
    control,
    name: 'content_declaration_attachments'
  })

  function handleRemoveContentDeclarationAttachmentField(index: number) {
    removeContentDeclarationAttachmentField(index)
  }

  function handleAppendContentDeclarationAttachmentField() {
    appendContentDeclarationAttachmentField({ attachment: undefined })
  }

  const contentDeclarationAttachments = useWatch({
    control,
    name: 'content_declaration_attachments'
  })

  const inputRefBox = useRef<HTMLInputElement | null>(null)
  const inputRefContent = useRef<HTMLInputElement | null>(null)
  const handleInputRefBox = () => inputRefBox.current?.click()
  const handleInputRefContent = () => inputRefContent.current?.click()

  if (isLoading || isLoadingPage) {
    return <GeneralContentLoading />
  }

  if (!addressCollected.includes(address_id)) {
    return (

      <Flex>
        <Box
          as="form"
          flex="1"
          borderRadius="8px"
          bg="white"
          p={['6', '8']}
          onSubmit={handleSubmit(handleCreateDeliveryBusinessService)}
          noValidate
        >
          <Divider my="6" borderColor="gray.700" />

          <Stack
            spacing="24px"
            mt="4"
            direction={['column', 'column', 'column']}
          >
            {sourceAddresses.map((address) => (
              <Input
                key={address.id}
                defaultValue={`${address.trading_name} | ${address.branch} | ${address.street} | ${address.number} | ${address.neighborhood} | ${address.cityIDAddress.name} | ${address.cep}`.toUpperCase()}
                isDisabled={true}
                name="firstname"
                label="Endereço Destinatário"
              />
            ))}

            <Select
              {...register('driver_id')}
              name="driver_id"
              label="Pertence ao Motorista"
              error={errors.driver_id}
              drivers={sourceDrivers}
              isDisabled={isDisabled}
              placeholder="Selecione uma opção..."
              required
            />

            <Input
              {...register('current_delivery_volume')}
              name="current_delivery_volume"
              label="Volumes Entregues e/ou em Entrega"
              defaultValue={
                deliveryServiceFilteredById.length > 0
                  ? deliveryServiceFilteredById
                    .map((delivery) => delivery.delivery_volume)
                    .reduce((a, b) => a! + b!)!
                  : 0
              }
              isDisabled={true}
            />

            <Stack
              w="full"
              spacing="24px"
              direction={['column', 'column', 'row']}
            >
              {serviceType !== 'DEDICADO' && (
                <Input
                  {...register('landing_volume')}
                  name="landing_volume"
                  label="Volume Desembarcado"
                  isDisabled={true}
                />
              )}

              <Input
                {...register('delivery_volume')}
                name="delivery_volume"
                label="Volume"
                error={errors.delivery_volume}
                isDisabled={isDisabled}
                required
              />
            </Stack>

            {isDriverSelected && (
              <Button
                type="submit"
                colorScheme="blue"
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
              >
                Iniciar Entrega
              </Button>
            )}
            <Divider my="6" borderColor="gray.700" />
          </Stack>
          <Flex mt="8" justify="flex-end">
            <HStack>
              <Link to="/servicos-business/entregas">
                <Button type="button" colorScheme="gray">
                  Serviços Business à Entregar
                </Button>
              </Link>
            </HStack>
          </Flex>
        </Box>
      </Flex>

    )
  } else {
    return (

      <Flex>
        <Box
          as="form"
          flex="1"
          borderRadius="8px"
          bg="white"
          p={['6', '8']}
          onSubmit={handleSubmit(
            slug ? handleUpdateDeliveryService : handleFinishDeliveryService,
          )}
          noValidate
        >
          <Alert status="info">
            <AlertIcon />
            {slug ? 'Atualizar Entrega' : 'Entrega Iniciada'}
          </Alert>

          <Divider my="6" borderColor="gray.700" />

          <Stack
            spacing="24px"
            mt="4"
            direction={['column', 'column', 'row']}
          >
            {sourceAddresses.map((address) => (
              <Input
                key={address.id}
                defaultValue={`${address.trading_name} | ${address.branch} | ${address.street} | ${address.number} | ${address.neighborhood} | ${address.cityIDAddress.name} | ${address.cep}`.toUpperCase()}
                isDisabled={true}
                name="firstname"
                label="Endereço Destinatário"
              />
            ))}

            <Select
              {...register('driver_id')}
              name="driver_id"
              label="Pertence ao Motorista"
              error={errors.driver_id}
              drivers={sourceDrivers}
              isDisabled={
                stepOfService === 'deliveringService' || !!slug
                  ? isDisabled
                  : true
              }
              placeholder="Selecione uma opção..."
              required
            />
          </Stack>
          <Stack
            spacing="24px"
            mt="4"
            direction={['column', 'column', 'row']}
          >
            <Input
              {...register('responsible_name')}
              name="responsible_name"
              label="Nome do Responsável"
              error={errors.responsible_name}
              isDisabled={isDisabled}
              required
            />
          </Stack>

          <Stack
            spacing="24px"
            mt="4"
            direction={['column', 'column', 'row']}
          >
            <Stack
              w="100%"
              spacing="24px"
              direction={['column', 'column', 'row']}
            >
              {serviceType !== 'DEDICADO' && (
                <Input
                  {...register('landing_volume')}
                  name="landing_volume"
                  label="Volume Desembarcado"
                  isDisabled={true}
                />
              )}
              <Input
                {...register('delivery_volume')}
                name="delivery_volume"
                label="Volume"
                error={errors.delivery_volume}
                isDisabled={!slug === true}
                required
              />
            </Stack>
          </Stack>

          {!!slug && (
            <Stack
              spacing="24px"
              mt="4"
              direction={['column', 'column', 'row']}
            >
              <Select
                {...register('box_photo_change')}
                negative_positive={negative_positive}
                placeholder="Selecione uma opção..."
                name="box_photo_change"
                label="Alterar Foto da Caixa?"
                error={errors.box_photo_change}
                isDisabled={isDisabled}
                required
              />

              <Select
                {...register('content_photo_change')}
                negative_positive={negative_positive}
                placeholder="Selecione uma opção..."
                name="content_photo_change"
                label="Alterar Foto do Conteúdo?"
                error={errors.content_photo_change}
                isDisabled={isDisabled}
                required
              />
            </Stack>
          )}

          {slug ? (
            <>
              {deliveryServiceFilteredById
                .filter((delService) => delService.address_id === address_id)
                .map((delService) => (
                  <Stack
                    key={delService.id}
                    spacing="24px"
                    mt="4"
                    direction={['column', 'column', 'row']}
                  >
                    <Input name="link_box" hidden>
                      <ChakraLink
                        fontSize="lg"
                        href={handleChangeUrl(delService.box_photo as any)}
                        isExternal
                      >
                        Foto da caixa <Icon as={FiExternalLink} mx="2px" />
                      </ChakraLink>
                    </Input>

                    {delService.content_declaration_attachments !== null && (
                      delService.content_declaration_attachments.map((contentDeclaration) => {
                        return (
                          <Input name="link_content" hidden>
                            <ChakraLink
                              fontSize="lg"
                              href={handleChangeUrl(contentDeclaration.key)}
                              isExternal
                            >
                              {contentDeclaration.filename}{' '}
                              <Icon as={FiExternalLink} mx="2px" />
                            </ChakraLink>
                          </Input>
                        )
                      })
                    )}
                  </Stack>
                ))}
              <Stack
                spacing="24px"
                mt="4"
                direction={['column', 'column', 'row']}
              >
                {changeContentPhoto === 'SIM' && (
                  <>
                    {contentDeclarationAttachmentsFields.map((field, index) => {
                      return (
                        <Stack key={field.id} w='full' spacing="24px" mt="4" direction={['column', 'column', 'row']}>
                          <Input
                            {...register(`content_declaration_attachments.${index}.attachment`)}
                            name={`content_declaration_attachments.${index}.attachment`}
                            label="Declaração de Conteúdo"
                            type="file"
                            accept={supportedFileFormats.join(", ")}
                            error={errors.content_declaration_attachments ? errors?.content_declaration_attachments[index]?.attachment : undefined}
                            hidden
                            required
                          >
                            <Stack
                              w="full"
                              spacing="24px"
                              mt="4"
                              justifyContent='space-between'
                              direction={['column', 'column', 'row']}
                            >
                              <Button
                                as={FormLabel}
                                htmlFor={`content_declaration_attachments.${index}.attachment`}
                                _hover={{
                                  cursor: 'pointer'
                                }}
                                mr="2"
                                leftIcon={<Icon as={FaFile} />}
                              >
                                Upload
                              </Button>
                              {contentDeclarationAttachments && contentDeclarationAttachments[index]?.attachment?.length > 0 && (
                                <ChakraLink
                                  href={URL.createObjectURL(contentDeclarationAttachments[index].attachment[0])}
                                >
                                  {contentDeclarationAttachments[index].attachment[0].name} (Visualizar)
                                </ChakraLink>
                              )}
                              <Button
                                leftIcon={<Icon as={FaTimes} />}
                                variant="ghost"
                                onClick={() => handleRemoveContentDeclarationAttachmentField(index)}
                                h="48px"
                              >
                                Remover
                              </Button>
                            </Stack>
                          </Input>
                        </Stack>
                      )
                    })}

                    <Stack
                      w="full"
                      mt="4"
                    >
                      <Button
                        _hover={{
                          cursor: 'pointer'
                        }}
                        mr="2"
                        leftIcon={<Icon as={FaPlus} />}
                        onClick={handleAppendContentDeclarationAttachmentField}
                      >
                        Declaração de Conteúdo
                      </Button>
                      <Text
                        fontSize="sm"
                        color="red.500"
                      >
                        {errors?.content_declaration_attachments?.message}
                      </Text>
                    </Stack>
                  </>
                )}
              </Stack>
            </>
          ) : (
            <>
              {contentDeclarationAttachmentsFields.map((field, index) => {
                return (
                  <Stack key={field.id} w='full' spacing="24px" mt="4" direction={['column', 'column', 'row']}>
                    <Input
                      {...register(`content_declaration_attachments.${index}.attachment`)}
                      name={`content_declaration_attachments.${index}.attachment`}
                      label="Declaração de Conteúdo"
                      type="file"
                      accept={supportedFileFormats.join(", ")}
                      error={errors.content_declaration_attachments ? errors?.content_declaration_attachments[index]?.attachment : undefined}
                      hidden
                      required
                    >
                      <Stack
                        w="full"
                        spacing="24px"
                        mt="4"
                        justifyContent='space-between'
                        direction={['column', 'column', 'row']}
                      >
                        <Button
                          as={FormLabel}
                          htmlFor={`content_declaration_attachments.${index}.attachment`}
                          _hover={{
                            cursor: 'pointer'
                          }}
                          mr="2"
                          leftIcon={<Icon as={FaFile} />}
                        >
                          Upload
                        </Button>
                        {contentDeclarationAttachments && contentDeclarationAttachments[index]?.attachment?.length > 0 && (
                          <ChakraLink
                            href={URL.createObjectURL(contentDeclarationAttachments[index].attachment[0])}
                          >
                            {contentDeclarationAttachments[index].attachment[0].name} (Visualizar)
                          </ChakraLink>
                        )}
                        <Button
                          leftIcon={<Icon as={FaTimes} />}
                          variant="ghost"
                          onClick={() => handleRemoveContentDeclarationAttachmentField(index)}
                          h="48px"
                        >
                          Remover
                        </Button>
                      </Stack>
                    </Input>
                  </Stack>
                )
              })}

              <Stack
                w="full"
                mt="4"
              >
                <Button
                  _hover={{
                    cursor: 'pointer'
                  }}
                  mr="2"
                  leftIcon={<Icon as={FaPlus} />}
                  onClick={handleAppendContentDeclarationAttachmentField}
                >
                  Declaração de Conteúdo
                </Button>
                <Text
                  fontSize="sm"
                  color="red.500"
                >
                  {errors?.content_declaration_attachments?.message}
                </Text>
              </Stack>
            </>
          )}

          <TextArea
            {...register('observation')}
            name="observation"
            label="Observações"
            isDisabled={isDisabled}
          />

          <Flex w="full" mt="8" justify="flex-end">
            <Stack spacing="12px" direction="row">
              <Button
                type="submit"
                colorScheme="blue"
                isLoading={isSubmitting}
                loadingText={isWideVersion ? 'Carregando' : ' '}
              >
                {!isWideVersion ? (
                  <Icon as={FaSave} />
                ) : slug ? (
                  'Atualizar entrega'
                ) : (
                  'Finalizar entrega'
                )}
              </Button>

              <Link to="/servicos-business/entregas">
                <Button type="button" colorScheme="gray">
                  {isWideVersion ? (
                    'Serviços Business à Entregar'
                  ) : (
                    <Icon as={FaList} />
                  )}
                </Button>
              </Link>
            </Stack>
          </Flex>
        </Box>
      </Flex>

    )
  }
}
