import { Box, Divider, Grid, GridItem, ModalBody, ModalCloseButton, ModalContent, ModalHeader } from "@chakra-ui/react"
import { getSpecialBudgetCosts } from "api/budgets/getSpecialBudgetCosts"
import { useAuth } from "hooks/auth/useAuth"
import { useQuery } from "react-query"
import { CreateSpecialBudgetCost } from "./CreateSpecialBudgetCost"
import { SpecialBudgetCostsTable } from "./SpecialBudgetCostsTable"

interface SpecialBudgetCostsProps {
  budgetId: string
}

export function SpecialBudgetCosts({ budgetId }: SpecialBudgetCostsProps) {

  const { userLogged } = useAuth()

  const doesUserCanEditSpecialBudgetCost = userLogged?.permissions.includes('edit-special-budget-cost')
  const doesUserCanViewSpecialBudgetCost = userLogged?.permissions.includes('view-special-budget-cost')
  const doesUserCanCreateSpecialBudgetCost = userLogged?.permissions.includes('create-special-budget-cost')

  const {
    data: specialBudgetCostsResult
  } = useQuery({
    queryKey: ['special-budget-costs'],
    queryFn: () => getSpecialBudgetCosts({ budgetId }),
    refetchOnWindowFocus: false
  })


  return (
    <ModalContent>
      <ModalHeader>
        Custos Especiais
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Grid
          templateColumns={
            doesUserCanCreateSpecialBudgetCost &&
              (doesUserCanViewSpecialBudgetCost || doesUserCanEditSpecialBudgetCost) && Boolean(specialBudgetCostsResult?.budgetSpecialCosts?.length)
              ? "1fr auto 1fr" : "1fr"}
          gap={6}
        >
          {(doesUserCanViewSpecialBudgetCost || doesUserCanEditSpecialBudgetCost) && Boolean(specialBudgetCostsResult?.budgetSpecialCosts?.length) && (
            <GridItem>
              <SpecialBudgetCostsTable
                budgetId={budgetId}
                specialBudgetCostsResult={specialBudgetCostsResult}
              />
            </GridItem>
          )}
          {doesUserCanCreateSpecialBudgetCost && (
            <>
              <Box>
                <Divider orientation="vertical" />
              </Box>
              <GridItem>
                <CreateSpecialBudgetCost
                  budgetId={budgetId}
                />
              </GridItem>
            </>

          )}
        </Grid>
      </ModalBody>
    </ModalContent>
  )
}
