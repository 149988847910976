import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface DeleteExtraAndDiscountParams {
  extraAndDiscountId: string
}
export async function deleteExtraAndDiscount({
  extraAndDiscountId,
}: DeleteExtraAndDiscountParams) {
  try {
    await api.delete(`/extras-and-discounts/${extraAndDiscountId}`)

  } catch (error) {
    console.log("error", error)
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}
