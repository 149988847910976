import { Button, Flex, Icon, IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { Interactions } from "components/Interactions/Interactions";
import { differenceInMinutes, format, isBefore, isSameDay, set } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { Occurrence } from "hooks/occurrences/dtos/Occurrence";
import { Services } from "hooks/services/dtos/InBoardService";
import { interactionStatusColors } from "pages/Dashboard/Llm/components/DelayedCollects";
import { FaClock, FaExclamation, FaEye, FaPen, FaPeopleArrows } from "react-icons/fa";
import { Link } from "react-router-dom";
import { formatDate } from "utils/DateFunctions/formatDate";

interface InBoardServiceTableRowProps {
  service: Services
  handleConfirmDestinationBranch(service: Services, destinationBranch: string): Promise<void>
}

function compareBoardDateWithDateNow(
  boardDate: string,
  boardHour: string
) {
  const [collectEndHours, collectEndMinutes] = formatDate
    .handle(boardHour, 'DateOnlyWithHourMinute').split(':')


  const differenceToNow = differenceInMinutes(
    new Date(),
    set(new Date(boardDate), {
      hours: Number(collectEndHours),
      minutes: Number(collectEndMinutes)
    })
  )

  return differenceToNow > 0
}

export function InBoardServiceTableRow({ service, handleConfirmDestinationBranch }: InBoardServiceTableRowProps) {
  const { userLogged } = useAuth()
  const isLLMUser = userLogged.loglife_employee
  const { isOpen, onOpen, onClose } = useDisclosure();
  const userHasStartBoardServicePermission =
    userLogged?.permissions.includes('add-boarding-service')


  const collectDelayIntercurrences = [
    'ATRASO NA COLETA',
    'NO SHOW'
  ]

  const serviceCollectDelayOccurrence =
    service.occurrenceIDService
      ?.filter(occurrence => collectDelayIntercurrences.includes(occurrence.intercurrence))
      .reduce((acc, curr) => {
        return new Date(acc.createdAt) > new Date(curr.createdAt) ? acc : curr
      }, {} as Occurrence)

  const collectDate = serviceCollectDelayOccurrence?.occurrence_date
    ? formatDate.handle(serviceCollectDelayOccurrence.occurrence_date, "DateWithoutHourToShow")
    : formatDate.handle(service.serviceIDRequested.collect_date, "DateWithoutHourToShow")

  const deliveryDelayOccurrences = [
    'ATRASO NA ENTREGA',
    'CANCELAMENTO DE VOO',
    'CORTE DE VOO (NÃO ALOCADO VOO PLANEJADO)',
    'ATRASO NA TRANSFERÊNCIA',
    'ATRASO NA LIBERAÇÃO'
  ]

  const serviceDeliveryDelayOccurrence =
    service.occurrenceIDService
      .filter(occurrence => deliveryDelayOccurrences.includes(occurrence.intercurrence))
      .reduce((acc, curr) => {
        return new Date(acc.createdAt) > new Date(curr.createdAt) ? acc : curr
      }, {} as Occurrence)

  const deliveryDate = serviceDeliveryDelayOccurrence?.occurrence_date
    ? formatDate.handle(serviceDeliveryDelayOccurrence.occurrence_date, "DateWithoutHourToShow")
    : formatDate.handle(service.serviceIDRequested.delivery_date, "DateWithoutHourToShow")

  let boardDate = ''
  let boardHour = ''
  let shipping = ''
  let sourceBranch = ''
  let destinationBranch = ''
  let sourceCollector = ''

  const serviceHasCrossdocking = service.serviceIDRequested.crossdocking_collector_id !== null

  let isServiceDelayed = false

  if (serviceHasCrossdocking) {
    const crossdockingBoardAlreadyFinished = service.serviceIDBoard.find(board => {
      return board.step === 'DONE' && board.collector_id === service.serviceIDRequested.crossdocking_collector_id
    })

    const isBoardDateBeforeToToday = isBefore(
      new Date(service.serviceIDRequested.board_date),
      new Date()
    )

    const isBoardDateSameToToday = isSameDay(
      new Date(service.serviceIDRequested.board_date),
      new Date()
    )

    if (!isBoardDateSameToToday && isBoardDateBeforeToToday) {
      isServiceDelayed = true
    } else if (isBoardDateSameToToday) {
      const [boardHours, boardMinutes] = format(new Date(service.serviceIDRequested.board_hour), "HH:mm").split(':').map(Number)

      console.log(differenceInMinutes(
        set(new Date(), {
          hours: boardHours,
          minutes: boardMinutes
        }), new Date()
      ))

      isServiceDelayed = differenceInMinutes(
        set(new Date(), {
          hours: boardHours,
          minutes: boardMinutes
        }), new Date()
      ) < 240
    }

    if (crossdockingBoardAlreadyFinished) {
      boardDate = service.serviceIDRequested.board_date
      boardHour = service.serviceIDRequested.board_hour

      shipping = service.shippings.find(ship => {
        return ship.id === service.serviceIDRequested.sourceCrossdockingBranchIDService.shipping_id
      })?.company_name
      sourceBranch = service.serviceIDRequested.sourceCrossdockingBranchIDService.nickname
      destinationBranch = service.serviceIDRequested.destinationBranchIDService.nickname
      sourceCollector = service.serviceIDRequested.crossdockingCollectorIDService.trading_name
    } else {
      boardDate = service.serviceIDRequested.crossdocking_board_date
      boardHour = service.serviceIDRequested.crossdocking_board_hour
      shipping = service.shippings.find(ship => {
        return ship.id === service.serviceIDRequested.sourceBranchIDService.shipping_id
      })?.company_name
      sourceBranch = service.serviceIDRequested.sourceBranchIDService.nickname
      destinationBranch = service.serviceIDRequested.destinationCrossdockingBranchIDService.nickname
      sourceCollector = service.serviceIDRequested.sourceCollectorIDService.trading_name
    }
  } else {
    boardDate = service.serviceIDRequested.board_date
    boardHour = service.serviceIDRequested.board_hour
    shipping = service.shippings.find(ship => {
      return ship.id === service.serviceIDRequested.sourceBranchIDService.shipping_id
    })?.company_name
    sourceBranch = service.serviceIDRequested.sourceBranchIDService.nickname
    destinationBranch = service.serviceIDRequested.destinationBranchIDService.nickname
    sourceCollector = service.serviceIDRequested.sourceCollectorIDService.trading_name
  }

  let tableLineColor = ''

  const isCurrentDateBiggerThanBoardDate =
    compareBoardDateWithDateNow(boardDate, boardHour)

  if (
    serviceCollectDelayOccurrence.id ||
    serviceDeliveryDelayOccurrence.id
  ) {
    tableLineColor = 'yellow.300'
  } else if (isCurrentDateBiggerThanBoardDate) {
    tableLineColor = 'red.300'
  }
  return (
    <Tr
      color={tableLineColor}
    >
      <Td>
        <Flex align='baseline' gap={2}>
          {service?.serviceIDRequested?.budgetIDService?.priority_budget && (
            <Icon as={FaExclamation} color={'red.500'} />
          )}
          {isServiceDelayed && (
            <Icon as={FaClock} color={'red.500'} />
          )}
          {isLLMUser && (
            <Flex h="full" justify="flex-end" align="flex-end">
              <IconButton
                aria-label="Abrir Interações"
                icon={<FaPeopleArrows />}
                colorScheme={interactionStatusColors[service.last_interaction_status] || interactionStatusColors.default}
                size="sm"
                onClick={onOpen}
                variant='ghost'

              />
              <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />

                <Interactions isOpen={isOpen} serviceId={service.id} />
              </Modal>

            </Flex>

          )}
        </Flex>
      </Td>
      <Td>
        <Flex>
          {userHasStartBoardServicePermission && (
            <Button
              onClick={() => handleConfirmDestinationBranch(service, destinationBranch)}
              variant='unstyled'
            >
              <Flex w='full' h='full' align='center' justify='center'>
                <Icon as={FaPen} />
              </Flex>
            </Button>
          )}
          <Button
            as={Link}
            to={`/servico/detalhes/${service.id}`}
            variant='unstyled'
          >
            <Flex w='full' h='full' align='center' justify='center'>
              <Icon as={FaEye} />
            </Flex>
          </Button>
        </Flex>
      </Td>
      <Td>{service.protocol}</Td>
      <Td>{collectDate}</Td>
      <Td>{formatDate.handle(boardDate, 'DateWithoutHourToShow')}</Td>
      <Td>{formatDate.handle(boardHour, 'DateOnlyWithHourMinute')}</Td>
      <Td>{shipping}</Td>
      <Td>{sourceBranch}</Td>
      <Td>{destinationBranch}</Td>
      <Td>{service.customerIDService.trading_firstname}</Td>
      <Td>{Array.from(new Set(service.source_cities.map(city => city.name))).join(', ')}</Td>
      <Td>{Array.from(new Set(service.destination_cities.map(city => city.name))).join(', ')}</Td>
      <Td>{deliveryDate}</Td>
      <Td>{sourceCollector}</Td>
    </Tr>
  )
}
