import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Price, PriceSituations } from "./_types/Price";


interface GetPricesParams {
  pageSize: number
  currentPage: number
  situation?: PriceSituations | null
  sourceCity?: string | null
  destinationCity?: string | null
}

export interface GetPricesResponse {
  prices: Price[]
  meta: {
    currentPage: number
    pageSize: number
    totalPages: number
    count: number
  }
}

export async function getPrices({
  pageSize,
  currentPage,
  situation,
  sourceCity,
  destinationCity
}: GetPricesParams) {
  try {
    const { data } = await api.get<GetPricesResponse>('/prices', {
      params: {
        pageSize,
        currentPage,
        situation,
        sourceCity,
        destinationCity
      }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

