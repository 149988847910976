import { TableContainer, Table, Thead, Tr, Th, Tbody } from "@chakra-ui/react";
import { GetDelayedCollectsResponse } from "api/service/getDelayedCollects";


import { DelayedCollectTableRow } from "./DelayedCollectTableRow";

interface DelayedCollectsTableProps {
  delayedCollectsResult: GetDelayedCollectsResponse
}

export function DelayedCollectsTable({ delayedCollectsResult }: DelayedCollectsTableProps) {
  return (
    <TableContainer
      rounded="md"
      mt="6"
    >
      <Table size="sm">
        <Thead>
          <Tr
            h="40px"
            bg="#38c3fa"
          >
            <Th></Th>
            <Th color="white">Protocolo</Th>
            <Th color="white">Cliente</Th>
            <Th color="white">Origem</Th>
            <Th color="white">Destino</Th>
            <Th color="white">Status</Th>
            <Th color="white" isNumeric>Atraso</Th>
          </Tr>
        </Thead>
        <Tbody>
          {delayedCollectsResult?.services?.map((service) => {
            return (
              <DelayedCollectTableRow
                key={service.id}
                service={service}
              />
            )
          })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
